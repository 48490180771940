import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";
let OrmProductBarcode = class OrmProductBarcode extends OrmInformationRegister {
    constructor() {
        super();
    }
    get product() {
        return this._product;
    }
    set product(v) {
        this._product = v;
    }
    get feature() {
        if (!this._feature) {
            this._feature = new OrmProductFeature();
        }
        return this._feature;
    }
    set feature(v) {
        let oldValue = this._feature;
        this._feature = v;
        super.notifyFieldChange('feature', oldValue, v);
    }
    get unit() {
        return this._unit;
    }
    set unit(v) {
        this._unit = v;
    }
    get barcode() {
        return this._barcode;
    }
    set barcode(v) {
        this._barcode = v;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Товар',
        type: 'relation',
        relationModel: 'OrmProduct',
        relationType: 'belongsTo',
        required: false,
        standart: false
    })
], OrmProductBarcode.prototype, "_product", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Характеристика',
        type: 'relation',
        relationModel: 'OrmProductFeature',
        relationType: 'belongsTo',
        required: false,
        standart: false
    })
], OrmProductBarcode.prototype, "_feature", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationModel: 'OrmProductUnit',
        relationType: 'belongsTo',
        required: false,
        standart: false
    })
], OrmProductBarcode.prototype, "_unit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Штрихкод',
        type: 'string',
        required: true,
        standart: false
    })
], OrmProductBarcode.prototype, "_barcode", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProduct)
], OrmProductBarcode.prototype, "product", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductFeature)
], OrmProductBarcode.prototype, "feature", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProduct)
], OrmProductBarcode.prototype, "unit", null);
tslib_1.__decorate([
    Expose()
], OrmProductBarcode.prototype, "barcode", null);
OrmProductBarcode = tslib_1.__decorate([
    NatModel({
        plural: 'productBarcodes',
        displayName: 'Штрихкод',
        pluralDisplayName: 'Штрихкоды'
    })
], OrmProductBarcode);
export { OrmProductBarcode };
