import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatRestRepository } from "@natiwi/core/network/repositories/rest.repository";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { Observable } from "rxjs";
import { OrmMetadata } from "@natiwi/core/models/metadata";
import { classToPlain, plainToClass, Expose } from "class-transformer";
import { map } from "rxjs/internal/operators/map";
import { OrmContainer } from "@natiwi/shared/models/container.model";
import { Type } from "@angular/core";
import { OrmFileMetadata } from "@natiwi/shared/models/file-metadata";

export class NatContainerRepository extends NatRestRepository<OrmContainer> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/containers';
        let repoModel: Type<OrmContainer> = OrmContainer;
        super.init(repoUrl, repoModel);
        super.createСontractItem('upload', '/:container/upload', NatVerbType.POST);
    }

    public upload<T extends OrmMetadata>(container: string, metadata: NatUploadFileMetadata): Observable<T> {
        let stringlifyData: string = JSON.stringify(classToPlain(metadata, { excludePrefixes: ["_"] }));
        let params: Map<string, string> = new Map();
        params.set('container', container);
        let request: Observable<T> = this.invokeStaticMethod<T>('upload', params, stringlifyData);
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as T;;
        }));
    }
}

export class NatUploadFileMetadata {
    private _containerType: string;
    private _containerId: string;
    constructor(private name: string, private ownerId: string, private ownerType: string, private file: string, private filename: string) { }

    @Expose()
    public get containerType(): string {
        return this._containerType;
    }

    public set containerType(v: string) {
        this._containerType = v;
    }
    @Expose()
    public get containerId(): string {
        return this._containerId;
    }

    public set containerId(v: string) {
        this._containerId = v;
    }
}
