import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NatLoginComponent } from '@natiwi/auth/login';
import { NatRestorePasswordComponent } from '@natiwi/auth/restore-password';
import { NatAuthGuard } from '@natiwi/auth/auth.guard';

const loginRoutes: Routes = [
  {
    path: 'login',
    component: NatLoginComponent,
    canActivate: [NatAuthGuard]
  },
  {
    path: 'login/restore',
    component: NatRestorePasswordComponent,
    canActivate: [NatAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(loginRoutes)],
  exports: [RouterModule]
})
export class NatAuthRoutingModule { }
