import "reflect-metadata";
import { Type, Expose } from "class-transformer";
import { setModelToGlobalStore } from "@natiwi/core/network/shared/global-variables";

export function NatProperty(propertyDecription: { [key: string]: any }) {
    return (target: any, propertyName: string) => {
        let propName = propertyName.replace(/(^_)/, '');
        propertyDecription.propertyName = propName;
        if (!propertyDecription || !target || !propName) {
            console.error('Модель, имя свойства и метаданные являются обязательными');
            return;
        }

        Reflect.defineMetadata(`natiwi:metadata:property:${propName}`, propertyDecription, target);
    }
}

export function NatModel(modelDecription: { [key: string]: any }) {
    return (target: any) => {
        modelDecription.modelName = target.prototype.constructor.name;
        if (!modelDecription || !target) {
            console.error('Модель и метаданные являются обязательными');
            return;
        }
        setModelToGlobalStore(modelDecription.modelName, target);
        Reflect.defineMetadata(`natiwi:metadata:model`, modelDecription, target);
    }
}

export function NatPolymorphicRelation(relationModelFieldName: string, acceptedModelList?: Array<any>) {
    return (target: any, key: string) => {

        let propertyDescription: Map<string, string> = new Map();
        propertyDescription.set(key, relationModelFieldName);

        Reflect.defineMetadata(`natiwi:mixins:polymorphicRelation:${relationModelFieldName}`, propertyDescription, target);

        // if (!acceptedModelList) {
        //     acceptedModelList = [];
        // }

        // if (!acceptedModelList.length) {
        //     modelStore.forEach((value, key) => {
        //         acceptedModelList.push(value);
        //     });
        // }
        // Type(() => null, {
        //     discriminator: {
        //         property: relationModelFieldName,
        //         subTypes: acceptedModelList.map(value => {
        //             let item = {
        //                 value: value,
        //                 name: value.prototype.constructor.name
        //             }
        //             return item;
        //         })
        //     }
        // })(target, key)
    }
}

export function NatPolymorphicProperty(propertyTypeFieldName: string) {
    return (target: any, key: string) => {

        let propertyDescription: Map<string, string> = new Map();
        propertyDescription.set(key, propertyTypeFieldName);

        Reflect.defineMetadata(`natiwi:mixins:polymorphicProperty:${propertyTypeFieldName}`, propertyDescription, target);
    }
}

export function NatRelation(realtionModelType) {
    return (target: any, key: string) => {
        if (realtionModelType.constructor) {
            Type(() => realtionModelType)(target, key)
        } else {
            throw 'Ошибка описания связи модели: неверный тип модели'
        }
    }
}

export function NatHierarchical(enabled: boolean) {
    return (target: any) => {
        if (!enabled) {
            Reflect.defineMetadata(`natiwi:metadata:property:parent`, undefined, target.prototype);
        }
    }
}

