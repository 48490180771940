import * as i0 from "@angular/core";
import * as i1 from "../core/services/auth.service";
import * as i2 from "@angular/router";
export class NatAuthGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(next, state) {
        let url = next._routerState.url;
        return this.checkAccess(url);
    }
    canActivateChild(next, state) {
        let url = next._routerState.url;
        return this.checkAccess(url);
    }
    canLoad(route, segments) {
        return true; //this.checkAccess(route.path);
    }
    checkAccess(currentUrl) {
        if (currentUrl && currentUrl === '/login') {
            if (this.authService.isAuthorized()) {
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }
        else {
            if (this.authService.isAuthorized()) {
                return true;
            }
            else {
                this.authService.redirectUrl = currentUrl || '/';
                this.router.navigate(['/login']);
                return false;
            }
        }
    }
}
NatAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NatAuthGuard_Factory() { return new NatAuthGuard(i0.ɵɵinject(i1.NatAuthService), i0.ɵɵinject(i2.Router)); }, token: NatAuthGuard, providedIn: "root" });
