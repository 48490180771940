import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose, Type } from "class-transformer";
import { OrmMetadata } from "@natiwi/core/models/metadata";

@NatModel({
    plural: 'containersMetadata',
    displayName: 'Контейнер файлов',
    pluralDisplayName: 'Контейнеры файлов'
})
export class OrmContainerMetadata extends OrmMetadata {
    
    @NatProperty({
        displayName: 'Тип содержимого',
        type: 'string',
        required: true,
        standart: false
    })
    private _contentType: string;
    
    constructor() {
        super();
    }
    
    @Expose()
    @Type(() => OrmContainerMetadata)
    public get contentType(): string {
        return this._contentType;
    }

    public set contentType(v: string) {
        this._contentType = v;
    }
}