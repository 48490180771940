import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    plural: 'priceTypes',
    displayName: 'Тип цены',
    pluralDisplayName: 'Типы цен'
})
@NatHierarchical(false)
export class OrmPriceType extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Способ задания цены',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'pricing_options',
        required: true,
        standart: false
    })
    private _pricingOption: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Базовый тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: false,
        standart: false
    })
    private _basePriceType: OrmPriceType;
    
    @NatProperty({
        displayName: 'Процент скидки/наценки',
        type: 'number',
        required: false,
        standart: false
    })
    private _discountMarkupPercent: number;
    
    @NatProperty({
        displayName: 'Округлять',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _roundOff: boolean;
    
    @NatProperty({
        displayName: 'Точность округления',
        type: 'number',
        required: false,
        standart: false
    })
    private _roundingAccuracy: number;
    
    @NatProperty({
        displayName: 'Психологическое округление',
        type: 'number',
        required: false,
        standart: false
    })
    private _roundingPsychological: number;
    
    @NatProperty({
        displayName: 'Вариант округления',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'rounding_options',
        required: false,
        standart: false
    })
    private _roundingOption: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Использовать цену при продаже клиентам',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _useWhenSellingToCustomers: boolean;
    
    @NatProperty({
        displayName: 'Использовать цену при вводе на основании',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _useWhenMakeBasedOn: boolean;
    
    @NatProperty({
        displayName: 'Статус',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'price_type_statuses',
        required: false,
        standart: false
    })
    private _status: OrmEnumerationItem;

    constructor() {
        super();
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get pricingOption(): OrmEnumerationItem {
        return this._pricingOption;
    }

    public set pricingOption(v: OrmEnumerationItem) {
        let oldValue = this._pricingOption;
        this._pricingOption = v;
        super.notifyFieldChange('pricingOption', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmPriceType)
    public get basePriceType(): OrmPriceType {
        return this._basePriceType;
    }

    public set basePriceType(v: OrmPriceType) {
        let oldValue = this._basePriceType;
        this._basePriceType = v;
        super.notifyFieldChange('basePriceType', oldValue, v);
    }

    @Expose()
    public get discountMarkupPercent(): number {
        return this._discountMarkupPercent;
    }

    public set discountMarkupPercent(v: number) {
        let oldValue = this._discountMarkupPercent;
        this._discountMarkupPercent = v;
        super.notifyFieldChange('discountMarkupPercent', oldValue, v);
    }
    
    @Expose()
    public get roundOff(): boolean {
        return this._roundOff;
    }

    public set roundOff(v: boolean) {
        let oldValue = this._roundOff;
        this._roundOff = v;
        super.notifyFieldChange('roundOff', oldValue, v);
    }
    
    @Expose()
    public get roundingAccuracy(): number {
        return this._roundingAccuracy;
    }

    public set roundingAccuracy(v: number) {
        let oldValue = this._roundingAccuracy;
        this._roundingAccuracy = v;
        super.notifyFieldChange('roundingAccuracy', oldValue, v);
    }
    
    @Expose()
    public get roundingPsychological(): number {
        return this._roundingPsychological;
    }

    public set roundingPsychological(v: number) {
        let oldValue = this._roundingPsychological;
        this._roundingPsychological = v;
        super.notifyFieldChange('roundingPsychological', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get roundingOption(): OrmEnumerationItem {
        return this._roundingOption;
    }

    public set roundingOption(v: OrmEnumerationItem) {
        let oldValue = this._roundingOption;
        this._roundingOption = v;
        super.notifyFieldChange('roundingOption', oldValue, v);
    }
    
    @Expose()
    public get useWhenSellingToCustomers(): boolean {
        return this._useWhenSellingToCustomers;
    }

    public set useWhenSellingToCustomers(v: boolean) {
        let oldValue = this._useWhenSellingToCustomers;
        this._useWhenSellingToCustomers = v;
        super.notifyFieldChange('useWhenSellingToCustomers', oldValue, v);
    }
    
    @Expose()
    public get useWhenMakeBasedOn(): boolean {
        return this._useWhenMakeBasedOn;
    }

    public set useWhenMakeBasedOn(v: boolean) {
        let oldValue = this._useWhenMakeBasedOn;
        this._useWhenMakeBasedOn = v;
        super.notifyFieldChange('useWhenMakeBasedOn', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get status(): OrmEnumerationItem {
        return this._status;
    }

    public set status(v: OrmEnumerationItem) {
        let oldValue = this._status;
        this._status = v;
        super.notifyFieldChange('status', oldValue, v);
    }
    
}