/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-right-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i12 from "@angular/common";
import * as i13 from "./header-right-menu.component";
import * as i14 from "../../../../core/services/auth.service";
var styles_NatHeaderRightMenuComponent = [i0.styles];
var RenderType_NatHeaderRightMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NatHeaderRightMenuComponent, data: {} });
export { RenderType_NatHeaderRightMenuComponent as RenderType_NatHeaderRightMenuComponent };
export function View_NatHeaderRightMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "button", [["aria-haspopup", "true"], ["class", "mat-menu-trigger"], ["mat-icon-button", ""]], [[1, "aria-expanded", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 1196032, null, 0, i3.MatMenuTrigger, [i4.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i5.Directionality], i6.FocusMonitor], { menu: [0, "menu"] }, null), i1.ɵdid(2, 180224, null, 0, i7.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["more_vert"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "mat-menu", [], null, null, null, i11.View__MatMenu_0, i11.RenderType__MatMenu)), i1.ɵdid(7, 1294336, [["menu", 4]], 3, i3._MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 1, { _allItems: 1 }), i1.ɵqud(603979776, 2, { items: 1 }), i1.ɵqud(603979776, 3, { lazyContent: 0 }), i1.ɵprd(2048, null, i3.MatMenu, null, [i3._MatMenu]), i1.ɵprd(2048, null, i3.MAT_MENU_PANEL, null, [i3.MatMenu]), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.toggleFullScreen() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_MatMenuItem_0, i11.RenderType_MatMenuItem)), i1.ɵdid(14, 180224, [[1, 4], [2, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i12.DOCUMENT, i6.FocusMonitor, [2, i3.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(15, 0, ["", " "])), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.logout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_MatMenuItem_0, i11.RenderType_MatMenuItem)), i1.ɵdid(17, 180224, [[1, 4], [2, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i12.DOCUMENT, i6.FocusMonitor, [2, i3.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵted(-1, 0, ["\u0412\u044B\u0445\u043E\u0434"]))], function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 1, 0, currVal_3); _ck(_v, 4, 0); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).menuOpen || null); var currVal_1 = (i1.ɵnov(_v, 2).disabled || null); var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 4).inline; var currVal_5 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 14).role; var currVal_7 = i1.ɵnov(_v, 14)._highlighted; var currVal_8 = i1.ɵnov(_v, 14)._triggersSubmenu; var currVal_9 = i1.ɵnov(_v, 14)._getTabIndex(); var currVal_10 = i1.ɵnov(_v, 14).disabled.toString(); var currVal_11 = (i1.ɵnov(_v, 14).disabled || null); _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = (_co.isFullScreen ? "\u0421\u0442\u0430\u043D\u0434\u0430\u0440\u0442\u043D\u044B\u0439 \u0432\u0438\u0434" : "\u041D\u0430 \u0432\u0435\u0441\u044C \u044D\u043A\u0440\u0430\u043D"); _ck(_v, 15, 0, currVal_12); var currVal_13 = i1.ɵnov(_v, 17).role; var currVal_14 = i1.ɵnov(_v, 17)._highlighted; var currVal_15 = i1.ɵnov(_v, 17)._triggersSubmenu; var currVal_16 = i1.ɵnov(_v, 17)._getTabIndex(); var currVal_17 = i1.ɵnov(_v, 17).disabled.toString(); var currVal_18 = (i1.ɵnov(_v, 17).disabled || null); _ck(_v, 16, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }); }
export function View_NatHeaderRightMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nat-header-right-menu", [], null, null, null, View_NatHeaderRightMenuComponent_0, RenderType_NatHeaderRightMenuComponent)), i1.ɵdid(1, 114688, null, 0, i13.NatHeaderRightMenuComponent, [i14.NatAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NatHeaderRightMenuComponentNgFactory = i1.ɵccf("nat-header-right-menu", i13.NatHeaderRightMenuComponent, View_NatHeaderRightMenuComponent_Host_0, {}, {}, []);
export { NatHeaderRightMenuComponentNgFactory as NatHeaderRightMenuComponentNgFactory };
