import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NatHeaderComponent } from '@natiwi/shared/layout/header/header.component';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NatTabsModule } from '@natiwi/shared/tabs';
import { NatSearchBarModule } from '@natiwi/shared/search-bar';
import { NatMainMenuModule, NatHeaderRightMenuModule } from '@natiwi/shared';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [NatHeaderComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatToolbarModule,
    NatMainMenuModule,
    NatHeaderRightMenuModule,    
    NatTabsModule,
    NatSearchBarModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [NatHeaderComponent]
})
export class NatHeaderModule { }
