import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { Expose, Type } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPartnerContactSet } from "@natiwi/shared/models/partner-contact-set.model";
import { OrmPartnerContract } from "@natiwi/shared/models/partner-contract.model";

@NatModel({
    plural: 'partners',
    displayName: 'Партнер',
    pluralDisplayName: 'Партнеры'
})
export class OrmPartner extends OrmCatalog {

    @NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: false,
        standart: false
    })
    private _firstName: string;

    @NatProperty({
        displayName: 'Отчество',
        type: 'string',
        required: false,
        standart: false
    })
    private _middleName: string;

    @NatProperty({
        displayName: 'Фамилия',
        type: 'string',
        required: false,
        standart: false
    })
    private _lastName: string;

    @NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: false,
        standart: false
    })
    private _priceType: OrmPriceType;

    @NatProperty({
        displayName: 'Тип регистрации',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'partner_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Тип владения',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'partner_ownership_types',
        required: false,
        standart: false
    })
    private _ownershipType: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Пол',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'gender',
        required: false,
        standart: false
    })
    private _gender: OrmEnumerationItem;

    // @NatProperty({
    //     displayName: 'Набор контактов',
    //     type: 'relation',
    //     relationType: 'belongsTo',
    //     relationModel: 'OrmPartnerContactSet',
    //     required: false,
    //     standart: false
    // })
    // private _conctactSet: OrmPartnerContactSet;

    @NatProperty({
        displayName: 'Основной договор',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPartnerContract',
        required: false,
        standart: false
    })
    private _contract: OrmPartnerContract;

    @NatProperty({
        displayName: 'Клиент',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _client: boolean;

    @NatProperty({
        displayName: 'Поставщик',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _supplier: boolean;

    @NatProperty({
        displayName: 'Прочие отношения',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _otherRelations: boolean;

    @NatProperty({
        displayName: 'Дата рождения',
        type: 'date',
        required: false,
        standart: false
    })
    private _birthdate: Date;

    @NatProperty({
        displayName: 'ИНН',
        type: 'string',
        required: false,
        standart: false
    })
    private _itn: string;

    @NatProperty({
        displayName: 'КПП',
        type: 'string',
        required: false,
        standart: false
    })
    private _iec: string;

    // "bankAccountId": {
    //     "displayName": "Идентификатор банковского счета",
    //     "type": "string",
    //     "default": null
    // },

    constructor() {
        super();
    }

    @Expose()
    public get firstName(): string {
        return this._firstName;
    }

    public set firstName(v: string) {
        let oldValue = this._firstName;
        this._firstName = v;
        super.notifyFieldChange('firstName', oldValue, v);
    }

    @Expose()
    public get middleName(): string {
        return this._middleName;
    }

    public set middleName(v: string) {
        let oldValue = this._middleName;
        this._middleName = v;
        super.notifyFieldChange('middleName', oldValue, v);
    }

    @Expose()
    public get lastName(): string {
        return this._lastName;
    }

    public set lastName(v: string) {
        let oldValue = this._lastName;
        this._lastName = v;
        super.notifyFieldChange('lastName', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmPriceType)
    public get priceType(): OrmPriceType {
        return this._priceType;
    }

    public set priceType(v: OrmPriceType) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get ownershipType(): OrmEnumerationItem {
        return this._ownershipType;
    }

    public set ownershipType(v: OrmEnumerationItem) {
        let oldValue = this._ownershipType;
        this._ownershipType = v;
        super.notifyFieldChange('ownershipType', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get gender(): OrmEnumerationItem {
        return this._gender;
    }

    public set gender(v: OrmEnumerationItem) {
        let oldValue = this._gender;
        this._gender = v;
        super.notifyFieldChange('gender', oldValue, v);
    }

    // @Expose()
    // @NatRelation(OrmPartnerContactSet)
    // public get conctactSet(): OrmPartnerContactSet {
    //     return this._conctactSet;
    // }

    // public set conctactSet(v: OrmPartnerContactSet) {
    //     let oldValue = this._conctactSet;
    //     this._conctactSet = v;
    //     super.notifyFieldChange('conctactSet', oldValue, v);
    // }

    @Expose()
    @NatRelation(OrmPartnerContract)
    public get contract(): OrmPartnerContract {
        return this._contract;
    }

    public set contract(v: OrmPartnerContract) {
        let oldValue = this._contract;
        this._contract = v;
        super.notifyFieldChange('contract', oldValue, v);
    }

    @Expose()
    public get client(): boolean {
        return this._client;
    }

    public set client(v: boolean) {
        let oldValue = this._client;
        this._client = v;
        super.notifyFieldChange('client', oldValue, v);
    }

    @Expose()
    public get supplier(): boolean {
        return this._supplier;
    }

    public set supplier(v: boolean) {
        let oldValue = this._supplier;
        this._supplier = v;
        super.notifyFieldChange('supplier', oldValue, v);
    }

    @Expose()
    public get otherRelations(): boolean {
        return this._otherRelations;
    }

    public set otherRelations(v: boolean) {
        let oldValue = this._otherRelations;
        this._otherRelations = v;
        super.notifyFieldChange('otherRelations', oldValue, v);
    }

    @Expose()
    @Type(() => Date)
    public get birthdate(): Date {
        return this._birthdate;
    }

    public set birthdate(v: Date) {
        let oldValue = this._birthdate;
        this._birthdate = v;
        super.notifyFieldChange('birthdate', oldValue, v);
    }

    @Expose()
    public get itn(): string {
        return this._itn;
    }

    public set itn(v: string) {
        let oldValue = this._itn;
        this._itn = v;
        super.notifyFieldChange('itn', oldValue, v);
    }

    @Expose()
    public get iec(): string {
        return this._iec;
    }

    public set iec(v: string) {
        let oldValue = this._iec;
        this._iec = v;
        super.notifyFieldChange('iec', oldValue, v);
    }
}