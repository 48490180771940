import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { Injectable, Injector } from "@angular/core";
import { NatAuthInterceptor } from "@natiwi/core/services/auth-interceptor.service";
import { GlobalInjector } from "@natiwi/core/network/shared/global-variables";

export class NatRestAdapter {

    private http: HttpClient;
    constructor(private _baseUrl: string) {
        this.http = GlobalInjector.get(HttpClient);
    }

    public execute<K>(verbType: NatVerbType = NatVerbType.GET, resource: string = '', bodyParams?: any): Observable<K> {
        let verbTypeInLowCase = verbType.toLowerCase();
        let url = `${this._baseUrl}${resource}`;
        //переделать
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        if (!!this.http[verbTypeInLowCase] && this.http[verbTypeInLowCase] instanceof Function) {
            let request: Observable<K> = this.http[verbTypeInLowCase](url, bodyParams || httpOptions, bodyParams ? httpOptions : undefined);
            return request;
        }
        return throwError(`Данный тип запроса (${verbType}) не поддерживается`)
    }
}
