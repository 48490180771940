import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmReport } from "@natiwi/shared/models/report.model";
import { OrmSamplingSchemePresetItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-item.model";

@NatModel({
    plural: 'reportPresets',
    displayName: 'Вариант отчета',
    pluralDisplayName: 'Варианты отчетов'
})
@NatHierarchical(false)
export class OrmReportPreset extends OrmCatalog {

    @NatProperty({
        displayName: 'Отчет',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmReport',
        required: true,
        standart: false
    })
    private _report: OrmReport;

    @NatProperty({
        displayName: 'Вариант схемы выборки данных',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemePresetItem',
        required: false,
        standart: false
    })
    private _samplingSchemePreset: OrmSamplingSchemePresetItem;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmReport)
    public get report(): OrmReport {
        return this._report;
    }

    public set report(v: OrmReport) {
        let oldValue = this._report;
        this._report = v;
        super.notifyFieldChange('report', oldValue, v);
    }
    
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetItem)
    public get samplingSchemePreset(): OrmSamplingSchemePresetItem {
        return this._samplingSchemePreset;
    }

    public set samplingSchemePreset(v: OrmSamplingSchemePresetItem) {
        let oldValue = this._samplingSchemePreset;
        this._samplingSchemePreset = v;
        super.notifyFieldChange('samplingSchemePreset', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetItem)
    public get samplingSchemePresetValue(): OrmSamplingSchemePresetItem {
        return this._samplingSchemePreset;
    }
    
    // public set samplingSchemePresetValue(v: OrmSamplingSchemePresetItem) {
    //     let oldValue = this._samplingSchemePreset;
    //     this._samplingSchemePreset = v;
    //     super.notifyFieldChange('samplingSchemePreset', oldValue, v);
    // }

}