import { NatModelController } from "@natiwi/core/controllers/model.controller";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { NatEnumerationItemRepository } from "@natiwi/shared/repositories/enumeration-item.repository";

export class NatEnumerationItemController extends NatModelController<OrmEnumerationItem>{

    constructor(_repository?: NatEnumerationItemRepository) {
        super(_repository);
        super.registerDataClass(OrmEnumerationItem);
    }
}