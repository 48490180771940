import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";

@NatModel({
    plural: 'productBarcodes',
    displayName: 'Штрихкод',
    pluralDisplayName: 'Штрихкоды'
})
export class OrmProductBarcode extends OrmInformationRegister {

    @NatProperty({
        displayName: 'Товар',
        type: 'relation',
        relationModel: 'OrmProduct',
        relationType: 'belongsTo',
        required: false,
        standart: false
    })
    private _product: OrmProduct;

    @NatProperty({
        displayName: 'Характеристика',
        type: 'relation',
        relationModel: 'OrmProductFeature',
        relationType: 'belongsTo',
        required: false,
        standart: false
    })
    private _feature: OrmProductFeature;

    @NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationModel: 'OrmProductUnit',
        relationType: 'belongsTo',
        required: false,
        standart: false
    })
    private _unit: OrmProductUnit;

    @NatProperty({
        displayName: 'Штрихкод',
        type: 'string',
        required: true,
        standart: false
    })
    private _barcode: string;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmProduct)
    public get product(): OrmProduct {
        return this._product;
    }

    public set product(v: OrmProduct) {
        this._product = v;
    }

    @Expose()
    @NatRelation(OrmProductFeature)
    public get feature(): OrmProductFeature {
        if (!this._feature) {
            this._feature = new OrmProductFeature();
        }
        return this._feature;
    }

    public set feature(v: OrmProductFeature) {
        let oldValue = this._feature;
        this._feature = v;
        super.notifyFieldChange('feature', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmProduct)
    public get unit(): OrmProductUnit {
        return this._unit;
    }

    public set unit(v: OrmProductUnit) {
        this._unit = v;
    }

    @Expose()
    public get barcode(): string {
        return this._barcode;
    }

    public set barcode(v: string) {
        this._barcode = v;
    }

}