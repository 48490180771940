import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";

@NatModel({
    displayName: 'Элемент группировки',
    pluralDisplayName: 'Элементы группировкии'
})
@NatHierarchical(false)
export class OrmSamplingSchemePresetGroupFieldItem extends OrmObjectItem {

    @NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _enabled: boolean;

    @NatProperty({
        displayName: 'Тип группировки',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'sampling_scheme_group_types',
        required: false,
        standart: false
    })
    private _groupType: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
    private _field: OrmSamplingSchemeFieldItem;

    constructor() {
        super();
    }

    @Expose()
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(v: boolean) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get groupType(): OrmEnumerationItem {
        return this._groupType;
    }

    public set groupType(v: OrmEnumerationItem) {
        let oldValue = this._groupType;
        this._groupType = v;
        super.notifyFieldChange('groupType', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get field(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set field(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get fieldValue(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set fieldValue(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }

}