/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i3 from "@angular/material/toolbar";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "../menu/main-menu/main-menu.component.ngfactory";
import * as i7 from "../menu/main-menu/main-menu.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../tabs/tabs.component.ngfactory";
import * as i10 from "../../tabs/tabs.component";
import * as i11 from "../../../core/services/navigation/navigation.service";
import * as i12 from "../menu/header-right-menu/header-right-menu.component.ngfactory";
import * as i13 from "../menu/header-right-menu/header-right-menu.component";
import * as i14 from "../../../core/services/auth.service";
import * as i15 from "./header.component";
var styles_NatHeaderComponent = [i0.styles];
var RenderType_NatHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NatHeaderComponent, data: {} });
export { RenderType_NatHeaderComponent as RenderType_NatHeaderComponent };
export function View_NatHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-toolbar", [["class", " mat-toolbar"], ["color", "primary"], ["role", "heading"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i2.View_MatToolbar_0, i2.RenderType_MatToolbar)), i1.ɵdid(1, 4243456, null, 1, i3.MatToolbar, [i1.ElementRef, i4.Platform, i5.DOCUMENT], { color: [0, "color"] }, null), i1.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "nat-main-menu", [], null, null, null, i6.View_NatMainMenuComponent_0, i6.RenderType_NatMainMenuComponent)), i1.ɵdid(4, 114688, null, 0, i7.NatMainMenuComponent, [i8.MatDialog], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "nat-tabs", [], null, null, null, i9.View_NatTabsComponent_0, i9.RenderType_NatTabsComponent)), i1.ɵdid(6, 4308992, null, 0, i10.NatTabsComponent, [i11.NatNavigationService], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "nat-header-right-menu", [], null, null, null, i12.View_NatHeaderRightMenuComponent_0, i12.RenderType_NatHeaderRightMenuComponent)), i1.ɵdid(8, 114688, null, 0, i13.NatHeaderRightMenuComponent, [i14.NatAuthService], null, null)], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i1.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_NatHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nat-header", [], null, null, null, View_NatHeaderComponent_0, RenderType_NatHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i15.NatHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NatHeaderComponentNgFactory = i1.ɵccf("nat-header", i15.NatHeaderComponent, View_NatHeaderComponent_Host_0, {}, {}, []);
export { NatHeaderComponentNgFactory as NatHeaderComponentNgFactory };
