import { Type, Expose } from "class-transformer";
import { OrmDocument } from "@natiwi/core/models/document";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmOrganization } from "@natiwi/shared/models/organization.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPaymentOption } from "@natiwi/shared/models/payment-option.model";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmWarehouse } from "@natiwi/shared/models/warehouse.model";
import { OrmCashBox } from "@natiwi/shared/models/cash-box.model";
import { OrmLoyaltyCard } from "@natiwi/shared/models/loyalty-card.model";
import { OrmCoupon } from "@natiwi/shared/models/coupon.model";
import { OrmPartnerContract } from "@natiwi/shared/models/partner-contract.model";
import { OrmUser } from "@natiwi/core/models/user";
import { OrmDocumentItem } from "@natiwi/core/models/document-item";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmContactKind } from "@natiwi/shared/models/contact-kind.model";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmPartner } from "@natiwi/shared/models/partner.model";


@NatModel({
    displayName: 'Товар',
    pluralDisplayName: 'Товары'
})
export class OrmCustomerOrderProductItem extends OrmDocumentItem {

    @NatProperty({
        displayName: 'Товар',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProduct',
        required: true,
        standart: false
    })
    private _product: OrmProduct;

    @NatProperty({
        displayName: 'Характеристика',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductFeature',
        required: false,
        standart: false
    })
    private _feature: OrmProductFeature;

    @NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: true,
        standart: false
    })
    private _unit: OrmProductUnit;

    @NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
    private _priceType: OrmPriceType;

    @NatProperty({
        displayName: 'Склад',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmWarehouse',
        required: false,
        standart: false
    })
    private _warehouse: OrmWarehouse;

    @NatProperty({
        displayName: 'Ручной процент скидки',
        type: 'number',
        required: false,
        standart: false
    })
    private _manualDiscountPercent: number;

    @NatProperty({
        displayName: 'Ручная сумма скидки',
        type: 'number',
        required: false,
        standart: false
    })
    private _manualDiscountAmount: number;

    @NatProperty({
        displayName: 'Автоматический процент скидки',
        type: 'number',
        required: false,
        standart: false
    })
    private _autoDiscountPercent: number;

    @NatProperty({
        displayName: 'Автоматическая сумма скидки',
        type: 'number',
        required: false,
        standart: false
    })
    private _autoDiscountAmount: number;

    @NatProperty({
        displayName: 'Начислено бонусов',
        type: 'number',
        required: false,
        standart: false
    })
    private _bonusPointIncome: number;

    @NatProperty({
        displayName: 'Начислено бонусов в валюте',
        type: 'number',
        required: false,
        standart: false
    })
    private _bonusPointIncomeConverted: number;

    @NatProperty({
        displayName: 'Списано бонусов',
        type: 'number',
        required: false,
        standart: false
    })
    private _bonusPointOutcome: number;

    @NatProperty({
        displayName: 'Списано бонусов в валюте',
        type: 'number',
        required: false,
        standart: false
    })
    private _bonusPointOutcomeConverted: number;

    @NatProperty({
        displayName: 'Цена',
        type: 'number',
        required: true,
        standart: false
    })
    private _price: number;

    @NatProperty({
        displayName: 'Количество',
        type: 'number',
        required: true,
        standart: false
    })
    private _quantity: number;

    @NatProperty({
        displayName: 'Резерв',
        type: 'number',
        required: false,
        standart: false
    })
    private _reserve: number;

    @NatProperty({
        displayName: 'Сумма',
        type: 'number',
        required: true,
        standart: false
    })
    private _amount: number;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmProduct)
    public get product(): OrmProduct {
        if (!this._product) {
            this._product = null;
        }
        return this._product;
    }

    public set product(v: OrmProduct) {
        let oldValue = this._product;
        this._product = v;
        super.notifyFieldChange('product', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmProductFeature)
    public get feature(): OrmProductFeature {
        if (!this._feature) {
            this._feature = null;
        }
        return this._feature;
    }

    public set feature(v: OrmProductFeature) {
        let oldValue = this._feature;
        this._feature = v;
        super.notifyFieldChange('feature', oldValue, v);
    }


    @Expose()
    @NatRelation(OrmProductUnit)
    public get unit(): OrmProductUnit {
        if (!this._unit) {
            this._unit = null;
        }
        return this._unit;
    }

    public set unit(v: OrmProductUnit) {
        let oldValue = this._unit;
        this._unit = v;
        super.notifyFieldChange('unit', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmPriceType)
    public get priceType(): OrmPriceType {
        if (!this._priceType) {
            this._priceType = null;
        }
        return this._priceType;
    }

    public set priceType(v: OrmPriceType) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmWarehouse)
    public get warehouse(): OrmWarehouse {
        if (!this._warehouse) {
            this._warehouse = null;
        }
        return this._warehouse;
    }

    public set warehouse(v: OrmWarehouse) {
        let oldValue = this._warehouse;
        this._warehouse = v;
        super.notifyFieldChange('warehouse', oldValue, v);
    }

    @Expose()
    public get manualDiscountPercent(): number {
        return this._manualDiscountPercent;
    }

    public set manualDiscountPercent(v: number) {
        let oldValue = this._manualDiscountPercent;
        this._manualDiscountPercent = v;
        super.notifyFieldChange('manualDiscountPercent', oldValue, v);
    }

    @Expose()
    public get manualDiscountAmount(): number {
        return this._manualDiscountAmount;
    }

    public set manualDiscountAmount(v: number) {
        let oldValue = this._manualDiscountAmount;
        this._manualDiscountAmount = v;
        super.notifyFieldChange('manualDiscountAmount', oldValue, v);
    }

    @Expose()
    public get autoDiscountPercent(): number {
        return this._autoDiscountPercent;
    }

    public set autoDiscountPercent(v: number) {
        let oldValue = this._autoDiscountPercent;
        this._autoDiscountPercent = v;
        super.notifyFieldChange('autoDiscountPercent', oldValue, v);
    }

    @Expose()
    public get autoDiscountAmount(): number {
        return this._autoDiscountAmount;
    }

    public set autoDiscountAmount(v: number) {
        let oldValue = this._autoDiscountAmount;
        this._autoDiscountAmount = v;
        super.notifyFieldChange('autoDiscountAmount', oldValue, v);
    }

    @Expose()
    public get bonusPointIncome(): number {
        return this._bonusPointIncome;
    }

    public set bonusPointIncome(v: number) {
        let oldValue = this._bonusPointIncome;
        this._bonusPointIncome = v;
        super.notifyFieldChange('bonusPointIncome', oldValue, v);
    }

    @Expose()
    public get bonusPointIncomeConverted(): number {
        return this._bonusPointIncomeConverted;
    }

    public set bonusPointIncomeConverted(v: number) {
        let oldValue = this._bonusPointIncomeConverted;
        this._bonusPointIncomeConverted = v;
        super.notifyFieldChange('bonusPointIncomeConverted', oldValue, v);
    }

    @Expose()
    public get bonusPointOutcome(): number {
        return this._bonusPointOutcome;
    }

    public set bonusPointOutcome(v: number) {
        let oldValue = this._bonusPointOutcome;
        this._bonusPointOutcome = v;
        super.notifyFieldChange('bonusPointOutcome', oldValue, v);
    }

    @Expose()
    public get bonusPointOutcomeConverted(): number {
        return this._bonusPointOutcomeConverted;
    }

    public set bonusPointOutcomeConverted(v: number) {
        let oldValue = this._bonusPointOutcomeConverted;
        this._bonusPointOutcomeConverted = v;
        super.notifyFieldChange('bonusPointOutcomeConverted', oldValue, v);
    }

    @Expose()
    public get price(): number {
        return this._price;
    }

    public set price(v: number) {
        let oldValue = this._price;
        this._price = v;
        super.notifyFieldChange('price', oldValue, v);
    }

    @Expose()
    public get quantity(): number {
        return this._quantity;
    }

    public set quantity(v: number) {
        let oldValue = this._quantity;
        this._quantity = v;
        super.notifyFieldChange('quantity', oldValue, v);
    }

    @Expose()
    public get reserve(): number {
        return this._reserve;
    }

    public set reserve(v: number) {
        let oldValue = this._reserve;
        this._reserve = v;
        super.notifyFieldChange('reserve', oldValue, v);
    }

    @Expose()
    public get amount(): number {
        return this._amount;
    }

    public set amount(v: number) {
        let oldValue = this._amount;
        this._amount = v;
        super.notifyFieldChange('amount', oldValue, v);
    }

}

@NatModel({
    displayName: 'Контактная информация',
    pluralDisplayName: 'Список контактной информации'
})
export class OrmCustomerOrderContactItem extends OrmObjectItem {

    @NatProperty({
        displayName: 'Представление',
        type: 'string',
        required: true,
        standart: false
    })
    private _view: string;

    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'contact_types',
        required: true,
        standart: false
    })
    private _type: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Вид',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmContactKind',
        required: true,
        standart: false
    })

    private _kind: OrmContactKind;

    constructor() {
        super();
    }

    @Expose()
    public get view(): string {
        return this._view;
    }

    public set view(v: string) {
        let oldValue = this._view;
        this._view = v;
        super.notifyFieldChange('view', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmContactKind)
    public get kind(): OrmContactKind {
        return this._kind;
    }

    public set kind(v: OrmContactKind) {
        let oldValue = this._kind;
        this._kind = v;
        super.notifyFieldChange('kind', oldValue, v);
    }
}

@NatModel({
    displayName: 'Набор контактной информации',
    pluralDisplayName: 'Наборы контактной информации'
})
export class OrmCustomerOrderContactSet extends OrmDocumentItem {

    @NatProperty({
        displayName: 'Контакты',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmCustomerOrderContactItem',
        required: true,
        standart: false
    })
    private _contactList: Array<OrmCustomerOrderContactItem>;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmCustomerOrderContactItem)
    public get contactList(): Array<OrmCustomerOrderContactItem> {
        if (!this._contactList) {
            this._contactList = new Array();
        }
        return this._contactList;
    }

    public set contactList(v: Array<OrmCustomerOrderContactItem>) {
        let oldValue = this._contactList;
        this._contactList = v;
        super.notifyFieldChange('contactList', oldValue, v);
    }
}

@NatModel({
    plural: 'orders',
    displayName: 'Заказ покупателя',
    pluralDisplayName: 'Заказы покупателя'
})
export class OrmCustomerOrder extends OrmDocument {

    @NatProperty({
        displayName: 'Организация',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmOrganization',
        required: false,
        standart: false
    })
    private _organization: OrmOrganization;

    @NatProperty({
        displayName: 'Контрагент',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPartner',
        required: true,
        standart: false
    })
    private _partner: OrmPartner;

    @NatProperty({
        displayName: 'Договор',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPartnerContract',
        required: false,
        standart: false
    })
    private _contract: OrmPartnerContract;

    @NatProperty({
        displayName: 'Способ оплаты',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPaymentOption',
        required: false,
        standart: false
    })
    private _paymentOption: OrmPaymentOption;

    @NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
    private _priceType: OrmPriceType;

    @NatProperty({
        displayName: 'Склад',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmWarehouse',
        required: true,
        standart: false
    })
    private _warehouse: OrmWarehouse;

    @NatProperty({
        displayName: 'Касса',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmCashBox',
        required: false,
        standart: false
    })
    private _cashBox: OrmCashBox;

    @NatProperty({
        displayName: 'Карта лояльности',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmLoyaltyCard',
        required: false,
        standart: false
    })
    private _loyaltyCard: OrmLoyaltyCard;

    @NatProperty({
        displayName: 'Купон',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmCoupon',
        required: false,
        standart: false
    })
    private _coupon: OrmCoupon;

    @NatProperty({
        displayName: 'Менеджер',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmUser',
        required: false,
        standart: false
    })
    private _manager: OrmUser;

    @NatProperty({
        displayName: 'Статус',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'customer_order_statuses',
        required: true,
        standart: false
    })
    private _status: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Сумма',
        type: 'number',
        required: true,
        standart: false
    })
    private _amount: number;

    @NatProperty({
        displayName: 'Примечание',
        type: 'string',
        required: false,
        standart: false
    })
    private _note: string;


    @NatProperty({
        displayName: 'Товары',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmCustomerOrderProductItem',
        required: true,
        standart: false
    })
    private _productItems: Array<OrmCustomerOrderProductItem>;

    @NatProperty({
        displayName: 'Набор контактов',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmCustomerOrderContactSet',
        required: true,
        standart: false
    })
    private _contactSet: OrmCustomerOrderContactSet;
    
    // @NatProperty({
    //     displayName: 'Товары',
    //     type: 'relation',
    //     relationType: 'embedsMany',
    //     relationModel: 'OrmCustomerOrderProductItem',
    //     required: true,
    //     standart: false
    // })
    // private _prepayItems: Array<OrmCustomerOrderPrepayItem>;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmOrganization)
    public get organization(): OrmOrganization {
        return this._organization;
    }

    public set organization(v: OrmOrganization) {
        let oldValue = this._organization;
        this._organization = v;
        super.notifyFieldChange('organization', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmPartner)
    public get partner(): OrmPartner {
        return this._partner;
    }

    public set partner(v: OrmPartner) {
        let oldValue = this._partner;
        this._partner = v;
        super.notifyFieldChange('partner', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmPartnerContract)
    public get contract(): OrmPartnerContract {
        return this._contract;
    }

    public set contract(v: OrmPartnerContract) {
        let oldValue = this._contract;
        this._contract = v;
        super.notifyFieldChange('contract', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmPaymentOption)
    public get paymentOption(): OrmPaymentOption {
        return this._paymentOption;
    }

    public set paymentOption(v: OrmPaymentOption) {
        let oldValue = this._paymentOption;
        this._paymentOption = v;
        super.notifyFieldChange('paymentOption', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmPriceType)
    public get priceType(): OrmPriceType {
        return this._priceType;
    }

    public set priceType(v: OrmPriceType) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmWarehouse)
    public get warehouse(): OrmWarehouse {
        return this._warehouse;
    }

    public set warehouse(v: OrmWarehouse) {
        let oldValue = this._warehouse;
        this._warehouse = v;
        super.notifyFieldChange('warehouse', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmCashBox)
    public get cashBox(): OrmCashBox {
        return this._cashBox;
    }

    public set cashBox(v: OrmCashBox) {
        let oldValue = this._cashBox;
        this._cashBox = v;
        super.notifyFieldChange('cashBox', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmLoyaltyCard)
    public get loyaltyCard(): OrmLoyaltyCard {
        return this._loyaltyCard;
    }

    public set loyaltyCard(v: OrmLoyaltyCard) {
        let oldValue = this._loyaltyCard;
        this._loyaltyCard = v;
        super.notifyFieldChange('loyaltyCard', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmCoupon)
    public get coupon(): OrmCoupon {
        return this._coupon;
    }

    public set coupon(v: OrmCoupon) {
        let oldValue = this._coupon;
        this._coupon = v;
        super.notifyFieldChange('coupon', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmUser)
    public get manager(): OrmUser {
        return this._manager;
    }

    public set manager(v: OrmUser) {
        let oldValue = this._manager;
        this._manager = v;
        super.notifyFieldChange('manager', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get status(): OrmEnumerationItem {
        return this._status;
    }

    public set status(v: OrmEnumerationItem) {
        let oldValue = this._status;
        this._status = v;
        super.notifyFieldChange('status', oldValue, v);
    }

    @Expose()
    public get amount(): number {
        return this._amount;
    }

    public set amount(v: number) {
        let oldValue = this._amount;
        this._amount = v;
        super.notifyFieldChange('amount', oldValue, v);
    }

    @Expose()
    public get note(): string {
        return this._note;
    }

    public set note(v: string) {
        let oldValue = this._note;
        this._note = v;
        super.notifyFieldChange('note', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmCustomerOrderProductItem)
    public get productItems(): Array<OrmCustomerOrderProductItem> {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }

    public set productItems(v: Array<OrmCustomerOrderProductItem>) {
        let oldValue = this._productItems;
        this._productItems = v;
        super.notifyFieldChange('productItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmCustomerOrderProductItem)
    private get productItemList(): Array<OrmCustomerOrderProductItem> {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }
    
    @Expose()
    @NatRelation(OrmCustomerOrderContactSet)
    public get contactSet(): OrmCustomerOrderContactSet {
        return this._contactSet;
    }

    public set contactSet(v: OrmCustomerOrderContactSet) {
        let oldValue = this._contactSet;
        this._contactSet = v;
        super.notifyFieldChange('contactSet', oldValue, v);
    }
    
    // @Expose()
    // @NatRelation(OrmCustomerOrderPrepayItem)
    // public get prepayItems(): Array<OrmCustomerOrderPrepayItem> {
    //     if (!this._prepayItems) {
    //         this._prepayItems = new Array();
    //     }
    //     return this._prepayItems;
    // }

    // public set prepayItems(v: Array<OrmCustomerOrderPrepayItem>) {
    //     let oldValue = this._prepayItems;
    //     this._prepayItems = v;
    //     super.notifyFieldChange('prepayItems', oldValue, v);
    // }
    

    /**
    * toString
    */
    public toString(): string {
        if (super.toString()) {
            return `Заказ ${super.toString()}`;
        }
        return '';
    }
}


// "contactSet": {
//     "displayName": "Набор контактов",
//     "type": "embedsOne",
//     "model": "OrmCustomerOrderContactSet",
//     "property": "contactSetValue",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Значение набора контактов",
//             "type": "OrmCustomerOrderContactSet",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": {
//             "relation": "contacts",
//             "scope": {
//                 "include": ["type", "kind"]
//             }
//         }
//     }
// },
// "productItems": {
//     "displayName": "Товары",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderProductItem",
//     "as": "productItems",
//     "property": "productItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список товаров",
//             "type": "OrmCustomerOrderProductItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": ["product", "feature", "unit", "priceType", "warehouse"]
//     }
// },
// "prepayItems": {
//     "displayName": "Предоплаты",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderPrepayItem",
//     "as": "prepayItems",
//     "property": "prepayItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список предоплат",
//             "type": "OrmCustomerOrderPrepayItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": [
//             "document"
//         ]
//     }
// },
// "loyaltyPolicyItems": {
//     "displayName": "Политики лояльности",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderLoyaltyPolicyItem",
//     "as": "loyaltyPolicyItems",
//     "property": "loyaltyPolicyItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список политик лояльности",
//             "type": "OrmCustomerOrderLoyaltyPolicyItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": ["loyaltyPolicy"]
//     }
// },
// "bonusPointIncomeItems": {
//     "displayName": "Начисленные бонусы",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderBonusPointIncomeItem",
//     "as": "bonusPointIncomeItems",
//     "property": "bonusPointIncomeItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список начисленных бонусов",
//             "type": "OrmCustomerOrderBonusPointIncomeItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": ["loyaltyProgram", "product", "feature", "unit"]
//     }
// },
// "acquiringOperations": {
//     "displayName": "Экваринговые операции",
//     "type": "hasMany",
//     "model": "OrmAcquiringOperation",
//     "foreignKey": "ownerId"
// },
// "cashlessReceipts": {
//     "displayName": "Поступления безналичных денежных средств",
//     "type": "hasMany",
//     "model": "OrmCashlessReceipt",
//     "foreignKey": "ownerId"
// },
// "cashlessWriteOffs": {
//     "displayName": "Списания безналичных денежных средств",
//     "type": "hasMany",
//     "model": "OrmCashlessWriteOff",
//     "foreignKey": "ownerId"
// },
// "incomeCashOrders": {
//     "displayName": "Приходные кассовые ордера",
//     "type": "hasMany",
//     "model": "OrmIncomeCashOrder",
//     "foreignKey": "ownerId"
// },
// "refundsOfProducts": {
//     "displayName": "Возвраты товаров",
//     "type": "hasMany",
//     "model": "OrmRefundOfProducts",
//     "foreignKey": "ownerId"
// },
// "saleOfProducts": {
//     "displayName": "Реализации товаров и услуг",
//     "type": "hasMany",
//     "model": "OrmSaleOfProducts",
//     "foreignKey": "ownerId"
// },
// "checks": {
//     "displayName": "Чеки продажи",
//     "type": "hasMany",
//     "model": "OrmCheck",
//     "foreignKey": "customerOrderId"
// },
// "checkRefunds": {
//     "displayName": "Чеки возврата",
//     "type": "hasMany",
//     "model": "OrmCheckRefund",
//     "foreignKey": "customerOrderId"
// }