import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    plural: 'naturalPersons',
    displayName: 'Физическое лицо',
    pluralDisplayName: 'Физические лица'
})
@NatHierarchical(false)
export class OrmNaturalPerson extends OrmCatalog {

    @NatProperty({
        displayName: 'Пол',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'gender',
        required: false,
        standart: false
    })
    private _gender: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Дата рождения',
        type: 'date',
        required: false,
        standart: false
    })
    private _birthdate: Date;

    @NatProperty({
        displayName: 'ИНН',
        type: 'string',
        required: false,
        standart: false
    })
    private _itn: string;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._gender;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._gender;
        this._gender = v;
        super.notifyFieldChange('gender', oldValue, v);
    }

    @Expose()
    @Type(() => Date)
    public get birthdate(): Date {
        return this._birthdate;
    }

    public set birthdate(v: Date) {
        let oldValue = this._birthdate;
        this._birthdate = v;
        super.notifyFieldChange('birthdate', oldValue, v);
    }

    @Expose()
    public get itn(): string {
        return this._itn;
    }

    public set itn(v: string) {
        let oldValue = this._itn;
        this._itn = v;
        super.notifyFieldChange('itn', oldValue, v);
    }
}