import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { NatPriceTypeRepository } from "@natiwi/shared/repositories/price-type.repository";

export class NatPriceTypeController extends NatCatalogController<OrmPriceType>{

    constructor(_repository?: NatPriceTypeRepository) {
        super(_repository);
        super.registerDataClass(OrmPriceType);
    }
}