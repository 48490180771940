import { NatProductRepository } from "@natiwi/shared/repositories/product.repository";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";

export class NatProductController extends NatCatalogController<OrmProduct>{

    constructor(_repository?: NatProductRepository) {
        super(_repository);
        super.registerDataClass(OrmProduct);
    }
}