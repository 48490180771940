import * as tslib_1 from "tslib";
import { Expose, Type } from "class-transformer";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";
const ɵ0 = () => Date;
export class OrmObject extends OrmPersistedModel {
    constructor() {
        super();
    }
    get createdAt() {
        return this._createdAt;
    }
    set createdAt(v) {
        let oldValue = this._createdAt;
        this._createdAt = v;
        super.notifyFieldChange('createdAt', oldValue, v);
    }
    get deleted() {
        return this._deleted;
    }
    set deleted(v) {
        let oldValue = this._deleted;
        this._deleted = v;
        super.notifyFieldChange('deleted', oldValue, v);
    }
}
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата создания',
        type: 'date',
        required: false,
        standart: false
    })
], OrmObject.prototype, "_createdAt", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Пометка на удаление',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmObject.prototype, "_deleted", void 0);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmObject.prototype, "createdAt", null);
tslib_1.__decorate([
    Expose()
], OrmObject.prototype, "deleted", null);
export { ɵ0 };
