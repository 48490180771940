import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
let OrmSamplingSchemeParameterFieldItem = class OrmSamplingSchemeParameterFieldItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get name() {
        return this._name;
    }
    set name(v) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    get designation() {
        return this._designation;
    }
    set designation(v) {
        let oldValue = this._designation;
        this._designation = v;
        super.notifyFieldChange('designation', oldValue, v);
    }
    get required() {
        return this._required;
    }
    set required(v) {
        let oldValue = this._required;
        this._required = v;
        super.notifyFieldChange('required', oldValue, v);
    }
    get enabled() {
        return this._enabled;
    }
    set enabled(v) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    get field() {
        return this._field;
    }
    set field(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    get fieldValue() {
        return this._field;
    }
    set fieldValue(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    /**
   * toString
   */
    toString() {
        return this._name || '';
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
], OrmSamplingSchemeParameterFieldItem.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Назначение',
        type: 'string',
        required: true,
        standart: false
    })
], OrmSamplingSchemeParameterFieldItem.prototype, "_designation", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Обязательный',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmSamplingSchemeParameterFieldItem.prototype, "_required", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmSamplingSchemeParameterFieldItem.prototype, "_enabled", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemeParameterFieldItem.prototype, "_field", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeParameterFieldItem.prototype, "name", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeParameterFieldItem.prototype, "designation", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeParameterFieldItem.prototype, "required", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeParameterFieldItem.prototype, "enabled", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemeParameterFieldItem.prototype, "field", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemeParameterFieldItem.prototype, "fieldValue", null);
OrmSamplingSchemeParameterFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Параметр',
        pluralDisplayName: 'Параметры'
    }),
    NatHierarchical(false)
], OrmSamplingSchemeParameterFieldItem);
export { OrmSamplingSchemeParameterFieldItem };
