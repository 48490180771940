var OrmProductUnit_1;
import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmProductUnit = OrmProductUnit_1 = class OrmProductUnit extends OrmCatalog {
    constructor() {
        super();
    }
    get unit() {
        return this._unit;
    }
    set unit(v) {
        let oldValue = this._unit;
        this._unit = v;
        super.notifyFieldChange('unit', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    get packageType() {
        return this._packageType;
    }
    set packageType(v) {
        let oldValue = this._packageType;
        this._packageType = v;
        super.notifyFieldChange('packageType', oldValue, v);
    }
    get quantityOfOwnerUnitIncludedInPackage() {
        return this._quantityOfOwnerUnitIncludedInPackage;
    }
    set quantityOfOwnerUnitIncludedInPackage(v) {
        let oldValue = this._quantityOfOwnerUnitIncludedInPackage;
        this._quantityOfOwnerUnitIncludedInPackage = v;
        super.notifyFieldChange('quantityOfOwnerUnitIncludedInPackage', oldValue, v);
    }
    get quantityOfParentPackageIncludedInPackage() {
        return this._quantityOfParentPackageIncludedInPackage;
    }
    set quantityOfParentPackageIncludedInPackage(v) {
        let oldValue = this._quantityOfParentPackageIncludedInPackage;
        this._quantityOfParentPackageIncludedInPackage = v;
        super.notifyFieldChange('quantityOfParentPackageIncludedInPackage', oldValue, v);
    }
    get quantityOfPackageIncludedInOwnerUnit() {
        return this._quantityOfPackageIncludedInOwnerUnit;
    }
    set quantityOfPackageIncludedInOwnerUnit(v) {
        let oldValue = this._quantityOfPackageIncludedInOwnerUnit;
        this._quantityOfPackageIncludedInOwnerUnit = v;
        super.notifyFieldChange('quantityOfPackageIncludedInOwnerUnit', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Базовая единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: false,
        standart: false
    })
], OrmProductUnit.prototype, "_unit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'unit_types',
        required: false,
        standart: false
    })
], OrmProductUnit.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип упаковки',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'unit_package_types',
        required: false,
        standart: false
    })
], OrmProductUnit.prototype, "_packageType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Количество базовых единиц измерения в упаковке',
        type: 'number',
        required: false,
        standart: false
    })
], OrmProductUnit.prototype, "_quantityOfOwnerUnitIncludedInPackage", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Количество родительских упаковок в упаковке',
        type: 'number',
        required: false,
        standart: false
    })
], OrmProductUnit.prototype, "_quantityOfParentPackageIncludedInPackage", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Количество упаковок в базовой единице измерения',
        type: 'number',
        required: false,
        standart: false
    })
], OrmProductUnit.prototype, "_quantityOfPackageIncludedInOwnerUnit", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductUnit_1)
], OrmProductUnit.prototype, "unit", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmProductUnit.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmProductUnit.prototype, "packageType", null);
tslib_1.__decorate([
    Expose()
], OrmProductUnit.prototype, "quantityOfOwnerUnitIncludedInPackage", null);
tslib_1.__decorate([
    Expose()
], OrmProductUnit.prototype, "quantityOfParentPackageIncludedInPackage", null);
tslib_1.__decorate([
    Expose()
], OrmProductUnit.prototype, "quantityOfPackageIncludedInOwnerUnit", null);
OrmProductUnit = OrmProductUnit_1 = tslib_1.__decorate([
    NatModel({
        plural: 'productUnits',
        displayName: 'Единица измерения',
        pluralDisplayName: 'Единицы измерения'
    })
], OrmProductUnit);
export { OrmProductUnit };
