import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmSamplingSchemePresetStructureFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-structure-field-item.model";
import { OrmSamplingSchemePresetGroupFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-group-field-item.model";

@NatModel({
    displayName: 'Поле структуры группировки списком',
    pluralDisplayName: 'Поля структуры группировки списком'
})
@NatHierarchical(false)
export class OrmSamplingSchemePresetStructureListFieldItem extends OrmSamplingSchemePresetStructureFieldItem {

    @NatProperty({
        displayName: 'Группировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetGroupFieldItem',
        required: false,
        standart: false
    })
    private _groupFieldItems: Array<OrmSamplingSchemePresetGroupFieldItem>;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmSamplingSchemePresetGroupFieldItem)
    public get groupFieldItems(): Array<OrmSamplingSchemePresetGroupFieldItem> {
        return this._groupFieldItems;
    }

    public set groupFieldItems(v: Array<OrmSamplingSchemePresetGroupFieldItem>) {
        let oldValue = this._groupFieldItems;
        this._groupFieldItems = v;
        super.notifyFieldChange('groupFieldItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetGroupFieldItem)
    public get groupFieldItemList(): Array<OrmSamplingSchemePresetGroupFieldItem> {
        return this._groupFieldItems;
    }

    public set groupFieldItemList(v: Array<OrmSamplingSchemePresetGroupFieldItem>) {
        let oldValue = this._groupFieldItems;
        this._groupFieldItems = v;
        super.notifyFieldChange('groupFieldItems', oldValue, v);
    }

}