import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmResponsiblePerson } from "@natiwi/shared/models/responsible-person.model";
import { NatResponsiblePersonRepository } from "@natiwi/shared/repositories/responsible-person.repository";

export class NatResponsiblePersonController extends NatCatalogController<OrmResponsiblePerson>{

    constructor(_repository?: NatResponsiblePersonRepository) {
        super(_repository);
        super.registerDataClass(OrmResponsiblePerson);
    }
}