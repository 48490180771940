import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";

@NatModel({
    plural: 'productFeatures',
    displayName: 'Характеристика номенклатуры',
    pluralDisplayName: 'Характеристики номенклатуры'
})
@NatHierarchical(false)
export class OrmProductFeature extends OrmCatalog {

    constructor() {
        super();
    }
}