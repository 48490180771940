import { NatForm } from "@natiwi/core/forms/form";
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { GlobalInjector } from "@natiwi/core/network/shared/global-variables";
import { NatNavigationService } from "@natiwi/core/services/navigation/navigation.service";

interface NatActivatedRouteSnapshot extends ActivatedRouteSnapshot {
    _routerState: RouterStateSnapshot;
}

class NatNavigationItemRoute {

    private _source: string;
    private _routeUrl: string;
    private _queryParams: {
        [key: string]: string | Array<any>
    }

    constructor(url: string) {
        let isContainParams: boolean;
        if (!url) {
            throw 'Не удалось создать маршрут: url обязателен'
        }
        this._source = url;
        this.parse();
    }

    public get source(): string {
        return this._source;
    }

    public get url(): string {
        return this._routeUrl;
    }

    public get queryParams(): {
        [key: string]: string | Array<any>
    } {
        if (!this._queryParams) {
            this._queryParams = {};
        }
        return this._queryParams;
    }

    private parse() {
        let url = this._source;
        if ((url.indexOf('?') > -1) && (url.indexOf('?') < (url.length - 1))) {
            this._queryParams = {};
            let qeryParamsString = url.split('?')[1];
            //let params: URLSearchParams = new URLSearchParams(qeryParamsString);
            this._routeUrl = url.split('?')[0];

            let paramsPairs = qeryParamsString.split('&');
            for (let pair of paramsPairs) {
                let key = pair.split('=')[0];
                let value = pair.split('=')[1];
                if (this._queryParams[key] === undefined) {
                    this._queryParams[key] = value;
                } else {
                    let arr = new Array<any>();
                    arr.push(this._queryParams[key]);
                    arr.push(value);
                    this._queryParams[key] = arr
                }
            }
        } else {
            this._routeUrl = url;
        }
    }

    public merge(item: NatNavigationItemRoute): NatNavigationItemRoute {
        this._queryParams = item.queryParams//Object.assign(this.queryParams, item.queryParams );
        //item._queryParams = Object.assign(item.queryParams, this._queryParams);
        return this;
    }
}

export class NatNavigationItem {

    private _routeItem: NatNavigationItemRoute;
    private _title: string;
    private _isClosable: boolean;
    private _component: NatForm;
    private _navigationService: NatNavigationService;

    constructor(url: string, component?: NatForm, params?: { [key: string]: any }) {
        this._routeItem = new NatNavigationItemRoute(url);
        this._isClosable = true;
        if (!url) {
            throw 'Не удалось создать элемент навигации: параметр Url является обязательным';
        }
        if (params) {
            this._title = params['title'] || '';
            this._isClosable = !!params['isClosable'];
        }
        if (component) {
            // this._navigationService = GlobalInjector.get(NatNavigationService);            
            this.bindComponent(component);
        }
    }

    public get routeItem(): NatNavigationItemRoute {
        return this._routeItem;
    }

    public get title(): string {
        return this._title;
    }

    public get isClosable(): boolean {
        return this._isClosable;
    }

    public get component(): NatForm {
        return this._component;
    }

    public bindComponent(component: NatForm): void {
        setTimeout(() => this._navigationService = GlobalInjector.get(NatNavigationService));
        if (!component) {
            throw 'Не удалось связать элемент навигации с компанентой: параметр Component является обязательным';
        }
        this._component = component;
        component.getTitle().subscribe(title => {
            this._title = title;
        });
        component.onCloseHandler = () => {
            if (this._navigationService) {
                console.log('closing');
                this._navigationService.deleteNavigationItem(this);
            }
        }
    }

    static fromUrl(url: string, component?: NatForm, params?: { [key: string]: any }): NatNavigationItem {
        if (!url) {
            throw 'Не удалось создать элемент навигации: параметр Url является обязательным';
        }
        return new NatNavigationItem(url, component, params);
    }

    static fromActivatedRoute(activatedRoute: ActivatedRoute, component?: NatForm, params?: { [key: string]: any }): NatNavigationItem {
        if (!activatedRoute) {
            throw 'Не удалось создать элемент навигации: параметр ActivatedRoute является обязательным';
        }
        let url: string;
        let routerSnapshot: NatActivatedRouteSnapshot = activatedRoute.snapshot as NatActivatedRouteSnapshot;
        url = routerSnapshot._routerState.url;
        return NatNavigationItem.fromUrl(url, component, params);
    }

    static fromNavigationItem(navigationItem: NatNavigationItem): NatNavigationItem {
        if (!navigationItem) {
            throw 'Не удалось создать элемент навигации: параметр типа NatNavigationItem является обязательным';
        }
        let newItem: NatNavigationItem;
        let url = navigationItem.routeItem.source;
        if (!url) {
            throw 'Не удалось создать элемент навигации: параметр Url является обязательным'
        }
        newItem = NatNavigationItem.fromUrl(url);
        for (let key in navigationItem) {
            newItem[key] = navigationItem[key];
        }
        if (navigationItem._component) {
            newItem.bindComponent(navigationItem._component);
        }
        return newItem;
    }
}
