import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose, Type } from "class-transformer";
import { OrmFileMetadata } from "@natiwi/shared/models/file-metadata";

@NatModel({
    plural: 'images',
    displayName: 'Изображение',
    pluralDisplayName: 'Изображения'
})
export class OrmImageMetadata extends OrmFileMetadata {

    @NatProperty({
        displayName: 'Исходное изображение',
        type: 'relation',
        relationModel: 'OrmImageMetadata',
        required: false,
        standart: false
    })
    private _originalImage: OrmImageMetadata;

    @NatProperty({
        displayName: 'Изображение предпросмотра',
        type: 'string',
        required: false,
        standart: false
    })
    private _preview: string;
    
    @NatProperty({
        displayName: 'Ширина',
        type: 'number',
        required: true,
        standart: false
    })
    private _width: number;
    
    @NatProperty({
        displayName: 'Ширина',
        type: 'number',
        required: true,
        standart: false
    })
    private _height: number;

    constructor() {
        super();
    }
    
    @Expose()
    @Type(() => OrmImageMetadata)
    public get originalImage(): OrmImageMetadata {
        return this._originalImage;
    }

    public set originalImage(v: OrmImageMetadata) {
        this._originalImage = v;
    }

    @Expose()
    public get preview(): string {
        return this._preview;
    }

    public set preview(v: string) {
        this._preview = v;
    }
    
    @Expose()
    public get width(): number {
        return this._width;
    }

    public set width(v: number) {
        this._width = v;
    }
    
    @Expose()
    public get height(): number {
        return this._height;
    }

    public set height(v: number) {
        this._height = v;
    }

}