import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';


export class NatEvent {
  private _type: string;
  private _message: string;

  constructor(type: string, message: string) {
    this._type = type;
    this._message = message;
  }

  public get message(): string {
    return this._message
  }

  public get type(): string {
    return this._type
  }

}

@Injectable()
export class NatNotificationService {

  constructor(private _snackBar: MatSnackBar) {
  }

  private openSnackBar(message: string, action: string) {
    if (!action) {
      action = ''
    }
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  /**
   * sendEvent
   */
  public sendEvent(event: NatEvent) {
    switch (event.type) {
      case 'error':
        this.openSnackBar(event.message, null)
        break;
      case 'success':
        this.openSnackBar(event.message, null)
        break;
      default:
        this.openSnackBar(event.message, null)
        break;
    }
  }

}