import { NatObjectRepository, ViewListResponse } from "@natiwi/core/network/repositories/object.repository";
import { OrmObject } from "@natiwi/core/models/object";
import { Observable } from "rxjs";
import { NatPersistedModelController } from "@natiwi/core/controllers/persisted-model.controller";
import { NatFilter } from "@natiwi/core/network/shared/filter";

export abstract class NatObjectController<Model extends OrmObject> extends NatPersistedModelController<Model>{

    constructor(_repository: NatObjectRepository<Model>) {
        super(_repository);
    }
    
    /**
    * viewList
    */
    public viewList(filter?: NatFilter): Observable<ViewListResponse<Model>> {
        let params: Map<string, any>;
        if (filter) {
            params = new Map<string, any>()
            params.set('filter', filter);
        }
        let repo: NatObjectRepository<Model> = this.getRepository();
        return repo.viewList(params);
    }

    /**
     * makeDeleted
     */
    public makeDeleted(object: Model): Observable<Model> {
        if (!object) {
            throw 'Объект не задан'
        }
        let mode: boolean = !(!!object.deleted);
        let repo: NatObjectRepository<Model> = this.getRepository();
        return repo.makeDeleted(mode, object);
    }
}