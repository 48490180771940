import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmDocument } from "@natiwi/core/models/document";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmOrganization } from "@natiwi/shared/models/organization.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPaymentOption } from "@natiwi/shared/models/payment-option.model";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmWarehouse } from "@natiwi/shared/models/warehouse.model";
import { OrmCashBox } from "@natiwi/shared/models/cash-box.model";
import { OrmLoyaltyCard } from "@natiwi/shared/models/loyalty-card.model";
import { OrmCoupon } from "@natiwi/shared/models/coupon.model";
import { OrmPartnerContract } from "@natiwi/shared/models/partner-contract.model";
import { OrmUser } from "@natiwi/core/models/user";
import { OrmDocumentItem } from "@natiwi/core/models/document-item";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmContactKind } from "@natiwi/shared/models/contact-kind.model";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmPartner } from "@natiwi/shared/models/partner.model";
let OrmCustomerOrderProductItem = class OrmCustomerOrderProductItem extends OrmDocumentItem {
    constructor() {
        super();
    }
    get product() {
        if (!this._product) {
            this._product = null;
        }
        return this._product;
    }
    set product(v) {
        let oldValue = this._product;
        this._product = v;
        super.notifyFieldChange('product', oldValue, v);
    }
    get feature() {
        if (!this._feature) {
            this._feature = null;
        }
        return this._feature;
    }
    set feature(v) {
        let oldValue = this._feature;
        this._feature = v;
        super.notifyFieldChange('feature', oldValue, v);
    }
    get unit() {
        if (!this._unit) {
            this._unit = null;
        }
        return this._unit;
    }
    set unit(v) {
        let oldValue = this._unit;
        this._unit = v;
        super.notifyFieldChange('unit', oldValue, v);
    }
    get priceType() {
        if (!this._priceType) {
            this._priceType = null;
        }
        return this._priceType;
    }
    set priceType(v) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }
    get warehouse() {
        if (!this._warehouse) {
            this._warehouse = null;
        }
        return this._warehouse;
    }
    set warehouse(v) {
        let oldValue = this._warehouse;
        this._warehouse = v;
        super.notifyFieldChange('warehouse', oldValue, v);
    }
    get manualDiscountPercent() {
        return this._manualDiscountPercent;
    }
    set manualDiscountPercent(v) {
        let oldValue = this._manualDiscountPercent;
        this._manualDiscountPercent = v;
        super.notifyFieldChange('manualDiscountPercent', oldValue, v);
    }
    get manualDiscountAmount() {
        return this._manualDiscountAmount;
    }
    set manualDiscountAmount(v) {
        let oldValue = this._manualDiscountAmount;
        this._manualDiscountAmount = v;
        super.notifyFieldChange('manualDiscountAmount', oldValue, v);
    }
    get autoDiscountPercent() {
        return this._autoDiscountPercent;
    }
    set autoDiscountPercent(v) {
        let oldValue = this._autoDiscountPercent;
        this._autoDiscountPercent = v;
        super.notifyFieldChange('autoDiscountPercent', oldValue, v);
    }
    get autoDiscountAmount() {
        return this._autoDiscountAmount;
    }
    set autoDiscountAmount(v) {
        let oldValue = this._autoDiscountAmount;
        this._autoDiscountAmount = v;
        super.notifyFieldChange('autoDiscountAmount', oldValue, v);
    }
    get bonusPointIncome() {
        return this._bonusPointIncome;
    }
    set bonusPointIncome(v) {
        let oldValue = this._bonusPointIncome;
        this._bonusPointIncome = v;
        super.notifyFieldChange('bonusPointIncome', oldValue, v);
    }
    get bonusPointIncomeConverted() {
        return this._bonusPointIncomeConverted;
    }
    set bonusPointIncomeConverted(v) {
        let oldValue = this._bonusPointIncomeConverted;
        this._bonusPointIncomeConverted = v;
        super.notifyFieldChange('bonusPointIncomeConverted', oldValue, v);
    }
    get bonusPointOutcome() {
        return this._bonusPointOutcome;
    }
    set bonusPointOutcome(v) {
        let oldValue = this._bonusPointOutcome;
        this._bonusPointOutcome = v;
        super.notifyFieldChange('bonusPointOutcome', oldValue, v);
    }
    get bonusPointOutcomeConverted() {
        return this._bonusPointOutcomeConverted;
    }
    set bonusPointOutcomeConverted(v) {
        let oldValue = this._bonusPointOutcomeConverted;
        this._bonusPointOutcomeConverted = v;
        super.notifyFieldChange('bonusPointOutcomeConverted', oldValue, v);
    }
    get price() {
        return this._price;
    }
    set price(v) {
        let oldValue = this._price;
        this._price = v;
        super.notifyFieldChange('price', oldValue, v);
    }
    get quantity() {
        return this._quantity;
    }
    set quantity(v) {
        let oldValue = this._quantity;
        this._quantity = v;
        super.notifyFieldChange('quantity', oldValue, v);
    }
    get reserve() {
        return this._reserve;
    }
    set reserve(v) {
        let oldValue = this._reserve;
        this._reserve = v;
        super.notifyFieldChange('reserve', oldValue, v);
    }
    get amount() {
        return this._amount;
    }
    set amount(v) {
        let oldValue = this._amount;
        this._amount = v;
        super.notifyFieldChange('amount', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Товар',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProduct',
        required: true,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_product", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Характеристика',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductFeature',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_feature", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: true,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_unit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_priceType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Склад',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmWarehouse',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_warehouse", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Ручной процент скидки',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_manualDiscountPercent", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Ручная сумма скидки',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_manualDiscountAmount", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Автоматический процент скидки',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_autoDiscountPercent", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Автоматическая сумма скидки',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_autoDiscountAmount", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Начислено бонусов',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_bonusPointIncome", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Начислено бонусов в валюте',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_bonusPointIncomeConverted", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Списано бонусов',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_bonusPointOutcome", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Списано бонусов в валюте',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_bonusPointOutcomeConverted", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Цена',
        type: 'number',
        required: true,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_price", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Количество',
        type: 'number',
        required: true,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_quantity", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Резерв',
        type: 'number',
        required: false,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_reserve", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Сумма',
        type: 'number',
        required: true,
        standart: false
    })
], OrmCustomerOrderProductItem.prototype, "_amount", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProduct)
], OrmCustomerOrderProductItem.prototype, "product", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductFeature)
], OrmCustomerOrderProductItem.prototype, "feature", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductUnit)
], OrmCustomerOrderProductItem.prototype, "unit", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType)
], OrmCustomerOrderProductItem.prototype, "priceType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmWarehouse)
], OrmCustomerOrderProductItem.prototype, "warehouse", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "manualDiscountPercent", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "manualDiscountAmount", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "autoDiscountPercent", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "autoDiscountAmount", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "bonusPointIncome", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "bonusPointIncomeConverted", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "bonusPointOutcome", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "bonusPointOutcomeConverted", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "price", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "quantity", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "reserve", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderProductItem.prototype, "amount", null);
OrmCustomerOrderProductItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Товар',
        pluralDisplayName: 'Товары'
    })
], OrmCustomerOrderProductItem);
export { OrmCustomerOrderProductItem };
let OrmCustomerOrderContactItem = class OrmCustomerOrderContactItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get view() {
        return this._view;
    }
    set view(v) {
        let oldValue = this._view;
        this._view = v;
        super.notifyFieldChange('view', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    get kind() {
        return this._kind;
    }
    set kind(v) {
        let oldValue = this._kind;
        this._kind = v;
        super.notifyFieldChange('kind', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Представление',
        type: 'string',
        required: true,
        standart: false
    })
], OrmCustomerOrderContactItem.prototype, "_view", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'contact_types',
        required: true,
        standart: false
    })
], OrmCustomerOrderContactItem.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вид',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmContactKind',
        required: true,
        standart: false
    })
], OrmCustomerOrderContactItem.prototype, "_kind", void 0);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrderContactItem.prototype, "view", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmCustomerOrderContactItem.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmContactKind)
], OrmCustomerOrderContactItem.prototype, "kind", null);
OrmCustomerOrderContactItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Контактная информация',
        pluralDisplayName: 'Список контактной информации'
    })
], OrmCustomerOrderContactItem);
export { OrmCustomerOrderContactItem };
let OrmCustomerOrderContactSet = class OrmCustomerOrderContactSet extends OrmDocumentItem {
    constructor() {
        super();
    }
    get contactList() {
        if (!this._contactList) {
            this._contactList = new Array();
        }
        return this._contactList;
    }
    set contactList(v) {
        let oldValue = this._contactList;
        this._contactList = v;
        super.notifyFieldChange('contactList', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Контакты',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmCustomerOrderContactItem',
        required: true,
        standart: false
    })
], OrmCustomerOrderContactSet.prototype, "_contactList", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmCustomerOrderContactItem)
], OrmCustomerOrderContactSet.prototype, "contactList", null);
OrmCustomerOrderContactSet = tslib_1.__decorate([
    NatModel({
        displayName: 'Набор контактной информации',
        pluralDisplayName: 'Наборы контактной информации'
    })
], OrmCustomerOrderContactSet);
export { OrmCustomerOrderContactSet };
let OrmCustomerOrder = class OrmCustomerOrder extends OrmDocument {
    // @NatProperty({
    //     displayName: 'Товары',
    //     type: 'relation',
    //     relationType: 'embedsMany',
    //     relationModel: 'OrmCustomerOrderProductItem',
    //     required: true,
    //     standart: false
    // })
    // private _prepayItems: Array<OrmCustomerOrderPrepayItem>;
    constructor() {
        super();
    }
    get organization() {
        return this._organization;
    }
    set organization(v) {
        let oldValue = this._organization;
        this._organization = v;
        super.notifyFieldChange('organization', oldValue, v);
    }
    get partner() {
        return this._partner;
    }
    set partner(v) {
        let oldValue = this._partner;
        this._partner = v;
        super.notifyFieldChange('partner', oldValue, v);
    }
    get contract() {
        return this._contract;
    }
    set contract(v) {
        let oldValue = this._contract;
        this._contract = v;
        super.notifyFieldChange('contract', oldValue, v);
    }
    get paymentOption() {
        return this._paymentOption;
    }
    set paymentOption(v) {
        let oldValue = this._paymentOption;
        this._paymentOption = v;
        super.notifyFieldChange('paymentOption', oldValue, v);
    }
    get priceType() {
        return this._priceType;
    }
    set priceType(v) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }
    get warehouse() {
        return this._warehouse;
    }
    set warehouse(v) {
        let oldValue = this._warehouse;
        this._warehouse = v;
        super.notifyFieldChange('warehouse', oldValue, v);
    }
    get cashBox() {
        return this._cashBox;
    }
    set cashBox(v) {
        let oldValue = this._cashBox;
        this._cashBox = v;
        super.notifyFieldChange('cashBox', oldValue, v);
    }
    get loyaltyCard() {
        return this._loyaltyCard;
    }
    set loyaltyCard(v) {
        let oldValue = this._loyaltyCard;
        this._loyaltyCard = v;
        super.notifyFieldChange('loyaltyCard', oldValue, v);
    }
    get coupon() {
        return this._coupon;
    }
    set coupon(v) {
        let oldValue = this._coupon;
        this._coupon = v;
        super.notifyFieldChange('coupon', oldValue, v);
    }
    get manager() {
        return this._manager;
    }
    set manager(v) {
        let oldValue = this._manager;
        this._manager = v;
        super.notifyFieldChange('manager', oldValue, v);
    }
    get status() {
        return this._status;
    }
    set status(v) {
        let oldValue = this._status;
        this._status = v;
        super.notifyFieldChange('status', oldValue, v);
    }
    get amount() {
        return this._amount;
    }
    set amount(v) {
        let oldValue = this._amount;
        this._amount = v;
        super.notifyFieldChange('amount', oldValue, v);
    }
    get note() {
        return this._note;
    }
    set note(v) {
        let oldValue = this._note;
        this._note = v;
        super.notifyFieldChange('note', oldValue, v);
    }
    get productItems() {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }
    set productItems(v) {
        let oldValue = this._productItems;
        this._productItems = v;
        super.notifyFieldChange('productItems', oldValue, v);
    }
    get productItemList() {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }
    get contactSet() {
        return this._contactSet;
    }
    set contactSet(v) {
        let oldValue = this._contactSet;
        this._contactSet = v;
        super.notifyFieldChange('contactSet', oldValue, v);
    }
    // @Expose()
    // @NatRelation(OrmCustomerOrderPrepayItem)
    // public get prepayItems(): Array<OrmCustomerOrderPrepayItem> {
    //     if (!this._prepayItems) {
    //         this._prepayItems = new Array();
    //     }
    //     return this._prepayItems;
    // }
    // public set prepayItems(v: Array<OrmCustomerOrderPrepayItem>) {
    //     let oldValue = this._prepayItems;
    //     this._prepayItems = v;
    //     super.notifyFieldChange('prepayItems', oldValue, v);
    // }
    /**
    * toString
    */
    toString() {
        if (super.toString()) {
            return `Заказ ${super.toString()}`;
        }
        return '';
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Организация',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmOrganization',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_organization", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Контрагент',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPartner',
        required: true,
        standart: false
    })
], OrmCustomerOrder.prototype, "_partner", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Договор',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPartnerContract',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_contract", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Способ оплаты',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPaymentOption',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_paymentOption", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
], OrmCustomerOrder.prototype, "_priceType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Склад',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmWarehouse',
        required: true,
        standart: false
    })
], OrmCustomerOrder.prototype, "_warehouse", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Касса',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmCashBox',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_cashBox", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Карта лояльности',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmLoyaltyCard',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_loyaltyCard", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Купон',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmCoupon',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_coupon", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Менеджер',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmUser',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_manager", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Статус',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'customer_order_statuses',
        required: true,
        standart: false
    })
], OrmCustomerOrder.prototype, "_status", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Сумма',
        type: 'number',
        required: true,
        standart: false
    })
], OrmCustomerOrder.prototype, "_amount", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Примечание',
        type: 'string',
        required: false,
        standart: false
    })
], OrmCustomerOrder.prototype, "_note", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Товары',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmCustomerOrderProductItem',
        required: true,
        standart: false
    })
], OrmCustomerOrder.prototype, "_productItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Набор контактов',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmCustomerOrderContactSet',
        required: true,
        standart: false
    })
], OrmCustomerOrder.prototype, "_contactSet", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmOrganization)
], OrmCustomerOrder.prototype, "organization", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPartner)
], OrmCustomerOrder.prototype, "partner", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPartnerContract)
], OrmCustomerOrder.prototype, "contract", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPaymentOption)
], OrmCustomerOrder.prototype, "paymentOption", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType)
], OrmCustomerOrder.prototype, "priceType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmWarehouse)
], OrmCustomerOrder.prototype, "warehouse", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmCashBox)
], OrmCustomerOrder.prototype, "cashBox", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmLoyaltyCard)
], OrmCustomerOrder.prototype, "loyaltyCard", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmCoupon)
], OrmCustomerOrder.prototype, "coupon", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmUser)
], OrmCustomerOrder.prototype, "manager", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmCustomerOrder.prototype, "status", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrder.prototype, "amount", null);
tslib_1.__decorate([
    Expose()
], OrmCustomerOrder.prototype, "note", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmCustomerOrderProductItem)
], OrmCustomerOrder.prototype, "productItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmCustomerOrderProductItem)
], OrmCustomerOrder.prototype, "productItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmCustomerOrderContactSet)
], OrmCustomerOrder.prototype, "contactSet", null);
OrmCustomerOrder = tslib_1.__decorate([
    NatModel({
        plural: 'orders',
        displayName: 'Заказ покупателя',
        pluralDisplayName: 'Заказы покупателя'
    })
], OrmCustomerOrder);
export { OrmCustomerOrder };
// "contactSet": {
//     "displayName": "Набор контактов",
//     "type": "embedsOne",
//     "model": "OrmCustomerOrderContactSet",
//     "property": "contactSetValue",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Значение набора контактов",
//             "type": "OrmCustomerOrderContactSet",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": {
//             "relation": "contacts",
//             "scope": {
//                 "include": ["type", "kind"]
//             }
//         }
//     }
// },
// "productItems": {
//     "displayName": "Товары",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderProductItem",
//     "as": "productItems",
//     "property": "productItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список товаров",
//             "type": "OrmCustomerOrderProductItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": ["product", "feature", "unit", "priceType", "warehouse"]
//     }
// },
// "prepayItems": {
//     "displayName": "Предоплаты",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderPrepayItem",
//     "as": "prepayItems",
//     "property": "prepayItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список предоплат",
//             "type": "OrmCustomerOrderPrepayItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": [
//             "document"
//         ]
//     }
// },
// "loyaltyPolicyItems": {
//     "displayName": "Политики лояльности",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderLoyaltyPolicyItem",
//     "as": "loyaltyPolicyItems",
//     "property": "loyaltyPolicyItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список политик лояльности",
//             "type": "OrmCustomerOrderLoyaltyPolicyItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": ["loyaltyPolicy"]
//     }
// },
// "bonusPointIncomeItems": {
//     "displayName": "Начисленные бонусы",
//     "type": "embedsMany",
//     "model": "OrmCustomerOrderBonusPointIncomeItem",
//     "as": "bonusPointIncomeItems",
//     "property": "bonusPointIncomeItemList",
//     "options": {
//         "validate": true,
//         "forceId": false,
//         "property": {
//             "displayName": "Список начисленных бонусов",
//             "type": "OrmCustomerOrderBonusPointIncomeItem",
//             "default": null
//         }
//     },
//     "scope": {
//         "include": ["loyaltyProgram", "product", "feature", "unit"]
//     }
// },
// "acquiringOperations": {
//     "displayName": "Экваринговые операции",
//     "type": "hasMany",
//     "model": "OrmAcquiringOperation",
//     "foreignKey": "ownerId"
// },
// "cashlessReceipts": {
//     "displayName": "Поступления безналичных денежных средств",
//     "type": "hasMany",
//     "model": "OrmCashlessReceipt",
//     "foreignKey": "ownerId"
// },
// "cashlessWriteOffs": {
//     "displayName": "Списания безналичных денежных средств",
//     "type": "hasMany",
//     "model": "OrmCashlessWriteOff",
//     "foreignKey": "ownerId"
// },
// "incomeCashOrders": {
//     "displayName": "Приходные кассовые ордера",
//     "type": "hasMany",
//     "model": "OrmIncomeCashOrder",
//     "foreignKey": "ownerId"
// },
// "refundsOfProducts": {
//     "displayName": "Возвраты товаров",
//     "type": "hasMany",
//     "model": "OrmRefundOfProducts",
//     "foreignKey": "ownerId"
// },
// "saleOfProducts": {
//     "displayName": "Реализации товаров и услуг",
//     "type": "hasMany",
//     "model": "OrmSaleOfProducts",
//     "foreignKey": "ownerId"
// },
// "checks": {
//     "displayName": "Чеки продажи",
//     "type": "hasMany",
//     "model": "OrmCheck",
//     "foreignKey": "customerOrderId"
// },
// "checkRefunds": {
//     "displayName": "Чеки возврата",
//     "type": "hasMany",
//     "model": "OrmCheckRefund",
//     "foreignKey": "customerOrderId"
// }
