import { NatKeyValueObserver } from '@natiwi/core/models/observer';
import { NatObservable } from '@natiwi/core/models/observable';

export abstract class OrmModel extends NatObservable<NatKeyValueObserver<any, any>> {
    constructor() {
        super();
    }

    protected notifyFieldChange<T>(fieldName: string, oldValue: T, newValue: T, forcePropagate: boolean = false): void {
        if (oldValue === newValue && !forcePropagate) {
            return;
        }
        if (this.getObservers() && this.getObservers().length) {
            for (let observer of this.getObservers()) {
                let key = observer.key;
                if (!key || key === fieldName) {
                    observer.onChangeKeyValue(this, fieldName, oldValue, newValue);
                }
            }
            console.log(`${this.constructor.name}.${fieldName} = ${newValue}`)
        }
    }
}