import * as tslib_1 from "tslib";
import { NatOrder } from "@natiwi/core/network/shared/network.enum";
import { Expose, Transform } from "class-transformer";
const ɵ0 = (value, obj, type) => {
    if (value && !value.size) {
        return undefined;
    }
    return value;
}, ɵ1 = value => {
    if (value && !value.length) {
        return undefined;
    }
    return value;
}, ɵ2 = value => {
    if (value && !value.length) {
        return undefined;
    }
    let orderList = new Array();
    value.forEach(element => {
        let order = `${element.property} ${element.order}`;
        orderList.push(order);
    });
    return orderList;
}, ɵ3 = value => {
    if (value && !value.size) {
        return undefined;
    }
    return value;
}, ɵ4 = value => {
    if (value && !value.size) {
        return undefined;
    }
    return value;
}, ɵ5 = value => {
    if (value && !value.size) {
        return undefined;
    }
    return value;
};
export class NatFilter {
    constructor() { }
    get where() {
        if (!this._where) {
            this._where = new NatFilterWhere();
        }
        return this._where;
    }
    set where(v) {
        this._where = v;
    }
    get include() {
        if (!this._include) {
            this._include = new Array();
        }
        return this._include;
    }
    set include(v) {
        this._include = v;
    }
    get order() {
        if (!this._order) {
            this._order = new Array();
        }
        return this._order;
    }
    set order(v) {
        this._order = v;
    }
    get skip() {
        return this._skip;
    }
    set skip(v) {
        this._skip = v;
    }
    get limit() {
        return this._limit;
    }
    set limit(v) {
        this._limit = v;
    }
    get aggregate() {
        if (!this._aggregate) {
            this._aggregate = new Array();
        }
        return this._aggregate;
    }
    set aggregate(v) {
        this._aggregate = v;
    }
    get fields() {
        if (!this._fields) {
            this._fields = new NatFilterFields();
        }
        return this._fields;
    }
    set fields(v) {
        this._fields = v;
    }
    /**
     * merge
     */
    merge(item) {
        let filter = this;
        let mergedFilter = new NatFilter();
        if (filter.where || item.where) {
            let andOperator = new NatFilterOperatorAnd();
            andOperator.push(item.where, filter.where);
            mergedFilter.where.set('and', andOperator);
        }
        if (filter.include || item.include) {
            mergedFilter.include = filter.include.concat(filter.include, item.include);
        }
        if (filter.order || item.order) {
            mergedFilter.order = filter.order.concat(filter.order, item.order);
        }
        if (filter.skip || item.skip) {
            mergedFilter.skip = item.skip;
        }
        if (filter.limit || item.limit) {
            mergedFilter.limit = item.limit;
        }
        return mergedFilter;
    }
}
tslib_1.__decorate([
    Expose(),
    Transform(ɵ0)
], NatFilter.prototype, "where", null);
tslib_1.__decorate([
    Expose(),
    Transform(ɵ1)
], NatFilter.prototype, "include", null);
tslib_1.__decorate([
    Expose(),
    Transform(ɵ2)
], NatFilter.prototype, "order", null);
tslib_1.__decorate([
    Expose()
], NatFilter.prototype, "skip", null);
tslib_1.__decorate([
    Expose()
], NatFilter.prototype, "limit", null);
tslib_1.__decorate([
    Expose(),
    Transform(ɵ3),
    Expose(),
    Transform(ɵ4)
], NatFilter.prototype, "aggregate", null);
tslib_1.__decorate([
    Expose(),
    Transform(ɵ5)
], NatFilter.prototype, "fields", null);
export class NatFilterWhere extends Map {
    constructor() {
        super();
    }
}
const ɵ6 = value => {
    if (!value) {
        return undefined;
    }
    return value;
};
export class NatFilterInclude {
    constructor(relation, scope) {
        this.relation = relation;
        this.scope = scope;
    }
    get relation() {
        return this._relation;
    }
    set relation(v) {
        this._relation = v;
    }
    get scope() {
        // if (!this._scope) {
        //     this._scope = new NatFilter();
        // }
        return this._scope;
    }
    set scope(v) {
        this._scope = v;
    }
}
tslib_1.__decorate([
    Expose()
], NatFilterInclude.prototype, "relation", null);
tslib_1.__decorate([
    Expose(),
    Transform(ɵ6)
], NatFilterInclude.prototype, "scope", null);
export class NatFilterOrder {
    constructor(property, order = NatOrder.ASC) {
        this._property = property;
        this._order = order;
    }
    get property() {
        return this._property;
    }
    get order() {
        return this._order;
    }
}
let NatFilterAggregate = class NatFilterAggregate {
    constructor() { }
};
NatFilterAggregate = tslib_1.__decorate([
    Expose()
], NatFilterAggregate);
let NatFilterFields = class NatFilterFields {
    constructor() { }
};
NatFilterFields = tslib_1.__decorate([
    Expose()
], NatFilterFields);
// export class NatFilterOperatorAnd extends NatFilterWhere {
//     constructor() {
//         super();
//     }
//     set(value: any): this {
//         let values = []
//         if (super.has('and')) {
//             values = super.get('and');
//         }
//         values.push(value);
//         return super.set('and', values);
//     }
// }
export class NatFilterOperatorRegExp extends NatFilterWhere {
    constructor() {
        super();
    }
    set(value, flags = ['i', 'g']) {
        let regexpFlags = flags.join('');
        let regexp = new RegExp(value, regexpFlags);
        return super.set('regexp', regexp);
    }
}
export class NatFilterOperatorAnd extends Array {
    constructor() {
        super();
    }
}
export class NatFilterOperatorOr extends Array {
    constructor() {
        super();
    }
}
export class NatFilterOperatorNeq extends NatFilterWhere {
    constructor() {
        super();
    }
    set(key, value) {
        let newValue = {
            neq: value
        };
        return super.set(key, newValue);
    }
}
export class NatFilterOperatorGt extends NatFilterWhere {
    constructor() {
        super();
    }
    set(key, value) {
        let newValue = {
            gt: value
        };
        return super.set(key, newValue);
    }
}
export class NatFilterOperatorLt extends NatFilterWhere {
    constructor() {
        super();
    }
    set(key, value) {
        let newValue = {
            lt: value
        };
        return super.set(key, newValue);
    }
}
export class NatFilterOperatorInq {
    constructor() {
        this.inq = new Array();
    }
    /**
     * push
     */
    push(...el) {
        this.inq = this.inq.concat(el);
    }
}
class NatFilterOperatorNin extends Array {
    constructor() {
        super();
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
