import { NatInformationRegisterController } from "@natiwi/core/controllers/information-register.controller";
import { OrmProductPrice } from "@natiwi/shared/models/product-price.model";
import { NatProductPriceRepository } from "@natiwi/shared/repositories/product-price.repository";

export class NatProductPriceController extends NatInformationRegisterController<OrmProductPrice>{

    constructor(_repository?: NatProductPriceRepository) {
        super(_repository);
        super.registerDataClass(OrmProductPrice);
    }
}