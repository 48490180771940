import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmOrganizationBankAccount } from "@natiwi/shared/models/organization-bank-account.model";
import { OrmResponsiblePerson } from "@natiwi/shared/models/responsible-person.model";
let OrmOrganization = class OrmOrganization extends OrmCatalog {
    constructor() {
        super();
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    get bankAccount() {
        return this._bankAccount;
    }
    set bankAccount(v) {
        let oldValue = this._bankAccount;
        this._bankAccount = v;
        super.notifyFieldChange('bankAccount', oldValue, v);
    }
    get director() {
        return this._director;
    }
    set director(v) {
        let oldValue = this._director;
        this._director = v;
        super.notifyFieldChange('director', oldValue, v);
    }
    get chiefAccountant() {
        return this._chiefAccountant;
    }
    set chiefAccountant(v) {
        let oldValue = this._chiefAccountant;
        this._chiefAccountant = v;
        super.notifyFieldChange('chiefAccountant', oldValue, v);
    }
    get itn() {
        return this._itn;
    }
    set itn(v) {
        let oldValue = this._itn;
        this._itn = v;
        super.notifyFieldChange('itn', oldValue, v);
    }
    get iec() {
        return this._iec;
    }
    set iec(v) {
        let oldValue = this._iec;
        this._iec = v;
        super.notifyFieldChange('iec', oldValue, v);
    }
    get okato() {
        return this._okato;
    }
    set okato(v) {
        let oldValue = this._okato;
        this._okato = v;
        super.notifyFieldChange('okato', oldValue, v);
    }
    get oktmo() {
        return this._oktmo;
    }
    set oktmo(v) {
        let oldValue = this._oktmo;
        this._oktmo = v;
        super.notifyFieldChange('oktmo', oldValue, v);
    }
    get psrn() {
        return this._psrn;
    }
    set psrn(v) {
        let oldValue = this._psrn;
        this._psrn = v;
        super.notifyFieldChange('psrn', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип регистрации',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'partner_ownership_types',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Банковский счет',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmOrganizationBankAccount',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_bankAccount", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Директор',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmResponsiblePerson',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_director", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Главный бухгалтер',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmResponsiblePerson',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_chiefAccountant", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'ИНН',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_itn", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'КПП',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_iec", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'ОКАТО',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_okato", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'ОКТМО',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_oktmo", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'ОГРН',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganization.prototype, "_psrn", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmOrganization.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmOrganizationBankAccount)
], OrmOrganization.prototype, "bankAccount", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmResponsiblePerson)
], OrmOrganization.prototype, "director", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmResponsiblePerson)
], OrmOrganization.prototype, "chiefAccountant", null);
tslib_1.__decorate([
    Expose()
], OrmOrganization.prototype, "itn", null);
tslib_1.__decorate([
    Expose()
], OrmOrganization.prototype, "iec", null);
tslib_1.__decorate([
    Expose()
], OrmOrganization.prototype, "okato", null);
tslib_1.__decorate([
    Expose()
], OrmOrganization.prototype, "oktmo", null);
tslib_1.__decorate([
    Expose()
], OrmOrganization.prototype, "psrn", null);
OrmOrganization = tslib_1.__decorate([
    NatModel({
        plural: 'organizations',
        displayName: 'Организация',
        pluralDisplayName: 'Организации'
    }),
    NatHierarchical(false)
], OrmOrganization);
export { OrmOrganization };
