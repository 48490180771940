import { NatFormControlHost } from "@natiwi/shared/form-controls/form-control-hosts/form-control.host";
import { OrmModel } from "@natiwi/core/models/model";
import { NatValueObservable, NatModelObservable } from "@natiwi/core/models/observable";
import { NatValueObserver, NatKeyValueObserver } from "@natiwi/core/models/observer";
import { FormGroup, FormControl } from "@angular/forms";
import { NatFilter } from "@natiwi/core/network/shared/filter";
import { NatPersistedModelController } from "@natiwi/core/controllers/persisted-model.controller";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { NatAppService } from "@natiwi/core";
import { Injector } from "@angular/core";
import { NatFormControlHostConfigure } from "@natiwi/core/forms/form";

export class NatPropertyFormControlHost<ValueType extends OrmModel, ObjectType extends OrmModel, ObservableType extends NatModelObservable<ValueType, ObjectType>> extends NatFormControlHost<ValueType, ObjectType, ObservableType> {

    private _controlName: string;
    private _formControl: FormControl;
    private _formGroup: FormGroup;
    private _observable: ObservableType;
    private _observer?: NatValueObserver<ValueType>;

    constructor(_controlName: string, _formControl: FormControl, _formGroup: FormGroup, _observable: ObservableType, private _configuration?: NatFormControlHostConfigure, _observer?: NatKeyValueObserver<ValueType, ObjectType>) {
        super(_controlName, _formControl, _formGroup, _observable, _configuration, _observer);
        this._formControl = _formControl;
        _formGroup.addControl(_controlName, this._formControl);
        this._formControl.valueChanges.subscribe(value => {
            this.controlValueChangedHandler(value, _observable);
        });
    }

    public focus() {

    }

    public blur() {

    }

    protected controlValueChangedHandler(value: ValueType, observable: ObservableType) {
        console.log('set value to model')
        observable.set(value);
    }

    static create<ValueType extends OrmModel, ObjectType extends OrmModel, ObservableType extends NatModelObservable<ValueType, ObjectType>>(controlName: string,
        formGroup: FormGroup, observable: ObservableType, configuration?: NatFormControlHostConfigure, observer?: NatKeyValueObserver<ValueType, ObjectType>): NatPropertyFormControlHost<ValueType, ObjectType, ObservableType> {
        let formControl = new FormControl(observable.get());
        if (configuration && !(configuration instanceof NatFormControlHostConfigure)) {
            throw 'Неверный тип объекта конфигурации.'
        }
        let propertyControlHost = new NatPropertyFormControlHost(controlName, formControl, formGroup, observable, configuration, observer) as NatPropertyFormControlHost<ValueType, ObjectType, ObservableType>;
        return propertyControlHost;
    }
}