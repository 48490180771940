import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmValueMetadataItem = class OrmValueMetadataItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get name() {
        return this._name;
    }
    set name(v) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    get relationType() {
        return this._relationType;
    }
    set relationType(v) {
        let oldValue = this._relationType;
        this._relationType = v;
        super.notifyFieldChange('relationType', oldValue, v);
    }
    get polymorphicRelationAcceptedModelList() {
        return this._polymorphicRelationAcceptedModelList;
    }
    set polymorphicRelationAcceptedModelList(v) {
        let oldValue = this._polymorphicRelationAcceptedModelList;
        this._polymorphicRelationAcceptedModelList = v;
        super.notifyFieldChange('polymorphicRelationAcceptedModelList', oldValue, v);
    }
    get required() {
        return this._required;
    }
    set required(v) {
        let oldValue = this._required;
        this._required = v;
        super.notifyFieldChange('required', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    get typeId() {
        return this._typeId;
    }
    set typeId(v) {
        let oldValue = this._typeId;
        this._typeId = v;
        super.notifyFieldChange('typeId', oldValue, v);
    }
    get meaning() {
        return this._meaning;
    }
    set meaning(v) {
        let oldValue = this._meaning;
        this._meaning = v;
        super.notifyFieldChange('meaning', oldValue, v);
    }
    /**
   * toString
   */
    toString() {
        return this._name || '';
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: false,
        standart: false
    })
], OrmValueMetadataItem.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'string',
        required: false,
        standart: false
    })
], OrmValueMetadataItem.prototype, "_relationType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Список принимаемых моделей',
        type: 'string',
        required: false,
        standart: false
    })
], OrmValueMetadataItem.prototype, "_polymorphicRelationAcceptedModelList", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Обязательный',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmValueMetadataItem.prototype, "_required", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'value_metadata_types',
        required: false,
        standart: false
    })
], OrmValueMetadataItem.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'string',
        required: false,
        standart: false
    })
], OrmValueMetadataItem.prototype, "_typeId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Смысловая нагрузка',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'meanings',
        required: false,
        standart: false
    })
], OrmValueMetadataItem.prototype, "_meaning", void 0);
tslib_1.__decorate([
    Expose()
], OrmValueMetadataItem.prototype, "name", null);
tslib_1.__decorate([
    Expose()
], OrmValueMetadataItem.prototype, "relationType", null);
tslib_1.__decorate([
    Expose()
], OrmValueMetadataItem.prototype, "polymorphicRelationAcceptedModelList", null);
tslib_1.__decorate([
    Expose()
], OrmValueMetadataItem.prototype, "required", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmValueMetadataItem.prototype, "type", null);
tslib_1.__decorate([
    Expose()
], OrmValueMetadataItem.prototype, "typeId", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmValueMetadataItem.prototype, "meaning", null);
OrmValueMetadataItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Метаданные значения',
        pluralDisplayName: 'Метаданные значений'
    }),
    NatHierarchical(false)
], OrmValueMetadataItem);
export { OrmValueMetadataItem };
