import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NatForm, NatFormControlHostConfigure, NatFormConfigure } from "@natiwi/core/forms/form";
import { NatPropertyErrorStateMatcher } from "@natiwi/shared/form-controls";
import { OnDestroy, Type } from "@angular/core";
import { NatKeyValueObserver } from "@natiwi/core/models/observer";
import { NatRestController } from "@natiwi/core/controllers/rest.controller";
import { OrmModel } from "@natiwi/core/models/model";
import { NatRelationFormControlHost } from "@natiwi/shared/form-controls/form-control-hosts/relation-form-control.host";
import { NatPropertyFormControlHost } from "@natiwi/shared/form-controls/form-control-hosts/property-form-control.host";
import { NatModelObservable } from "@natiwi/core/models/observable";
import { NatAppService } from "@natiwi/core";
import { NatPersistedModelController } from "@natiwi/core/controllers/persisted-model.controller";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { NatObjectItemController } from "@natiwi/core/controllers/object-item.controller";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { NatFilter } from "@natiwi/core/network/shared/filter";
import { NatFormControlHost } from "@natiwi/shared/form-controls/form-control-hosts/form-control.host";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { NatMainController } from "@natiwi/core/controllers/main.controller";
import { OrmRegister } from "@natiwi/core/models/register";
import { MatRow } from "@angular/material/table";

export abstract class NatFormDetail extends NatForm implements OnDestroy {

    private _formGroup: FormGroup;
    private _matcher: NatPropertyErrorStateMatcher;
    private _formControlHostMap: Map<string, any>;

    private _tableCellSelectedElementId: string;

    constructor(private formBuilder: FormBuilder) {
        super()
        this._matcher = new NatPropertyErrorStateMatcher();
        this._formControlHostMap = new Map();
        this.initForm();
    }

    public get tableCellSelectedElementId(): string {
        return this._tableCellSelectedElementId;
    }

    public get formGroup(): FormGroup {
        return this._formGroup;
    }

    public get formControlHostMap(): Map<string, any> {
        return this._formControlHostMap;
    }

    public get matcher(): NatPropertyErrorStateMatcher {
        return this._matcher;
    }

    private initForm() {
        this._formGroup = this.formBuilder.group({}, { updateOn: "blur" })
    }

    protected initFromControlHosts<T extends OrmPersistedModel>(object: T, helper: NatAppService, config?: NatFormConfigure) {
        if (!object) {
            console.warn('Невозможно создать/обновить элементы управления формы объекта. Отсутствует объект');
            return;
        }
        let model = object.constructor.prototype;
        let controller: NatRestController<T> = helper.getController(model);
        let propertiesMetadata = controller.modelMetadata.getProperties();
        propertiesMetadata.forEach(value => {
            let host;
            let configuration: NatFormControlHostConfigure = null;
            if (config && config.has(value.propertyName)) {
                configuration = <NatFormControlHostConfigure>config.get(value.propertyName);
            }
            switch (value.type) {
                case 'relation':
                    if (value.relationType === 'embedsMany') {
                        let configuration: NatFormConfigure = null;
                        if (config) {
                            configuration = <NatFormConfigure>config.get(value.propertyName) || null;
                        }
                        this.fetchTableFromControlHosts(value.propertyName, object[value.propertyName], helper, configuration);
                    } else if (value.relationType === 'belongsTo') {
                        let modelName: string = helper.getController(value.relationModel) ? value.relationModel : controller.modelMetadata.modelName;
                        host = NatRelationFormControlHost.create(modelName, helper, value.propertyName, this._formGroup, new NatModelObservable<any, T>(object, value.propertyName), configuration)
                    }
                    break;
                case 'enumeration':
                    let modelName: string = value.enumerationType;
                    host = NatRelationFormControlHost.create(modelName, helper, value.propertyName, this._formGroup, new NatModelObservable<any, T>(object, value.propertyName), configuration)
                    break;
                default:
                    host = NatPropertyFormControlHost.create(value.propertyName, this._formGroup, new NatModelObservable<any, T>(object, value.propertyName), configuration);
                    break;
            }
            //не забыть проверить
            // type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];
            // type MyPropType = PropType<OrmCustomerOrder, 'priceType'>;
            if (host instanceof NatFormControlHost) {
                if (value.required) {
                    host.addValidator([
                        Validators.required
                    ])
                }
                this._formControlHostMap.set(value.propertyName, host)
            }
        });
    }

    protected fetchTableFromControlHosts<T extends OrmObjectItem | OrmPersistedModel | OrmRegister>(propertyName: string, objectList: T[], helper: NatAppService, config?: NatFormConfigure) {
        if (!objectList || !objectList.length) {
            console.warn('Невозможно создать/обновить элементы управления табличной части. Отсутствуют данные');
            return;
        }
        let model = objectList[0] && objectList[0].constructor.prototype;
        let controller: NatMainController<T> = helper.getController(model);
        let propertiesMetadata = controller.modelMetadata.getProperties();
        objectList.forEach(element => {
            propertiesMetadata.forEach(value => {
                let host;
                let configuration: NatFormControlHostConfigure = null;
                if (config && config.has(value.propertyName)) {
                    configuration = <NatFormControlHostConfigure>config.get(value.propertyName) || null;
                }
                if (this._formControlHostMap.has(`${element.id}_${propertyName}_${value.propertyName}`)) {
                    return;
                }
                if (value.type === 'relation' || value.type === 'enumeration') {
                    let modelName: string = value.relationModel || value.enumerationType;
                    host = NatRelationFormControlHost.create(modelName, helper, `${element.id}_${propertyName}_${value.propertyName}`, this._formGroup, new NatModelObservable<any, T>(element, value.propertyName), configuration)
                } else {
                    host = NatPropertyFormControlHost.create(`${element.id}_${propertyName}_${value.propertyName}`, this._formGroup, new NatModelObservable<any, T>(element, value.propertyName), configuration);
                }
                if (host) {
                    if (value.required) {
                        host.addValidator([
                            Validators.required
                        ])
                    }
                    this._formControlHostMap.set(`${element.id}_${propertyName}_${value.propertyName}`, host)
                }
            });
        });
    }

    /**
     * tableCellClick
     */
    public tableCellClick(id: string) {
        this._tableCellSelectedElementId = id;
    }

    /**
     * tableCellBlur
     */
    public tableCellBlur(autocomplete?: MatAutocomplete) {
        if (autocomplete && !autocomplete.isOpen) {
            this._tableCellSelectedElementId = null;
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}