import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation, NatPolymorphicProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";

@NatModel({
    displayName: '',
    pluralDisplayName: ''
})
@NatHierarchical(false)
export class OrmArgumentItem extends OrmObjectItem {
    
    @NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
    private _name: string;

    @NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
    private _description: string;

    @NatProperty({
        displayName: 'Обязательный',
        type: 'boolean',
        required: true,
        standart: false
    })
    private _required: boolean;

    @NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
    private _valueId: any
    
    @NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
    private _valueType: string;
    
    
    constructor() {
        super();
    }
    
    @Expose()
    public get name(): string {
        return this._name;
    }

    public set name(v: string) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }

    @Expose()
    public get description(): string {
        return this._description;
    }

    public set description(v: string) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }

    @Expose()
    public get required(): boolean {
        return this._required;
    }

    public set required(v: boolean) {
        let oldValue = this._required;
        this._required = v;
        super.notifyFieldChange('required', oldValue, v);
    }
    
    @Expose()
    @NatPolymorphicProperty('valueType')
    public get valueId(): any {
        return this._valueId;
    }

    public set valueId(v: any) {
        let oldValue = this._valueId;
        this._valueId = v;
        super.notifyFieldChange('valueId', oldValue, v);
    }

    @Expose()
    public get valueType(): string {
        return this._valueType;
    }

    public set valueType(v: string) {
        let oldValue = this._valueType;
        this._valueType = v;
        super.notifyFieldChange('valueType', oldValue, v);
    }
}