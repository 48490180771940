import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmReport } from "@natiwi/shared/models/report.model";
import { OrmSamplingSchemePresetItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-item.model";
let OrmReportPreset = class OrmReportPreset extends OrmCatalog {
    constructor() {
        super();
    }
    get report() {
        return this._report;
    }
    set report(v) {
        let oldValue = this._report;
        this._report = v;
        super.notifyFieldChange('report', oldValue, v);
    }
    get samplingSchemePreset() {
        return this._samplingSchemePreset;
    }
    set samplingSchemePreset(v) {
        let oldValue = this._samplingSchemePreset;
        this._samplingSchemePreset = v;
        super.notifyFieldChange('samplingSchemePreset', oldValue, v);
    }
    get samplingSchemePresetValue() {
        return this._samplingSchemePreset;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Отчет',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmReport',
        required: true,
        standart: false
    })
], OrmReportPreset.prototype, "_report", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вариант схемы выборки данных',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemePresetItem',
        required: false,
        standart: false
    })
], OrmReportPreset.prototype, "_samplingSchemePreset", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmReport)
], OrmReportPreset.prototype, "report", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetItem)
], OrmReportPreset.prototype, "samplingSchemePreset", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetItem)
], OrmReportPreset.prototype, "samplingSchemePresetValue", null);
OrmReportPreset = tslib_1.__decorate([
    NatModel({
        plural: 'reportPresets',
        displayName: 'Вариант отчета',
        pluralDisplayName: 'Варианты отчетов'
    }),
    NatHierarchical(false)
], OrmReportPreset);
export { OrmReportPreset };
