import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmSamplingSchemeItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-item.model";
let OrmReport = class OrmReport extends OrmCatalog {
    // @NatProperty({
    //     displayName: 'Варианты схемы выборки данных',
    //     type: 'relation',
    //     relationType: 'hasMany',
    //     relationModel: 'OrmReportPreset',
    //     required: false,
    //     standart: false
    // })
    // private _presets: OrmReportPreset;
    constructor() {
        super();
    }
    get samplingScheme() {
        return this._samplingScheme;
    }
    set samplingScheme(v) {
        let oldValue = this._samplingScheme;
        this._samplingScheme = v;
        super.notifyFieldChange('samplingScheme', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Схема выборки данных',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeItem',
        required: false,
        standart: false
    })
], OrmReport.prototype, "_samplingScheme", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeItem)
], OrmReport.prototype, "samplingScheme", null);
OrmReport = tslib_1.__decorate([
    NatModel({
        plural: 'reports',
        displayName: 'Отчет',
        pluralDisplayName: 'Отчеты'
    }),
    NatHierarchical(false)
], OrmReport);
export { OrmReport };
