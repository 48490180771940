import { Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmReportPreset } from "@natiwi/shared/models/report-preset.model";

export class NatReportPresetRepository extends NatCatalogRepository<OrmReportPreset> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/reportPresets';
        let repoModel: Type<OrmReportPreset> = OrmReportPreset;
        super.init(repoUrl, repoModel);
    }

}