import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPaymentOptionArgumentItem } from "@natiwi/shared/models/payment-option-argument-item.model";
import { OrmImageMetadata } from "@natiwi/shared/models/image-metadata";
import { OrmPaymentOptionKind } from "@natiwi/shared/models/payment-option-kind.model";

@NatModel({
    plural: 'paymentOptions',
    displayName: 'Способ оплаты',
    pluralDisplayName: 'Способы оплаты'
})
@NatHierarchical(false)
export class OrmPaymentOption extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
    private _description: string;
    
    @NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _enabled: boolean;
    
    @NatProperty({
        displayName: 'Основное изображение',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmImageMetadata',
        required: false,
        standart: false
    })
    private _primaryImage: OrmImageMetadata;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'payment_option_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Аргументы',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmPaymentOptionKindArgumentItem',
        required: false,
        standart: false
    })
    private _argumentItems: Array<OrmPaymentOptionArgumentItem>;
    
    @NatProperty({
        displayName: 'Владелец',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPaymentOptionKind',
        required: false,
        standart: false
    })
    private _owner: OrmPaymentOptionKind;
    private _ownerType: string = 'OrmPaymentOptionKind';
    
    constructor() {
        super();
    }
    
    @Expose()
    public get description(): string {
        return this._description;
    }

    public set description(v: string) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    
    @Expose()
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(v: boolean) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmImageMetadata)
    public get primaryImage(): OrmImageMetadata {
        return this._primaryImage;
    }

    public set primaryImage(v: OrmImageMetadata) {
        let oldValue = this._primaryImage;
        this._primaryImage = v;
        super.notifyFieldChange('primaryImage', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    //
    @Expose()
    @NatRelation(OrmPaymentOptionArgumentItem)
    public get argumentItems(): Array<OrmPaymentOptionArgumentItem> {
        return this._argumentItems;
    }

    public set argumentItems(v: Array<OrmPaymentOptionArgumentItem>) {
        let oldValue = this._argumentItems;
        this._argumentItems = v;
        super.notifyFieldChange('argumentItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmPaymentOptionArgumentItem)
    public get argumentItemList(): Array<OrmPaymentOptionArgumentItem> {
        return this._argumentItems;
    }

    public set argumentItemList(v: Array<OrmPaymentOptionArgumentItem>) {
        let oldValue = this._argumentItems;
        this._argumentItems = v;
        super.notifyFieldChange('argumentItems', oldValue, v);
    }
    //
    
    @Expose()
    @NatRelation(OrmPaymentOptionKind)
    public get owner(): OrmPaymentOptionKind {
        return this._owner;
    }

    public set owner(v: OrmPaymentOptionKind) {
        let oldValue = this._owner;
        this._owner = v;
        super.notifyFieldChange('owner', oldValue, v);
    }
    
    @Expose()
    public get ownerType(): string {
        return this._ownerType;
    }
}