import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { Observable } from "rxjs";
import { classToPlain, plainToClass } from "class-transformer";
import { map } from "rxjs/operators";
import { OrmRegister } from "@natiwi/core/models/register";
import { Type } from "@angular/core";
import { NatModelRepository } from "@natiwi/core/network/repositories/model.repository";
import { natClassToPlain, natPlainToClass } from "@natiwi/core/network/repositories/rest.repository";

export abstract class NatRegisterRepository<T extends OrmRegister> extends NatModelRepository<T> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        super.createСontractItem('bulkUpsert', '/bulkUpsert', NatVerbType.POST);
    }

    public bulkUpsert(data: Array<T>): Observable<Array<T>> {
        let stringlifyData: string = natClassToPlain(this.dataClass, data);
        let request: Observable<Array<T>> = this.invokeStaticMethod<Array<T>>('bulkUpsert', null, stringlifyData);
        return request.pipe(map(response => {
            return natPlainToClass(this.dataClass, response) as Array<T>;
        }));
    }
}