import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatRestContract } from "@natiwi/core/network/rest-contract";
import { NatVerbType, NatParameterEncoding } from "@natiwi/core/network/shared/network.enum";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Type } from "@angular/core";
import { NatRestContractItem } from "@natiwi/core/network/rest-contract-item";
import { plainToClass, classToPlain } from "class-transformer";
import { getModelFromGlobalStore } from "@natiwi/core/network/shared/global-variables";

export abstract class NatRestRepository<T> {
    private _dataClass: Type<T>;
    private _restContract: NatRestContract;
    private _baseRepoUrl: string;
    protected _restAdapter: NatRestAdapter;

    constructor(adapter?: NatRestAdapter) {
        this._restContract = new NatRestContract();
        if (adapter) {
            this._restAdapter = adapter;
        }
    }

    public get restAdapter(): NatRestAdapter {
        return this._restAdapter;
    }

    public set restAdapter(v: NatRestAdapter) {
        this._restAdapter = v;
    }

    public get dataClass(): Type<T> {
        return this._dataClass;
    }

    protected get restContract(): NatRestContract {
        return this._restContract;
    }

    protected init(baseRepoUrl: string, dataClass: Type<T>) {
        this._dataClass = dataClass;
        this._baseRepoUrl = baseRepoUrl;
    }

    protected createСontractItem(methodName: string, urlPattern: string, type: NatVerbType = NatVerbType.GET, parameterEncoding: NatParameterEncoding = NatParameterEncoding.JSON) {
        let contractItem: NatRestContractItem = new NatRestContractItem(methodName, urlPattern, type, parameterEncoding)
        this.restContract.addСontractItem(contractItem);
    }

    //depricated
    protected addСontractItemInContract(contractItemList: Array<NatRestContractItem>) {
        contractItemList.forEach(element => {
            this.restContract.addСontractItem(element);
        });
    }

    /**
     * invokeStaticMethod
     */
    protected invokeStaticMethod<K>(method: string, params?: Map<string, string | Date>, bodyParams?: any): Observable<K> {
        let url: string = `${this._baseRepoUrl}${this.restContract.getUrlForMethod(method, params)}`;
        let verbType: NatVerbType = this.restContract.getVerbForMethod(method);
        let result: Observable<K> = this.restAdapter.execute<K>(verbType, url, bodyParams);
        return result;
    }

    /**
     * isAdapterExist
     */
    public isAdapterExist(): boolean {
        return !!this._restAdapter;
    }
}

export function natPlainToClass<T>(dataClass: Type<T>, obj: T | T[]): T | T[] {
    const model = dataClass;
    if (!model) {
        console.error('Модель обязательна');
        return;
    }
    const keys: any[] = Reflect.getMetadataKeys(model.prototype);
    keys.filter(key => key.toString().startsWith(`natiwi:mixins:polymorphicRelation:`))
        .forEach(key => {
            const metadata = <Map<string, string>>Reflect.getMetadata(key, model.prototype);
            metadata.forEach((value, key) => {
                if (Array.isArray(obj)) {
                    obj.forEach(object => {
                        if (object[key] && object[value]) {
                            object[key][value] = object[value]
                        }
                    });
                } else {
                    if (obj[key] && obj[value]) {
                        obj[key][value] = obj[value]
                    }
                }
            });
        }, []);
    keys.filter(key => key.toString().startsWith(`natiwi:mixins:polymorphicProperty:`))
        .forEach(key => {
            const metadata = <Map<string, string>>Reflect.getMetadata(key, model.prototype);
            metadata.forEach((value, key) => {
                if (Array.isArray(obj)) {
                    obj.forEach(object => {
                        if (object[value] === 'relation') {
                            let model = <any>getModelFromGlobalStore(object['valueRelationType']);
                            if (!model) return
                            object['value'] = natPlainToClass(model, object['value']);
                        }
                    });
                } else {
                    if (obj[value] === 'relation') {
                        let model = <any>getModelFromGlobalStore(obj['valueRelationType']);
                        if (!model) return
                        obj['value'] = natPlainToClass(model, obj['value']);
                    }
                }
            });
        }, []);
    let result = plainToClass(dataClass, obj,
        {
            excludeExtraneousValues: true
        }
    );
    return result;
}

export function natClassToPlain<T>(dataClass: Type<T>, obj: T | T[]): string {
    let plainedObject = classToPlain(obj, { excludePrefixes: ["_"], enableCircularCheck: true })
    let transform = function <K>(plainedObject: Object, orignObject: K) {
        if (!plainedObject || !orignObject) {
            return;
        }
        if (Array.isArray(plainedObject)) {
            plainedObject.forEach((value, index) => {
                transform(value, orignObject[index]);
            });
        } else if (plainedObject instanceof Object) {
            for (let key in plainedObject) {
                if (plainedObject[key] && plainedObject[key]['id']) {
                    plainedObject[`${key}Id`] = plainedObject[key]['id'];
                }
                if (plainedObject[key] instanceof Object) {
                    let newOrignObject;
                    if (orignObject instanceof Map) {
                        newOrignObject = orignObject.get(key);
                    } else if (orignObject instanceof Object) {
                        newOrignObject = orignObject[key];
                    }
                    if (newOrignObject instanceof RegExp) {
                        plainedObject[key] = newOrignObject.toString();
                    }
                    transform(plainedObject[key], newOrignObject);
                }
            }
        } 
       
    }
    let result;    
    transform(plainedObject, obj);
    result = plainedObject;
    result = JSON.stringify(plainedObject);
    return result;
}