import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
let OrmSamplingSchemePresetSelectedFieldItem = class OrmSamplingSchemePresetSelectedFieldItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get elementType() {
        return this._elementType;
    }
    set elementType(v) {
        let oldValue = this._elementType;
        this._elementType = v;
        super.notifyFieldChange('elementType', oldValue, v);
    }
    get enabled() {
        return this._enabled;
    }
    set enabled(v) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    get field() {
        return this._field;
    }
    set field(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    //
    get fieldValue() {
        return this._field;
    }
    set fieldValue(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    //
    get fieldId() {
        return this._field;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип элемента',
        type: 'number',
        required: true,
        standart: false
    })
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "_elementType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "_enabled", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "_field", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "elementType", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "enabled", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "field", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "fieldValue", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemePresetSelectedFieldItem.prototype, "fieldId", null);
OrmSamplingSchemePresetSelectedFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Отображаемое поле',
        pluralDisplayName: 'Отображаемые поля'
    }),
    NatHierarchical(false)
], OrmSamplingSchemePresetSelectedFieldItem);
export { OrmSamplingSchemePresetSelectedFieldItem };
