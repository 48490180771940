import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";

@NatModel({
    plural: 'productSegments',
    displayName: 'Сегмент номенклатуры',
    pluralDisplayName: 'Сегменты номенклатуры'
})
export class OrmProductSegment extends OrmCatalog {

    constructor() {
        super();
    }
}