import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPaymentOptionKindArgumentItem } from "@natiwi/shared/models/payment-option-kind-argument-item.model";
let OrmPaymentOptionKind = class OrmPaymentOptionKind extends OrmCatalog {
    constructor() {
        super();
    }
    get description() {
        return this._description;
    }
    set description(v) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    get processingModule() {
        return this._processingModule;
    }
    set processingModule(v) {
        let oldValue = this._processingModule;
        this._processingModule = v;
        super.notifyFieldChange('processingModule', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    //
    get argumentItems() {
        return this._argumentItems;
    }
    set argumentItems(v) {
        let oldValue = this._argumentItems;
        this._argumentItems = v;
        super.notifyFieldChange('argumentItems', oldValue, v);
    }
    get argumentItemList() {
        return this._argumentItems;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPaymentOptionKind.prototype, "_description", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Модуль обработки',
        type: 'string',
        required: true,
        standart: false
    })
], OrmPaymentOptionKind.prototype, "_processingModule", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'payment_option_types',
        required: false,
        standart: false
    })
], OrmPaymentOptionKind.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Аргументы',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmPaymentOptionKindArgumentItem',
        required: false,
        standart: false
    })
], OrmPaymentOptionKind.prototype, "_argumentItems", void 0);
tslib_1.__decorate([
    Expose()
], OrmPaymentOptionKind.prototype, "description", null);
tslib_1.__decorate([
    Expose()
], OrmPaymentOptionKind.prototype, "processingModule", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPaymentOptionKind.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPaymentOptionKindArgumentItem)
], OrmPaymentOptionKind.prototype, "argumentItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPaymentOptionKindArgumentItem)
], OrmPaymentOptionKind.prototype, "argumentItemList", null);
OrmPaymentOptionKind = tslib_1.__decorate([
    NatModel({
        displayName: 'Вид способа оплаты',
        pluralDisplayName: 'Виды способов оплаты'
    }),
    NatHierarchical(false)
], OrmPaymentOptionKind);
export { OrmPaymentOptionKind };
