import { Component, OnInit, Inject, Type } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrmCatalog } from '@natiwi/core/models/catalog';
import { FormBuilder, Validators } from '@angular/forms';
import { NatCatalogController } from '@natiwi/core/controllers/catalog.controller';
import { NatPropertyFormControlHost } from '@natiwi/shared/form-controls/form-control-hosts/property-form-control.host';
import { NatModelObservable } from '@natiwi/core/models/observable';
import { NatKeyValueObserver } from '@natiwi/core/models/observer';
import { Observable } from 'rxjs';
import { NatFormDetailCatalog } from '@natiwi/core/forms';

interface NatGroupHelper<Controller extends NatCatalogController<Model>, Model extends OrmCatalog> {
  controller: Controller;
  object: Model;
  parent: Model;
}

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css']
})
export class NatGroupDetailComponent<Controller extends NatCatalogController<Model>, Model extends OrmCatalog> extends NatFormDetailCatalog implements OnInit {

  private ObjectType: Type<Model>;
  private _controller: Controller;
  private _dataOfObject: Map<string, any>;  //костылинище
  isLoading: boolean;
  object: Model;

  constructor(public dialogRef: MatDialogRef<NatGroupDetailComponent<Controller, Model>>,
    @Inject(MAT_DIALOG_DATA) public data: NatGroupHelper<Controller, Model>, private _formBuilder: FormBuilder) {
    super(_formBuilder);
    this._controller = this.data.controller;
    this.ObjectType = this._controller.dataClass;
    this._dataOfObject = new Map();
  }
  
  // private mergeObject(targetObject: Model, sourceObject: Model): Model {
  //   for (let key in sourceObject) {
  //     if (!(targetObject[key] instanceof Object)) {
  //       targetObject[key] = sourceObject[key];
  //     }
  //   }
  //   return targetObject;
  // }

  ngOnInit() {
    if (this.data && this.data.object) {
      this.object = this.data.object;
      this._dataOfObject.set('name', this.object.name);//костылинище
      this._dataOfObject.set('priority', this.object.priority);//костылинище
    } else {
      this.object = new this.ObjectType();
      this.object.elementType = 1;
      this.object.parent = this.data.parent;
    }
    let nameControlHost = NatPropertyFormControlHost.create('name', super.formGroup, new NatModelObservable<any, Model>(this.object, 'name'));
    nameControlHost.addValidator([
      Validators.required
    ]);
    let priorityControlHost = NatPropertyFormControlHost.create('priority', super.formGroup, new NatModelObservable<any, Model>(this.object, 'priority'));
    this.dialogRef.backdropClick().subscribe(()=>{
      this.object.name = this._dataOfObject.has('name') && this._dataOfObject.get('name');//костылинище
      this.object.priority = this._dataOfObject.has('priority') && this._dataOfObject.get('priority');//костылинище
      
    })
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.object.name = this._dataOfObject.has('name') && this._dataOfObject.get('name');//костылинище
      this.object.priority = this._dataOfObject.has('priority') && this._dataOfObject.get('priority');//костылинище
      }
    })
  }

  bulkUpsert(): void {
    if (!this.formGroup.valid) {
      throw 'Заполните обязательные поля'
    }
    this.isLoading = true;
    this._controller.bulkUpsert([this.object]).subscribe(result => {
      this.isLoading = false;
      if (result && result.length) {
        this.object = super.mergeObject(this.object, result[0]);
      }
      this.dialogRef.close(this.object);
    });
  }
  
  makeDelete(): void {
    if (!this.object.id) {
      throw 'Нельзя удалить новую папку'
    }
    this.isLoading = true;
    this._controller.makeDeleted(this.object).subscribe(result => {
      this.isLoading = false;
      if (result) {
        this.object = super.mergeObject(this.object, result);
      }
    });
  }

  cancel(): void {
    this.object.name = this._dataOfObject.has('name') && this._dataOfObject.get('name');//костылинище
    this.object.priority = this._dataOfObject.has('priority') && this._dataOfObject.get('priority');//костылинище    
    this.dialogRef.close();
  }
  
 

}
