import { Subject } from "rxjs";
import { NatValueObserver, NatKeyValueObserver } from "@natiwi/core/models/observer";
import { OrmModel } from "@natiwi/core/models/model";

export interface NatIObservable<Observer> {

    registerObserver(observer: Observer);

    unregisterObserver(observer: Observer);

    unregisterAll();
}

export abstract class NatObservable<Observer> implements NatIObservable<Observer>{

    private _observers: Array<Observer>;

    constructor() {
        this._observers = new Array();
    }

    private isExist(observer: Observer) {
        return !!this._observers.find((o) => o === observer);
    }

    public registerObserver(observer: Observer): void {
        if (!observer) {
            throw 'Cлушатель является обязательным';
        }
        if (this.isExist(observer)) {
            throw 'Данный слушатель уже был зарегистрирован';
        }
        this._observers.push(observer);
    }

    public unregisterObserver(observer: Observer): void {
        if (!observer) {
            throw 'Cлушатель является обязательным';
        }
        if (!this.isExist(observer)) {
            throw 'Данный слушатель не был зарегистрирован';
        }
        this._observers.splice(this._observers.indexOf(observer), 1);
    }

    public unregisterAll(): void {
        this._observers.length = 0;
    }

    public getObservers(): Array<Observer> {
        return this._observers;
    }

}

export class NatValueObservable<Observer extends NatValueObserver<ValueType>, ValueType> extends NatObservable<Observer> {

    constructor(private _value: ValueType) {
        super();
    }

    /**
     * set
     */
    public set(value: ValueType) {
        let oldValue = this._value;
        this._value = value;
        this.notifyChanged(oldValue, value);
    }

    /**
     * get
     */
    public get(): ValueType {
        return this._value;
    }

    public notifyChanged(oldValue: ValueType, value: ValueType) {
        if (this.getObservers() && this.getObservers().length) {
            for (let observer of this.getObservers()) {
                observer.onChange(oldValue, value);
            }
        }
    }

}

export abstract class NatKeyValueObservable<ValueType, ObjectType> extends NatValueObservable<NatKeyValueObserver<ValueType, ObjectType>, ValueType> {

    constructor(private __object: ObjectType, private __key: string) {
        super(null);
    }

    public get key(): string {
        return this.__key;
    }
    
    /**
     * set
     */
    public set(value: ValueType) {
        let oldValue = this.get();
        this.__object[this.__key]= value;
    }
    
    /**
     * get
     */
    public get(): ValueType {
        return this.__object[this.__key];
    }

    public notifyChanged(oldValue: ValueType, newValue: ValueType) {
        if (this.getObservers() && this.getObservers().length) {
            this.getObservers().forEach(observer => {
                observer.onChangeKeyValue(this.__object, this.__key, oldValue, newValue);
            });
        }
    }

}

export class NatModelObservable<ValueType, ObjectType extends OrmModel> extends NatKeyValueObservable<ValueType, ObjectType> {

    constructor(private _object: ObjectType, private _key: string) {
        super(_object, _key);
        _object.registerObserver(new NatKeyValueObserver<ValueType, ObjectType>(_key, (object, key, oldValue, newValue) => {
            this.notifyChanged(oldValue, newValue);
        }));
    }
    
    /**
     * getObservableObject
     */
    public getObservableObject(): ObjectType {
        return this._object;
    }
}