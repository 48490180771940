import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmWarehouse = class OrmWarehouse extends OrmCatalog {
    constructor() {
        super();
    }
    get retailPriceType() {
        return this._retailPriceType;
    }
    set retailPriceType(v) {
        let oldValue = this._retailPriceType;
        this._retailPriceType = v;
        super.notifyFieldChange('retailPriceType', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Розничный тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: false,
        standart: false
    })
], OrmWarehouse.prototype, "_retailPriceType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'warehouse_types',
        required: false,
        standart: false
    })
], OrmWarehouse.prototype, "_type", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType)
], OrmWarehouse.prototype, "retailPriceType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmWarehouse.prototype, "type", null);
OrmWarehouse = tslib_1.__decorate([
    NatModel({
        plural: 'warehouses',
        displayName: 'Склад',
        pluralDisplayName: 'Склады'
    })
], OrmWarehouse);
export { OrmWarehouse };
