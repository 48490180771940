import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation, NatPolymorphicProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeParameterFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-parameter-field-item.model";
let OrmSamplingSchemePresetParameterFieldItem = class OrmSamplingSchemePresetParameterFieldItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get valueId() {
        return this._valueId;
    }
    set valueId(v) {
        let oldValue = this._valueId;
        this._valueId = v;
        super.notifyFieldChange('valueId', oldValue, v);
    }
    get valueType() {
        return this._valueType;
    }
    set valueType(v) {
        let oldValue = this._valueType;
        this._valueType = v;
        super.notifyFieldChange('valueType', oldValue, v);
    }
    get parameterField() {
        return this._parameterField;
    }
    set parameterField(v) {
        let oldValue = this._parameterField;
        this._parameterField = v;
        super.notifyFieldChange('parameterField', oldValue, v);
    }
    get parameterFieldValue() {
        return this._parameterField;
    }
    set parameterFieldValue(v) {
        let oldValue = this._parameterField;
        this._parameterField = v;
        super.notifyFieldChange('parameterField', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetParameterFieldItem.prototype, "_valueId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetParameterFieldItem.prototype, "_valueType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поле параметра',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeParameterFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetParameterFieldItem.prototype, "_parameterField", void 0);
tslib_1.__decorate([
    Expose(),
    NatPolymorphicProperty('valueType')
], OrmSamplingSchemePresetParameterFieldItem.prototype, "valueId", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetParameterFieldItem.prototype, "valueType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeParameterFieldItem)
], OrmSamplingSchemePresetParameterFieldItem.prototype, "parameterField", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeParameterFieldItem)
], OrmSamplingSchemePresetParameterFieldItem.prototype, "parameterFieldValue", null);
OrmSamplingSchemePresetParameterFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Отображаемое поле',
        pluralDisplayName: 'Отображаемые поля'
    }),
    NatHierarchical(false)
], OrmSamplingSchemePresetParameterFieldItem);
export { OrmSamplingSchemePresetParameterFieldItem };
