import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemePresetSelectedFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-selected-field-item.model";
import { OrmSamplingSchemePresetConditionFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-condition-field-item.model";
import { OrmSamplingSchemePresetParameterFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-parameter-field-item.model";
import { OrmSamplingSchemePresetOrderFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-order-field-item.model";
import { OrmSamplingSchemePresetStructureListFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-structure-list-field-item.model";
let OrmSamplingSchemePresetItem = class OrmSamplingSchemePresetItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get skip() {
        return this._skip;
    }
    set skip(v) {
        let oldValue = this._skip;
        this._skip = v;
        super.notifyFieldChange('skip', oldValue, v);
    }
    get limit() {
        return this._limit;
    }
    set limit(v) {
        let oldValue = this._limit;
        this._limit = v;
        super.notifyFieldChange('limit', oldValue, v);
    }
    //
    get selectedFieldItems() {
        return this._selectedFieldItems;
    }
    set selectedFieldItems(v) {
        let oldValue = this._selectedFieldItems;
        this._selectedFieldItems = v;
        super.notifyFieldChange('selectedFieldItems', oldValue, v);
    }
    get selectedFieldItemList() {
        return this._selectedFieldItems;
    }
    set selectedFieldItemList(v) {
        let oldValue = this._selectedFieldItems;
        this._selectedFieldItems = v;
        super.notifyFieldChange('selectedFieldItems', oldValue, v);
    }
    //
    get conditionFieldItems() {
        return this._conditionFieldItems;
    }
    set conditionFieldItems(v) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
    get conditionFieldItemList() {
        return this._conditionFieldItems;
    }
    set conditionFieldItemList(v) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
    //
    get parameterFieldItems() {
        return this._parameterFieldItems;
    }
    set parameterFieldItems(v) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
    get parameterFieldItemList() {
        return this._parameterFieldItems;
    }
    set parameterFieldItemList(v) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
    //
    get orderFieldItems() {
        return this._orderFieldItems;
    }
    set orderFieldItems(v) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
    get orderFieldItemList() {
        return this._orderFieldItems;
    }
    set orderFieldItemList(v) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
    //
    get structureFieldItems() {
        return this._structureFieldItems;
    }
    set structureFieldItems(v) {
        let oldValue = this._structureFieldItems;
        this._structureFieldItems = v;
        super.notifyFieldChange('structureFieldItems', oldValue, v);
    }
    get structureFieldItemList() {
        return this._structureFieldItems;
    }
    set structureFieldItemList(v) {
        let oldValue = this._structureFieldItems;
        this._structureFieldItems = v;
        super.notifyFieldChange('structureFieldItems', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Количество пропускаемых элементов',
        type: 'number',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetItem.prototype, "_skip", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Максимальное количество элементов в выборке',
        type: 'number',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetItem.prototype, "_limit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Выбранные поля',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetSelectedFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetItem.prototype, "_selectedFieldItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Условия',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetConditionFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetItem.prototype, "_conditionFieldItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Параметры',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetParameterFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetItem.prototype, "_parameterFieldItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Сортировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetOrderFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetItem.prototype, "_orderFieldItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Группировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetStructureListFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetItem.prototype, "_structureFieldItems", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetItem.prototype, "skip", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetItem.prototype, "limit", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetSelectedFieldItem)
], OrmSamplingSchemePresetItem.prototype, "selectedFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetSelectedFieldItem)
], OrmSamplingSchemePresetItem.prototype, "selectedFieldItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetConditionFieldItem)
], OrmSamplingSchemePresetItem.prototype, "conditionFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetConditionFieldItem)
], OrmSamplingSchemePresetItem.prototype, "conditionFieldItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetParameterFieldItem)
], OrmSamplingSchemePresetItem.prototype, "parameterFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetParameterFieldItem)
], OrmSamplingSchemePresetItem.prototype, "parameterFieldItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetOrderFieldItem)
], OrmSamplingSchemePresetItem.prototype, "orderFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetOrderFieldItem)
], OrmSamplingSchemePresetItem.prototype, "orderFieldItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetStructureListFieldItem)
], OrmSamplingSchemePresetItem.prototype, "structureFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetStructureListFieldItem)
], OrmSamplingSchemePresetItem.prototype, "structureFieldItemList", null);
OrmSamplingSchemePresetItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Вариант настроек схемы выборки данных',
        pluralDisplayName: 'Варианты настроек схемы выборки данных'
    }),
    NatHierarchical(false)
], OrmSamplingSchemePresetItem);
export { OrmSamplingSchemePresetItem };
