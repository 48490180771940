import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmPartner } from "@natiwi/shared/models/partner.model";

export class NatPartnerRepository extends NatCatalogRepository<OrmPartner> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/partners';
        let repoModel: Type<OrmPartner> = OrmPartner;
        this.init(repoUrl, repoModel);
    }
}