import { Injectable } from '@angular/core';
import {
  CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot,
  UrlTree, Router, CanLoad, Route, UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs';
import { NatAuthService } from '@natiwi/core';

interface NatActivatedRouteSnapshot extends ActivatedRouteSnapshot {
  _routerState: RouterStateSnapshot;
}

@Injectable({
  providedIn: 'root'
})
export class NatAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: NatAuthService, private router: Router) { }
  canActivate(
    next: NatActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url = next._routerState.url;
    return this.checkAccess(url);
  }
  canActivateChild(
    next: NatActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url = next._routerState.url;
    return this.checkAccess(url);
  }

  canLoad(
    route: Route, segments: UrlSegment[]): boolean | Promise<boolean> | Observable<boolean> {
    return true//this.checkAccess(route.path);
  }

  checkAccess(currentUrl: string): boolean {
    if (currentUrl && currentUrl === '/login') {
      if (this.authService.isAuthorized()) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    } else {
      if (this.authService.isAuthorized()) {
        return true;
      } else {
        this.authService.redirectUrl = currentUrl || '/';
        this.router.navigate(['/login']);
        return false;
      }
    }
  }

}
