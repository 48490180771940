import * as tslib_1 from "tslib";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel } from "@natiwi/core/decorators/matadata.decorator";
let OrmProductSegment = class OrmProductSegment extends OrmCatalog {
    constructor() {
        super();
    }
};
OrmProductSegment = tslib_1.__decorate([
    NatModel({
        plural: 'productSegments',
        displayName: 'Сегмент номенклатуры',
        pluralDisplayName: 'Сегменты номенклатуры'
    })
], OrmProductSegment);
export { OrmProductSegment };
