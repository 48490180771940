import * as tslib_1 from "tslib";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { OrmReport } from "@natiwi/shared/models/report.model";
import { natClassToPlain, natPlainToClass } from "@natiwi/core/network/repositories/rest.repository";
import { map } from "rxjs/internal/operators/map";
import { OrmReportPreset } from "@natiwi/shared/models/report-preset.model";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
import { Type as CTType, Expose, Transform } from "class-transformer";
export class NatReportRepository extends NatCatalogRepository {
    constructor(adapter) {
        super(adapter);
        let repoUrl = '/reports';
        let repoModel = OrmReport;
        super.init(repoUrl, repoModel);
        super.createСontractItem('execute', '/:id/execute', NatVerbType.POST);
    }
    execute(id, preset) {
        let stringlifyData = natClassToPlain(OrmReportPreset, preset);
        // let stringlifyBodyData: string = JSON.stringify(classToPlain(object, { excludePrefixes: ["_"] }));
        let stringifyParams = new Map();
        stringifyParams.set('id', id);
        let request = this.invokeStaticMethod('execute', stringifyParams, stringlifyData);
        return request.pipe(map(response => {
            return natPlainToClass(NatReportExecuteResponse, response);
        }));
    }
}
const ɵ0 = () => NatReportExecuteResponseColumns, ɵ1 = (value, obj, type) => {
    let reponseRows = obj && obj['rows'] || [];
    reponseRows.forEach((element, index) => {
        for (let key in element) {
            value[index].set(key, element[key]);
        }
    });
    return value;
}, ɵ2 = () => NatReportExecuteResponseRow;
export class NatReportExecuteResponse {
    constructor() { }
    get columns() {
        return this._columns;
    }
    set columns(value) {
        this._columns = value;
    }
    get rows() {
        return this._rows;
    }
    set rows(value) {
        this._rows = value;
    }
}
tslib_1.__decorate([
    Expose(),
    CTType(ɵ0)
], NatReportExecuteResponse.prototype, "columns", null);
tslib_1.__decorate([
    Expose(),
    Transform(ɵ1),
    CTType(ɵ2)
], NatReportExecuteResponse.prototype, "rows", null);
export class NatReportExecuteResponseColumns extends OrmSamplingSchemeFieldItem {
    constructor() {
        super();
    }
    get metadataItemList() {
        return this._metadataItemList;
    }
    set metadataItemList(value) {
        this._metadataItemList = value;
    }
    get propertyPathFieldItemList() {
        return this._propertyPathFieldItemList;
    }
    set propertyPathFieldItemList(value) {
        this._propertyPathFieldItemList = value;
    }
}
tslib_1.__decorate([
    Expose()
], NatReportExecuteResponseColumns.prototype, "metadataItemList", null);
tslib_1.__decorate([
    Expose()
], NatReportExecuteResponseColumns.prototype, "propertyPathFieldItemList", null);
export class NatReportExecuteResponseRow extends Map {
}
export { ɵ0, ɵ1, ɵ2 };
