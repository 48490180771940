import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmProductKind } from "@natiwi/shared/models/product-kind.model";
import { OrmPaymentOptionKind } from "@natiwi/shared/models/payment-option-kind.model";
import { NatPaymentOptionKindRepository } from "@natiwi/shared/repositories/payment-option-kind.repository";

export class NatPaymentOptionKindController extends NatCatalogController<OrmPaymentOptionKind>{

    constructor(_repository?: NatPaymentOptionKindRepository) {
        super(_repository);
        super.registerDataClass(OrmPaymentOptionKind);
    }
}