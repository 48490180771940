import { Injectable, Type } from "@angular/core";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatInformationRegisterRepository } from "@natiwi/core/network/repositories/information-register.repository";
import { OrmProductBarcode } from "@natiwi/shared/models/product-barcode.model";

export class NatProductBarcodeRepository extends NatInformationRegisterRepository<OrmProductBarcode> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/productBarcodes';
        let repoModel: Type<OrmProductBarcode> = OrmProductBarcode;
        this.init(repoUrl, repoModel);
    }
}