import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
let OrmSamplingSchemeAggregateFieldItem = class OrmSamplingSchemeAggregateFieldItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get expression() {
        return this._expression;
    }
    set expression(v) {
        let oldValue = this._expression;
        this._expression = v;
        super.notifyFieldChange('expression', oldValue, v);
    }
    get field() {
        return this._field;
    }
    set field(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    get fieldValue() {
        return this._field;
    }
    set fieldValue(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Выражение',
        type: 'string',
        required: false,
        standart: false
    })
], OrmSamplingSchemeAggregateFieldItem.prototype, "_expression", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemeAggregateFieldItem.prototype, "_field", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeAggregateFieldItem.prototype, "expression", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemeAggregateFieldItem.prototype, "field", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemeAggregateFieldItem.prototype, "fieldValue", null);
OrmSamplingSchemeAggregateFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Агрегированное поле',
        pluralDisplayName: 'Агрегированные поля'
    }),
    NatHierarchical(false)
], OrmSamplingSchemeAggregateFieldItem);
export { OrmSamplingSchemeAggregateFieldItem };
