import { NgModule } from '@angular/core';
import { NatPropertyStringComponent } from './property-string/property-string.component';
import { NatPropertyDirective } from '@natiwi/shared/form-controls/property/property.directive';

@NgModule({
    declarations: [NatPropertyStringComponent, NatPropertyDirective],
    imports: [],
    exports: [NatPropertyDirective, NatPropertyStringComponent],
    entryComponents:[NatPropertyStringComponent]
})
export class NatFormControlsModule { }
