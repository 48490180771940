import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { Observable } from "rxjs";
import { classToPlain, plainToClass } from "class-transformer";
import { map } from "rxjs/operators";
import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { NatRegisterRepository } from "@natiwi/core/network/repositories/register.repository";
import { Type } from "@angular/core";

export abstract class NatInformationRegisterRepository<T extends OrmInformationRegister> extends NatRegisterRepository<T> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        super.createСontractItem('sliceLast', '/sliceLast');
    }

    public sliceLast(period: Date, params: Map<string, any>): Observable<Array<T>> {
        let stringifyParams: Map<string, string | Date> = new Map();
        stringifyParams.set('period', period);
        params.forEach((value: any, key: string) => {
            stringifyParams.set(key, JSON.stringify(classToPlain(value, { excludePrefixes: ["_"] })));
        });
        let request: Observable<Array<T>> = this.invokeStaticMethod<Array<T>>('sliceLast', stringifyParams);
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as Array<T>;
        }));
    }
}