import * as tslib_1 from "tslib";
import { OrmModel } from "@natiwi/core/models/model";
import { Expose } from "class-transformer";
import * as uuid from 'uuid';
export class OrmRegister extends OrmModel {
    constructor() {
        super();
        this._id = uuid.v4();
    }
    get id() {
        return this._id;
    }
    set id(v) {
        this._id = v;
    }
}
tslib_1.__decorate([
    Expose()
], OrmRegister.prototype, "id", null);
