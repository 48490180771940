import { Expose, Type } from "class-transformer";
import { OrmObject } from "@natiwi/core/models/object";
import { NatProperty, NatModel, NatPolymorphicRelation, NatPolymorphicProperty, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";
import { OrmOrganization } from "@natiwi/shared/models/organization.model";

@NatModel({
    plural: 'constants',
    displayName: 'Константа',
    pluralDisplayName: 'Константы'
})
@NatHierarchical(false)
export class OrmConstant extends OrmObject {
    
    @NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
    private _name: string;

    @NatProperty({
        displayName: 'Приоритет',
        type: 'number',
        required: false
    })
    private _priority: number;
    
    @NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
    @Expose()
    private _description: any;
    
    @NatProperty({
        displayName: 'Значение',
        type: 'polymorphicRelation',
        required: false,
        standart: false
    })
    private _value: any;
    
    @NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
    private _valueId: any
    
    @NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
    private _valueType: string;
    
    @NatProperty({
        displayName: 'Модель связи',
        type: 'string',
        required: false,
        standart: false
    })
    private _valueRelationType: string;
    
    @NatProperty({
        displayName: 'Значение связи',
        type: 'string',
        required: false,
        standart: false
    })
    private _valueRelationValue: string;
    
    @NatProperty({
        displayName: 'Предопределенное значение',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _predefined: boolean;
    
    @NatProperty({
        displayName: 'Имя предопределенного значения',
        type: 'string',
        required: false,
        standart: false
    })
    private _predefinedName: string;

    constructor() {
        super();
    }
    
    @Expose()
    public get name(): string {
        if (!this._name) {
            return this._name = '';
        }
        return this._name;
    }

    public set name(v: string) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    
    @Expose()
    public get priority(): number {
        return this._priority;
    }

    public set priority(v: number) {
        let oldValue = this._priority;
        this._priority = v;
        super.notifyFieldChange('priority', oldValue, v);
    }
    
    @Expose()
    public get description(): any {
        return this._description;
    }

    public set description(v: any) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    
    @Expose()
    @NatPolymorphicRelation('valueRelationModel')
    public get value(): any {
        return this._value;
    }

    public set value(v: any) {
        this._value = v;
    }
    
    @Expose()
    @NatPolymorphicProperty('valueType')
    public get valueId(): any {
        return this._valueId;
    }

    public set valueId(v: any) {
        this._valueId = v;
    }

    @Expose()
    public get valueType(): string {
        return this._valueType;
    }

    public set valueType(v: string) {
        this._valueType = v;
    }
    
    @Expose()
    public get valueRelationType(): string {
        return this._valueRelationType;
    }

    public set valueRelationType(v: string) {
        this._valueRelationType = v;
    }
    
    @Expose()
    public get valueRelationValue(): string {
        return this._valueRelationValue;
    }

    public set valueRelationValue(v: string) {
        this._valueRelationValue = v;
    }
    
    @Expose()
    public get predefined(): boolean {
        return this._predefined;
    }

    public set predefined(v: boolean) {
        this._predefined = v;
    }
    
    @Expose()
    public get predefinedName(): string {
        return this._predefinedName;
    }

    public set predefinedName(v: string) {
        this._predefinedName = v;
    }
     /**
     * toString
     */
    public toString(): string {
        return this._name;
    }
    
}