import { NatKeyValueObservable, NatValueObservable, NatModelObservable } from "@natiwi/core/models/observable";
import { OrmModel } from "@natiwi/core/models/model";
import { NatValueObserver, NatKeyValueObserver } from "@natiwi/core/models/observer";
import { FormGroup, FormControl, ValidatorFn } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { NatFormControlHostConfigure } from "@natiwi/core/forms";

export abstract class NatFormControlHost<ValueType, ObjectType extends OrmModel, ObservableType extends NatModelObservable<ValueType, ObjectType>> {

    private __validators: Array<ValidatorFn>;
    protected _formObject: ObjectType;
    protected _baseFilterFn: Function;

    constructor(private __controlName: string, private __formControl: FormControl, private __formGroup: FormGroup, private __observable: ObservableType, private __configuration?: NatFormControlHostConfigure, private __observer?: NatKeyValueObserver<ValueType, ObjectType>) {

        if (this.__observer) {
            this.__observable.registerObserver(this.__observer);
        }
        this.__validators = new Array();
        let formControlObserver = new NatKeyValueObserver(null, (object, key, oldValue, newValue) => {
            console.log('set value to control')
            this.__formControl.setValue(newValue, { emitEvent: false });
        });
        this.__observable.registerObserver(formControlObserver);
        this._formObject = this.__observable.getObservableObject();        
        if (this.__configuration) {
            if (this.__configuration.eventMap) {
                this.__configuration.eventMap.forEach((handler, key) => {
                    let observer = new NatKeyValueObserver(key, handler)
                    this._formObject.registerObserver(observer);
                });
            }
            if (this.__configuration.baseFilterFn) {
                this._baseFilterFn = this.__configuration.baseFilterFn
            }
        }
    }

    public get validators(): Array<ValidatorFn> {
        return this.__validators;
    }

    public get formControl(): FormControl {
        return this.__formControl;
    }

    public clearValue(event) {
        event.preventDefault();
        event.stopPropagation();
        this.__formControl.setValue(null);
        this.__formControl.markAsDirty();
        this.__formControl.markAsTouched();
    }

    /**
     * focus
     */
    public abstract focus();

    /**
     * focus
     */
    public abstract blur();

    /**
     * addValidator
     */
    public addValidator(validator: ValidatorFn | ValidatorFn[]) {
        if (Array.isArray(validator)) {
            validator.forEach((value) => this.__validators.push(value));
        } else {
            this.__validators.push(validator);
        }
        this.__formControl.setValidators(this.__validators);
        // this.__formControl.updateValueAndValidity();
    }

    /**
     * clearValidators
     */
    public clearValidators() {
        this.__validators.length = 0;
        this.__formControl.clearValidators();
        this.__formControl.updateValueAndValidity();
    }

}