import * as tslib_1 from "tslib";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";
let OrmProductFeature = class OrmProductFeature extends OrmCatalog {
    constructor() {
        super();
    }
};
OrmProductFeature = tslib_1.__decorate([
    NatModel({
        plural: 'productFeatures',
        displayName: 'Характеристика номенклатуры',
        pluralDisplayName: 'Характеристики номенклатуры'
    }),
    NatHierarchical(false)
], OrmProductFeature);
export { OrmProductFeature };
