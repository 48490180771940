import * as tslib_1 from "tslib";
import { OrmModel } from "@natiwi/core/models/model";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose } from "class-transformer";
import * as uuid from 'uuid';
export class OrmObjectItem extends OrmModel {
    constructor() {
        super();
        this._id = uuid.v4();
    }
    get id() {
        return this._id;
    }
    set id(v) {
        let oldValue = this._id;
        if (v) {
            this._id = v;
        }
        else {
            this._id = null;
        }
        super.notifyFieldChange('id', oldValue, this._id);
    }
}
tslib_1.__decorate([
    NatProperty({
        displayName: 'Идентификатор',
        type: 'string',
        required: true,
        standart: false
    }),
    Expose()
], OrmObjectItem.prototype, "id", null);
