import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPaymentOptionKindArgumentItem } from "@natiwi/shared/models/payment-option-kind-argument-item.model";

@NatModel({
    displayName: 'Вид способа оплаты',
    pluralDisplayName: 'Виды способов оплаты'
})
@NatHierarchical(false)
export class OrmPaymentOptionKind extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
    private _description: string;
    
    @NatProperty({
        displayName: 'Модуль обработки',
        type: 'string',
        required: true,
        standart: false
    })
    private _processingModule: string;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'payment_option_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Аргументы',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmPaymentOptionKindArgumentItem',
        required: false,
        standart: false
    })
    private _argumentItems: Array<OrmPaymentOptionKindArgumentItem>;
    
    constructor() {
        super();
    }
    
    @Expose()
    public get description(): string {
        return this._description;
    }

    public set description(v: string) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    
    @Expose()
    public get processingModule(): string {
        return this._processingModule;
    }

    public set processingModule(v: string) {
        let oldValue = this._processingModule;
        this._processingModule = v;
        super.notifyFieldChange('processingModule', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    //
    @Expose()
    @NatRelation(OrmPaymentOptionKindArgumentItem)
    public get argumentItems(): Array<OrmPaymentOptionKindArgumentItem> {
        return this._argumentItems;
    }

    public set argumentItems(v: Array<OrmPaymentOptionKindArgumentItem>) {
        let oldValue = this._argumentItems;
        this._argumentItems = v;
        super.notifyFieldChange('argumentItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmPaymentOptionKindArgumentItem)
    public get argumentItemList(): Array<OrmPaymentOptionKindArgumentItem> {
        return this._argumentItems;
    }

    // public set argumentItemList(v: Array<OrmPaymentOptionKindArgumentItem>) {
    //     let oldValue = this._argumentItems;
    //     this._argumentItems = v;
    //     super.notifyFieldChange('argumentItems', oldValue, v);
    // }
    //
   
}