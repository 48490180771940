import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { NatPartnerRepository } from "@natiwi/shared/repositories/partner.repository";
import { OrmPartner } from "@natiwi/shared/models/partner.model";

export class NatPartnerController extends NatCatalogController<OrmPartner>{

    constructor(_repository?: NatPartnerRepository) {
        super(_repository);
        super.registerDataClass(OrmPartner);
    }
}