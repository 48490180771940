import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose, Type } from "class-transformer";
import { OrmMetadata } from "@natiwi/core/models/metadata";

@NatModel({
    plural: 'files',
    displayName: 'Файл',
    pluralDisplayName: 'Файлы'
})
export class OrmFileMetadata extends OrmMetadata {

    @NatProperty({
        displayName: 'Формат',
        type: 'string',
        required: false,
        standart: false
    })
    private _format: string;

    @NatProperty({
        displayName: 'Имя файла',
        type: 'string',
        required: true,
        standart: false
    })
    private _filename: string;

    @NatProperty({
        displayName: 'Тэг',
        type: 'string',
        required: false,
        standart: false
    })
    private _tag: string;

    @NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
    private _description: string;

    constructor() {
        super();
    }

    @Expose()
    public get format(): string {
        return this._format;
    }

    public set format(v: string) {
        this._format = v;
    }

    @Expose()
    public get filename(): string {
        return this._filename;
    }

    public set filename(v: string) {
        this._filename = v;
    }

    @Expose()
    public get tag(): string {
        return this._tag;
    }

    public set tag(v: string) {
        this._tag = v;
    }

    @Expose()
    public get description(): string {
        return this._description;
    }

    public set description(v: string) {
        this._description = v;
    }
}