import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmNaturalPerson } from "@natiwi/shared/models/natural-person.model";

@NatModel({
    plural: 'responsiblePersons',
    displayName: 'Ответственное лицо',
    pluralDisplayName: 'Ответственные лица'
})
@NatHierarchical(false)
export class OrmResponsiblePerson extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Физическое лицо',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmNaturalPerson',
        required: false,
        standart: false
    })
    private _naturalPerson: OrmNaturalPerson;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'responsible_person_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Должность',
        type: 'string',
        required: false,
        standart: false
    })
    private _position: string;
    
    @NatProperty({
        displayName: 'Начало действия',
        type: 'date',
        required: true,
        standart: false
    })
    private _startedAt: Date;
    
    @NatProperty({
        displayName: 'Окончание действия',
        type: 'date',
        required: false,
        standart: false
    })
    private _expiredAt: Date;
    
    constructor() {
        super();
    }
    
    @Expose()
    @NatRelation(OrmNaturalPerson)
    public get naturalPerson(): OrmNaturalPerson {
        return this._naturalPerson;
    }

    public set naturalPerson(v: OrmNaturalPerson) {
        let oldValue = this._naturalPerson;
        this._naturalPerson = v;
        super.notifyFieldChange('naturalPerson', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    
    @Expose()
    public get position(): string {
        return this._position;
    }

    public set position(v: string) {
        let oldValue = this._position;
        this._position = v;
        super.notifyFieldChange('position', oldValue, v);
    }
    
    @Expose()
    @Type(() => Date)
    public get startedAt(): Date {
        return this._startedAt;
    }

    public set startedAt(v: Date) {
        let oldValue = this._startedAt;
        this._startedAt = v;
        super.notifyFieldChange('startedAt', oldValue, v);
    }
    
    @Expose()
    @Type(() => Date)
    public get expiredAt(): Date {
        return this._expiredAt;
    }

    public set expiredAt(v: Date) {
        let oldValue = this._expiredAt;
        this._expiredAt = v;
        super.notifyFieldChange('expiredAt', oldValue, v);
    }
}