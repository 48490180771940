import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmContactKind } from "@natiwi/shared/models/contact-kind.model";
import { OrmPartner } from "@natiwi/shared/models/partner.model";
let OrmPartnerContact = 
// @NatHierarchical(false)
class OrmPartnerContact extends OrmInformationRegister {
    constructor() {
        super();
    }
    get view() {
        return this._view;
    }
    set view(v) {
        let oldValue = this._view;
        this._view = v;
        super.notifyFieldChange('view', oldValue, v);
    }
    get partner() {
        return this._partner;
    }
    set partner(v) {
        let oldValue = this._partner;
        this._partner = v;
        super.notifyFieldChange('partner', oldValue, v);
    }
    get type() {
        if (!this._type) {
            this._type = new OrmEnumerationItem();
        }
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    get kind() {
        return this._kind;
    }
    set kind(v) {
        let oldValue = this._kind;
        this._kind = v;
        super.notifyFieldChange('kind', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Значение контакта',
        type: 'string',
        required: true,
        standart: false
    })
], OrmPartnerContact.prototype, "_view", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Контрагент',
        type: 'relation',
        relationModel: 'OrmPartner',
        relationType: 'belongsTo',
        required: true,
        standart: false
    })
], OrmPartnerContact.prototype, "_partner", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'contact_types',
        required: true,
        standart: false
    })
], OrmPartnerContact.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вид',
        type: 'relation',
        relationModel: 'OrmContactKind',
        relationType: 'belongsTo',
        required: true,
        standart: false
    })
], OrmPartnerContact.prototype, "_kind", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Набор контактов',
        type: 'relation',
        relationModel: 'OrmPartnerContactSet',
        relationType: 'belongsTo',
        required: true,
        standart: false
    })
], OrmPartnerContact.prototype, "_contactSet", void 0);
tslib_1.__decorate([
    Expose()
], OrmPartnerContact.prototype, "view", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPartner)
], OrmPartnerContact.prototype, "partner", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPartnerContact.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmContactKind)
], OrmPartnerContact.prototype, "kind", null);
OrmPartnerContact = tslib_1.__decorate([
    NatModel({
        plural: 'partnerContacts',
        displayName: 'Контактная информация',
        pluralDisplayName: 'Список контактной информации'
    })
    // @NatHierarchical(false)
], OrmPartnerContact);
export { OrmPartnerContact };
