import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NatRootRoutingModule } from '@natiwi/shared/layout/root/root-routing.module';
import { NatRootComponent } from '@natiwi/shared/layout/root/root.component';
import { NatHeaderModule } from '@natiwi/shared/layout/header';
import { NatFooterModule } from '@natiwi/shared/layout/footer';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NatOverviewModule } from '@natiwi/overview';
import { NatGroupDetailComponent } from '@natiwi/shared/groups/detail/group-detail.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NatToolbarDirective } from '@natiwi/shared/directives/nat-toolbar-with-outside-template.directive';

@NgModule({
  declarations: [NatRootComponent, NatGroupDetailComponent, NatToolbarDirective],
  imports: [
    CommonModule,
    MatSidenavModule,
    NatRootRoutingModule,
    NatHeaderModule,
    NatFooterModule,
    NatOverviewModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule
  ],
  exports: [
    NatRootComponent,
    NatRootRoutingModule,
    NatGroupDetailComponent
  ],
  entryComponents: [NatGroupDetailComponent]
})
export class NatRootModule { }
