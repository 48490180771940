/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./overview.component";
var styles_NatOverviewComponent = [i0.styles];
var RenderType_NatOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NatOverviewComponent, data: {} });
export { RenderType_NatOverviewComponent as RenderType_NatOverviewComponent };
export function View_NatOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "paper"], ["style", "height:98%;"]], null, null, null, null, null))], null, null); }
export function View_NatOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nat-overview", [], null, null, null, View_NatOverviewComponent_0, RenderType_NatOverviewComponent)), i1.ɵdid(1, 245760, null, 0, i2.NatOverviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NatOverviewComponentNgFactory = i1.ɵccf("nat-overview", i2.NatOverviewComponent, View_NatOverviewComponent_Host_0, {}, {}, []);
export { NatOverviewComponentNgFactory as NatOverviewComponentNgFactory };
