import { Injectable } from "@angular/core";
import { NatDocumentController } from "@natiwi/core/controllers/document.controller";
import { OrmCustomerOrder } from "@natiwi/shared/models/customer-order.model";
import { NatCustomerOrdersRepository } from "@natiwi/shared/repositories/customer-orders.repository";

export class NatCustomerOrderController extends NatDocumentController<OrmCustomerOrder>{

    constructor(_repository?: NatCustomerOrdersRepository) {
        super(_repository);
        super.registerDataClass(OrmCustomerOrder);
    }
}