var OrmImageMetadata_1;
import * as tslib_1 from "tslib";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose, Type } from "class-transformer";
import { OrmFileMetadata } from "@natiwi/shared/models/file-metadata";
const ɵ0 = () => OrmImageMetadata_1;
let OrmImageMetadata = OrmImageMetadata_1 = class OrmImageMetadata extends OrmFileMetadata {
    constructor() {
        super();
    }
    get originalImage() {
        return this._originalImage;
    }
    set originalImage(v) {
        this._originalImage = v;
    }
    get preview() {
        return this._preview;
    }
    set preview(v) {
        this._preview = v;
    }
    get width() {
        return this._width;
    }
    set width(v) {
        this._width = v;
    }
    get height() {
        return this._height;
    }
    set height(v) {
        this._height = v;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Исходное изображение',
        type: 'relation',
        relationModel: 'OrmImageMetadata',
        required: false,
        standart: false
    })
], OrmImageMetadata.prototype, "_originalImage", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Изображение предпросмотра',
        type: 'string',
        required: false,
        standart: false
    })
], OrmImageMetadata.prototype, "_preview", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Ширина',
        type: 'number',
        required: true,
        standart: false
    })
], OrmImageMetadata.prototype, "_width", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Ширина',
        type: 'number',
        required: true,
        standart: false
    })
], OrmImageMetadata.prototype, "_height", void 0);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmImageMetadata.prototype, "originalImage", null);
tslib_1.__decorate([
    Expose()
], OrmImageMetadata.prototype, "preview", null);
tslib_1.__decorate([
    Expose()
], OrmImageMetadata.prototype, "width", null);
tslib_1.__decorate([
    Expose()
], OrmImageMetadata.prototype, "height", null);
OrmImageMetadata = OrmImageMetadata_1 = tslib_1.__decorate([
    NatModel({
        plural: 'images',
        displayName: 'Изображение',
        pluralDisplayName: 'Изображения'
    })
], OrmImageMetadata);
export { OrmImageMetadata };
export { ɵ0 };
