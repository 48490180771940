import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";

@NatModel({
    displayName: 'Агрегированное поле',
    pluralDisplayName: 'Агрегированные поля'
})
@NatHierarchical(false)
export class OrmSamplingSchemeAggregateFieldItem extends OrmObjectItem {
    
    @NatProperty({
        displayName: 'Выражение',
        type: 'string',
        required: false,
        standart: false
    })
    private _expression: string;

    @NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
    private _field: OrmSamplingSchemeFieldItem;
    
    constructor() {
        super();
    }
    
    @Expose()
    public get expression(): string {
        return this._expression;
    }

    public set expression(v: string) {
        let oldValue = this._expression;
        this._expression = v;
        super.notifyFieldChange('expression', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get field(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set field(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get fieldValue(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set fieldValue(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
}