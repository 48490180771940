import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { NatHeaderRightMenuComponent } from '@natiwi/shared/layout/menu/header-right-menu/header-right-menu.component';

@NgModule({
  declarations: [NatHeaderRightMenuComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ],
  exports: [NatHeaderRightMenuComponent]
})
export class NatHeaderRightMenuModule {
  
  
}
