import { OrmConstant } from "@natiwi/shared/models/constant.model";
import { NatObjectController } from "@natiwi/core/controllers/object.controller";
import { NatConstantRepository } from "@natiwi/shared/repositories/constant.repository";

export class NatConstantController extends NatObjectController<OrmConstant>{

    constructor(_repository?: NatConstantRepository) {
        super(_repository);
        super.registerDataClass(OrmConstant);
    }
}