import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";
import { NatProductFeatureRepository } from "@natiwi/shared/repositories/product-feature.repository";

export class NatProductFeatureController extends NatCatalogController<OrmProductFeature>{

    constructor(_repository?: NatProductFeatureRepository) {
        super(_repository);
        super.registerDataClass(OrmProductFeature);
    }
}