import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmOrganizationBankAccount } from "@natiwi/shared/models/organization-bank-account.model";
import { NatOrganizationBankAccountRepository } from "@natiwi/shared/repositories/organization-bank-account.repository";

export class NatOrganizationBankAccountController extends NatCatalogController<OrmOrganizationBankAccount>{

    constructor(_repository?: NatOrganizationBankAccountRepository) {
        super(_repository);
        super.registerDataClass(OrmOrganizationBankAccount);
    }
}