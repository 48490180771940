import * as tslib_1 from "tslib";
import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { OrmRole } from "@natiwi/core/models/role";
const ɵ0 = () => OrmRole;
let OrmRoleMapping = class OrmRoleMapping extends OrmPersistedModel {
    constructor() {
        super();
    }
    get principalType() {
        return this._principalType;
    }
    set principalType(v) {
        this._principalType = v;
    }
    get principalId() {
        return this._principalId;
    }
    set principalId(v) {
        this._principalId = v;
    }
    get role() {
        return this._role;
    }
    set role(v) {
        this._role = v;
    }
    isRole(name) {
        let result = false;
        if (this._role) {
            result = this._role.isRole(name);
        }
        return result;
    }
    static hasRole(roleMappingList, name) {
        return !!roleMappingList.find(element => element.isRole(name));
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип сущности',
        type: 'string',
        required: false,
        standart: false
    })
], OrmRoleMapping.prototype, "_principalType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Идентификатор сущности',
        type: 'string',
        required: false,
        standart: false
    })
], OrmRoleMapping.prototype, "_principalId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Идентификатор роли',
        type: 'string',
        required: false,
        standart: false
    })
], OrmRoleMapping.prototype, "_roleId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Роль',
        type: 'relation',
        relationModel: 'OrmRole',
        required: false,
        standart: false
    })
], OrmRoleMapping.prototype, "_role", void 0);
tslib_1.__decorate([
    Expose()
], OrmRoleMapping.prototype, "principalType", null);
tslib_1.__decorate([
    Expose()
], OrmRoleMapping.prototype, "principalId", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmRoleMapping.prototype, "role", null);
OrmRoleMapping = tslib_1.__decorate([
    NatModel({
        plural: 'rolesMapping',
        displayName: 'Карта роли',
        pluralDisplayName: 'Карты ролей'
    })
], OrmRoleMapping);
export { OrmRoleMapping };
export { ɵ0 };
