import * as tslib_1 from "tslib";
import { NatRestRepository } from "@natiwi/core/network/repositories/rest.repository";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { classToPlain, plainToClass, Expose } from "class-transformer";
import { map } from "rxjs/internal/operators/map";
import { OrmContainer } from "@natiwi/shared/models/container.model";
export class NatContainerRepository extends NatRestRepository {
    constructor(adapter) {
        super(adapter);
        let repoUrl = '/containers';
        let repoModel = OrmContainer;
        super.init(repoUrl, repoModel);
        super.createСontractItem('upload', '/:container/upload', NatVerbType.POST);
    }
    upload(container, metadata) {
        let stringlifyData = JSON.stringify(classToPlain(metadata, { excludePrefixes: ["_"] }));
        let params = new Map();
        params.set('container', container);
        let request = this.invokeStaticMethod('upload', params, stringlifyData);
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response, {
                excludeExtraneousValues: true
            });
            ;
        }));
    }
}
export class NatUploadFileMetadata {
    constructor(name, ownerId, ownerType, file, filename) {
        this.name = name;
        this.ownerId = ownerId;
        this.ownerType = ownerType;
        this.file = file;
        this.filename = filename;
    }
    get containerType() {
        return this._containerType;
    }
    set containerType(v) {
        this._containerType = v;
    }
    get containerId() {
        return this._containerId;
    }
    set containerId(v) {
        this._containerId = v;
    }
}
tslib_1.__decorate([
    Expose()
], NatUploadFileMetadata.prototype, "containerType", null);
tslib_1.__decorate([
    Expose()
], NatUploadFileMetadata.prototype, "containerId", null);
