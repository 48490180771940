import { OrmModel } from "@natiwi/core/models/model";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";

export abstract class OrmDocumentItem extends OrmObjectItem {
    
    @NatProperty({
        displayName: 'Ключ связи',
        type: 'string',
        required: false,
        standart: false
    })
    private _bindingKey: string;
    
    constructor() {
        super();
    }
    
    public get bindingKey() : string {
        return this._bindingKey;
    }
    
    public set bindingKey(v : string) {
        let oldValue = this._bindingKey;
        if (v) {
            this._bindingKey = v;
        } else {
            this._bindingKey = null;
        }
        super.notifyFieldChange('bindingKey', oldValue, this._bindingKey);
    }
    
    
}