import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmContactKind } from "@natiwi/shared/models/contact-kind.model";
import { NatContactKindRepository } from "@natiwi/shared/repositories/contact-kind.repository";

export class NatContactKindController extends NatCatalogController<OrmContactKind>{

    constructor(_repository?: NatContactKindRepository) {
        super(_repository);
        super.registerDataClass(OrmContactKind);
    }
}