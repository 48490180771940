import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmValueMetadataItem } from "@natiwi/core/models/value-metadata-item.model";

@NatModel({
    displayName: 'Метаданные свойства',
    pluralDisplayName: 'Метаданные свойств'
})
@NatHierarchical(false)
export class OrmPropertyMetadataItem extends OrmValueMetadataItem {
    
    @NatProperty({
        displayName: 'Имя свойства',
        type: 'string',
        required: false,
        standart: false
    })
    private _propertyName: string;
    
    @NatProperty({
        displayName: 'Имя свойства типа полиморфной cвязи',
        type: 'string',
        required: false,
        standart: false
    })
    private _polymorphicRelationTypePropertyName: string;
    
    @NatProperty({
        displayName: 'Имя свойства cвязи',
        type: 'string',
        required: false,
        standart: false
    })
    private _relationPropertyName: string;
    
    constructor() {
        super();
    }
    
    @Expose()
    public get propertyName(): string {
        return this._propertyName;
    }

    public set propertyName(v: string) {
        let oldValue = this._propertyName;
        this._propertyName = v;
        super.notifyFieldChange('propertyName', oldValue, v);
    }
    
    @Expose()
    public get polymorphicRelationTypePropertyName(): string {
        return this._polymorphicRelationTypePropertyName;
    }

    public set polymorphicRelationTypePropertyName(v: string) {
        let oldValue = this._polymorphicRelationTypePropertyName;
        this._polymorphicRelationTypePropertyName = v;
        super.notifyFieldChange('polymorphicRelationTypePropertyName', oldValue, v);
    }
    
    @Expose()
    public get relationPropertyName(): string {
        return this._relationPropertyName;
    }

    public set relationPropertyName(v: string) {
        let oldValue = this._relationPropertyName;
        this._relationPropertyName = v;
        super.notifyFieldChange('relationPropertyName', oldValue, v);
    }
   
}