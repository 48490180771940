import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NatAuthGuard } from '@natiwi/auth';
import { NatRootComponent } from '@natiwi/shared/layout/root/root.component';
import { NatOverviewComponent } from '@natiwi/overview';

const mainRoutes: Routes = [
  {
    path: '',
    component: NatRootComponent,
    canActivate: [NatAuthGuard],
    canActivateChild: [NatAuthGuard],
    children: [
      {
        path: '',
        component: NatOverviewComponent,
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
      },
      {
        path: 'productKinds',
        loadChildren: () => import('@natiwi/product-kinds/product-kind.module').then(mod => mod.NatProductKindModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'products',
        loadChildren: () => import('@natiwi/products/products.module').then(mod => mod.NatProductsModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'orders',
        loadChildren: () => import('@natiwi/customer-orders/customer-orders.module').then(mod => mod.NatCustomerOrdersModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'warehouses',
        loadChildren: () => import('@natiwi/warehouses/warehouses.module').then(mod => mod.NatWarehouseModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'priceTypes',
        loadChildren: () => import('@natiwi/price-types/price-types.module').then(mod => mod.NatPriceTypeModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'productUnits',
        loadChildren: () => import('@natiwi/product-units/product-units.module').then(mod => mod.NatProductUnitModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'productFeatures',
        loadChildren: () => import('@natiwi/product-features/product-feature.module').then(mod => mod.NatProductFeatureModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'partners',
        loadChildren: () => import('@natiwi/partners/partners.module').then(mod => mod.NatPartnerModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('@natiwi/settings/settings.module').then(mod => mod.NatSettingsModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('@natiwi/reports/reports.module').then(mod => mod.NatReportsModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      },
      {
        path: 'paymentOptions',
        loadChildren: () => import('@natiwi/payment-options/payment-options.module').then(mod => mod.NatPaymentOptionModule),
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        canLoad: [NatAuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(mainRoutes)],
  exports: [RouterModule]
})
export class NatRootRoutingModule { }
