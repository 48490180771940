import { Type, Expose } from "class-transformer";
import { OrmDocument } from "@natiwi/core/models/document";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmOrganization } from "@natiwi/shared/models/organization.model";
import { OrmDocumentItem } from "@natiwi/core/models/document-item";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";

@NatModel({
    displayName: 'Товар',
    pluralDisplayName: 'Товары'
})
export class OrmSettingPricesOfProductsProductItem extends OrmDocumentItem {
    
        @NatProperty({
            displayName: 'Товар',
            type: 'relation',
            relationModel: 'OrmProduct',
            required: true,
            standart: false
        })
        private _product: OrmProduct;
    
        @NatProperty({
            displayName: 'Характеристика',
            type: 'relation',
            relationModel: 'OrmProductFeature',
            required: false,
            standart: false
        })
        private _feature: OrmProductFeature;
    
        @NatProperty({
            displayName: 'Единица измерения',
            type: 'relation',
            relationModel: 'OrmProductUnit',
            required: true,
            standart: false
        })
        private _unit: OrmProductUnit;
    
        @NatProperty({
            displayName: 'Тип цены',
            type: 'relation',
            relationModel: 'OrmPriceType',
            required: true,
            standart: false
        })
        private _priceType: OrmPriceType;
    
        @NatProperty({
            displayName: 'Цена',
            type: 'number',
            required: true,
            standart: false
        })
        private _price: number;
    
        @NatProperty({
            displayName: 'Цена изменена вручную',
            type: 'boolean',
            required: false,
            standart: false
        })
        private _priceChangedManually: boolean;
    
        constructor() {
            super();
        }
        
        @Expose()
        @NatRelation(OrmProduct)
        public get product(): OrmProduct {
            if (!this._product) {
                this._product = new OrmProduct();
            }
            return this._product;
        }
    
        public set product(v: OrmProduct) {
            let oldValue = this._product;
            this._product = v;
            super.notifyFieldChange('product', oldValue, v);
        }
        
        @Expose()
        @NatRelation(OrmProductFeature)
        public get feature(): OrmProductFeature {
            if (!this._feature) {
                this._feature = null;
            }
            return this._feature;
        }
    
        public set feature(v: OrmProductFeature) {
            let oldValue = this._feature;
            this._feature = v;
            super.notifyFieldChange('feature', oldValue, v);
        }
    
        
        @Expose()
        @NatRelation(OrmProductUnit)
        public get unit(): OrmProductUnit {
            if (!this._unit) {
                this._unit = new OrmProductUnit();
            }
            return this._unit;
        }
    
        public set unit(v: OrmProductUnit) {
            let oldValue = this._unit;
            this._unit = v;
            super.notifyFieldChange('unit', oldValue, v);
        }
        
        @Expose()
        @NatRelation(OrmPriceType)
        public get priceType(): OrmPriceType {
            if (!this._priceType) {
                this._priceType = new OrmPriceType();
            }
            return this._priceType;
        }
    
        public set priceType(v: OrmPriceType) {
            let oldValue = this._priceType;
            this._priceType = v;
            super.notifyFieldChange('priceType', oldValue, v);
        }
    
        @Expose()
        public get price(): number {
            return this._price;
        }
    
        public set price(v: number) {
            let oldValue = this._price;
            this._price = v;
            super.notifyFieldChange('price', oldValue, v);
        }
    
        @Expose()
        public get priceChangedManually(): boolean {
            return !!this._priceChangedManually;
        }
    
        public set priceChangedManually(v: boolean) {
            let oldValue = this._priceChangedManually;
            this._priceChangedManually = v;
            super.notifyFieldChange('priceChangedManually', oldValue, v);
        }
    }
    
    @NatModel({
        displayName: 'Использованный тип цены',
        pluralDisplayName: 'Использованные типы цен'
    })
    export class OrmSettingPricesOfProductsPriceTypeItem extends OrmDocumentItem {
        
        @NatProperty({
            displayName: 'Тип цены',
            type: 'relation',
            relationModel: 'OrmPriceType',
            required: true,
            standart: false
        })
        private _priceType: OrmPriceType;
    
        constructor() {
            super();
        }
        
        @Expose()
        @NatRelation(OrmPriceType)
        public get priceType(): OrmPriceType {
            if (!this._priceType) {
                this._priceType = new OrmPriceType();
            }
            return this._priceType;
        }
    
        public set priceType(v: OrmPriceType) {
            let oldValue = this._priceType;
            this._priceType = v;
            super.notifyFieldChange('priceType', oldValue, v);
        }
    }



@NatModel({
    plural: 'settingPricesOfProducts',
    displayName: 'Установка цен на товары',
    pluralDisplayName: 'Установки цен на товары'
})
export class OrmSettingPricesOfProducts extends OrmDocument {

    @NatProperty({
        displayName: 'Организация',
        type: 'relation',
        relationModel: 'OrmOrganization',
        required: false,
        standart: false
    })
    private _organization: OrmOrganization;
    
    @NatProperty({
        displayName: 'Список товаров',
        type: 'relation',
        relationModel: 'OrmSettingPricesOfProductsProductItem',
        required: false,
        standart: false
    })
    private _productItems: Array<OrmSettingPricesOfProductsProductItem>;
    
    @NatProperty({
        displayName: 'Список использованных типов цен',
        type: 'relation',
        relationModel: 'OrmSettingPricesOfProductsPriceTypeItem',
        required: false,
        standart: false
    })
    private _priceTypeItems: Array<OrmSettingPricesOfProductsPriceTypeItem>;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmOrganization)
    public get organization(): OrmOrganization {
        if (!this._organization) {
            this._organization = new OrmOrganization();
        }
        return this._organization;
    }

    public set organization(v: OrmOrganization) {
        let oldValue = this._organization;
        this._organization = v;
        super.notifyFieldChange('organization', oldValue, v);
    }
    
    // @Expose()
    @NatRelation(OrmSettingPricesOfProductsProductItem)
    // @NatEmbedRelation(OrmSettingPricesOfProductsProductItem, 'productItemList')
    public get productItems(): Array<OrmSettingPricesOfProductsProductItem> {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }

    public set productItems(v: Array<OrmSettingPricesOfProductsProductItem>) {
        let oldValue = this._productItems;
        this._productItems = v;
        super.notifyFieldChange('productItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSettingPricesOfProductsProductItem)
    // @NatEmbedRelation(OrmSettingPricesOfProductsProductItem, 'productItemList')
    private get productItemList(): Array<OrmSettingPricesOfProductsProductItem> {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }
    
    // @Expose()
    @NatRelation(OrmSettingPricesOfProductsPriceTypeItem)
    // @NatEmbedRelation(OrmSettingPricesOfProductsPriceTypeItem, 'priceTypeItemList')
    public get priceTypeItems(): Array<OrmSettingPricesOfProductsPriceTypeItem> {
        if (!this._priceTypeItems) {
            this._priceTypeItems = new Array();
        }
        return this._priceTypeItems;
    }

    public set priceTypeItems(v: Array<OrmSettingPricesOfProductsPriceTypeItem>) {
        let oldValue = this._priceTypeItems;
        this._priceTypeItems = v;
        super.notifyFieldChange('priceTypeItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSettingPricesOfProductsPriceTypeItem)
    // @NatEmbedRelation(OrmSettingPricesOfProductsPriceTypeItem, 'priceTypeItemList')
    private get priceTypeItemList(): Array<OrmSettingPricesOfProductsPriceTypeItem> {
        if (!this._priceTypeItems) {
            this._priceTypeItems = new Array();
        }
        return this._priceTypeItems;
    }
}

