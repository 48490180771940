import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemePresetOrderFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-order-field-item.model";

@NatModel({
    displayName: 'Поле структуры группировки',
    pluralDisplayName: 'Поля структуры группировки'
})
@NatHierarchical(false)
export class OrmSamplingSchemePresetStructureFieldItem extends OrmObjectItem {

    @NatProperty({
        displayName: 'Заголовок',
        type: 'string',
        required: false,
        standart: false
    })
    private _title: string;

    @NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _enabled: boolean;

    @NatProperty({
        displayName: 'Сортировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetOrderFieldItem',
        required: false,
        standart: false
    })
    private _orderFieldItems: Array<OrmSamplingSchemePresetOrderFieldItem>;

    constructor() {
        super();
    }

    @Expose()
    public get title(): string {
        return this._title;
    }

    public set title(v: string) {
        let oldValue = this._title;
        this._title = v;
        super.notifyFieldChange('title', oldValue, v);
    }

    @Expose()
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(v: boolean) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmSamplingSchemePresetOrderFieldItem)
    public get orderFieldItems(): Array<OrmSamplingSchemePresetOrderFieldItem> {
        return this._orderFieldItems;
    }

    public set orderFieldItems(v: Array<OrmSamplingSchemePresetOrderFieldItem>) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
    
    //
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetOrderFieldItem)
    public get orderFieldItemList(): Array<OrmSamplingSchemePresetOrderFieldItem> {
        return this._orderFieldItems;
    }

    public set orderFieldItemList(v: Array<OrmSamplingSchemePresetOrderFieldItem>) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
    
    //

}