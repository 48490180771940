import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";

export class NatProductFeatureRepository extends NatCatalogRepository<OrmProductFeature> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/productFeatures';
        let repoModel: Type<OrmProductFeature> = OrmProductFeature;
        this.init(repoUrl, repoModel);
    }
}