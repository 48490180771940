import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation, NatPolymorphicProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeParameterFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-parameter-field-item.model";

@NatModel({
    displayName: 'Отображаемое поле',
    pluralDisplayName: 'Отображаемые поля'
})
@NatHierarchical(false)
export class OrmSamplingSchemePresetParameterFieldItem extends OrmObjectItem {
    
    @NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
    private _valueId: any
    
    @NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
    private _valueType: string;
    
    @NatProperty({
        displayName: 'Поле параметра',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeParameterFieldItem',
        required: false,
        standart: false
    })
    private _parameterField: OrmSamplingSchemeParameterFieldItem;
    
    constructor() {
        super();
    }
    
    @Expose()
    @NatPolymorphicProperty('valueType')
    public get valueId(): any {
        return this._valueId;
    }

    public set valueId(v: any) {
        let oldValue = this._valueId;
        this._valueId = v;
        super.notifyFieldChange('valueId', oldValue, v);
    }

    @Expose()
    public get valueType(): string {
        return this._valueType;
    }

    public set valueType(v: string) {
        let oldValue = this._valueType;
        this._valueType = v;
        super.notifyFieldChange('valueType', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmSamplingSchemeParameterFieldItem)
    public get parameterField(): OrmSamplingSchemeParameterFieldItem {
        return this._parameterField;
    }

    public set parameterField(v: OrmSamplingSchemeParameterFieldItem) {
        let oldValue = this._parameterField;
        this._parameterField = v;
        super.notifyFieldChange('parameterField', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeParameterFieldItem)
    public get parameterFieldValue(): OrmSamplingSchemeParameterFieldItem {
        return this._parameterField;
    }

    public set parameterFieldValue(v: OrmSamplingSchemeParameterFieldItem) {
        let oldValue = this._parameterField;
        this._parameterField = v;
        super.notifyFieldChange('parameterField', oldValue, v);
    }
}