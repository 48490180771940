import * as tslib_1 from "tslib";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";
let OrmCashBox = class OrmCashBox extends OrmCatalog {
    constructor() {
        super();
    }
};
OrmCashBox = tslib_1.__decorate([
    NatModel({
        plural: 'cashBoxs',
        displayName: 'Касса',
        pluralDisplayName: 'Кассы'
    }),
    NatHierarchical(false)
], OrmCashBox);
export { OrmCashBox };
