import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatRelation, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";
import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmContactKind } from "@natiwi/shared/models/contact-kind.model";
import { OrmPartnerContactSet } from "@natiwi/shared/models/partner-contact-set.model";
import { OrmPartner } from "@natiwi/shared/models/partner.model";

@NatModel({
    plural: 'partnerContacts',
    displayName: 'Контактная информация',
    pluralDisplayName: 'Список контактной информации'
})
// @NatHierarchical(false)
export class OrmPartnerContact extends OrmInformationRegister {

    @NatProperty({
        displayName: 'Значение контакта',
        type: 'string',
        required: true,
        standart: false
    })
    private _view: string;

    @NatProperty({
        displayName: 'Контрагент',
        type: 'relation',
        relationModel: 'OrmPartner',
        relationType: 'belongsTo',
        required: true,
        standart: false
    })
    private _partner: OrmPartner;

    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'contact_types',
        required: true,
        standart: false
    })
    private _type: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Вид',
        type: 'relation',
        relationModel: 'OrmContactKind',
        relationType: 'belongsTo',
        required: true,
        standart: false
    })
    private _kind: OrmContactKind;

    @NatProperty({
        displayName: 'Набор контактов',
        type: 'relation',
        relationModel: 'OrmPartnerContactSet',
        relationType: 'belongsTo',
        required: true,
        standart: false
    })
    private _contactSet: OrmPartnerContactSet;

    constructor() {
        super();
    }

    @Expose()
    public get view(): string {
        return this._view;
    }

    public set view(v: string) {
        let oldValue = this._view;
        this._view = v;
        super.notifyFieldChange('view', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmPartner)
    public get partner(): OrmPartner {
        return this._partner;
    }

    public set partner(v: OrmPartner) {
        let oldValue = this._partner;
        this._partner = v;
        super.notifyFieldChange('partner', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        if (!this._type) {
            this._type = new OrmEnumerationItem();
        }
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmContactKind)
    public get kind(): OrmContactKind {
        return this._kind;
    }

    public set kind(v: OrmContactKind) {
        let oldValue = this._kind;
        this._kind = v;
        super.notifyFieldChange('kind', oldValue, v);
    }
}