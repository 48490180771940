import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { NatProductUnitRepository } from "@natiwi/shared/repositories/product-unit.repository";

export class NatProductUnitController extends NatCatalogController<OrmProductUnit>{

    constructor(_repository?: NatProductUnitRepository) {
        super(_repository);
        super.registerDataClass(OrmProductUnit);
    }
}