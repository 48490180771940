import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatMetadataRepository } from "@natiwi/core/network/repositories/metadata.repository";
import { OrmContainerMetadata } from "@natiwi/shared/models/container-metadata";
import { Type } from "@angular/core";

export class NatContainerMetadataRepository extends NatMetadataRepository<OrmContainerMetadata> {
    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/containersMetadata';
        let repoModel: Type<OrmContainerMetadata> = OrmContainerMetadata;
        this.init(repoUrl, repoModel);
    }
}
