import { NatPersistedModelRepository } from "@natiwi/core/network/repositories/persisted-model.repository";
import { NatFilter, NatFilterInclude } from "@natiwi/core/network/shared/filter";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { NatModelMetadata } from "@natiwi/core/models/model-metadata";
import { Type } from "@angular/core";
import { GlobalInjector } from "@natiwi/core/network/shared/global-variables";
import { NatModelController } from "@natiwi/core/controllers/model.controller";

export abstract class NatPersistedModelController<Model extends OrmPersistedModel> extends NatModelController<Model> {

    constructor(_repository: NatPersistedModelRepository<Model>) {
        super(_repository);
    }

    /**
     * findById
     */
    public findById(id: string, filter?: NatFilter): Observable<Model> {
        let params: Map<string, any> = new Map<string, any>();
        if (!filter) {
            filter = new NatFilter()
        }
        this.includeRelations(filter);
        params.set('filter', filter);
        let repo: NatPersistedModelRepository<Model> = this.getRepository();
        return repo.findById(id, params);
    }

    /**
     * bulkUpsert
     */
    public bulkUpsert(data: Array<Model>): Observable<Array<Model>> {
        let repo: NatPersistedModelRepository<Model> = this.getRepository();
        return repo.bulkUpsert(data);
    }

    /**
     * saveOrCreate
     */
    public saveOrCreate(object: Model): Observable<Model> {
        let repo: NatPersistedModelRepository<Model> = this.getRepository();
        return repo.saveOrCreate(object);
    }
    
}