import * as tslib_1 from "tslib";
import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatPolymorphicRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { OrmRoleMapping } from "@natiwi/core/models/role-mapping";
import { OrmPartner } from "@natiwi/shared/models/partner.model";
const ɵ0 = () => OrmRoleMapping;
let OrmUser = class OrmUser extends OrmPersistedModel {
    constructor() {
        super();
    }
    get generatedEmail() {
        return this._generatedEmail;
    }
    set generatedEmail(v) {
        this._generatedEmail = v;
    }
    get anonymous() {
        return this._anonymous;
    }
    set anonymous(v) {
        this._anonymous = v;
    }
    get employee() {
        return this._employee;
    }
    set employee(v) {
        this._employee = v;
    }
    get roles() {
        return this._roles;
    }
    set roles(v) {
        this._roles = v;
    }
    get principal() {
        return this._principal;
    }
    set principal(v) {
        this._principal = v;
    }
    get principalType() {
        return this._principalType;
    }
    set principalType(v) {
        this._principalType = v;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Анонимный',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmUser.prototype, "_anonymous", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Почта сгенерирована',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmUser.prototype, "_generatedEmail", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Сотрудник',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmUser.prototype, "_employee", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Роли',
        type: 'array',
        required: false,
        standart: false
    })
], OrmUser.prototype, "_roles", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Главная сущность',
        type: 'polymorphicRelation',
        required: false,
        standart: false
    })
], OrmUser.prototype, "_principal", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Главная сущность тип',
        type: 'string',
        required: false,
        standart: false
    })
], OrmUser.prototype, "_principalType", void 0);
tslib_1.__decorate([
    Expose()
], OrmUser.prototype, "generatedEmail", null);
tslib_1.__decorate([
    Expose()
], OrmUser.prototype, "anonymous", null);
tslib_1.__decorate([
    Expose()
], OrmUser.prototype, "employee", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmUser.prototype, "roles", null);
tslib_1.__decorate([
    Expose(),
    NatPolymorphicRelation('principalType', [OrmPartner])
], OrmUser.prototype, "principal", null);
tslib_1.__decorate([
    Expose()
], OrmUser.prototype, "principalType", null);
OrmUser = tslib_1.__decorate([
    NatModel({
        plural: 'users',
        displayName: 'Пользователь',
        pluralDisplayName: 'Пользователи'
    })
], OrmUser);
export { OrmUser };
export { ɵ0 };
