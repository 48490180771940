import * as tslib_1 from "tslib";
import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
const ɵ0 = () => Date, ɵ1 = () => Date;
let OrmRole = class OrmRole extends OrmPersistedModel {
    constructor() {
        super();
    }
    get name() {
        return this._name;
    }
    set name(v) {
        this._name = v;
    }
    get created() {
        return this._created;
    }
    set created(v) {
        this._created = v;
    }
    get modified() {
        return this._modified;
    }
    set modified(v) {
        this._modified = v;
    }
    get predefined() {
        return this._predefined;
    }
    set predefined(v) {
        this._predefined = v;
    }
    get predefinedName() {
        return this._predefinedName;
    }
    set predefinedName(v) {
        this._predefinedName = v;
    }
    get predefinedVersion() {
        return this._predefinedVersion;
    }
    set predefinedVersion(v) {
        this._predefinedVersion = v;
    }
    isRole(name) {
        return this._name === name;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя роли',
        type: 'string',
        required: false,
        standart: false
    })
], OrmRole.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата создания',
        type: 'date',
        required: false,
        standart: false
    })
], OrmRole.prototype, "_created", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата изменения',
        type: 'date',
        required: false,
        standart: false
    })
], OrmRole.prototype, "_modified", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Предопределенная роль',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmRole.prototype, "_predefined", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Предопределенное имя',
        type: 'string',
        required: false,
        standart: false
    })
], OrmRole.prototype, "_predefinedName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Версия предопределения',
        type: 'number',
        required: false,
        standart: false
    })
], OrmRole.prototype, "_predefinedVersion", void 0);
tslib_1.__decorate([
    Expose()
], OrmRole.prototype, "name", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmRole.prototype, "created", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ1)
], OrmRole.prototype, "modified", null);
tslib_1.__decorate([
    Expose()
], OrmRole.prototype, "predefined", null);
tslib_1.__decorate([
    Expose()
], OrmRole.prototype, "predefinedName", null);
tslib_1.__decorate([
    Expose()
], OrmRole.prototype, "predefinedVersion", null);
OrmRole = tslib_1.__decorate([
    NatModel({
        plural: 'roles',
        displayName: 'Роль',
        pluralDisplayName: 'Роли'
    })
], OrmRole);
export { OrmRole };
export { ɵ0, ɵ1 };
