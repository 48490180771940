import { Expose, Type } from "class-transformer";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";

export abstract class OrmObject extends OrmPersistedModel {
    
    @NatProperty({
        displayName: 'Дата создания',
        type: 'date',
        required: false,
        standart: false
    })
    private _createdAt: Date;

    @NatProperty({
        displayName: 'Пометка на удаление',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _deleted: boolean;

    constructor() {
        super();
    }
    
    @Expose()
    @Type(() => Date)
    public get createdAt(): Date {
        return this._createdAt;
    }

    public set createdAt(v: Date) {
        let oldValue = this._createdAt;
        this._createdAt = v;
        super.notifyFieldChange('createdAt', oldValue, v);
    }

    @Expose()
    public get deleted(): boolean {
        return this._deleted;
    }

    public set deleted(v: boolean) {
        let oldValue = this._deleted;
        this._deleted = v;
        super.notifyFieldChange('deleted', oldValue, v);
    }

}

