import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { OrmRole } from "@natiwi/core/models/role";

@NatModel({
    plural: 'rolesMapping',
    displayName: 'Карта роли',
    pluralDisplayName: 'Карты ролей'
})
export class OrmRoleMapping extends OrmPersistedModel {

    @NatProperty({
        displayName: 'Тип сущности',
        type: 'string',
        required: false,
        standart: false
    })
    private _principalType: string;

    @NatProperty({
        displayName: 'Идентификатор сущности',
        type: 'string',
        required: false,
        standart: false
    })
    private _principalId: string;

    @NatProperty({
        displayName: 'Идентификатор роли',
        type: 'string',
        required: false,
        standart: false
    })
    private _roleId: string;

    @NatProperty({
        displayName: 'Роль',
        type: 'relation',
        relationModel: 'OrmRole',
        required: false,
        standart: false
    })
    private _role: OrmRole;

    constructor() {
        super();
    }

    @Expose()
    public get principalType(): string {
        return this._principalType;
    }

    public set principalType(v: string) {
        this._principalType = v;
    }

    @Expose()
    public get principalId(): string {
        return this._principalId;
    }

    public set principalId(v: string) {
        this._principalId = v;
    }

    @Expose()
    @Type(() => OrmRole)
    public get role(): OrmRole {
        return this._role;
    }

    public set role(v: OrmRole) {
        this._role = v;
    }

    public isRole(name: string): boolean {
        let result: boolean = false;
        if (this._role) {
            result = this._role.isRole(name);
        }
        return result;
    }

    static hasRole(roleMappingList: Array<OrmRoleMapping>, name: string): boolean {
        return !!roleMappingList.find(element => element.isRole(name));
    }

}