import * as tslib_1 from "tslib";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
export class OrmDocumentItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get bindingKey() {
        return this._bindingKey;
    }
    set bindingKey(v) {
        let oldValue = this._bindingKey;
        if (v) {
            this._bindingKey = v;
        }
        else {
            this._bindingKey = null;
        }
        super.notifyFieldChange('bindingKey', oldValue, this._bindingKey);
    }
}
tslib_1.__decorate([
    NatProperty({
        displayName: 'Ключ связи',
        type: 'string',
        required: false,
        standart: false
    })
], OrmDocumentItem.prototype, "_bindingKey", void 0);
