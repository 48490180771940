import { Injectable, Type } from "@angular/core";
import { OrmUser } from "@natiwi/core/models/user";
import { NatPersistedModelRepository } from "@natiwi/core/network/repositories/persisted-model.repository";
import { Observable, throwError } from "rxjs";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { classToPlain, plainToClass } from "class-transformer";
import { map } from "rxjs/internal/operators/map";
import { OrmAccessToken } from "@natiwi/core/models/access-token";

export class NatUserRepository extends NatPersistedModelRepository<OrmUser> {

    constructor() {
        super();
        let repoUrl: string = '/users';
        let repoModel: Type<OrmUser> = OrmUser;
        super.init(repoUrl, repoModel);
        super.createСontractItem('process', '/process', NatVerbType.POST);
        super.createСontractItem('login', '/login', NatVerbType.POST);
    }

    public process(data: OrmUser): Observable<OrmUser> {
        let stringlifyData: string = JSON.stringify(classToPlain(data, { excludePrefixes: ["_"] }));
        let result: Observable<OrmUser> = this.invokeStaticMethod<OrmUser>('process', null, stringlifyData);
        return result.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as OrmUser;
        }));
    }

    public login(email: string, password: string, params?: Map<string, any>): Observable<OrmAccessToken> {
        if (!email || !email.length || !password || !password.length) {
            return throwError('Параметры email и password являются обязательными');
        }
        let credentials = {
            email: email,
            password: password
        }
        let stringlifyData: string = JSON.stringify(credentials);
        let stringifyParams: Map<string, string> = new Map<string, string>();
        if (params && params.size) {
            params.forEach((value: any, key: string) => {
                if (typeof value !== 'object') {
                    stringifyParams.set(key, value);
                } else {
                    let plainParams = classToPlain(value, { excludePrefixes: ["_"], enableCircularCheck: true })
                    stringifyParams.set(key, JSON.stringify(plainParams));
                }
            });
        }
        let result: Observable<OrmAccessToken> = this.invokeStaticMethod<OrmAccessToken>('login', stringifyParams, stringlifyData);
        return result.pipe(map(response => {
            return plainToClass(OrmAccessToken, response,
                {
                    excludeExtraneousValues: true
                }
            ) as OrmAccessToken;
        }));
    }

}