import { Injectable, ComponentRef } from '@angular/core';
import { NatCoreModule } from '../core.module';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface NatDetachedRouteHandle extends DetachedRouteHandle {
  componentRef: ComponentRef<any>;
}
interface NatActivatedRouteSnapshot extends ActivatedRouteSnapshot {
  _routerState: RouterStateSnapshot;
}

export class NatRouteReuseStrategyService implements RouteReuseStrategy {

  private storedRouteHandles;
  private storedComponentRefs:Map<string, ComponentRef<any>>;
  
  constructor() {
    this.storedRouteHandles = new Map<string, DetachedRouteHandle>();
    this.storedComponentRefs = new Map<string, ComponentRef<any>>();
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const shouldDetach = !this.skipRouteCache(route);
    console.log('[shouldDetach]', shouldDetach);
    return shouldDetach;
  }
  store(route: NatActivatedRouteSnapshot, handle: NatDetachedRouteHandle): void {
    const key = this.getFullPath(route);

    if (!handle || this.skipRouteCache(route)) {
      return;
    }

    const previousStoredRouteHandle = this.storedRouteHandles.get(key);
    if (previousStoredRouteHandle && previousStoredRouteHandle.componentRef !== handle.componentRef) {
      previousStoredRouteHandle.componentRef.destroy();
    }
    console.log(`[storeSetting]: to key - ${key}`, handle);
    this.storedRouteHandles.set(key, handle);
  }

  shouldAttach(route: NatActivatedRouteSnapshot): boolean {
    const key = this.getFullPath(route);
    const stored = this.storedRouteHandles.get(key);
    const destroyed = !!(
      stored && stored.componentRef.hostView.destroyed
    );
    const shouldAttach = !destroyed && !!stored && !this.skipRouteCache(route);

    if (destroyed) {
      this.storedRouteHandles.delete(key);
    }
    console.log('[shouldAttach]', shouldAttach);
    return shouldAttach;
  }

  retrieve(route: NatActivatedRouteSnapshot): DetachedRouteHandle {
    const key = this.getFullPath(route);
    const stored = this.storedRouteHandles.get(key);
    const destroyed = !!(
      stored && stored.componentRef.hostView.destroyed
    );
    const shouldBeRetrieved = !destroyed && !!stored;

    if (destroyed) {
      this.storedRouteHandles.delete(key);
    }
    if (this.skipRouteCache(route)) {
      return null;
    }
    console.log('[retrieve]', stored);
    return stored || null;
  }

  shouldReuseRoute(future: NatActivatedRouteSnapshot, curr: NatActivatedRouteSnapshot): boolean {
    let shouldReuseRoute = future.routeConfig === curr.routeConfig && JSON.stringify(future.params) === JSON.stringify(curr.params)
    console.log('[shouldReuseRoute]', shouldReuseRoute);
    return shouldReuseRoute
  }

  private getFullPath(route: NatActivatedRouteSnapshot): string {
    let key = route._routerState.url.split('?')[0];
    //доп. страховка
    if (!route.component) {
      key = 'parent_route/' + key;
    }
    console.log('urlKey', key);
    return key;
  }

  private skipRouteCache(route: ActivatedRouteSnapshot): boolean {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return true;
    }
    return false;
  }

  private callHook(detachedTree: NatDetachedRouteHandle, hookName: string): void {
    const componentRef = detachedTree.componentRef;
    if (
      componentRef &&
      componentRef.instance &&
      typeof componentRef.instance[hookName] === 'function'
    ) {
      componentRef.instance[hookName]();
    }
  }
  
  public getStoredComponentRef(urlKey: string): ComponentRef<any> {
    const stored = this.storedComponentRefs.get(urlKey);
    if (!stored) {
      return;
    }
    return stored;
  }

  public deleteCachedRoute(urlKey: string): void {
    const stored = this.storedRouteHandles.get(urlKey);
    if (!stored) {
      return;
    }
    stored.componentRef.destroy();
    this.storedRouteHandles.delete(urlKey);
  }

  public deleteAllCachedRoutes(): void {
    // По-возможности, провести рефакторинг.
    // Страницы кэшируются. При выходе из кабинета остаются ссылки,
    // которые пытаются замещать новые экземпляры. Приложение падает
    this.storedRouteHandles.values(stored => stored && stored.componentRef.destroy())
    this.storedRouteHandles.clear()
  }
}
