import { NatPersistedModelRepository } from "@natiwi/core/network/repositories/persisted-model.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Type as CTType, plainToClassFromExist, Expose, plainToClass, classToPlain } from "class-transformer";
import { OrmObject } from "@natiwi/core/models/object";
import { OrmModel } from "@natiwi/core/models/model";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { Type } from "@angular/core";

export abstract class NatObjectRepository<T extends OrmObject> extends NatPersistedModelRepository<T> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);

        super.createСontractItem('process', '/process', NatVerbType.POST);
        super.createСontractItem('makeDeleted', '/makeDeleted', NatVerbType.POST);
        super.createСontractItem('viewList', '/viewList');

    }

    public viewList(params?: Map<string, any>): Observable<ViewListResponse<T>> {
        let stringifyParams: Map<string, string> = new Map<string, string>();
        if (params && params.size) {
            params.forEach((value: any, key: string) => {
                stringifyParams.set(key, JSON.stringify(classToPlain(value, { excludePrefixes: ["_"] })));
            });
        }
        let result: Observable<ViewListResponse<T>> = this.invokeStaticMethod<ViewListResponse<T>>('viewList', stringifyParams);
        return result.pipe(map(response => {
            return plainToClassFromExist(new ViewListResponse<T>(this.dataClass), response,
                {
                    excludeExtraneousValues: true
                }
            ) as ViewListResponse<T>;;
        }));
    }

    public process(data: T): Observable<T> {
        let stringlifyData: string = JSON.stringify(classToPlain(data, { excludePrefixes: ["_"] }));
        let result: Observable<T> = this.invokeStaticMethod<T>('process', null, stringlifyData);
        return result.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as T;
        }));
    }

    public makeDeleted(mode: boolean, object: T): Observable<T> {
        let stringlifyBodyData: string = JSON.stringify(classToPlain(object, { excludePrefixes: ["_"] }));
        let stringifyParams: Map<string, string> = new Map<string, string>();
        stringifyParams.set('mode', `${mode}`);
        stringifyParams.set('id', object.id);
        
        let result: Observable<T> = this.invokeStaticMethod<T>('makeDeleted', stringifyParams, stringlifyBodyData);
        return result.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as T;
        }));
    }
}

export class ViewListResponse<T extends OrmModel> {

    private _output: Array<T>;
    private _outputInfo: ViewListOutputInfoResponse;
    private type: Type<T>;

    constructor(type: Type<T>, output?: Array<T>, outputInfo?: ViewListOutputInfoResponse) {
        this._output = output;
        this._outputInfo = outputInfo;
        this.type = type;
    }

    @Expose()
    @CTType(options => (options.newObject as ViewListResponse<T>).type)
    public get output(): Array<T> {
        return this._output;
    }

    public set output(value: Array<T>) {
        this._output = value;
    }

    @Expose()
    @CTType(() => ViewListOutputInfoResponse)
    public get outputInfo(): ViewListOutputInfoResponse {
        return this._outputInfo;
    }

    public set outputInfo(value: ViewListOutputInfoResponse) {
        this._outputInfo = value;
    }
}

export class ViewListOutputInfoResponse {
    private _count: number = 0;
    private _totalCount: number = 0;

    constructor(count: number, totalCount: number) {
        this._count = count;
        this._totalCount = totalCount;
    }
    @Expose()
    public get count(): number {
        return this._count;
    }

    public set count(value: number) {
        this._count = value;
    }
    @Expose()
    public get totalCount(): number {
        return this._totalCount;
    }

    public set totalCount(value: number) {
        this._totalCount = value;
    }
}