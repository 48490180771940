import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NatCoreModule } from '@natiwi/core';
import { NatAuthRoutingModule } from '@natiwi/auth/auth-routing.module';
import { NatLoginComponent } from '@natiwi/auth/login';
import { NatRestorePasswordComponent } from '@natiwi/auth/restore-password';
import { NatAuthGuard } from '@natiwi/auth/auth.guard';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [NatLoginComponent, NatRestorePasswordComponent],
  imports: [
    CommonModule,
    NatCoreModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NatAuthRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule
  ],
  exports:[NatAuthRoutingModule],
  providers: [NatAuthGuard]
})
export class NatAuthModule { }
