var OrmContainerMetadata_1;
import * as tslib_1 from "tslib";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose, Type } from "class-transformer";
import { OrmMetadata } from "@natiwi/core/models/metadata";
const ɵ0 = () => OrmContainerMetadata_1;
let OrmContainerMetadata = OrmContainerMetadata_1 = class OrmContainerMetadata extends OrmMetadata {
    constructor() {
        super();
    }
    get contentType() {
        return this._contentType;
    }
    set contentType(v) {
        this._contentType = v;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип содержимого',
        type: 'string',
        required: true,
        standart: false
    })
], OrmContainerMetadata.prototype, "_contentType", void 0);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmContainerMetadata.prototype, "contentType", null);
OrmContainerMetadata = OrmContainerMetadata_1 = tslib_1.__decorate([
    NatModel({
        plural: 'containersMetadata',
        displayName: 'Контейнер файлов',
        pluralDisplayName: 'Контейнеры файлов'
    })
], OrmContainerMetadata);
export { OrmContainerMetadata };
export { ɵ0 };
