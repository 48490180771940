import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";

@NatModel({
    displayName: 'Набор данных',
    pluralDisplayName: 'Наборы данных'
})
@NatHierarchical(false)
export class OrmSamplingSchemeDataSetItem extends OrmObjectItem {

    @NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
    private _name: string;

    @NatProperty({
        displayName: 'Целевая модель',
        type: 'string',
        required: true,
        standart: false
    })
    private _targetModel: string;

    @NatProperty({
        displayName: 'Запрос',
        type: 'string',
        required: false,
        standart: false
    })
    private _query: string;

    @NatProperty({
        displayName: 'Поля',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
    private _fieldItems: Array<OrmSamplingSchemeFieldItem>;

    constructor() {
        super();
    }

    @Expose()
    public get name(): string {
        return this._name;
    }

    public set name(v: string) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }

    @Expose()
    public get targetModel(): string {
        return this._targetModel;
    }

    public set targetModel(v: string) {
        let oldValue = this._targetModel;
        this._targetModel = v;
        super.notifyFieldChange('targetModel', oldValue, v);
    }

    @Expose()
    public get query(): string {
        return this._query;
    }

    public set query(v: string) {
        let oldValue = this._query;
        this._query = v;
        super.notifyFieldChange('query', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get fieldItems(): Array<OrmSamplingSchemeFieldItem> {
        return this._fieldItems;
    }

    public set fieldItems(v: Array<OrmSamplingSchemeFieldItem>) {
        let oldValue = this._fieldItems;
        this._fieldItems = v;
        super.notifyFieldChange('fieldItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get fieldItemList(): Array<OrmSamplingSchemeFieldItem> {
        return this._fieldItems;
    }

    public set fieldItemList(v: Array<OrmSamplingSchemeFieldItem>) {
        let oldValue = this._fieldItems;
        this._fieldItems = v;
        super.notifyFieldChange('fieldItems', oldValue, v);
    }

    /**
  * toString
  */
    public toString(): string {
        return this._name || '';
    }
}