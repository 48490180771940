import { Injectable, Type } from "@angular/core";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatInformationRegisterRepository } from "@natiwi/core/network/repositories/information-register.repository";
import { OrmProductPrice } from "@natiwi/shared/models/product-price.model";

export class NatProductPriceRepository extends NatInformationRegisterRepository<OrmProductPrice> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/productPrices';
        let repoModel: Type<OrmProductPrice> = OrmProductPrice;
        this.init(repoUrl, repoModel);
    }
}