import { BehaviorSubject, Subject } from "rxjs";
import { OnDestroy } from "@angular/core";
import { NatFilter } from "@natiwi/core/network/shared/filter";
import { NatKeyValueObserver } from "@natiwi/core/models/observer";

export abstract class NatForm implements OnDestroy {

    private _title: string;
    private _titleSubject: BehaviorSubject<string>;
    private _closeable: boolean;
    private _onCloseHandler: Function;

    constructor() {
        this._title = 'Загрузка...';
        this._titleSubject = new BehaviorSubject(this._title);
    }


    public set onCloseHandler(v: Function) {
        this._onCloseHandler = v;
    }


    public set title(v: string) {
        this._title = v;
        this._titleSubject.next(this._title);
    }

    public getTitle(): BehaviorSubject<string> {
        return this._titleSubject;
    }

    protected close(): void {
        if (this._onCloseHandler) {
            this._onCloseHandler();
        } else {
            console.log('Метод закрытия формы не определен');
        }
    }

    ngOnDestroy(): void {
        this._titleSubject.complete();
    }
}

export class NatFormConfigure extends Map<string, NatFormConfigure | NatFormControlHostConfigure>{

    constructor() {
        super();
    }
}

export class NatFormControlHostConfigure {

    private _baseFilterFn: (object) => NatFilter;
    private _options: NatFormControlHostConfigureOptions;
    private _eventMap: Map<string, (object, key, oldValue, newValue) => void>;

    constructor() { 
        this._eventMap = new Map();
    }

    public get baseFilterFn(): (object) => NatFilter {
        return this._baseFilterFn;
    }

    public set baseFilterFn(v: (object) => NatFilter) {
        this._baseFilterFn = v;
    }

    public get eventMap(): Map<string, (object, key, oldValue, newValue) => void> {
        return this._eventMap;
    }

    public get options(): NatFormControlHostConfigureOptions {
        return this._options;
    }

    public set options(v: NatFormControlHostConfigureOptions) {
        this._options = v;
    }

}

export class NatFormControlHostConfigureOptions extends Map<string, any>{

}