import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    plural: 'warehouses',
    displayName: 'Склад',
    pluralDisplayName: 'Склады'
})
export class OrmWarehouse extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Розничный тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: false,
        standart: false
    })
    private _retailPriceType: OrmPriceType;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'warehouse_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;

    constructor() {
        super();
    }
    
    @Expose()
    @NatRelation(OrmPriceType)
    public get retailPriceType(): OrmPriceType {
        return this._retailPriceType;
    }

    public set retailPriceType(v: OrmPriceType) {
        let oldValue = this._retailPriceType;
        this._retailPriceType = v;
        super.notifyFieldChange('retailPriceType', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
}