import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeParameterFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-parameter-field-item.model";
import { OrmSamplingSchemeDataSetItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-data-set-item.model";
import { OrmSamplingSchemeAggregateFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-aggregate-field-item.model";

@NatModel({
    displayName: 'Схема выборки данных',
    pluralDisplayName: 'Схемы выборки данных'
})
@NatHierarchical(false)
export class OrmSamplingSchemeItem extends OrmObjectItem {

    @NatProperty({
        displayName: 'Наборы данных',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeDataSetItem',
        required: false,
        standart: false
    })
    private _dataSetItems: Array<OrmSamplingSchemeDataSetItem>;

    @NatProperty({
        displayName: 'Агрегирования',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeAggregateFieldItem',
        required: false,
        standart: false
    })
    private _aggregateFieldItems: Array<OrmSamplingSchemeAggregateFieldItem>;
    
    @NatProperty({
        displayName: 'Параметры',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeParameterFieldItem',
        required: false,
        standart: false
    })
    private _parameterFieldItems: Array<OrmSamplingSchemeParameterFieldItem>;
    
    constructor() {
        super();
    }
//
    @Expose()
    @NatRelation(OrmSamplingSchemeDataSetItem)
    public get dataSetItems(): Array<OrmSamplingSchemeDataSetItem> {
        return this._dataSetItems;
    }

    public set dataSetItems(v: Array<OrmSamplingSchemeDataSetItem>) {
        let oldValue = this._dataSetItems;
        this._dataSetItems = v;
        super.notifyFieldChange('dataSetItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeDataSetItem)
    public get dataSetItemList(): Array<OrmSamplingSchemeDataSetItem> {
        return this._dataSetItems;
    }

    public set dataSetItemList(v: Array<OrmSamplingSchemeDataSetItem>) {
        let oldValue = this._dataSetItems;
        this._dataSetItems = v;
        super.notifyFieldChange('dataSetItems', oldValue, v);
    }
    //
    
    @Expose()
    @NatRelation(OrmSamplingSchemeAggregateFieldItem)
    public get aggregateFieldItems(): Array<OrmSamplingSchemeAggregateFieldItem> {
        return this._aggregateFieldItems;
    }

    public set aggregateFieldItems(v: Array<OrmSamplingSchemeAggregateFieldItem>) {
        let oldValue = this._aggregateFieldItems;
        this._aggregateFieldItems = v;
        super.notifyFieldChange('aggregateFieldItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeAggregateFieldItem)
    public get aggregateFieldItemList(): Array<OrmSamplingSchemeAggregateFieldItem> {
        return this._aggregateFieldItems;
    }

    public set aggregateFieldItemList(v: Array<OrmSamplingSchemeAggregateFieldItem>) {
        let oldValue = this._aggregateFieldItems;
        this._aggregateFieldItems = v;
        super.notifyFieldChange('aggregateFieldItems', oldValue, v);
    }
    
    //
    @Expose()
    @NatRelation(OrmSamplingSchemeParameterFieldItem)
    public get parameterFieldItems(): Array<OrmSamplingSchemeParameterFieldItem> {
        return this._parameterFieldItems;
    }

    public set parameterFieldItems(v: Array<OrmSamplingSchemeParameterFieldItem>) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeParameterFieldItem)
    public get parameterFieldItemList(): Array<OrmSamplingSchemeParameterFieldItem> {
        return this._parameterFieldItems;
    }

    public set parameterFieldItemList(v: Array<OrmSamplingSchemeParameterFieldItem>) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
    //
}