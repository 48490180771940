import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmLoyaltyCard } from "@natiwi/shared/models/loyalty-card.model";

export class NatLoyaltyCardRepository extends NatCatalogRepository<OrmLoyaltyCard> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/loyaltyCards';
        let repoModel: Type<OrmLoyaltyCard> = OrmLoyaltyCard;
        this.init(repoUrl, repoModel);
    }
}