import * as tslib_1 from "tslib";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel } from "@natiwi/core/decorators/matadata.decorator";
let OrmLoyaltyCard = class OrmLoyaltyCard extends OrmCatalog {
    constructor() {
        super();
    }
};
OrmLoyaltyCard = tslib_1.__decorate([
    NatModel({
        plural: 'loyaltyCards',
        displayName: 'Карта лояльности',
        pluralDisplayName: 'Карты лояльности'
    })
], OrmLoyaltyCard);
export { OrmLoyaltyCard };
