import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatMetadataRepository } from "@natiwi/core/network/repositories/metadata.repository";
import { Type } from "@angular/core";
import { OrmImageMetadata } from "@natiwi/shared/models/image-metadata";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { plainToClass, classToPlain } from "class-transformer";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { NatUploadFileMetadata } from "@natiwi/shared/repositories/container.repository";

export class NatImageMetadataRepository extends NatMetadataRepository<OrmImageMetadata> {
    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/images';
        let repoModel: Type<OrmImageMetadata> = OrmImageMetadata;
        this.init(repoUrl, repoModel);
        super.createСontractItem('download', '/:imageId/:sizeId/download');
        super.createСontractItem('upload', '/upload', NatVerbType.POST);
    }
    
    public upload(metadata: NatUploadFileMetadata): Observable<OrmImageMetadata> {
        let stringlifyData: string = JSON.stringify(classToPlain(metadata, { excludePrefixes: ["_"] }));
        let request: Observable<OrmImageMetadata> = this.invokeStaticMethod<OrmImageMetadata>('upload', null, stringlifyData);
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as OrmImageMetadata;
        }));
    }

    public download(imageId: string, sizeId: string): Observable<OrmImageMetadata> {
        if (!imageId || !sizeId) {
            return throwError( 'Идентификаторы изорбражения или размера не заданы');
        }
        let params: Map<string, string> = new Map();
        params.set('imageId', imageId);
        params.set('sizeId', sizeId);
        let request: Observable<OrmImageMetadata> = this.invokeStaticMethod<OrmImageMetadata>('download', params);
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as OrmImageMetadata;
        }));
    }
}
