import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmNaturalPerson } from "@natiwi/shared/models/natural-person.model";
import { NatNaturalPersonRepository } from "@natiwi/shared/repositories/natural-person.repository";

export class NatNaturalPersonController extends NatCatalogController<OrmNaturalPerson>{

    constructor(_repository?: NatNaturalPersonRepository) {
        super(_repository);
        super.registerDataClass(OrmNaturalPerson);
    }
}