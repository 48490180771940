import * as tslib_1 from "tslib";
import { OrmModel } from "@natiwi/core/models/model";
import { Expose } from "class-transformer";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";
export class OrmPersistedModel extends OrmModel {
    constructor() {
        super();
    }
    get id() {
        return this._id;
    }
    set id(v) {
        let oldValue = this._id;
        if (v) {
            this._id = v;
        }
        else {
            this._id = null;
        }
        super.notifyFieldChange('id', oldValue, this._id);
    }
    /**
     * isNew проверяет новый ли объект
     */
    isNew() {
        return !(!!this._id);
    }
    /**
     * toString
     */
    toString() {
        return this._id;
    }
}
tslib_1.__decorate([
    NatProperty({
        displayName: 'Идентификатор',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPersistedModel.prototype, "_id", void 0);
tslib_1.__decorate([
    Expose()
], OrmPersistedModel.prototype, "id", null);
