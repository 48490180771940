import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { NatAuthService } from '@natiwi/core/services/auth.service';

@Injectable()
export class NatAuthInterceptor implements HttpInterceptor {

  constructor(private authService: NatAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getAuthToken();

    if (authToken) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authToken.id)
      });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}