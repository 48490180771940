import { OrmModel } from "@natiwi/core/models/model";
import { Expose } from "class-transformer";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import * as uuid from 'uuid';


export abstract class OrmPersistedModel extends OrmModel {

    @NatProperty({
        displayName: 'Идентификатор',
        type: 'string',
        required: false,
        standart: false
    })
    private _id: string;

    constructor() {
        super();
    }

    @Expose()
    public get id(): string {
        return this._id;
    }

    public set id(v: string) {
        let oldValue = this._id;
        if (v) {
            this._id = v;
        } else {
            this._id = null;
        }
        super.notifyFieldChange('id', oldValue, this._id);
    }

    /**
     * isNew проверяет новый ли объект
     */
    public isNew(): boolean {
        return !(!!this._id);
    }

    /**
     * toString
     */
    public toString(): string {
        return this._id;
    }

}