import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmProductKind } from "@natiwi/shared/models/product-kind.model";
import { OrmPaymentOption } from "@natiwi/shared/models/payment-option.model";
import { NatPaymentOptionRepository } from "@natiwi/shared/repositories/payment-option.repository";

export class NatPaymentOptionController extends NatCatalogController<OrmPaymentOption>{

    constructor(_repository?: NatPaymentOptionRepository) {
        super(_repository);
        super.registerDataClass(OrmPaymentOption);
    }
}