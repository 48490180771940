import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { NatNavigationService } from '@natiwi/core';
import { NatNavigationItem } from '@natiwi/core/services/navigation/navigation-item';

@Component({
  selector: 'nat-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class NatTabsComponent implements OnInit {

  @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;
  
  tabs = [];

  constructor(private navigationService: NatNavigationService) { }

  ngOnInit() {
    this.tabs = this.navigationService.getNavigationItems();
  }

  ngAfterViewInit() {
    if (!this.tabGroup) {
      throw 'tab group need';
    }

    this.navigationService.activeNavigationItem.subscribe(r => {
      this.tabGroup.selectedIndex = this.tabs.indexOf(r);
    });

    this.tabGroup.selectedIndexChange.subscribe(index => {
      let tab = this.tabs[index];
      this.navigationService.navigate(tab).subscribe();
    });
  }

  closeTab(event, tab) {
    event.preventDefault();
    event.stopPropagation();
    this.navigationService.deleteNavigationItem(tab);
  }
}
