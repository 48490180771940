import { OrmModel } from "@natiwi/core/models/model";
import { Expose } from "class-transformer";
import { NatProperty, NatModel, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumeration } from "@natiwi/shared/models/enumeration.model";

@NatModel({
    plural: 'enumerationItems',
    displayName: 'Перечисление',
    pluralDisplayName: 'Перечисления'
})
export class OrmEnumerationItem extends OrmModel {

    @NatProperty({
        displayName: 'Идентификатор',
        type: 'string',
        required: true,
        standart: false
    })
    private _id: string;
    
    @NatProperty({
        displayName: 'Идентификатор ключа',
        type: 'string',
        required: true,
        standart: false
    })
    private _keyId: string;
    
    @NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
    private _name: string;
    
    @NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
    private _description: string;
    
    // @NatProperty({
    //     displayName: 'Владелец',
    //     type: 'relation',
    //     relationModel: 'OrmEnumeration',
    //     required: true,
    //     standart: false
    // })
    // private _owner: OrmEnumeration;
    
    
    constructor() {
        super();
    }

    @Expose()
    public get id(): string {
        return this._id;
    }

    public set id(v: string) {
        let oldValue = this._id;
        if (v) {
            this._id = v;
        } else {
            this._id = null;
        }
        super.notifyFieldChange('id', oldValue, this._id);
    }
    
    @Expose()    
    public get keyId() : string {
        return this._keyId;
    }
    
    public set keyId(v: string) {
        let oldValue = this._keyId;
        this._keyId = v;
        super.notifyFieldChange('keyId', oldValue, v);
    }
    
    @Expose()    
    public get name() : string {
        return this._name;
    }
    
    public set name(v: string) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    
    @Expose()    
    public get description() : string {
        return this._description;
    }
    
    public set description(v: string) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    
    // @Expose()
    // @NatRelation(OrmEnumeration)
    // public get owner(): OrmEnumeration {
    //     if (!this._owner) {
    //         this._owner = new OrmEnumeration();
    //     }
    //     return this._owner;
    // }

    // public set owner(v: OrmEnumeration) {
    //     let oldValue = this._owner;
    //     this._owner = v;
    //     super.notifyFieldChange('owner', oldValue, v);
    // }

    /**
     * toString
     */
    public toString(): string {
        return this._name || '';
    }

}