import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmLoyaltyCard } from "@natiwi/shared/models/loyalty-card.model";
import { NatLoyaltyCardRepository } from "@natiwi/shared/repositories/loyalty-cards.repository";

export class NatLoyaltyCardController extends NatCatalogController<OrmLoyaltyCard>{

    constructor(_repository?: NatLoyaltyCardRepository) {
        super(_repository);
        super.registerDataClass(OrmLoyaltyCard);
    }
}