import { Component, OnInit } from '@angular/core';
import { NatMenuBodyComponent } from '@natiwi/shared/layout/menu/main-menu/main-menu-body';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'nat-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css'],
  providers: []
})
export class NatMainMenuComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  private dialogConfig = {
    disableClose: true,
    closeOnNavigation: true,
    position: {
      left: '0',
      top: '0'
    },
    minHeight: '100%',
    minWidth: '100%',
    height: '100%',
    width: '100%',
    panelClass: 'nat-main-menu-dialog-panel',
  };

  ngOnInit() {
  }

  openMenu() {
    const dialogRef = this.dialog.open(NatMenuBodyComponent, this.dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
