import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    plural: 'productKinds',
    displayName: 'Вид номенклатуры',
    pluralDisplayName: 'Виды номенклатуры'
})
export class OrmProductKind extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: true,
        standart: false
    })
    private _unit: OrmProductUnit;
    
    @NatProperty({
        displayName: 'Вариант учета',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'product_accounting_options',
        required: false,
        standart: false
    })
    private _accountingOption: OrmEnumerationItem;

    constructor() {
        super();
    }
    
    @Expose()
    @NatRelation(OrmProductUnit)
    public get unit(): OrmProductUnit {
        // if (!this._unit) {
        //     this._unit = new OrmProductUnit();
        // }
        return this._unit;
    }

    public set unit(v: OrmProductUnit) {
        this._unit = v;
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get accountingOption(): OrmEnumerationItem {
        return this._accountingOption;
    }

    public set accountingOption(v: OrmEnumerationItem) {
        let oldValue = this._accountingOption;
        this._accountingOption = v;
        super.notifyFieldChange('accountingOption', oldValue, v);
    }
}