import { NgModule, Injector } from '@angular/core';
import { NatAppService } from '@natiwi/core';

import { NatProductController } from '@natiwi/shared/controllers/product.controller';
import { NatCustomerOrderController } from '@natiwi/shared/controllers/customer-orders.controller';
import { NatUserController } from '@natiwi/shared/controllers/user.controller';
import { NatProductRepository } from '@natiwi/shared/repositories/product.repository';
import { NatUserRepository } from '@natiwi/shared/repositories/user.repository';
import { NatCustomerOrdersRepository } from '@natiwi/shared/repositories/customer-orders.repository';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: []
})
export class NatControllerModule {}
