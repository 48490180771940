import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
export class NatModelMetadata {
    constructor(data) {
        this._modelName = data.modelName;
        this._plural = data.plural;
        this._displayName = data.displayName;
        this._pluralDisplayName = data.pluralDisplayName;
        this._propertyList = new Array();
        if (data && data.propertyList && data.propertyList instanceof Array) {
            data.propertyList.forEach(element => {
                if (element && element.propertyName) {
                    let property = new NatModelPropertyMetadata(element);
                    this._propertyList.push(property);
                }
            });
        }
        if (!this._properties) {
            this._properties = new Map();
            for (let prop of this._propertyList) {
                if (prop && prop.propertyName) {
                    this._properties.set(prop.propertyName, prop);
                }
            }
        }
    }
    get modelName() {
        return this._modelName;
    }
    get plural() {
        return this._plural;
    }
    get displayName() {
        return this._displayName;
    }
    get pluralDisplayName() {
        return this._pluralDisplayName;
    }
    /**
     * getProperties
     */
    getProperties() {
        return this._propertyList || null;
    }
    /**
     * getProperty
     */
    getProperty(propertyName) {
        return this._properties.has(propertyName) ? this._properties.get(propertyName) : null;
    }
}
tslib_1.__decorate([
    Expose()
], NatModelMetadata.prototype, "modelName", null);
tslib_1.__decorate([
    Expose()
], NatModelMetadata.prototype, "plural", null);
tslib_1.__decorate([
    Expose()
], NatModelMetadata.prototype, "displayName", null);
tslib_1.__decorate([
    Expose()
], NatModelMetadata.prototype, "pluralDisplayName", null);
export class NatModelPropertyMetadata {
    constructor(data) {
        if (!data) {
            console.error('Нет данных для формирования метаданных');
            return;
        }
        this._propertyName = data.propertyName;
        this._displayName = data.displayName;
        this._type = data.type;
        this._relationType = data.relationType;
        this._relationModel = data.relationModel;
        this._enumerationType = data.enumerationType;
        this._enumerationId = data.enumerationId;
        this._required = data.required || false;
        this._standart = data.standart || false;
        if (!this._propertyName) {
            console.error('Метаданные свойства не корректны: имя свойства не указано');
        }
    }
    get propertyName() {
        return this._propertyName;
    }
    get displayName() {
        return this._displayName;
    }
    get type() {
        return this._type;
    }
    get relationType() {
        return this._relationType;
    }
    get relationModel() {
        return this._relationModel;
    }
    get enumerationType() {
        return this._enumerationType;
    }
    get enumerationId() {
        return this._enumerationId;
    }
    get required() {
        return this._required;
    }
    get standart() {
        return this._standart;
    }
}
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "propertyName", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "displayName", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "type", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "relationType", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "relationModel", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "enumerationType", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "enumerationId", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "required", null);
tslib_1.__decorate([
    Expose()
], NatModelPropertyMetadata.prototype, "standart", null);
