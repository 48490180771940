import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPropertyMetadataItem } from "@natiwi/core/models/property-metadata-item.model";

@NatModel({
    displayName: 'Поле',
    pluralDisplayName: 'Поля'
})
@NatHierarchical(false)
export class OrmSamplingSchemeFieldItem extends OrmPropertyMetadataItem {

    @NatProperty({
        displayName: 'Тип формы',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'field_types',
        required: false,
        standart: false
    })
    private _fieldType: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Тип формы',
        type: 'string',
        required: false,
        standart: false
    })
    private _fieldTypeId: string;
    
    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get fieldType(): OrmEnumerationItem {
        return this._fieldType;
    }

    public set fieldType(v: OrmEnumerationItem) {
        let oldValue = this._fieldType;
        this._fieldType = v;
        super.notifyFieldChange('fieldType', oldValue, v);
    }
    
    @Expose()
    public get fieldTypeId(): string {
        return this._fieldTypeId;
    }

    public set fieldTypeId(v: string) {
        let oldValue = this._fieldTypeId;
        this._fieldTypeId = v;
        super.notifyFieldChange('fieldTypeId', oldValue, v);
    }

}