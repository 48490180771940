import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmObject } from "@natiwi/core/models/object";
import { NatProperty, NatModel, NatPolymorphicRelation, NatPolymorphicProperty, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";
let OrmConstant = class OrmConstant extends OrmObject {
    constructor() {
        super();
    }
    get name() {
        if (!this._name) {
            return this._name = '';
        }
        return this._name;
    }
    set name(v) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    get priority() {
        return this._priority;
    }
    set priority(v) {
        let oldValue = this._priority;
        this._priority = v;
        super.notifyFieldChange('priority', oldValue, v);
    }
    get description() {
        return this._description;
    }
    set description(v) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    get value() {
        return this._value;
    }
    set value(v) {
        this._value = v;
    }
    get valueId() {
        return this._valueId;
    }
    set valueId(v) {
        this._valueId = v;
    }
    get valueType() {
        return this._valueType;
    }
    set valueType(v) {
        this._valueType = v;
    }
    get valueRelationType() {
        return this._valueRelationType;
    }
    set valueRelationType(v) {
        this._valueRelationType = v;
    }
    get valueRelationValue() {
        return this._valueRelationValue;
    }
    set valueRelationValue(v) {
        this._valueRelationValue = v;
    }
    get predefined() {
        return this._predefined;
    }
    set predefined(v) {
        this._predefined = v;
    }
    get predefinedName() {
        return this._predefinedName;
    }
    set predefinedName(v) {
        this._predefinedName = v;
    }
    /**
    * toString
    */
    toString() {
        return this._name;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
], OrmConstant.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Приоритет',
        type: 'number',
        required: false
    })
], OrmConstant.prototype, "_priority", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    }),
    Expose()
], OrmConstant.prototype, "_description", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Значение',
        type: 'polymorphicRelation',
        required: false,
        standart: false
    })
], OrmConstant.prototype, "_value", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
], OrmConstant.prototype, "_valueId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
], OrmConstant.prototype, "_valueType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Модель связи',
        type: 'string',
        required: false,
        standart: false
    })
], OrmConstant.prototype, "_valueRelationType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Значение связи',
        type: 'string',
        required: false,
        standart: false
    })
], OrmConstant.prototype, "_valueRelationValue", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Предопределенное значение',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmConstant.prototype, "_predefined", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя предопределенного значения',
        type: 'string',
        required: false,
        standart: false
    })
], OrmConstant.prototype, "_predefinedName", void 0);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "name", null);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "priority", null);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "description", null);
tslib_1.__decorate([
    Expose(),
    NatPolymorphicRelation('valueRelationModel')
], OrmConstant.prototype, "value", null);
tslib_1.__decorate([
    Expose(),
    NatPolymorphicProperty('valueType')
], OrmConstant.prototype, "valueId", null);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "valueType", null);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "valueRelationType", null);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "valueRelationValue", null);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "predefined", null);
tslib_1.__decorate([
    Expose()
], OrmConstant.prototype, "predefinedName", null);
OrmConstant = tslib_1.__decorate([
    NatModel({
        plural: 'constants',
        displayName: 'Константа',
        pluralDisplayName: 'Константы'
    }),
    NatHierarchical(false)
], OrmConstant);
export { OrmConstant };
