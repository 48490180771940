import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmProductKind } from "@natiwi/shared/models/product-kind.model";
import { OrmImageMetadata } from "@natiwi/shared/models/image-metadata";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmProduct = class OrmProduct extends OrmCatalog {
    constructor() {
        super();
    }
    get article() {
        return this._article;
    }
    set article(v) {
        let oldValue = this._article;
        this._article = v;
        super.notifyFieldChange('article', oldValue, v);
    }
    get description() {
        return this._description;
    }
    set description(v) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    get weightCanManuallySet() {
        return this._weightCanManuallySet;
    }
    set weightCanManuallySet(v) {
        let oldValue = this._weightCanManuallySet;
        this._weightCanManuallySet = v;
        super.notifyFieldChange('weightCanManuallySet', oldValue, v);
    }
    get kind() {
        return this._kind;
    }
    set kind(v) {
        let oldValue = this._kind;
        this._kind = v;
        super.notifyFieldChange('kind', oldValue, v);
    }
    get unit() {
        return this._unit;
    }
    set unit(v) {
        this._unit = v;
    }
    get primaryImage() {
        return this._primaryImage;
    }
    set primaryImage(v) {
        this._primaryImage = v;
    }
    get useFeatureOption() {
        return this._useFeatureOption;
    }
    set useFeatureOption(v) {
        let oldValue = this._useFeatureOption;
        this._useFeatureOption = v;
        super.notifyFieldChange('useFeatureOption', oldValue, v);
    }
    get accountingOption() {
        return this._accountingOption;
    }
    set accountingOption(v) {
        let oldValue = this._accountingOption;
        this._accountingOption = v;
        super.notifyFieldChange('accountingOption', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Артикул',
        type: 'string',
        required: false,
        standart: false
    })
], OrmProduct.prototype, "_article", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
], OrmProduct.prototype, "_description", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Установка веса вручную',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmProduct.prototype, "_weightCanManuallySet", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вид номенклатуры',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductKind',
        required: true,
        standart: false
    })
], OrmProduct.prototype, "_kind", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: true,
        standart: false
    })
], OrmProduct.prototype, "_unit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Основное изображение',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmImageMetadata',
        required: false,
        standart: false
    })
], OrmProduct.prototype, "_primaryImage", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вариант использования характеристик',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'use_feature_options',
        required: false,
        standart: false
    })
], OrmProduct.prototype, "_useFeatureOption", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вариант учета',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'product_accounting_options',
        required: false,
        standart: false
    })
], OrmProduct.prototype, "_accountingOption", void 0);
tslib_1.__decorate([
    Expose()
], OrmProduct.prototype, "article", null);
tslib_1.__decorate([
    Expose()
], OrmProduct.prototype, "description", null);
tslib_1.__decorate([
    Expose()
], OrmProduct.prototype, "weightCanManuallySet", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductKind)
], OrmProduct.prototype, "kind", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductUnit)
], OrmProduct.prototype, "unit", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmImageMetadata)
], OrmProduct.prototype, "primaryImage", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmProduct.prototype, "useFeatureOption", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmProduct.prototype, "accountingOption", null);
OrmProduct = tslib_1.__decorate([
    NatModel({
        plural: 'products',
        displayName: 'Товар',
        pluralDisplayName: 'Товары'
    })
], OrmProduct);
export { OrmProduct };
