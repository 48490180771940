<h1 mat-dialog-title>{{object.id ? 'Редактирование группы' : 'Создание группы'}}</h1>
<div mat-dialog-content>
	<form [formGroup]="formGroup">
		<mat-form-field class="nat-input-field" *ngIf="formGroup.controls['name']">
			<mat-label>Наименование</mat-label>
			<input matInput formControlName="name" cdkFocusInitial [errorStateMatcher]="matcher">
			<mat-hint>Наименование группы</mat-hint>
			<mat-error *ngIf="formGroup.controls['name'].hasError('required')">
				Наименование группы <strong>обязательно</strong>
			</mat-error>
		</mat-form-field>
		<br>
		<mat-form-field class="nat-input-field" *ngIf="formGroup.controls['priority']">
			<mat-label>Приоритет</mat-label>
			<input matInput formControlName="priority" type="number">
			<mat-hint>Приоритет</mat-hint>
		</mat-form-field>
	</form>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="cancel()">Отмена</button>
	<button mat-button (click)="bulkUpsert()" [disabled]=!formGroup.valid cdkFocusInitial>Сохранить</button>
	<button mat-button (click)="makeDelete()" [disabled]=!object?.id>{{object?.deleted ? 'Снять пометку на удаление' : 'Пометить на удаление'}}</button>
</div>
