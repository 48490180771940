import * as tslib_1 from "tslib";
import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmNaturalPerson } from "@natiwi/shared/models/natural-person.model";
const ɵ0 = () => Date, ɵ1 = () => Date;
let OrmResponsiblePerson = class OrmResponsiblePerson extends OrmCatalog {
    constructor() {
        super();
    }
    get naturalPerson() {
        return this._naturalPerson;
    }
    set naturalPerson(v) {
        let oldValue = this._naturalPerson;
        this._naturalPerson = v;
        super.notifyFieldChange('naturalPerson', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    get position() {
        return this._position;
    }
    set position(v) {
        let oldValue = this._position;
        this._position = v;
        super.notifyFieldChange('position', oldValue, v);
    }
    get startedAt() {
        return this._startedAt;
    }
    set startedAt(v) {
        let oldValue = this._startedAt;
        this._startedAt = v;
        super.notifyFieldChange('startedAt', oldValue, v);
    }
    get expiredAt() {
        return this._expiredAt;
    }
    set expiredAt(v) {
        let oldValue = this._expiredAt;
        this._expiredAt = v;
        super.notifyFieldChange('expiredAt', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Физическое лицо',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmNaturalPerson',
        required: false,
        standart: false
    })
], OrmResponsiblePerson.prototype, "_naturalPerson", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'responsible_person_types',
        required: false,
        standart: false
    })
], OrmResponsiblePerson.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Должность',
        type: 'string',
        required: false,
        standart: false
    })
], OrmResponsiblePerson.prototype, "_position", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Начало действия',
        type: 'date',
        required: true,
        standart: false
    })
], OrmResponsiblePerson.prototype, "_startedAt", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Окончание действия',
        type: 'date',
        required: false,
        standart: false
    })
], OrmResponsiblePerson.prototype, "_expiredAt", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmNaturalPerson)
], OrmResponsiblePerson.prototype, "naturalPerson", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmResponsiblePerson.prototype, "type", null);
tslib_1.__decorate([
    Expose()
], OrmResponsiblePerson.prototype, "position", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmResponsiblePerson.prototype, "startedAt", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ1)
], OrmResponsiblePerson.prototype, "expiredAt", null);
OrmResponsiblePerson = tslib_1.__decorate([
    NatModel({
        plural: 'responsiblePersons',
        displayName: 'Ответственное лицо',
        pluralDisplayName: 'Ответственные лица'
    }),
    NatHierarchical(false)
], OrmResponsiblePerson);
export { OrmResponsiblePerson };
export { ɵ0, ɵ1 };
