import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmProduct } from "@natiwi/shared/models/product.model";

export class NatProductRepository extends NatCatalogRepository<OrmProduct> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/products';
        let repoModel: Type<OrmProduct> = OrmProduct;
        this.init(repoUrl, repoModel);
    }
}