import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";
import { OrmValueMetadataItem } from "@natiwi/core/models/value-metadata-item.model";
let OrmPropertyMetadataItem = class OrmPropertyMetadataItem extends OrmValueMetadataItem {
    constructor() {
        super();
    }
    get propertyName() {
        return this._propertyName;
    }
    set propertyName(v) {
        let oldValue = this._propertyName;
        this._propertyName = v;
        super.notifyFieldChange('propertyName', oldValue, v);
    }
    get polymorphicRelationTypePropertyName() {
        return this._polymorphicRelationTypePropertyName;
    }
    set polymorphicRelationTypePropertyName(v) {
        let oldValue = this._polymorphicRelationTypePropertyName;
        this._polymorphicRelationTypePropertyName = v;
        super.notifyFieldChange('polymorphicRelationTypePropertyName', oldValue, v);
    }
    get relationPropertyName() {
        return this._relationPropertyName;
    }
    set relationPropertyName(v) {
        let oldValue = this._relationPropertyName;
        this._relationPropertyName = v;
        super.notifyFieldChange('relationPropertyName', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя свойства',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPropertyMetadataItem.prototype, "_propertyName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя свойства типа полиморфной cвязи',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPropertyMetadataItem.prototype, "_polymorphicRelationTypePropertyName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя свойства cвязи',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPropertyMetadataItem.prototype, "_relationPropertyName", void 0);
tslib_1.__decorate([
    Expose()
], OrmPropertyMetadataItem.prototype, "propertyName", null);
tslib_1.__decorate([
    Expose()
], OrmPropertyMetadataItem.prototype, "polymorphicRelationTypePropertyName", null);
tslib_1.__decorate([
    Expose()
], OrmPropertyMetadataItem.prototype, "relationPropertyName", null);
OrmPropertyMetadataItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Метаданные свойства',
        pluralDisplayName: 'Метаданные свойств'
    }),
    NatHierarchical(false)
], OrmPropertyMetadataItem);
export { OrmPropertyMetadataItem };
