import { Component, OnInit } from '@angular/core';
import { NatNavigationService } from '@natiwi/core';
import { NatNavigationItem } from '@natiwi/core/services/navigation/navigation-item';

@Component({
  selector: 'nat-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class NatRootComponent implements OnInit {

  constructor(private navigationService: NatNavigationService) {
    let url = '/'
    let navigationItemParams = {
      title: 'Главная',
      isClosable: false
    }
    let navigationItem = NatNavigationItem.fromUrl(url, null, navigationItemParams);
    this.navigationService.addNavigateItem(navigationItem);
  }

  ngOnInit() {

  }

  onActivate(event, outlet) {
    let navigationItems = this.navigationService.getNavigationItems();
    let newNavigationItem = NatNavigationItem.fromActivatedRoute(outlet.activatedRoute, event);
    let navigationItem = navigationItems.find(tab => {
      return tab.routeItem.url === newNavigationItem.routeItem.url;
    });
    if (!!navigationItem) {
      navigationItem.bindComponent(event);
    } else {
      this.navigationService.addNavigateItem(newNavigationItem);
    }
  }
}
