import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";

@NatModel({
    displayName: 'Параметр',
    pluralDisplayName: 'Параметры'
})
@NatHierarchical(false)
export class OrmSamplingSchemeParameterFieldItem extends OrmObjectItem {

    @NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
    private _name: string;

    @NatProperty({
        displayName: 'Назначение',
        type: 'string',
        required: true,
        standart: false
    })
    private _designation: string;

    @NatProperty({
        displayName: 'Обязательный',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _required: boolean;

    @NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _enabled: boolean;

    @NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
    private _field: OrmSamplingSchemeFieldItem;

    constructor() {
        super();
    }

    @Expose()
    public get name(): string {
        return this._name;
    }

    public set name(v: string) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }

    @Expose()
    public get designation(): string {
        return this._designation;
    }

    public set designation(v: string) {
        let oldValue = this._designation;
        this._designation = v;
        super.notifyFieldChange('designation', oldValue, v);
    }

    @Expose()
    public get required(): boolean {
        return this._required;
    }

    public set required(v: boolean) {
        let oldValue = this._required;
        this._required = v;
        super.notifyFieldChange('required', oldValue, v);
    }

    @Expose()
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(v: boolean) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get field(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set field(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get fieldValue(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set fieldValue(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }

    /**
   * toString
   */
    public toString(): string {
        return this._name || '';
    }
}