import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { NatRegisterController } from "@natiwi/core/controllers/register.controller";
import { NatFilter } from "@natiwi/core/network/shared/filter";
import { NatInformationRegisterRepository } from "@natiwi/core/network/repositories/information-register.repository";
import { Observable } from "rxjs";

export abstract class NatInformationRegisterController<Model extends OrmInformationRegister> extends NatRegisterController<Model>{

    constructor(_repository: NatInformationRegisterRepository<Model>) {
        super(_repository);
    }

    /**
     * sliceLast
     */
    public sliceLast(period: Date, filter?: NatFilter): Observable<Array<Model>> {
        let params: Map<string, any>;
        params = new Map<string, any>()
        if (filter) {
            params.set('filter', filter);
        }
        let repo: NatInformationRegisterRepository<Model> = this.getRepository();
        return repo.sliceLast(period, params);
    }
}