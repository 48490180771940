import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeParameterFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-parameter-field-item.model";
import { OrmSamplingSchemeDataSetItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-data-set-item.model";
import { OrmSamplingSchemeAggregateFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-aggregate-field-item.model";
let OrmSamplingSchemeItem = class OrmSamplingSchemeItem extends OrmObjectItem {
    constructor() {
        super();
    }
    //
    get dataSetItems() {
        return this._dataSetItems;
    }
    set dataSetItems(v) {
        let oldValue = this._dataSetItems;
        this._dataSetItems = v;
        super.notifyFieldChange('dataSetItems', oldValue, v);
    }
    get dataSetItemList() {
        return this._dataSetItems;
    }
    set dataSetItemList(v) {
        let oldValue = this._dataSetItems;
        this._dataSetItems = v;
        super.notifyFieldChange('dataSetItems', oldValue, v);
    }
    //
    get aggregateFieldItems() {
        return this._aggregateFieldItems;
    }
    set aggregateFieldItems(v) {
        let oldValue = this._aggregateFieldItems;
        this._aggregateFieldItems = v;
        super.notifyFieldChange('aggregateFieldItems', oldValue, v);
    }
    get aggregateFieldItemList() {
        return this._aggregateFieldItems;
    }
    set aggregateFieldItemList(v) {
        let oldValue = this._aggregateFieldItems;
        this._aggregateFieldItems = v;
        super.notifyFieldChange('aggregateFieldItems', oldValue, v);
    }
    //
    get parameterFieldItems() {
        return this._parameterFieldItems;
    }
    set parameterFieldItems(v) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
    get parameterFieldItemList() {
        return this._parameterFieldItems;
    }
    set parameterFieldItemList(v) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Наборы данных',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeDataSetItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemeItem.prototype, "_dataSetItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Агрегирования',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeAggregateFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemeItem.prototype, "_aggregateFieldItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Параметры',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeParameterFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemeItem.prototype, "_parameterFieldItems", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeDataSetItem)
], OrmSamplingSchemeItem.prototype, "dataSetItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeDataSetItem)
], OrmSamplingSchemeItem.prototype, "dataSetItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeAggregateFieldItem)
], OrmSamplingSchemeItem.prototype, "aggregateFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeAggregateFieldItem)
], OrmSamplingSchemeItem.prototype, "aggregateFieldItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeParameterFieldItem)
], OrmSamplingSchemeItem.prototype, "parameterFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeParameterFieldItem)
], OrmSamplingSchemeItem.prototype, "parameterFieldItemList", null);
OrmSamplingSchemeItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Схема выборки данных',
        pluralDisplayName: 'Схемы выборки данных'
    }),
    NatHierarchical(false)
], OrmSamplingSchemeItem);
export { OrmSamplingSchemeItem };
