import { Expose, Type } from "class-transformer";
import { OrmObject } from "@natiwi/core/models/object";
import { NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";

export abstract class OrmCatalog extends OrmObject {

    @NatProperty({
        displayName: 'Наименование',
        type: 'string',
        required: true,
        standart: false
    })
    private _name: string;

    @NatProperty({
        displayName: 'Отображаемое наименование',
        type: 'string',
        required: false,
        standart: true
    })
    private _displayName: string;

    @NatProperty({
        displayName: 'Тип элемента',
        type: 'number',
        required: false
    })
    private _elementType: number;

    @NatProperty({
        displayName: 'Приоритет',
        type: 'number',
        required: false
    })
    private _priority: number;

    @NatProperty({
        displayName: 'Родитель',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmCatalog',
        required: false,
        standart: false
    })
    private _parent: any;

    @NatProperty({
        displayName: 'Номер',
        type: 'string',
        required: false,
        standart: true
    })
    private _sequenceNumber: string;

    constructor() {
        super();
    }

    @Expose()
    public get name(): string {
        return this._name;
    }

    public set name(v: string) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }

    @Expose()
    public get displayName(): string {
        return this._displayName;
    }

    public set displayName(v: string) {
        let oldValue = this._displayName;
        this._displayName = v;
        super.notifyFieldChange('displayName', oldValue, v);
    }

    @Expose()
    public get elementType(): number {
        return this._elementType;
    }

    public set elementType(v: number) {
        let oldValue = this._elementType;
        this._elementType = v;
        super.notifyFieldChange('elementType', oldValue, v);
    }

    @Expose()
    public get priority(): number {
        return this._priority;
    }

    public set priority(v: number) {
        let oldValue = this._priority;
        this._priority = v;
        super.notifyFieldChange('priority', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmCatalog)
    public get parent(): any {
        return this._parent;
    }

    public set parent(v: any) {
        let oldValue = this._parent;
        this._parent = v;
        super.notifyFieldChange('parent', oldValue, v);
    }

    @Expose()
    public get sequenceNumber(): string {
        //remove lead nulls
        // let nullMatch = this._sequenceNumber && this._sequenceNumber.match(/^0*/);
        // if (nullMatch) {
        //     let formatedNumber: string = this._sequenceNumber.slice();
        //     formatedNumber = formatedNumber.replace(/^0*/, '');
        //     return formatedNumber;
        // }
        return this._sequenceNumber;
    }

    public set sequenceNumber(v: string) {
        let oldValue = this._sequenceNumber;
        this._sequenceNumber = v;
        super.notifyFieldChange('sequenceNumber', oldValue, v);
    }

    /**
    * toString
    */
    public toString(): string {
        return this._displayName || this._name || '';
    }

}