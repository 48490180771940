var OrmPriceType_1;
import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmPriceType = OrmPriceType_1 = class OrmPriceType extends OrmCatalog {
    constructor() {
        super();
    }
    get pricingOption() {
        return this._pricingOption;
    }
    set pricingOption(v) {
        let oldValue = this._pricingOption;
        this._pricingOption = v;
        super.notifyFieldChange('pricingOption', oldValue, v);
    }
    get basePriceType() {
        return this._basePriceType;
    }
    set basePriceType(v) {
        let oldValue = this._basePriceType;
        this._basePriceType = v;
        super.notifyFieldChange('basePriceType', oldValue, v);
    }
    get discountMarkupPercent() {
        return this._discountMarkupPercent;
    }
    set discountMarkupPercent(v) {
        let oldValue = this._discountMarkupPercent;
        this._discountMarkupPercent = v;
        super.notifyFieldChange('discountMarkupPercent', oldValue, v);
    }
    get roundOff() {
        return this._roundOff;
    }
    set roundOff(v) {
        let oldValue = this._roundOff;
        this._roundOff = v;
        super.notifyFieldChange('roundOff', oldValue, v);
    }
    get roundingAccuracy() {
        return this._roundingAccuracy;
    }
    set roundingAccuracy(v) {
        let oldValue = this._roundingAccuracy;
        this._roundingAccuracy = v;
        super.notifyFieldChange('roundingAccuracy', oldValue, v);
    }
    get roundingPsychological() {
        return this._roundingPsychological;
    }
    set roundingPsychological(v) {
        let oldValue = this._roundingPsychological;
        this._roundingPsychological = v;
        super.notifyFieldChange('roundingPsychological', oldValue, v);
    }
    get roundingOption() {
        return this._roundingOption;
    }
    set roundingOption(v) {
        let oldValue = this._roundingOption;
        this._roundingOption = v;
        super.notifyFieldChange('roundingOption', oldValue, v);
    }
    get useWhenSellingToCustomers() {
        return this._useWhenSellingToCustomers;
    }
    set useWhenSellingToCustomers(v) {
        let oldValue = this._useWhenSellingToCustomers;
        this._useWhenSellingToCustomers = v;
        super.notifyFieldChange('useWhenSellingToCustomers', oldValue, v);
    }
    get useWhenMakeBasedOn() {
        return this._useWhenMakeBasedOn;
    }
    set useWhenMakeBasedOn(v) {
        let oldValue = this._useWhenMakeBasedOn;
        this._useWhenMakeBasedOn = v;
        super.notifyFieldChange('useWhenMakeBasedOn', oldValue, v);
    }
    get status() {
        return this._status;
    }
    set status(v) {
        let oldValue = this._status;
        this._status = v;
        super.notifyFieldChange('status', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Способ задания цены',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'pricing_options',
        required: true,
        standart: false
    })
], OrmPriceType.prototype, "_pricingOption", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Базовый тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_basePriceType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Процент скидки/наценки',
        type: 'number',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_discountMarkupPercent", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Округлять',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_roundOff", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Точность округления',
        type: 'number',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_roundingAccuracy", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Психологическое округление',
        type: 'number',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_roundingPsychological", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вариант округления',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'rounding_options',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_roundingOption", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Использовать цену при продаже клиентам',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_useWhenSellingToCustomers", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Использовать цену при вводе на основании',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_useWhenMakeBasedOn", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Статус',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'price_type_statuses',
        required: false,
        standart: false
    })
], OrmPriceType.prototype, "_status", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPriceType.prototype, "pricingOption", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType_1)
], OrmPriceType.prototype, "basePriceType", null);
tslib_1.__decorate([
    Expose()
], OrmPriceType.prototype, "discountMarkupPercent", null);
tslib_1.__decorate([
    Expose()
], OrmPriceType.prototype, "roundOff", null);
tslib_1.__decorate([
    Expose()
], OrmPriceType.prototype, "roundingAccuracy", null);
tslib_1.__decorate([
    Expose()
], OrmPriceType.prototype, "roundingPsychological", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPriceType.prototype, "roundingOption", null);
tslib_1.__decorate([
    Expose()
], OrmPriceType.prototype, "useWhenSellingToCustomers", null);
tslib_1.__decorate([
    Expose()
], OrmPriceType.prototype, "useWhenMakeBasedOn", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPriceType.prototype, "status", null);
OrmPriceType = OrmPriceType_1 = tslib_1.__decorate([
    NatModel({
        plural: 'priceTypes',
        displayName: 'Тип цены',
        pluralDisplayName: 'Типы цен'
    }),
    NatHierarchical(false)
], OrmPriceType);
export { OrmPriceType };
