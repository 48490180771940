import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmWarehouse } from "@natiwi/shared/models/warehouse.model";

export class NatWarehouseRepository extends NatCatalogRepository<OrmWarehouse> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/warehouses';
        let repoModel: Type<OrmWarehouse> = OrmWarehouse;
        this.init(repoUrl, repoModel);
    }
}