import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmProductSegment } from "@natiwi/shared/models/product-segment.model";
import { NatProductSegmentRepository } from "@natiwi/shared/repositories/product-segment.repository";

export class NatProductSegmentController extends NatCatalogController<OrmProductSegment>{

    constructor(_repository?: NatProductSegmentRepository) {
        super(_repository);
        super.registerDataClass(OrmProductSegment);
    }
}