import { NatFilter } from "@natiwi/core/network/shared/filter";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NatModelMetadata } from "@natiwi/core/models/model-metadata";
import { Type } from "@angular/core";
import { OrmModel } from "@natiwi/core/models/model";
import { NatRestRepository } from "@natiwi/core/network/repositories/rest.repository";

export abstract class NatMainController<Model extends OrmModel> {

    private _modelMetadata: NatModelMetadata;
    private _dataClass: Type<Model>;

    constructor() { }

    public get dataClass(): Type<Model> {
        return this._dataClass;
    }

    public get modelMetadata(): NatModelMetadata {
        return this._modelMetadata;
    }

    protected registerDataClass(dataClass: Type<Model>): void {
        if (!dataClass) {
            throw 'Класс модели данных является обязательным параметром';
        }
        this._dataClass = dataClass;
        this.initMetadata();
    }

    private initMetadata(): void {
        const model = this._dataClass;
        if (!model) {
            console.error('Модель обязательна');
            return;
        }
        const modelMetadata = Reflect.getMetadata('natiwi:metadata:model', model);
        if (!modelMetadata) {
            console.error(`Метаданные для модели "${model.prototype.constructor.name}" не найдены`);
            return;
        }
        modelMetadata['propertyList'] = [];
        const keys: any[] = Reflect.getMetadataKeys(model.prototype);
        const propertiesMetadata = keys
            .filter(key => key.toString().startsWith(`natiwi:metadata:property:`))
            .forEach(key => {
                const metadata = Reflect.getMetadata(key, model.prototype);
                modelMetadata['propertyList'].push(metadata)
            }, []);
        this._modelMetadata = new NatModelMetadata(modelMetadata);
    }
}