import * as tslib_1 from "tslib";
import { Expose, Type } from "class-transformer";
import { OrmObject } from "@natiwi/core/models/object";
import { NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmUser } from "@natiwi/core/models/user";
const ɵ0 = () => Date, ɵ1 = () => Date;
export class OrmDocument extends OrmObject {
    constructor() {
        super();
    }
    get sequenceNumber() {
        //remove lead nulls
        // let nullMatch = this._sequenceNumber && this._sequenceNumber.match(/^0*/);
        // if (nullMatch) {
        //     let formatedNumber: string = '';
        //     formatedNumber = this._sequenceNumber.replace(/^0*/, '');
        //     return formatedNumber;
        // }
        return this._sequenceNumber;
    }
    set sequenceNumber(v) {
        let oldValue = this._sequenceNumber;
        this._sequenceNumber = v;
        super.notifyFieldChange('sequenceNumber', oldValue, v);
    }
    get periodAt() {
        return this._periodAt;
    }
    set periodAt(v) {
        let oldValue = this._periodAt;
        this._periodAt = v;
        super.notifyFieldChange('periodAt', oldValue, v);
    }
    get postedAt() {
        return this._postedAt;
    }
    set postedAt(v) {
        let oldValue = this._postedAt;
        this._postedAt = v;
        super.notifyFieldChange('postedAt', oldValue, v);
    }
    get posted() {
        return this._posted;
    }
    set posted(v) {
        let oldValue = this._posted;
        this._posted = v;
        super.notifyFieldChange('posted', oldValue, v);
    }
    get responsible() {
        return this._responsible;
    }
    set responsible(v) {
        let oldValue = this._responsible;
        this._responsible = v;
        super.notifyFieldChange('responsible', oldValue, v);
    }
    /**
     * toString
     */
    toString() {
        if (this._sequenceNumber) {
            return `№${this.sequenceNumber}`;
        }
        return '';
    }
}
tslib_1.__decorate([
    NatProperty({
        displayName: 'Номер',
        type: 'string',
        required: false,
        standart: false
    })
], OrmDocument.prototype, "_sequenceNumber", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата документа',
        type: 'date',
        required: false,
        standart: false
    })
], OrmDocument.prototype, "_periodAt", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата проведения',
        type: 'date',
        required: false,
        standart: false
    })
], OrmDocument.prototype, "_postedAt", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Проведен',
        type: 'boolean',
        required: false,
        standart: true
    })
], OrmDocument.prototype, "_posted", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Ответственный',
        type: 'relation',
        relationModel: 'OrmUser',
        required: false,
        standart: false
    })
], OrmDocument.prototype, "_responsible", void 0);
tslib_1.__decorate([
    Expose()
], OrmDocument.prototype, "sequenceNumber", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmDocument.prototype, "periodAt", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ1)
], OrmDocument.prototype, "postedAt", null);
tslib_1.__decorate([
    Expose()
], OrmDocument.prototype, "posted", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmUser)
], OrmDocument.prototype, "responsible", null);
export { ɵ0, ɵ1 };
