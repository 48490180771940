import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";

@NatModel({
    plural: 'partnerContracts',
    displayName: 'Договор партнера',
    pluralDisplayName: 'Договоры партнеров'
})
export class OrmPartnerContract extends OrmCatalog {

    constructor() {
        super();
    }
}