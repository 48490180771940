import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmReport } from "@natiwi/shared/models/report.model";
import { Observable } from "rxjs";
import { natClassToPlain, natPlainToClass } from "@natiwi/core/network/repositories/rest.repository";
import { map } from "rxjs/internal/operators/map";
import { OrmReportPreset } from "@natiwi/shared/models/report-preset.model";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
import { Type as CTType, Expose, Transform } from "class-transformer";

export class NatReportRepository extends NatCatalogRepository<OrmReport> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/reports';
        let repoModel: Type<OrmReport> = OrmReport;
        super.init(repoUrl, repoModel);
        super.createСontractItem('execute', '/:id/execute', NatVerbType.POST);
    }

    public execute(id: string, preset: OrmReportPreset): Observable<NatReportExecuteResponse> {
        let stringlifyData: string = natClassToPlain(OrmReportPreset, preset);
        // let stringlifyBodyData: string = JSON.stringify(classToPlain(object, { excludePrefixes: ["_"] }));
        let stringifyParams: Map<string, string> = new Map<string, string>();
        stringifyParams.set('id', id);

        let request: Observable<NatReportExecuteResponse> = this.invokeStaticMethod<NatReportExecuteResponse>('execute', stringifyParams, stringlifyData);
        return request.pipe(map(response => {
            return natPlainToClass(NatReportExecuteResponse, response) as NatReportExecuteResponse;
        }));
    }
}

export class NatReportExecuteResponse {

    private _columns: Array<NatReportExecuteResponseColumns>;
    private _rows: Array<NatReportExecuteResponseRow>;

    constructor() { }

    @Expose()
    @CTType(() => NatReportExecuteResponseColumns)
    public get columns(): Array<NatReportExecuteResponseColumns> {
        return this._columns;
    }

    public set columns(value: Array<NatReportExecuteResponseColumns>) {
        this._columns = value;
    }

    @Expose()
    @Transform((value:Array<NatReportExecuteResponseRow>, obj, type) => {
        let reponseRows = obj && obj['rows'] || [];
        reponseRows.forEach((element, index) => {
            for (let key in element) {
               value[index].set(key, element[key]);
            }
        });
        return value;
    })
    @CTType(() => NatReportExecuteResponseRow)
    public get rows(): Array<NatReportExecuteResponseRow> {
        return this._rows;
    }

    public set rows(value: Array<NatReportExecuteResponseRow>) {
        this._rows = value;
    }
}

export class NatReportExecuteResponseColumns extends OrmSamplingSchemeFieldItem {

    private _metadataItemList: Array<any>;
    private _propertyPathFieldItemList: Array<any>;

    constructor() {
        super();
    }

    @Expose()
    public get metadataItemList(): Array<any> {
        return this._metadataItemList;
    }

    public set metadataItemList(value: Array<any>) {
        this._metadataItemList = value;
    }
    
    @Expose()
    public get propertyPathFieldItemList(): Array<any> {
        return this._propertyPathFieldItemList;
    }

    public set propertyPathFieldItemList(value: Array<any>) {
        this._propertyPathFieldItemList = value;
    }

}

export class NatReportExecuteResponseRow extends Map {

}

