import { NatFilter } from "@natiwi/core/network/shared/filter";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NatModelMetadata } from "@natiwi/core/models/model-metadata";
import { Type } from "@angular/core";
import { OrmModel } from "@natiwi/core/models/model";
import { NatRestRepository } from "@natiwi/core/network/repositories/rest.repository";
import { NatMainController } from "@natiwi/core/controllers/main.controller";

export abstract class NatRestController<Model extends OrmModel> extends NatMainController<Model>{
    
    constructor(private _repository?: NatRestRepository<Model>) {
        super();
    }

    public getRepository<T extends NatRestRepository<Model>>(): T {
        return this._repository as T;
    }

}