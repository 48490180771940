import { NatMetadataController } from "@natiwi/core/controllers/metadata.controller";
import { NatFileMetadataRepository } from "@natiwi/shared/repositories/file-metadata.repository";
import { OrmImageMetadata } from "@natiwi/shared/models/image-metadata";
import { NatImageMetadataRepository } from "@natiwi/shared/repositories/image-metadata.repository";
import { Observable, throwError } from "rxjs";
import { NatUploadFileMetadata } from "@natiwi/shared/repositories/container.repository";

export class NatImageMetadataController extends NatMetadataController<OrmImageMetadata>{

    constructor(_repository: NatImageMetadataRepository) {
        super(_repository);
        super.registerDataClass(OrmImageMetadata);
    }
    
    /**
     * upload
     */
    public upload(metadata: NatUploadFileMetadata): Observable<OrmImageMetadata> {
        let repo: NatImageMetadataRepository = this.getRepository();
        return repo.upload(metadata);
    }
    
    /**
     * download
     */
    public download(imageId: string, sizeId: string): Observable<OrmImageMetadata> {
        if (!imageId || !sizeId) {
            return throwError( 'Идентификаторы изорбражения или размера не заданы');
        }
        let repo: NatImageMetadataRepository = this.getRepository();
        return repo.download(imageId, sizeId);
    }
}