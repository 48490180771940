import { Type } from "@angular/core";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatInformationRegisterRepository } from "@natiwi/core/network/repositories/information-register.repository";
import { OrmPartnerContact } from "@natiwi/shared/models/partner-contact.model";

export class NatPartnerContactRepository extends NatInformationRegisterRepository<OrmPartnerContact> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/partnerContacts';
        let repoModel: Type<OrmPartnerContact> = OrmPartnerContact;
        this.init(repoUrl, repoModel);
    }
}