import { Injectable, Type } from "@angular/core";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatModelRepository } from "@natiwi/core/network/repositories/model.repository";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

export class NatEnumerationItemRepository extends NatModelRepository<OrmEnumerationItem> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/enumerationItems';
        let repoModel: Type<OrmEnumerationItem> = OrmEnumerationItem;
        this.init(repoUrl, repoModel);
    }
}