import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmReportPreset } from "@natiwi/shared/models/report-preset.model";
import { OrmSamplingSchemeItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-item.model";

@NatModel({
    plural: 'reports',
    displayName: 'Отчет',
    pluralDisplayName: 'Отчеты'
})
@NatHierarchical(false)
export class OrmReport extends OrmCatalog {

    @NatProperty({
        displayName: 'Схема выборки данных',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeItem',
        required: false,
        standart: false
    })
    private _samplingScheme: OrmSamplingSchemeItem;

    // @NatProperty({
    //     displayName: 'Варианты схемы выборки данных',
    //     type: 'relation',
    //     relationType: 'hasMany',
    //     relationModel: 'OrmReportPreset',
    //     required: false,
    //     standart: false
    // })
    // private _presets: OrmReportPreset;

    constructor() {
        super();
    }

    @Expose()
    @NatRelation(OrmSamplingSchemeItem)
    public get samplingScheme(): OrmSamplingSchemeItem {
        return this._samplingScheme;
    }

    public set samplingScheme(v: OrmSamplingSchemeItem) {
        let oldValue = this._samplingScheme;
        this._samplingScheme = v;
        super.notifyFieldChange('samplingScheme', oldValue, v);
    }

    // @Expose()
    // @NatRelation(OrmReportPreset)
    // public get presets(): OrmReportPreset {
    //     return this._presets;
    // }

    // public set presets(v: OrmReportPreset) {
    //     let oldValue = this._presets;
    //     this._presets = v;
    //     super.notifyFieldChange('presets', oldValue, v);
    // }
}