<div class="nat-login-form">
	<div class="nat-logo">
		<img src="https://natiwi.ru/assets/images/logo.svg?v=1" alt="natiwi logo">
	</div>
	<div class="nat-form">
		<form [formGroup]="loginFormGroup">
			<mat-form-field class="nat-input-field">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" [errorStateMatcher]="matcher">
				<mat-hint>E-mail пользователя</mat-hint>
				<mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
					Пожалуйста, введите корректный E-mail
				</mat-error>
				<mat-error *ngIf="emailFormControl.hasError('required')">
					Email <strong>обязателен</strong>
				</mat-error>
			</mat-form-field>
			<br>
			<mat-form-field class="nat-input-field">
				<mat-label>Пароль</mat-label>
				<input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'">
				<button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Скрыть пароль'" [attr.aria-pressed]="hidePassword">
					<mat-icon class="icon">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
				<mat-hint>Пароль пользователя</mat-hint>
				<mat-error *ngIf="passwordFormControl.hasError('required')">
					Пароль <strong>обязателен</strong>
				</mat-error>
			</mat-form-field>
			<mat-checkbox color="accent" formControlName="rememberMe">Запомнить меня</mat-checkbox>
		</form>
		<br>
		<button mat-button (click)="login()">Войти</button>
	</div>
</div>
