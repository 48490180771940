import { Type, Expose, TransformClassToPlain, Transform } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmProductKind } from "@natiwi/shared/models/product-kind.model";
import { OrmImageMetadata } from "@natiwi/shared/models/image-metadata";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    plural: 'products',
    displayName: 'Товар',
    pluralDisplayName: 'Товары'
})
export class OrmProduct extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Артикул',
        type: 'string',
        required: false,
        standart: false
    })
    private _article: string;
    
    @NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
    private _description: string;
    
    @NatProperty({
        displayName: 'Установка веса вручную',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _weightCanManuallySet: boolean;
    
    @NatProperty({
        displayName: 'Вид номенклатуры',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductKind',
        required: true,
        standart: false
    })
    private _kind: OrmProductKind;
    
    @NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: true,
        standart: false
    })
    private _unit: OrmProductUnit;
    
    @NatProperty({
        displayName: 'Основное изображение',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmImageMetadata',
        required: false,
        standart: false
    })
    private _primaryImage: OrmImageMetadata;
    
    @NatProperty({
        displayName: 'Вариант использования характеристик',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'use_feature_options',
        required: false,
        standart: false
    })
    private _useFeatureOption: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Вариант учета',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'product_accounting_options',
        required: false,
        standart: false
    })
    private _accountingOption: OrmEnumerationItem;
    
    constructor() {
        super();
    }

    @Expose()
    public get article(): string {
        return this._article;
    }

    public set article(v: string) {
        let oldValue = this._article;
        this._article = v;
        super.notifyFieldChange('article', oldValue, v);
    }
    
    @Expose()
    public get description(): string {
        return this._description;
    }

    public set description(v: string) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    
    @Expose()
    public get weightCanManuallySet(): boolean {
        return this._weightCanManuallySet;
    }

    public set weightCanManuallySet(v: boolean) {
        let oldValue = this._weightCanManuallySet;
        this._weightCanManuallySet = v;
        super.notifyFieldChange('weightCanManuallySet', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmProductKind)
    public get kind(): OrmProductKind {
        return this._kind;
    }

    public set kind(v: OrmProductKind) {
        let oldValue = this._kind;
        this._kind = v;
        super.notifyFieldChange('kind', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmProductUnit)
    public get unit(): OrmProductUnit {
        return this._unit;
    }

    public set unit(v: OrmProductUnit) {
        this._unit = v;
    }
    
    @Expose()
    @NatRelation(OrmImageMetadata)
    public get primaryImage(): OrmImageMetadata {
        return this._primaryImage;
    }

    public set primaryImage(v: OrmImageMetadata) {
        this._primaryImage = v;
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get useFeatureOption(): OrmEnumerationItem {
        return this._useFeatureOption;
    }

    public set useFeatureOption(v: OrmEnumerationItem) {
        let oldValue = this._useFeatureOption;
        this._useFeatureOption = v;
        super.notifyFieldChange('useFeatureOption', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get accountingOption(): OrmEnumerationItem {
        return this._accountingOption;
    }

    public set accountingOption(v: OrmEnumerationItem) {
        let oldValue = this._accountingOption;
        this._accountingOption = v;
        super.notifyFieldChange('accountingOption', oldValue, v);
    }
}