import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmResponsiblePerson } from "@natiwi/shared/models/responsible-person.model";

export class NatResponsiblePersonRepository extends NatCatalogRepository<OrmResponsiblePerson> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/responsiblePersons';
        let repoModel: Type<OrmResponsiblePerson> = OrmResponsiblePerson;
        this.init(repoUrl, repoModel);
    }
}