import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    plural: 'productUnits',
    displayName: 'Единица измерения',
    pluralDisplayName: 'Единицы измерения'
})
export class OrmProductUnit extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Базовая единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: false,
        standart: false
    })
    private _unit: OrmProductUnit;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'unit_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Тип упаковки',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'unit_package_types',
        required: false,
        standart: false
    })
    private _packageType: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Количество базовых единиц измерения в упаковке',
        type: 'number',
        required: false,
        standart: false
    })
    private _quantityOfOwnerUnitIncludedInPackage: number;
    
    @NatProperty({
        displayName: 'Количество родительских упаковок в упаковке',
        type: 'number',
        required: false,
        standart: false
    })
    private _quantityOfParentPackageIncludedInPackage: number;
    
    @NatProperty({
        displayName: 'Количество упаковок в базовой единице измерения',
        type: 'number',
        required: false,
        standart: false
    })
    private _quantityOfPackageIncludedInOwnerUnit: number;
    
    constructor() {
        super();
    }
    
    @Expose()
    @NatRelation(OrmProductUnit)
    public get unit(): OrmProductUnit {
        return this._unit;
    }

    public set unit(v: OrmProductUnit) {
        let oldValue = this._unit;
        this._unit = v;
        super.notifyFieldChange('unit', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get packageType(): OrmEnumerationItem {
        return this._packageType;
    }

    public set packageType(v: OrmEnumerationItem) {
        let oldValue = this._packageType;
        this._packageType = v;
        super.notifyFieldChange('packageType', oldValue, v);
    }

    @Expose()
    public get quantityOfOwnerUnitIncludedInPackage(): number {
        return this._quantityOfOwnerUnitIncludedInPackage;
    }

    public set quantityOfOwnerUnitIncludedInPackage(v: number) {
        let oldValue = this._quantityOfOwnerUnitIncludedInPackage;
        this._quantityOfOwnerUnitIncludedInPackage = v;
        super.notifyFieldChange('quantityOfOwnerUnitIncludedInPackage', oldValue, v);
    }
    
    @Expose()
    public get quantityOfParentPackageIncludedInPackage(): number {
        return this._quantityOfParentPackageIncludedInPackage;
    }

    public set quantityOfParentPackageIncludedInPackage(v: number) {
        let oldValue = this._quantityOfParentPackageIncludedInPackage;
        this._quantityOfParentPackageIncludedInPackage = v;
        super.notifyFieldChange('quantityOfParentPackageIncludedInPackage', oldValue, v);
    }
    
    @Expose()
    public get quantityOfPackageIncludedInOwnerUnit(): number {
        return this._quantityOfPackageIncludedInOwnerUnit;
    }

    public set quantityOfPackageIncludedInOwnerUnit(v: number) {
        let oldValue = this._quantityOfPackageIncludedInOwnerUnit;
        this._quantityOfPackageIncludedInOwnerUnit = v;
        super.notifyFieldChange('quantityOfPackageIncludedInOwnerUnit', oldValue, v);
    }
}