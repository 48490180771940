import { OrmModel } from "@natiwi/core/models/model";
import { Expose } from "class-transformer";
import * as uuid from 'uuid';


export abstract class OrmRegister extends OrmModel {
    private _id: string;

    constructor() {
        super();
        this._id = uuid.v4();        
    }

    @Expose()
    public get id(): string {
        return this._id;
    }

    public set id(v: string) {
        this._id = v;
    }
}