import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { NatFormDetail } from "@natiwi/core/forms/form-detail";
import { NatObjectController } from "@natiwi/core/controllers/object.controller";
import { OrmObject } from "@natiwi/core/models/object";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators/map";

export abstract class NatFormDetailObject extends NatFormDetail {

  constructor(formBuilder: FormBuilder) {
    super(formBuilder);
  }

  protected makeDeleted<T extends OrmObject>(object: T, controller: NatObjectController<T>): Observable<T> {
    return controller.makeDeleted(object).pipe(map(result => {
      if (result) {
        return this.mergeObject(object, result);;
      }
      return null;
    }))
  }

  protected mergeObject<T>(targetObject: T, sourceObject: T): T {
    for (let key in sourceObject) {
      if (!(targetObject[key] instanceof Object)) {
        targetObject[key] = sourceObject[key];
      }
    }
    return targetObject;
  }
}