import { Component, OnInit } from '@angular/core';
import { NatForm } from '@natiwi/core/forms/form';

@Component({
  selector: 'nat-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class NatOverviewComponent extends NatForm implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    this.title = 'Главная';
  }

}
