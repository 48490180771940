/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tabs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "@angular/common";
import * as i9 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i10 from "@angular/material/tabs";
import * as i11 from "./tabs.component";
import * as i12 from "../../core/services/navigation/navigation.service";
var styles_NatTabsComponent = [i0.styles];
var RenderType_NatTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NatTabsComponent, data: {} });
export { RenderType_NatTabsComponent as RenderType_NatTabsComponent };
function View_NatTabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["aria-label", "\u0417\u0430\u043A\u0440\u044B\u0442\u044C \u0432\u043A\u043B\u0430\u0434\u043A\u0443"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeTab($event, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"]))], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).inline; var currVal_3 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_NatTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NatTabsComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.isClosable; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_NatTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "mat-tab", [], null, null, null, i9.View_MatTab_0, i9.RenderType_MatTab)), i1.ɵdid(1, 770048, [[2, 4]], 2, i10.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(603979776, 3, { templateLabel: 0 }), i1.ɵqud(335544320, 4, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NatTabsComponent_2)), i1.ɵdid(5, 16384, [[3, 4]], 0, i10.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_NatTabsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { tabGroup: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"], ["color", "accent"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i9.View_MatTabGroup_0, i9.RenderType_MatTabGroup)), i1.ɵdid(2, 3325952, [[1, 4], ["tabGroup", 4]], 1, i10.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i10.MAT_TABS_CONFIG], [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵqud(603979776, 2, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NatTabsComponent_1)), i1.ɵdid(5, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "accent"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.tabs; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_NatTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nat-tabs", [], null, null, null, View_NatTabsComponent_0, RenderType_NatTabsComponent)), i1.ɵdid(1, 4308992, null, 0, i11.NatTabsComponent, [i12.NatNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NatTabsComponentNgFactory = i1.ɵccf("nat-tabs", i11.NatTabsComponent, View_NatTabsComponent_Host_0, {}, {}, []);
export { NatTabsComponentNgFactory as NatTabsComponentNgFactory };
