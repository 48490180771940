import * as tslib_1 from "tslib";
import { OrmModel } from "@natiwi/core/models/model";
import { Expose } from "class-transformer";
import { NatProperty, NatModel } from "@natiwi/core/decorators/matadata.decorator";
let OrmEnumerationItem = class OrmEnumerationItem extends OrmModel {
    // @NatProperty({
    //     displayName: 'Владелец',
    //     type: 'relation',
    //     relationModel: 'OrmEnumeration',
    //     required: true,
    //     standart: false
    // })
    // private _owner: OrmEnumeration;
    constructor() {
        super();
    }
    get id() {
        return this._id;
    }
    set id(v) {
        let oldValue = this._id;
        if (v) {
            this._id = v;
        }
        else {
            this._id = null;
        }
        super.notifyFieldChange('id', oldValue, this._id);
    }
    get keyId() {
        return this._keyId;
    }
    set keyId(v) {
        let oldValue = this._keyId;
        this._keyId = v;
        super.notifyFieldChange('keyId', oldValue, v);
    }
    get name() {
        return this._name;
    }
    set name(v) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    get description() {
        return this._description;
    }
    set description(v) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    // @Expose()
    // @NatRelation(OrmEnumeration)
    // public get owner(): OrmEnumeration {
    //     if (!this._owner) {
    //         this._owner = new OrmEnumeration();
    //     }
    //     return this._owner;
    // }
    // public set owner(v: OrmEnumeration) {
    //     let oldValue = this._owner;
    //     this._owner = v;
    //     super.notifyFieldChange('owner', oldValue, v);
    // }
    /**
     * toString
     */
    toString() {
        return this._name || '';
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Идентификатор',
        type: 'string',
        required: true,
        standart: false
    })
], OrmEnumerationItem.prototype, "_id", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Идентификатор ключа',
        type: 'string',
        required: true,
        standart: false
    })
], OrmEnumerationItem.prototype, "_keyId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
], OrmEnumerationItem.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
], OrmEnumerationItem.prototype, "_description", void 0);
tslib_1.__decorate([
    Expose()
], OrmEnumerationItem.prototype, "id", null);
tslib_1.__decorate([
    Expose()
], OrmEnumerationItem.prototype, "keyId", null);
tslib_1.__decorate([
    Expose()
], OrmEnumerationItem.prototype, "name", null);
tslib_1.__decorate([
    Expose()
], OrmEnumerationItem.prototype, "description", null);
OrmEnumerationItem = tslib_1.__decorate([
    NatModel({
        plural: 'enumerationItems',
        displayName: 'Перечисление',
        pluralDisplayName: 'Перечисления'
    })
], OrmEnumerationItem);
export { OrmEnumerationItem };
