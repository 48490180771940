import { Injectable, Type } from "@angular/core";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatObjectRepository } from "@natiwi/core/network/repositories/object.repository";
import { OrmConstant } from "@natiwi/shared/models/constant.model";

export class NatConstantRepository extends NatObjectRepository<OrmConstant> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/constants';
        let repoModel: Type<OrmConstant> = OrmConstant;
        this.init(repoUrl, repoModel);
    }
}