import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatPolymorphicProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
let OrmArgumentItem = class OrmArgumentItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get name() {
        return this._name;
    }
    set name(v) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    get description() {
        return this._description;
    }
    set description(v) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    get required() {
        return this._required;
    }
    set required(v) {
        let oldValue = this._required;
        this._required = v;
        super.notifyFieldChange('required', oldValue, v);
    }
    get valueId() {
        return this._valueId;
    }
    set valueId(v) {
        let oldValue = this._valueId;
        this._valueId = v;
        super.notifyFieldChange('valueId', oldValue, v);
    }
    get valueType() {
        return this._valueType;
    }
    set valueType(v) {
        let oldValue = this._valueType;
        this._valueType = v;
        super.notifyFieldChange('valueType', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
], OrmArgumentItem.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
], OrmArgumentItem.prototype, "_description", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Обязательный',
        type: 'boolean',
        required: true,
        standart: false
    })
], OrmArgumentItem.prototype, "_required", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
], OrmArgumentItem.prototype, "_valueId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
], OrmArgumentItem.prototype, "_valueType", void 0);
tslib_1.__decorate([
    Expose()
], OrmArgumentItem.prototype, "name", null);
tslib_1.__decorate([
    Expose()
], OrmArgumentItem.prototype, "description", null);
tslib_1.__decorate([
    Expose()
], OrmArgumentItem.prototype, "required", null);
tslib_1.__decorate([
    Expose(),
    NatPolymorphicProperty('valueType')
], OrmArgumentItem.prototype, "valueId", null);
tslib_1.__decorate([
    Expose()
], OrmArgumentItem.prototype, "valueType", null);
OrmArgumentItem = tslib_1.__decorate([
    NatModel({
        displayName: '',
        pluralDisplayName: ''
    }),
    NatHierarchical(false)
], OrmArgumentItem);
export { OrmArgumentItem };
