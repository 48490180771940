<mat-tab-group color="accent" #tabGroup>
	<mat-tab *ngFor="let tab of tabs" >
		<ng-template mat-tab-label>
			<span class="label">{{tab.title}}</span>
			<button *ngIf="tab.isClosable" mat-icon-button aria-label="Закрыть вкладку" (click)="closeTab($event, tab)">
				<mat-icon>clear</mat-icon>
			</button>
		</ng-template>
	</mat-tab>
</mat-tab-group>
