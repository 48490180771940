import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { Expose, Type } from "class-transformer";
import { OrmContainerMetadata } from "@natiwi/shared/models/container-metadata";

@NatModel({
    displayName: 'Метаданные файла',
    pluralDisplayName: 'Метаданные файлов'
})
export abstract class OrmMetadata extends OrmCatalog {
    
    // @NatProperty({
    //     displayName: 'Контейнер',
    //     type: 'OrmContainerMetadata',
    //     required: true,
    //     standart: false
    // })
    // private _container: OrmContainerMetadata;
    
    constructor() {
        super();
    }
    
    // @Expose()
    // @Type(() => OrmContainerMetadata)
    // public get container(): OrmContainerMetadata {
    //     return this._container;
    // }

    // public set container(v: OrmContainerMetadata) {
    //     this._container = v;
    // }
}