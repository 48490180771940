import { NatForm } from "@natiwi/core/forms/form";
import { OnDestroy, AfterViewInit, ViewChild, OnInit, Injector } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { NatAppService } from "@natiwi/core";
import { NatFilter, NatFilterOrder, NatFilterInclude, NatFilterOperatorNeq, NatFilterWhere, NatFilterOperatorRegExp } from "@natiwi/core/network/shared/filter";
import { startWith } from "rxjs/internal/operators/startWith";
import { switchMap } from "rxjs/internal/operators/switchMap";
import { map } from "rxjs/internal/operators/map";
import { catchError } from "rxjs/internal/operators/catchError";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { NatOrder } from "@natiwi/core/network/shared/network.enum";
import { ViewListResponse } from "@natiwi/core/network/repositories/object.repository";
import { NatObjectController } from "@natiwi/core/controllers/object.controller";
import { OrmObject } from "@natiwi/core/models/object";
import { merge, of, Observable, BehaviorSubject } from "rxjs";
import { plainToClass } from "class-transformer";
import { MatTableDataSource } from "@angular/material/table";
import { MatList } from "@angular/material/list";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { flatMap } from "rxjs/operators";
import { NatListFiltersComponent } from "@natiwi/shared/list-filters";

export abstract class NatFormList<Model extends OrmObject, Controller extends NatObjectController<Model>> extends NatForm implements AfterViewInit, OnDestroy {

    private _router: Router;
    private _activatedRoute: ActivatedRoute;

    private _model: Model;
    private _controller: Controller;

    private _queryParams: Map<string, any>;
    private _elementFilter: NatFilter;

    public dataSource: MatTableDataSource<Model>;
    public data: Array<Model>;
    public isLoading: boolean;
    public resultsLength: number;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(NatListFiltersComponent, { static: true }) listFilters: NatListFiltersComponent<Model, Controller>;

    constructor(private _helper: NatAppService, model: Model, private _injector: Injector) {
        super();

        this._router = _injector.get(Router)
        this._activatedRoute = _injector.get(ActivatedRoute);

        this._model = model;
        this._controller = _helper.getController(model.constructor.prototype);
        this.isLoading = true;
        this._elementFilter = new NatFilter();
        this.dataSource = new MatTableDataSource([]);
        this._queryParams = new Map();
    }

    public get elementFilter(): NatFilter {
        return this._elementFilter;
    }

    ngAfterViewInit(): void {

        this.isLoading = true;

        //query change subscribe
        this._activatedRoute.queryParamMap
            .subscribe(params => {
                if (params.has('q')) {
                    let data = params.get('q');
                    this._queryParams.set('q', decodeURI(data));
                } else {
                    this._queryParams.set('q', null);
                }
                if (params.has('page')) {
                    let data = +(params.get('page'));
                    this._queryParams.set('page', data);
                } else {
                    this._queryParams.set('page', this.paginator.pageIndex + 1);
                }
                if (params.has('sort')) {
                    let data = params.get('sort');
                    this._queryParams.set('sort', data);
                } else {
                    this._queryParams.set('sort', this.sort.active);
                }
                if (params.has('sortDirection')) {
                    let data = <'' | 'asc' | 'desc'>params.get('sortDirection');
                    this._queryParams.set('sortDirection', data);
                } else {
                    this._queryParams.set('sortDirection', this.sort.direction);
                }
                if (params.has('pageSize')) {
                    let data = +params.get('pageSize');
                    this._queryParams.set('pageSize', data);
                } else {
                    this._queryParams.set('pageSize', this.paginator.pageSize);
                }
                setTimeout(() => {
                    if (this._queryParams.has('page')) {
                        this.paginator.pageIndex = this._queryParams.get('page') - 1;
                    }
                    if (this._queryParams.has('sort')) {
                        this.sort.active = this._queryParams.get('sort');
                    }
                    if (this._queryParams.has('sortDirection')) {
                        this.sort.direction = this._queryParams.get('sortDirection');
                    }
                    if (this._queryParams.has('pageSize')) {
                        this.paginator.pageSize = this._queryParams.get('pageSize');
                    }
                })
                this.getElementList();
            });
        //list params change subscribe
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
            map((value, index) => {
                this.isLoading = true;
                this._queryParams.set('page', this.paginator.pageIndex + 1);
                if (this.sort.active) {
                    this._queryParams.set('sort', this.sort.active);
                }
                this._queryParams.set('sortDirection', this.sort.direction);
                this._queryParams.set('pageSize', this.paginator.pageSize);
                this.navigate();
            }),
            catchError(err => {
                throw err;
            })
            ).subscribe(() => {
            }, error => {
                console.log(error);
            });

        this.listFilters.onChange.subscribe(filter => {
            this._elementFilter = filter;
            if (this._queryParams.has('page')) {
                this.paginator.pageIndex = 0;
            }
            this.getElementList();
        });
    }

    private navigate(): void {
        this._router.navigate([],
            {
                relativeTo: this._activatedRoute,
                queryParams: this.mapToObject(this._queryParams)
            });
    }

    private getElementList() {
        let page = this._queryParams.get('page') - 1;
        let sort = this._queryParams.get('sort');
        let search = this._queryParams.get('q') || ''
        let sortDirection = <'' | 'asc' | 'desc'>this._queryParams.get('sortDirection') || null;
        let pageSize = this._queryParams.get('pageSize');
        let showDeleted = this._queryParams.get('deleted') || false;

        this.isLoading = true;
        this._elementFilter.limit = pageSize;
        this._elementFilter.skip = pageSize * page;
        if (search) {
            const regexpOperator = new NatFilterOperatorRegExp()
            regexpOperator.set(search)
            this._elementFilter.where.set('name', regexpOperator)
        } else {
            this._elementFilter.where.delete('name')
        }
        if (sort) {
            let filteredOrderList = this._elementFilter.order.filter(value => value.property !== sort);
            this._elementFilter.order = filteredOrderList;
            if (!!sortDirection) {
                let order = new NatFilterOrder(sort, NatOrder[sortDirection.toUpperCase()]);
                this._elementFilter.order.push(order);
            }
        } else {
            this._elementFilter.order.length = 0;
        }

        if (showDeleted && this._elementFilter.where.has('deleted')) {
            this._elementFilter.where.delete('deleted');
        } else {
            this._elementFilter.where.set('deleted', false);
        }

        this._controller.find(this._elementFilter).pipe(
            flatMap((result, index) => {
                this.dataSource.data = result;
                return this._controller.count(this._elementFilter.where)
            }),
            map(result => {
                this.resultsLength = result.count;
            })
        ).subscribe(result => {
            this.isLoading = false;
        });
    }


    private mapToObject(map: Map<string, any>): {} {
        let result = {};
        map.forEach((value, key) => {
            result[key] = value;
        });
        return result;
    }

    protected refreshList() {
        this.getElementList();
    }

    public showDeleted() {
        if (this._queryParams.has('deleted')) {
            this._queryParams.delete('deleted');
        } else {
             this._queryParams.set('deleted', true);
        }
        this.navigate();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}