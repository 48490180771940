import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmPartnerContract } from "@natiwi/shared/models/partner-contract.model";
import { NatPartnerContractRepository } from "@natiwi/shared/repositories/partner-contract.repository";

export class NatPartnerContractController extends NatCatalogController<OrmPartnerContract>{

    constructor(_repository?: NatPartnerContractRepository) {
        super(_repository);
        super.registerDataClass(OrmPartnerContract);
    }
}