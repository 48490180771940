import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation, NatPolymorphicProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    displayName: 'Отображаемое поле',
    pluralDisplayName: 'Отображаемые поля'
})
@NatHierarchical(false)
export class OrmSamplingSchemePresetConditionFieldItem extends OrmObjectItem {
    
    @NatProperty({
        displayName: 'Тип элемента',
        type: 'number',
        required: true,
        standart: false
    })
    private _elementType: number;
    
    @NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _enabled: boolean;
    
    @NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
    private _valueId: any
    
    @NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
    private _valueType: string;
    
    @NatProperty({
        displayName: 'Тип сравнения',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'comparison_types',
        required: false,
        standart: false
    })
    private _comparisonType: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Тип условия',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'condition_types',
        required: false,
        standart: false
    })
    private _fieldType: OrmEnumerationItem;

    @NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
    private _field: OrmSamplingSchemeFieldItem;
    
    @NatProperty({
        displayName: 'Условия',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetConditionFieldItem',
        required: false,
        standart: false
    })
    private _conditionFieldItems: Array<OrmSamplingSchemePresetConditionFieldItem>;
    
    constructor() {
        super();
    }
    
    @Expose()
    public get elementType(): number {
        return this._elementType;
    }

    public set elementType(v: number) {
        let oldValue = this._elementType;
        this._elementType = v;
        super.notifyFieldChange('elementType', oldValue, v);
    }
    
    @Expose()
    public get enabled(): boolean {
        return this._enabled;
    }

    public set enabled(v: boolean) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    
    @Expose()
    @NatPolymorphicProperty('valueType')
    public get valueId(): any {
        return this._valueId;
    }

    public set valueId(v: any) {
        this._valueId = v;
    }

    @Expose()
    public get valueType(): string {
        return this._valueType;
    }

    public set valueType(v: string) {
        this._valueType = v;
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get comparisonType(): OrmEnumerationItem {
        return this._comparisonType;
    }

    public set comparisonType(v: OrmEnumerationItem) {
        let oldValue = this._comparisonType;
        this._comparisonType = v;
        super.notifyFieldChange('comparisonType', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get fieldType(): OrmEnumerationItem {
        return this._fieldType;
    }

    public set fieldType(v: OrmEnumerationItem) {
        let oldValue = this._fieldType;
        this._fieldType = v;
        super.notifyFieldChange('fieldType', oldValue, v);
    }
//
    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get field(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set field(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmSamplingSchemeFieldItem)
    public get fieldValue(): OrmSamplingSchemeFieldItem {
        return this._field;
    }

    public set fieldValue(v: OrmSamplingSchemeFieldItem) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
//
    @Expose()
    @NatRelation(OrmSamplingSchemePresetConditionFieldItem)
    public get conditionFieldItems(): Array<OrmSamplingSchemePresetConditionFieldItem> {
        return this._conditionFieldItems;
    }

    public set conditionFieldItems(v: Array<OrmSamplingSchemePresetConditionFieldItem>) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
    @Expose()
    @NatRelation(OrmSamplingSchemePresetConditionFieldItem)
    public get conditionFieldItemList(): Array<OrmSamplingSchemePresetConditionFieldItem> {
        return this._conditionFieldItems;
    }

    public set conditionFieldItemList(v: Array<OrmSamplingSchemePresetConditionFieldItem>) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
    //
}