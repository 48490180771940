export enum NatVerbType {
    GET = 'GET',
    HEAD = 'HEAD',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    CONNECT = 'CONNECT',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE',
    PATCH = 'PATCH'
}

export enum NatParameterEncoding {
    JSON = 'JSON'
}

export enum NatOrder {
    ASC = 'ASC',
    DESC = 'DESC'
}