import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nat-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css']
})
export class NatRestorePasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
