export interface NatValueObserver<ValueType> {
    onChange(oldValue: ValueType, newValue: ValueType);
}

export class NatKeyValueObserver<ValueType, ObjectType> implements NatValueObserver<ValueType> {

    constructor(private _key: string, private _handler: (object: ObjectType, key: string, oldValue: ValueType, newValue: ValueType) => void) { }

    public get key(): string {
        return this._key;
    }

    public onChange(oldValue: ValueType, newValue: ValueType) {
        throw 'Метод запрещен, используйте onChangeKeyValue';
    }

    public onChangeKeyValue(object: ObjectType, key: string, oldValue: ValueType, newValue: ValueType) {
        if (this._handler) {
            this._handler(object, key, oldValue, newValue);
        }
    }
}