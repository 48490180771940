import { Expose, Type } from "class-transformer";
import { OrmObject } from "@natiwi/core/models/object";
import { NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmUser } from "@natiwi/core/models/user";

export abstract class OrmDocument extends OrmObject {

    @NatProperty({
        displayName: 'Номер',
        type: 'string',
        required: false,
        standart: false
    })
    private _sequenceNumber: string;

    @NatProperty({
        displayName: 'Дата документа',
        type: 'date',
        required: false,
        standart: false
    })
    private _periodAt: Date;

    @NatProperty({
        displayName: 'Дата проведения',
        type: 'date',
        required: false,
        standart: false
    })
    private _postedAt: Date;

    @NatProperty({
        displayName: 'Проведен',
        type: 'boolean',
        required: false,
        standart: true
    })
    private _posted: boolean;

    @NatProperty({
        displayName: 'Ответственный',
        type: 'relation',
        relationModel: 'OrmUser',
        required: false,
        standart: false
    })
    private _responsible: OrmUser;

    constructor() {
        super();
    }

    @Expose()
    public get sequenceNumber(): string {
        //remove lead nulls
        // let nullMatch = this._sequenceNumber && this._sequenceNumber.match(/^0*/);
        // if (nullMatch) {
        //     let formatedNumber: string = '';
        //     formatedNumber = this._sequenceNumber.replace(/^0*/, '');
        //     return formatedNumber;
        // }
        return this._sequenceNumber;
    }

    public set sequenceNumber(v: string) {
        let oldValue = this._sequenceNumber;
        this._sequenceNumber = v;
        super.notifyFieldChange('sequenceNumber', oldValue, v);
    }

    @Expose()
    @Type(() => Date)
    public get periodAt(): Date {
        return this._periodAt;
    }

    public set periodAt(v: Date) {
        let oldValue = this._periodAt;
        this._periodAt = v;
        super.notifyFieldChange('periodAt', oldValue, v);
    }

    @Expose()
    @Type(() => Date)
    public get postedAt(): Date {
        return this._postedAt;
    }

    public set postedAt(v: Date) {
        let oldValue = this._postedAt;
        this._postedAt = v;
        super.notifyFieldChange('postedAt', oldValue, v);
    }

    @Expose()
    public get posted(): boolean {
        return this._posted;
    }

    public set posted(v: boolean) {
        let oldValue = this._posted;
        this._posted = v;
        super.notifyFieldChange('posted', oldValue, v);
    }

    @Expose()
    @NatRelation(OrmUser)
    public get responsible(): OrmUser {
        return this._responsible;
    }

    public set responsible(v: OrmUser) {
        let oldValue = this._responsible;
        this._responsible = v;
        super.notifyFieldChange('responsible', oldValue, v);
    }

    /**
     * toString
     */
    public toString(): string {
        if (this._sequenceNumber) {
            return `№${this.sequenceNumber}`;
        }
        return '';
    }

}