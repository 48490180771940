import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmArgumentItem } from "@natiwi/core/models/argument-item";

@NatModel({
    displayName: 'Аргумент',
    pluralDisplayName: 'Аргументы'
})
export class OrmPaymentOptionKindArgumentItem extends OrmArgumentItem {

    constructor() {
        super();
    }
}