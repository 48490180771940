import * as tslib_1 from "tslib";
import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
const ɵ0 = () => Date;
let OrmNaturalPerson = class OrmNaturalPerson extends OrmCatalog {
    constructor() {
        super();
    }
    get type() {
        return this._gender;
    }
    set type(v) {
        let oldValue = this._gender;
        this._gender = v;
        super.notifyFieldChange('gender', oldValue, v);
    }
    get birthdate() {
        return this._birthdate;
    }
    set birthdate(v) {
        let oldValue = this._birthdate;
        this._birthdate = v;
        super.notifyFieldChange('birthdate', oldValue, v);
    }
    get itn() {
        return this._itn;
    }
    set itn(v) {
        let oldValue = this._itn;
        this._itn = v;
        super.notifyFieldChange('itn', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Пол',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'gender',
        required: false,
        standart: false
    })
], OrmNaturalPerson.prototype, "_gender", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата рождения',
        type: 'date',
        required: false,
        standart: false
    })
], OrmNaturalPerson.prototype, "_birthdate", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'ИНН',
        type: 'string',
        required: false,
        standart: false
    })
], OrmNaturalPerson.prototype, "_itn", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmNaturalPerson.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmNaturalPerson.prototype, "birthdate", null);
tslib_1.__decorate([
    Expose()
], OrmNaturalPerson.prototype, "itn", null);
OrmNaturalPerson = tslib_1.__decorate([
    NatModel({
        plural: 'naturalPersons',
        displayName: 'Физическое лицо',
        pluralDisplayName: 'Физические лица'
    }),
    NatHierarchical(false)
], OrmNaturalPerson);
export { OrmNaturalPerson };
export { ɵ0 };
