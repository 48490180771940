import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmSamplingSchemePresetOrderFieldItem = class OrmSamplingSchemePresetOrderFieldItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get enabled() {
        return this._enabled;
    }
    set enabled(v) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    get orderType() {
        return this._orderType;
    }
    set orderType(v) {
        let oldValue = this._orderType;
        this._orderType = v;
        super.notifyFieldChange('orderType', oldValue, v);
    }
    get field() {
        return this._field;
    }
    set field(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    //
    get fieldValue() {
        return this._field;
    }
    set fieldValue(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetOrderFieldItem.prototype, "_enabled", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип сортировки',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'sampling_scheme_order_types',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetOrderFieldItem.prototype, "_orderType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetOrderFieldItem.prototype, "_field", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetOrderFieldItem.prototype, "enabled", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmSamplingSchemePresetOrderFieldItem.prototype, "orderType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemePresetOrderFieldItem.prototype, "field", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemePresetOrderFieldItem.prototype, "fieldValue", null);
OrmSamplingSchemePresetOrderFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Отображаемое поле',
        pluralDisplayName: 'Отображаемые поля'
    }),
    NatHierarchical(false)
], OrmSamplingSchemePresetOrderFieldItem);
export { OrmSamplingSchemePresetOrderFieldItem };
