import * as tslib_1 from "tslib";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose } from "class-transformer";
import { OrmMetadata } from "@natiwi/core/models/metadata";
let OrmFileMetadata = class OrmFileMetadata extends OrmMetadata {
    constructor() {
        super();
    }
    get format() {
        return this._format;
    }
    set format(v) {
        this._format = v;
    }
    get filename() {
        return this._filename;
    }
    set filename(v) {
        this._filename = v;
    }
    get tag() {
        return this._tag;
    }
    set tag(v) {
        this._tag = v;
    }
    get description() {
        return this._description;
    }
    set description(v) {
        this._description = v;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Формат',
        type: 'string',
        required: false,
        standart: false
    })
], OrmFileMetadata.prototype, "_format", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя файла',
        type: 'string',
        required: true,
        standart: false
    })
], OrmFileMetadata.prototype, "_filename", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тэг',
        type: 'string',
        required: false,
        standart: false
    })
], OrmFileMetadata.prototype, "_tag", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
], OrmFileMetadata.prototype, "_description", void 0);
tslib_1.__decorate([
    Expose()
], OrmFileMetadata.prototype, "format", null);
tslib_1.__decorate([
    Expose()
], OrmFileMetadata.prototype, "filename", null);
tslib_1.__decorate([
    Expose()
], OrmFileMetadata.prototype, "tag", null);
tslib_1.__decorate([
    Expose()
], OrmFileMetadata.prototype, "description", null);
OrmFileMetadata = tslib_1.__decorate([
    NatModel({
        plural: 'files',
        displayName: 'Файл',
        pluralDisplayName: 'Файлы'
    })
], OrmFileMetadata);
export { OrmFileMetadata };
