import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";

@NatModel({
    plural: 'loyaltyCards',
    displayName: 'Карта лояльности',
    pluralDisplayName: 'Карты лояльности'
})
export class OrmLoyaltyCard extends OrmCatalog {

    constructor() {
        super();
    }
}