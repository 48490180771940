import * as tslib_1 from "tslib";
import { NatRestRepository, natPlainToClass, natClassToPlain } from "@natiwi/core/network/repositories/rest.repository";
import { Expose, plainToClassFromExist } from "class-transformer";
import { map } from "rxjs/internal/operators/map";
export class NatModelRepository extends NatRestRepository {
    constructor(adapter) {
        super(adapter);
        super.createСontractItem('find', '/');
        super.createСontractItem('count', '/count');
    }
    find(params) {
        let stringifyParams = new Map();
        if (params && params.size) {
            params.forEach((value, key) => {
                let i = natClassToPlain(this.dataClass, value);
                stringifyParams.set(key, i);
            });
        }
        let request = this.invokeStaticMethod('find', stringifyParams);
        return request.pipe(map(response => {
            return natPlainToClass(this.dataClass, response);
        }));
    }
    count(params) {
        let stringifyParams = new Map();
        if (params && params.size) {
            params.forEach((value, key) => {
                let i = natClassToPlain(this.dataClass, value);
                stringifyParams.set(key, i);
            });
        }
        let request = this.invokeStaticMethod('count', stringifyParams);
        return request.pipe(map(response => {
            return plainToClassFromExist(new CountResponse(), response);
        }));
    }
}
export class CountResponse {
    constructor() { }
    get count() {
        return this._count;
    }
    set count(v) {
        this._count = v;
    }
}
tslib_1.__decorate([
    Expose()
], CountResponse.prototype, "count", null);
