import { Injectable } from '@angular/core';
import { Router, RouteReuseStrategy, NavigationEnd } from '@angular/router';
import { NatRouteReuseStrategyService } from '@natiwi/core/services/route-reuse-strategy.service';
import { NatNavigationItem } from '@natiwi/core/services/navigation/navigation-item';
import { BehaviorSubject, Observable, of, merge, Subject, throwError, from } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';

@Injectable()
export class NatNavigationService {

    private _navigationItems: Array<NatNavigationItem>;
    private _activeNavigationItem: BehaviorSubject<NatNavigationItem>;
    private _natRouterReuseStrategy: NatRouteReuseStrategyService;
    private _activeNavigationItemIndex: number;

    constructor(private router: Router, private routerReuseStrategy: RouteReuseStrategy) {
        this._natRouterReuseStrategy = <NatRouteReuseStrategyService>routerReuseStrategy;
        this._navigationItems = new Array();
        this._activeNavigationItem = new BehaviorSubject(null);

        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                let url = e.urlAfterRedirects;
                // Исправить. Возможно, очищать непосредственно при выходе,
                // а не при совпадении пути
                if (url === '/login') {
                    this._navigationItems = []
                } else {
                    this.setActive(this.addNavigateItem(url));
                }
            }
        });
    }

    public get activeNavigationItem(): Subject<NatNavigationItem> {
        return this._activeNavigationItem;
    }

    private getNavigationItem(navigationItem: NatNavigationItem): NatNavigationItem {
        return this._navigationItems.find(value => value.routeItem.url === navigationItem.routeItem.url);
    }

    private isExist(navigationItem: NatNavigationItem): boolean {
        return !!this._navigationItems.find(value => value.routeItem.url === navigationItem.routeItem.url);
    }

    private updateNavigationItem(navigationItem: NatNavigationItem): NatNavigationItem {
        let item = this.getNavigationItem(navigationItem);
        let itemIndex = this._navigationItems.indexOf(item);
        item.routeItem.merge(navigationItem.routeItem);
        this._navigationItems[itemIndex] = item;
        return item;
    }

    public addNavigateItem(target: NatNavigationItem | string): NatNavigationItem {
        if (!target) {
            throw 'target need';
        }
        let targetNavigationItem: NatNavigationItem;
        let isTargetTypeNatNavigationItem: boolean = target instanceof NatNavigationItem;
        if (!isTargetTypeNatNavigationItem && typeof target !== 'string') {
            throw 'type not correct';
        }
        if (isTargetTypeNatNavigationItem) {
            targetNavigationItem = <NatNavigationItem>target;
        } else {
            targetNavigationItem = NatNavigationItem.fromUrl(<string>target);
        }
        if (!this.isExist(targetNavigationItem)) {
            this._navigationItems.push(targetNavigationItem);
        } else {
            this.updateNavigationItem(targetNavigationItem);
        }
        return this.getNavigationItem(targetNavigationItem);
    }

    public deleteNavigationItem(item: NatNavigationItem) {
        if (!item) {
            throw 'deleted item need'
        }
        if (!this.isExist(item)) {
            return;
        }
        let deletedItemIndex = this._navigationItems.indexOf(this.getNavigationItem(item));
        if (deletedItemIndex === this._activeNavigationItemIndex) {
            let futureNavigationItem;
            futureNavigationItem = deletedItemIndex >= 1 ? this._navigationItems[deletedItemIndex - 1] : this._navigationItems[0];
            this.navigate(futureNavigationItem).subscribe(result => {
                this._natRouterReuseStrategy.deleteCachedRoute(item.routeItem.url);
            });
        } else {
            this._natRouterReuseStrategy.deleteCachedRoute(item.routeItem.url);
        }
        this._navigationItems.splice(deletedItemIndex, 1);
    }

    public setActive(navigationItem: NatNavigationItem) {
        this._activeNavigationItemIndex = this._navigationItems.indexOf(this.getNavigationItem(navigationItem));
        this._activeNavigationItem.next(navigationItem)
    }

    public navigate(target: NatNavigationItem | string): Observable<NatNavigationItem> {
        if (!target) {
            throw 'Ошибка перехода: адрес назначения(url) не указан'
        }
        let targetNavigationItem;
        let isTargetTypeNatNavigationItem: boolean = target instanceof NatNavigationItem;
        if (!isTargetTypeNatNavigationItem && typeof target !== 'string') {
            throw 'type not correct';
        }
        if (isTargetTypeNatNavigationItem) {
            targetNavigationItem = target;
        } else {
            targetNavigationItem = this.addNavigateItem(targetNavigationItem);
        }
        let targetRouteItem = targetNavigationItem.routeItem;
        return from(this.router.navigate(
            [targetRouteItem.url],
            { queryParams: targetRouteItem.queryParams }
        )).pipe(map(result=>{
            if (result) {
                return targetNavigationItem;
            }else{
                return null;
            }
        }));
    }

    public getNavigationItems(): Array<NatNavigationItem> {
        return this._navigationItems;
    }
}
