import { NatAppService, NatAuthService } from '@natiwi/core';
import { registerLocaleData } from '@angular/common';
import { NatUserRepository } from '@natiwi/shared/repositories/user.repository';
import { NatUserController } from '@natiwi/shared/controllers/user.controller';
import { NatProductRepository } from '@natiwi/shared/repositories/product.repository';
import { NatProductController } from '@natiwi/shared/controllers/product.controller';
import { NatCustomerOrderController } from '@natiwi/shared/controllers/customer-orders.controller';
import { NatProductKindRepository } from '@natiwi/shared/repositories/product-kind.repository';
import { NatProductKindController } from '@natiwi/shared/controllers/product-kind.controller';
import { NatContainerRepository } from '@natiwi/shared/repositories/container.repository';
import { NatContainerController } from '@natiwi/shared/controllers/container.controller';
import { NatImageMetadataRepository } from '@natiwi/shared/repositories/image-metadata.repository';
import { NatImageMetadataController } from '@natiwi/shared/controllers/image-metadata.controller';
import { NatProductUnitRepository } from '@natiwi/shared/repositories/product-unit.repository';
import { NatProductUnitController } from '@natiwi/shared/controllers/product-unit.controller';
import { NatPriceTypeRepository } from '@natiwi/shared/repositories/price-type.repository';
import { NatPriceTypeController } from '@natiwi/shared/controllers/price-type.controller';
import { NatProductPriceRepository } from '@natiwi/shared/repositories/product-price.repository';
import { NatProductPriceController } from '@natiwi/shared/controllers/product-price.controller';
import { NatContainerMetadataRepository } from '@natiwi/shared/repositories/container-metadata.repository';
import { NatContainerMetadataController } from '@natiwi/shared/controllers/container-metadata.controller';
import { NatSettingPricesOfProductsRepository } from '@natiwi/shared/repositories/setting-prices-of-products.repository';
import { NatSettingPricesOfProductsController } from '@natiwi/shared/controllers/setting-prices-of-products.controller';
import { NatOrganizationRepository } from '@natiwi/shared/repositories/organization.repository';
import { NatOrganizationController } from '@natiwi/shared/controllers/organization.controller';
import { NatConstantRepository } from '@natiwi/shared/repositories/constant.repository';
import { NatConstantController } from '@natiwi/shared/controllers/constant.controller';
import { NatCustomerOrdersRepository } from '@natiwi/shared/repositories/customer-orders.repository';
import { NatPaymentOptionRepository } from '@natiwi/shared/repositories/payment-option.repository';
import { NatPaymentOptionController } from '@natiwi/shared/controllers/payment-option.controller';
import { NatWarehouseRepository } from '@natiwi/shared/repositories/warehouse.repository';
import { NatWarehouseController } from '@natiwi/shared/controllers/warehouse.controller';
import { NatCashBoxRepository } from '@natiwi/shared/repositories/cash-box.repository';
import { NatCashBoxController } from '@natiwi/shared/controllers/cash-box.controller';
import { NatLoyaltyCardRepository } from '@natiwi/shared/repositories/loyalty-cards.repository';
import { NatLoyaltyCardController } from '@natiwi/shared/controllers/loyalty-cards.controller';
import { NatCouponRepository } from '@natiwi/shared/repositories/coupon.repository';
import { NatCouponController } from '@natiwi/shared/controllers/coupon.controller';
import { NatPartnerContractRepository } from '@natiwi/shared/repositories/partner-contract.repository';
import { NatPartnerContractController } from '@natiwi/shared/controllers/partner-contract.controller';
import { NatContactKindRepository } from '@natiwi/shared/repositories/contact-kind.repository';
import { NatContactKindController } from '@natiwi/shared/controllers/contact-kind.controller';
import { NatPartnerRepository } from '@natiwi/shared/repositories/partner.repository';
import { NatPartnerController } from '@natiwi/shared/controllers/partner.controller';
import { NatEnumerationItemRepository } from '@natiwi/shared/repositories/enumeration-item.repository';
import { NatEnumerationItemController } from '@natiwi/shared/controllers/enumeration-item.controller';
import { NatCustomerOrderProductItemController } from '@natiwi/shared/controllers/customer-order-product-item.controller';
import { NatCustomerOrderContactSetController } from '@natiwi/shared/controllers/customer-order-contact-set.controller';
import { NatCustomerOrderContactItemController } from '@natiwi/shared/controllers/customer-order-contact-item.controller';
import { NatProductBarcodeRepository } from '@natiwi/shared/repositories/product-barcode.repository';
import { NatProductBarcodeController } from '@natiwi/shared/controllers/product-barcode.controller';
import { NatProductSegmentRepository } from '@natiwi/shared/repositories/product-segment.repository';
import { NatProductSegmentController } from '@natiwi/shared/controllers/product-segment.controller';
import { NatPartnerContactRepository } from '@natiwi/shared/repositories/partner-contact.repository';
import { NatPartnerContactController } from '@natiwi/shared/controllers/partner-contact.controller';
import { NatProductFeatureRepository } from '@natiwi/shared/repositories/product-feature.repository';
import { NatProductFeatureController } from '@natiwi/shared/controllers/product-feature.controller';
import { NatNaturalPersonRepository } from '@natiwi/shared/repositories/natural-person.repository';
import { NatNaturalPersonController } from '@natiwi/shared/controllers/natural-person.controller';
import { NatResponsiblePersonRepository } from '@natiwi/shared/repositories/responsible-person.repository';
import { NatResponsiblePersonController } from '@natiwi/shared/controllers/responsible-person.controller';
import { NatOrganizationBankAccountRepository } from '@natiwi/shared/repositories/organization-bank-account.repository';
import { NatOrganizationBankAccountController } from '@natiwi/shared/controllers/organization-bank-account.controller';
import { getRuPaginatorIntl } from '@natiwi/shared/localization';
import localeRu from '@angular/common/locales/ru';
import { NatReportRepository } from '@natiwi/shared/repositories/report.repository';
import { NatReportController } from '@natiwi/shared/controllers/report.controller';
import { NatPaymentOptionKindRepository } from '@natiwi/shared/repositories/payment-option-kind.repository';
import { NatPaymentOptionKindController } from '@natiwi/shared/controllers/payment-option-kind.controller';
import { NatReportPresetRepository } from '@natiwi/shared/repositories/report-preset.repository';
import { NatReportPresetController } from '@natiwi/shared/controllers/report-preset.controller';
const ɵ0 = getRuPaginatorIntl();
export class AppModule {
}
// register ru locale
registerLocaleData(localeRu, 'ru-RU');
export function appInitFactory(helper, auth) {
    return () => {
        //controllers registration    
        //user
        let userRepo = new NatUserRepository();
        let userCtrl = new NatUserController(userRepo);
        helper.registerController(userCtrl);
        //partner
        let partnerRepo = new NatPartnerRepository();
        let partnerCtrl = new NatPartnerController(partnerRepo);
        helper.registerController(partnerCtrl);
        //products
        let productRepo = new NatProductRepository();
        let productCtrl = new NatProductController(productRepo);
        helper.registerController(productCtrl);
        //productKinds
        let productKindRepo = new NatProductKindRepository();
        let productKindCtrl = new NatProductKindController(productKindRepo);
        helper.registerController(productKindCtrl);
        //productKinds
        let productSegmentRepo = new NatProductSegmentRepository();
        let productSegmentCtrl = new NatProductSegmentController(productSegmentRepo);
        helper.registerController(productSegmentCtrl);
        //productFeatures
        let productFeatureRepo = new NatProductFeatureRepository();
        let productFeatureCtrl = new NatProductFeatureController(productFeatureRepo);
        helper.registerController(productFeatureCtrl);
        //productUnits
        let productUnitRepo = new NatProductUnitRepository();
        let productUnitCtrl = new NatProductUnitController(productUnitRepo);
        helper.registerController(productUnitCtrl);
        //productPrices
        let productPriceRepo = new NatProductPriceRepository();
        let productPriceCtrl = new NatProductPriceController(productPriceRepo);
        helper.registerController(productPriceCtrl);
        //productBarcodes
        let productBarcodeRepo = new NatProductBarcodeRepository();
        let productBarcodeCtrl = new NatProductBarcodeController(productBarcodeRepo);
        helper.registerController(productBarcodeCtrl);
        //priceTypes
        let priceTypeRepo = new NatPriceTypeRepository();
        let priceTypeCtrl = new NatPriceTypeController(priceTypeRepo);
        helper.registerController(priceTypeCtrl);
        //container
        let containerRepo = new NatContainerRepository();
        let containerCtrl = new NatContainerController(containerRepo);
        helper.registerController(containerCtrl);
        //containerMetadata
        let containerMetadataRepo = new NatContainerMetadataRepository();
        let containerMetadataCtrl = new NatContainerMetadataController(containerMetadataRepo);
        helper.registerController(containerMetadataCtrl);
        //imageMetadata
        let imageMetadataRepo = new NatImageMetadataRepository();
        let imageMetadataCtrl = new NatImageMetadataController(imageMetadataRepo);
        helper.registerController(imageMetadataCtrl);
        //organizations
        let organizationRepo = new NatOrganizationRepository();
        let organizationsCtrl = new NatOrganizationController(organizationRepo);
        helper.registerController(organizationsCtrl);
        //settingPricesOfProducts
        let settingPricesOfProductsRepo = new NatSettingPricesOfProductsRepository();
        let settingPricesOfProductsCtrl = new NatSettingPricesOfProductsController(settingPricesOfProductsRepo);
        helper.registerController(settingPricesOfProductsCtrl);
        //constants
        let constantRepo = new NatConstantRepository();
        let constantCtrl = new NatConstantController(constantRepo);
        helper.registerController(constantCtrl);
        // customer order
        let customerOrderRepo = new NatCustomerOrdersRepository();
        let customerOrderCtrl = new NatCustomerOrderController(customerOrderRepo);
        helper.registerController(customerOrderCtrl);
        // payment options
        let paymentOptionRepo = new NatPaymentOptionRepository();
        let paymentOptionCtrl = new NatPaymentOptionController(paymentOptionRepo);
        helper.registerController(paymentOptionCtrl);
        // payment option kinds
        let paymentOptionKindRepo = new NatPaymentOptionKindRepository();
        let paymentOptionKindCtrl = new NatPaymentOptionKindController(paymentOptionKindRepo);
        helper.registerController(paymentOptionKindCtrl);
        //warehoses
        let warehouseRepo = new NatWarehouseRepository();
        let warehouseCtrl = new NatWarehouseController(warehouseRepo);
        helper.registerController(warehouseCtrl);
        //cashboxes
        let cashboxRepo = new NatCashBoxRepository();
        let cashboxCtrl = new NatCashBoxController(cashboxRepo);
        helper.registerController(cashboxCtrl);
        //loyalty cards
        let loyaltyCardRepo = new NatLoyaltyCardRepository();
        let loyaltyCardCtrl = new NatLoyaltyCardController(loyaltyCardRepo);
        helper.registerController(loyaltyCardCtrl);
        //coupons
        let couponRepo = new NatCouponRepository();
        let couponCtrl = new NatCouponController(couponRepo);
        helper.registerController(couponCtrl);
        //partner contracts
        let partnerContractRepo = new NatPartnerContractRepository();
        let partnerContractCtrl = new NatPartnerContractController(partnerContractRepo);
        helper.registerController(partnerContractCtrl);
        //Partner contact
        let partnerContactRepo = new NatPartnerContactRepository();
        let partnerContactCtrl = new NatPartnerContactController(partnerContactRepo);
        helper.registerController(partnerContactCtrl);
        //contact kinds
        let contactKindRepo = new NatContactKindRepository();
        let contactKindCtrl = new NatContactKindController(contactKindRepo);
        helper.registerController(contactKindCtrl);
        //enumeration items
        let enumerationItemsRepo = new NatEnumerationItemRepository();
        let enumerationItemsCtrl = new NatEnumerationItemController(enumerationItemsRepo);
        helper.registerController(enumerationItemsCtrl);
        //customerOrderProduct items
        let customerOrderProductItemCtrl = new NatCustomerOrderProductItemController();
        helper.registerController(customerOrderProductItemCtrl);
        //customerOrderProduct items
        let customerOrderContactSetCtrl = new NatCustomerOrderContactSetController();
        helper.registerController(customerOrderContactSetCtrl);
        //customerOrder Contact items
        let customerOrderContactItemCtrl = new NatCustomerOrderContactItemController();
        helper.registerController(customerOrderContactItemCtrl);
        //naturalPersons
        let naturalPersonRepo = new NatNaturalPersonRepository();
        let naturalPersonCtrl = new NatNaturalPersonController(naturalPersonRepo);
        helper.registerController(naturalPersonCtrl);
        //responsiblePersons
        let responsiblePersonRepo = new NatResponsiblePersonRepository();
        let responsiblePersonCtrl = new NatResponsiblePersonController(responsiblePersonRepo);
        helper.registerController(responsiblePersonCtrl);
        //organizationBankAccounts
        let organizationBankAccountRepo = new NatOrganizationBankAccountRepository();
        let organizationBankAccountCtrl = new NatOrganizationBankAccountController(organizationBankAccountRepo);
        helper.registerController(organizationBankAccountCtrl);
        //reports
        let reportRepo = new NatReportRepository();
        let reportCtrl = new NatReportController(reportRepo);
        helper.registerController(reportCtrl);
        //report presets
        let reportPresetRepo = new NatReportPresetRepository();
        let reportPresetCtrl = new NatReportPresetController(reportPresetRepo);
        helper.registerController(reportPresetCtrl);
        //auth init
        auth.init();
    };
}
export { ɵ0 };
