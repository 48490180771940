import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";

@NatModel({
    plural: 'roles',
    displayName: 'Роль',
    pluralDisplayName: 'Роли'
})
export class OrmRole extends OrmPersistedModel {

    @NatProperty({
        displayName: 'Имя роли',
        type: 'string',
        required: false,
        standart: false
    })
    private _name: string;

    @NatProperty({
        displayName: 'Дата создания',
        type: 'date',
        required: false,
        standart: false
    })
    private _created: Date;

    @NatProperty({
        displayName: 'Дата изменения',
        type: 'date',
        required: false,
        standart: false
    })
    private _modified: Date;

    @NatProperty({
        displayName: 'Предопределенная роль',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _predefined: boolean;

    @NatProperty({
        displayName: 'Предопределенное имя',
        type: 'string',
        required: false,
        standart: false
    })
    private _predefinedName: string;

    @NatProperty({
        displayName: 'Версия предопределения',
        type: 'number',
        required: false,
        standart: false
    })
    private _predefinedVersion: number;

    constructor() {
        super();
    }

    @Expose()
    public get name(): string {
        return this._name;
    }

    public set name(v: string) {
        this._name = v;
    }

    @Expose()
    @Type(() => Date)
    public get created(): Date {
        return this._created;
    }

    public set created(v: Date) {
        this._created = v;
    }

    @Expose()
    @Type(() => Date)
    public get modified(): Date {
        return this._modified;
    }

    public set modified(v: Date) {
        this._modified = v;
    }

    @Expose()
    public get predefined(): boolean {
        return this._predefined;
    }

    public set predefined(v: boolean) {
        this._predefined = v;
    }

    @Expose()
    public get predefinedName(): string {
        return this._predefinedName;
    }

    public set predefinedName(v: string) {
        this._predefinedName = v;
    }

    @Expose()
    public get predefinedVersion(): number {
        return this._predefinedVersion;
    }

    public set predefinedVersion(v: number) {
        this._predefinedVersion = v;
    }

    public isRole(name: string): boolean {
        return this._name === name;
    }

}