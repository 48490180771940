import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmProductKind = class OrmProductKind extends OrmCatalog {
    constructor() {
        super();
    }
    get unit() {
        // if (!this._unit) {
        //     this._unit = new OrmProductUnit();
        // }
        return this._unit;
    }
    set unit(v) {
        this._unit = v;
    }
    get accountingOption() {
        return this._accountingOption;
    }
    set accountingOption(v) {
        let oldValue = this._accountingOption;
        this._accountingOption = v;
        super.notifyFieldChange('accountingOption', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmProductUnit',
        required: true,
        standart: false
    })
], OrmProductKind.prototype, "_unit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Вариант учета',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'product_accounting_options',
        required: false,
        standart: false
    })
], OrmProductKind.prototype, "_accountingOption", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductUnit)
], OrmProductKind.prototype, "unit", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmProductKind.prototype, "accountingOption", null);
OrmProductKind = tslib_1.__decorate([
    NatModel({
        plural: 'productKinds',
        displayName: 'Вид номенклатуры',
        pluralDisplayName: 'Виды номенклатуры'
    })
], OrmProductKind);
export { OrmProductKind };
