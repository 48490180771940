import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmCoupon } from "@natiwi/shared/models/coupon.model";
import { NatCouponRepository } from "@natiwi/shared/repositories/coupon.repository";

export class NatCouponController extends NatCatalogController<OrmCoupon>{

    constructor(_repository?: NatCouponRepository) {
        super(_repository);
        super.registerDataClass(OrmCoupon);
    }
}