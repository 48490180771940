import { NatRestContractItem } from "@natiwi/core/network/rest-contract-item";
import { NatVerbType, NatParameterEncoding } from "@natiwi/core/network/shared/network.enum";

export class NatRestContract {

    private contractItemsMap: Map<string, NatRestContractItem>;

    constructor() {
        this.contractItemsMap = new Map<string, NatRestContractItem>();
    }

    /**
     * addСontractItem
     */
    public addСontractItem(contractItem: NatRestContractItem): void {
        if (!contractItem) {
            throw 'Параметры item обязательны';
        }
        let method = contractItem.methodName;
        this.contractItemsMap.set(method, contractItem);
    }

    /**
     * getPatternForMethod
     */
    public getPatternForMethod(method: string): string | null {
        if (!method) {
            throw 'Параметр method обязательный';
        }

        let contractItem = this.contractItemsMap.get(method);
        return !!contractItem ? contractItem.urlPattern : null;
    }

    /**
     * getVerbForMethod
     */
    public getVerbForMethod(method: string): NatVerbType {
        if (!method) {
            throw 'Параметр method обязательный';
        }
        let contractItem = this.contractItemsMap.get(method);
        return !!contractItem ? contractItem.verbType : NatVerbType.GET;
    }

    /**
     * getParameterEncodingForMethod
     */
    public getParameterEncodingForMethod(method: string): NatParameterEncoding {
        if (!method) {
            throw 'Параметр method обязательный';
        }
        let contractItem = this.contractItemsMap.get(method);
        return !!contractItem ? contractItem.parameterEncoding : NatParameterEncoding.JSON;
    }

    /**
     * getUrlForMethod
     */
    public getUrlForMethod(method: string, params?: Map<string, string | Date>): string {
        if (!method) {
            throw 'Параметр method обязательный';
        }
        let contractItem = this.contractItemsMap.get(method);
        let pattern = contractItem.urlPattern;
        return this.getUrl(pattern, params);
    }

    private getUrl(pattern: string, data?: Map<string, string | Date >): string {
        let url: string;
        if (!pattern) {
            throw 'Параметр pattern обязательный';
        }
        url = pattern;
        if (!data) {
            return url;
        } else {
            data.forEach((value: string | Date, key: string) => {
                if (url.indexOf(`:${key}`) > -1) {
                    url = url.replace(`:${key}`, String(data.get(key)));
                } else {
                    let firstUrlParam = url.indexOf('?') === -1;
                    if (firstUrlParam) {
                        url = `${url}?`;
                    } else {
                        url = `${url}&`;
                    }
                    url = `${url}${key}=${data.get(key)}`;
                }
            });
            return url;
        }
    }
}