import { Injectable, Type } from "@angular/core";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatDocumentRepository } from "@natiwi/core/network/repositories/document.repository";
import { OrmSettingPricesOfProducts } from "@natiwi/shared/models/setting-prices-of-products.model";

export class NatSettingPricesOfProductsRepository extends NatDocumentRepository<OrmSettingPricesOfProducts> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/settingPricesOfProducts';
        let repoModel: Type<OrmSettingPricesOfProducts> = OrmSettingPricesOfProducts;
        this.init(repoUrl, repoModel);
    }
}