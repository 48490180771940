import "reflect-metadata";
import { Injectable, Type } from '@angular/core';
import { NatPersistedModelController } from '@natiwi/core/controllers/persisted-model.controller';
import { OrmPersistedModel } from '@natiwi/core/models/persisted-model';
import { NatRestAdapter } from '@natiwi/core/network/adapters/rest.adapter';
import { OrmModel } from "@natiwi/core/models/model";
import { NatFilter } from "@natiwi/core/network/shared/filter";
import { BehaviorSubject, Observable } from "rxjs";
import { OrmConstant } from "@natiwi/shared/models/constant.model";
import { NatMainController } from "@natiwi/core/controllers/main.controller";
import { NatRestController } from "@natiwi/core/controllers/rest.controller";
import { GobalModelStore } from "@natiwi/core/network/shared/global-variables";

@Injectable()
export class NatAppService {

    private _modelMap: Map<string, any>;
    private _controllerMap: Map<any, any>;
    private _predefinedMap: Map<any, any>;
    private _constantsMap: Map<any, any>;
    private _constants: BehaviorSubject<Array<OrmConstant>>;

    constructor() {
        this._modelMap = new Map();
        this._controllerMap = new Map();
        this._predefinedMap = new Map();
        this._constantsMap = new Map();
        this._constants = new BehaviorSubject([]);
        this._constants.subscribe(constants => {
            constants.forEach((value: OrmConstant) => {
                let predefinedName = value.predefinedName;
                if (this._constantsMap.has(predefinedName)) {
                    this._constantsMap.get(predefinedName).next(value);
                } else {
                    let subject = new BehaviorSubject(value);
                    this._constantsMap.set(predefinedName, subject)
                }

            });
        })
    }

    /**
     * registerModel
     */
    private registerModel<T extends OrmModel>(model: T): void {
        if (!model) {
            throw 'Модель является обязательным параметром';
        }
        let modelName = model.constructor.name;
        this._modelMap.set(modelName, model);
    }

    private createRestAdapter(): NatRestAdapter {
        let baseUrl: string = '/api';
        return new NatRestAdapter(baseUrl);
    }

    /**
    * initPredefinedObjects
    */
    public initPredefinedObjects() {
        this._controllerMap.forEach((value: NatPersistedModelController<any>) => {
            let filter = new NatFilter();
            let model = value.dataClass.prototype;
            let subject = new BehaviorSubject([]);
            filter.where.set('predefined', true);
            this._predefinedMap.set(model, subject);
            if (value.find) {
                value.find(filter).subscribe(result => {
                    subject.next(result);
                });
            }
        });
    }

    /**
    * initConstants
    */
    public initConstants() {
        let constantController: NatPersistedModelController<any> = this.getController(OrmConstant.prototype)
        constantController.find().subscribe(result => {
            this._constants.next(result);
        }, err => {
            console.log(err);
        });
    }

    /**
     * registerController
     */
    public registerController<K extends OrmModel, T extends NatMainController<K>>(controller: T): void {
        if (!controller) {
            throw 'Контроллер является обязательным параметром';
        }
        let model = controller.dataClass.prototype;
        let modelName = model.constructor.name;
        if (controller instanceof NatRestController) {
            let repository = controller.getRepository();

            if (repository && !repository.isAdapterExist()) {
                repository.restAdapter = this.createRestAdapter();
            }
        }

        this._controllerMap.set(model, controller);
        if (!this._modelMap.has(modelName)) {
            this.registerModel(model);
        }
    }

    /**
     * getModel
     */
    public getModel<M extends OrmModel>(name: string): M {
        let result = null;
        if (this._modelMap.has(name)) {
            result = this._modelMap.get(name);
        }
        return <M>result;
    }

    /**
     * getController
     */
    public getController<T extends OrmModel, K extends NatMainController<T>>(data: T | string): K {
        let result = null;
        let model: T;
        if (typeof data === 'string') {
            model = this.getModel(data);
        } else {
            model = data;
        }
        if (this._controllerMap.has(model)) {
            result = this._controllerMap.get(model);
        }
        return <K>result;
    }

    /**
     * getPredefined
     */
    public getPredefined<T extends OrmModel>(data: T | string): BehaviorSubject<Array<T>> {
        let result = null;
        let model: T;
        if (typeof data === 'string') {
            model = this.getModel(data);
        } else {
            model = data;
        }
        if (this._predefinedMap.has(model)) {
            result = this._predefinedMap.get(model);
        }
        return <BehaviorSubject<Array<T>>>result;
    }

    /**
     * getPredefined
     */
    public getPredefinedConstant(predefinedName: string): BehaviorSubject<OrmConstant> {
        let result = null;
        if (this._constantsMap.has(predefinedName)) {
            result = this._constantsMap.get(predefinedName);
        } else {
            let subject = new BehaviorSubject(null);
            this._constantsMap.set(predefinedName, subject)
        }
        return result;
    }

    /**
     * getPredefined
     */
    public getConstants(): BehaviorSubject<Array<OrmConstant>> {
        return this._constants;
    }

}