import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { OrmUser } from "@natiwi/core/models/user";

@NatModel({
    plural: 'accessTokens',
    displayName: 'Токен доступа',
    pluralDisplayName: 'Токены доступа'
})
export class OrmAccessToken extends OrmPersistedModel {
    
    //properties

    @NatProperty({
        displayName: 'Время жизни',
        type: 'number',
        required: false,
        standart: false
    })
    private _ttl: number;

    @NatProperty({
        displayName: 'Дата создания',
        type: 'date',
        required: false,
        standart: false
    })
    private _created: Date;

    @NatProperty({
        displayName: 'Идентификатор пользователя',
        type: 'string',
        required: false,
        standart: false
    })
    private _userId: string;

    @NatProperty({
        displayName: 'Пользователь',
        type: 'relation',
        relationModel: 'OrmUser',
        required: false,
        standart: false
    })
    private _user: OrmUser;

    @Expose()
    public get ttl(): number {
        return this._ttl;
    }

    public set ttl(v: number) {
        this._ttl = v;
    }

    @Expose()
    @Type(() => Date)
    public get created(): Date {
        return this._created;
    }

    public set created(v: Date) {
        this._created = v;
    }

    @Expose()
    public get userId(): string {
        return this._userId;
    }

    public set userId(v: string) {
        this._userId = v;
    }

    
    //relations
    
    @Expose()
    @Type(() => OrmUser)
    public get user(): OrmUser {
        return this._user;
    }

    public set user(v: OrmUser) {
        this._user = v;
    }

}