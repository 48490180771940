import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmOrganizationBankAccount } from "@natiwi/shared/models/organization-bank-account.model";
import { OrmResponsiblePerson } from "@natiwi/shared/models/responsible-person.model";

@NatModel({
    plural: 'organizations',
    displayName: 'Организация',
    pluralDisplayName: 'Организации'
})
@NatHierarchical(false)
export class OrmOrganization extends OrmCatalog {
    
    @NatProperty({
        displayName: 'Тип регистрации',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'partner_ownership_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Банковский счет',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmOrganizationBankAccount',
        required: false,
        standart: false
    })
    private _bankAccount: OrmOrganizationBankAccount;
    
    @NatProperty({
        displayName: 'Директор',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmResponsiblePerson',
        required: false,
        standart: false
    })
    private _director: OrmResponsiblePerson;
    
    @NatProperty({
        displayName: 'Главный бухгалтер',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmResponsiblePerson',
        required: false,
        standart: false
    })
    private _chiefAccountant: OrmResponsiblePerson;
    
    @NatProperty({
        displayName: 'ИНН',
        type: 'string',
        required: false,
        standart: false
    })
    private _itn: string;
    
    @NatProperty({
        displayName: 'КПП',
        type: 'string',
        required: false,
        standart: false
    })
    private _iec: string;
    
    @NatProperty({
        displayName: 'ОКАТО',
        type: 'string',
        required: false,
        standart: false
    })
    private _okato: string;
    
    @NatProperty({
        displayName: 'ОКТМО',
        type: 'string',
        required: false,
        standart: false
    })
    private _oktmo: string;
    
    @NatProperty({
        displayName: 'ОГРН',
        type: 'string',
        required: false,
        standart: false
    })
    private _psrn: string;
    
    constructor() {
        super();
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmOrganizationBankAccount)
    public get bankAccount(): OrmOrganizationBankAccount {
        return this._bankAccount;
    }

    public set bankAccount(v: OrmOrganizationBankAccount) {
        let oldValue = this._bankAccount;
        this._bankAccount = v;
        super.notifyFieldChange('bankAccount', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmResponsiblePerson)
    public get director(): OrmResponsiblePerson {
        return this._director;
    }

    public set director(v: OrmResponsiblePerson) {
        let oldValue = this._director;
        this._director = v;
        super.notifyFieldChange('director', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmResponsiblePerson)
    public get chiefAccountant(): OrmResponsiblePerson {
        return this._chiefAccountant;
    }

    public set chiefAccountant(v: OrmResponsiblePerson) {
        let oldValue = this._chiefAccountant;
        this._chiefAccountant = v;
        super.notifyFieldChange('chiefAccountant', oldValue, v);
    }
    
    @Expose()
    public get itn(): string {
        return this._itn;
    }

    public set itn(v: string) {
        let oldValue = this._itn;
        this._itn = v;
        super.notifyFieldChange('itn', oldValue, v);
    }
    
    @Expose()
    public get iec(): string {
        return this._iec;
    }

    public set iec(v: string) {
        let oldValue = this._iec;
        this._iec = v;
        super.notifyFieldChange('iec', oldValue, v);
    }
    
    @Expose()
    public get okato(): string {
        return this._okato;
    }

    public set okato(v: string) {
        let oldValue = this._okato;
        this._okato = v;
        super.notifyFieldChange('okato', oldValue, v);
    }
    
    @Expose()
    public get oktmo(): string {
        return this._oktmo;
    }

    public set oktmo(v: string) {
        let oldValue = this._oktmo;
        this._oktmo = v;
        super.notifyFieldChange('oktmo', oldValue, v);
    }
    
    @Expose()
    public get psrn(): string {
        return this._psrn;
    }

    public set psrn(v: string) {
        let oldValue = this._psrn;
        this._psrn = v;
        super.notifyFieldChange('psrn', oldValue, v);
    }
}