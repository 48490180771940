import * as tslib_1 from "tslib";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel } from "@natiwi/core/decorators/matadata.decorator";
let OrmContactKind = class OrmContactKind extends OrmCatalog {
    constructor() {
        super();
    }
};
OrmContactKind = tslib_1.__decorate([
    NatModel({
        plural: 'contactKinds',
        displayName: 'Вид контактной информации',
        pluralDisplayName: 'Виды контактной информации'
    })
], OrmContactKind);
export { OrmContactKind };
