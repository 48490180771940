import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmSamplingSchemePresetStructureFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-structure-field-item.model";
import { OrmSamplingSchemePresetGroupFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-group-field-item.model";
let OrmSamplingSchemePresetStructureListFieldItem = class OrmSamplingSchemePresetStructureListFieldItem extends OrmSamplingSchemePresetStructureFieldItem {
    constructor() {
        super();
    }
    get groupFieldItems() {
        return this._groupFieldItems;
    }
    set groupFieldItems(v) {
        let oldValue = this._groupFieldItems;
        this._groupFieldItems = v;
        super.notifyFieldChange('groupFieldItems', oldValue, v);
    }
    get groupFieldItemList() {
        return this._groupFieldItems;
    }
    set groupFieldItemList(v) {
        let oldValue = this._groupFieldItems;
        this._groupFieldItems = v;
        super.notifyFieldChange('groupFieldItems', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Группировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetGroupFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetStructureListFieldItem.prototype, "_groupFieldItems", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetGroupFieldItem)
], OrmSamplingSchemePresetStructureListFieldItem.prototype, "groupFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetGroupFieldItem)
], OrmSamplingSchemePresetStructureListFieldItem.prototype, "groupFieldItemList", null);
OrmSamplingSchemePresetStructureListFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Поле структуры группировки списком',
        pluralDisplayName: 'Поля структуры группировки списком'
    }),
    NatHierarchical(false)
], OrmSamplingSchemePresetStructureListFieldItem);
export { OrmSamplingSchemePresetStructureListFieldItem };
