import { Injectable } from "@angular/core";
import { OrmUser } from "@natiwi/core/models/user";
import { NatUserRepository } from "@natiwi/shared/repositories/user.repository";
import { NatPersistedModelController } from "@natiwi/core/controllers/persisted-model.controller";
import { NatPersistedModelRepository } from "@natiwi/core/network/repositories/persisted-model.repository";
import { Observable } from "rxjs";
import { OrmAccessToken } from "@natiwi/core/models/access-token";
import { NatFilterInclude } from "@natiwi/core/network/shared/filter";

export class NatUserController extends NatPersistedModelController<OrmUser>{

    constructor(_repository?: NatUserRepository) {
        super(_repository);
        super.registerDataClass(OrmUser);
    }

    /**
     * login
     */
    public login(email: string, password: string, includeUser: boolean = false): Observable<OrmAccessToken> {
        let repo: NatUserRepository = this.getRepository();
        let params: Map<string, any> = new Map();
        if (includeUser) {
            params.set('include', 'user');
        }
        return repo.login(email, password, params);
    }

    /**
     * process
     */
    public process(data: OrmUser): Observable<OrmUser> {
        let repo: NatUserRepository = this.getRepository();
        return repo.process(data);
    }

}