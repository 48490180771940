import * as tslib_1 from "tslib";
import { NatPersistedModelRepository } from "@natiwi/core/network/repositories/persisted-model.repository";
import { map } from "rxjs/operators";
import { Type as CTType, plainToClassFromExist, Expose, plainToClass, classToPlain } from "class-transformer";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
export class NatObjectRepository extends NatPersistedModelRepository {
    constructor(adapter) {
        super(adapter);
        super.createСontractItem('process', '/process', NatVerbType.POST);
        super.createСontractItem('makeDeleted', '/makeDeleted', NatVerbType.POST);
        super.createСontractItem('viewList', '/viewList');
    }
    viewList(params) {
        let stringifyParams = new Map();
        if (params && params.size) {
            params.forEach((value, key) => {
                stringifyParams.set(key, JSON.stringify(classToPlain(value, { excludePrefixes: ["_"] })));
            });
        }
        let result = this.invokeStaticMethod('viewList', stringifyParams);
        return result.pipe(map(response => {
            return plainToClassFromExist(new ViewListResponse(this.dataClass), response, {
                excludeExtraneousValues: true
            });
            ;
        }));
    }
    process(data) {
        let stringlifyData = JSON.stringify(classToPlain(data, { excludePrefixes: ["_"] }));
        let result = this.invokeStaticMethod('process', null, stringlifyData);
        return result.pipe(map(response => {
            return plainToClass(this.dataClass, response, {
                excludeExtraneousValues: true
            });
        }));
    }
    makeDeleted(mode, object) {
        let stringlifyBodyData = JSON.stringify(classToPlain(object, { excludePrefixes: ["_"] }));
        let stringifyParams = new Map();
        stringifyParams.set('mode', `${mode}`);
        stringifyParams.set('id', object.id);
        let result = this.invokeStaticMethod('makeDeleted', stringifyParams, stringlifyBodyData);
        return result.pipe(map(response => {
            return plainToClass(this.dataClass, response, {
                excludeExtraneousValues: true
            });
        }));
    }
}
const ɵ0 = options => options.newObject.type, ɵ1 = () => ViewListOutputInfoResponse;
export class ViewListResponse {
    constructor(type, output, outputInfo) {
        this._output = output;
        this._outputInfo = outputInfo;
        this.type = type;
    }
    get output() {
        return this._output;
    }
    set output(value) {
        this._output = value;
    }
    get outputInfo() {
        return this._outputInfo;
    }
    set outputInfo(value) {
        this._outputInfo = value;
    }
}
tslib_1.__decorate([
    Expose(),
    CTType(ɵ0)
], ViewListResponse.prototype, "output", null);
tslib_1.__decorate([
    Expose(),
    CTType(ɵ1)
], ViewListResponse.prototype, "outputInfo", null);
export class ViewListOutputInfoResponse {
    constructor(count, totalCount) {
        this._count = 0;
        this._totalCount = 0;
        this._count = count;
        this._totalCount = totalCount;
    }
    get count() {
        return this._count;
    }
    set count(value) {
        this._count = value;
    }
    get totalCount() {
        return this._totalCount;
    }
    set totalCount(value) {
        this._totalCount = value;
    }
}
tslib_1.__decorate([
    Expose()
], ViewListOutputInfoResponse.prototype, "count", null);
tslib_1.__decorate([
    Expose()
], ViewListOutputInfoResponse.prototype, "totalCount", null);
export { ɵ0, ɵ1 };
