import { NatOrder } from "@natiwi/core/network/shared/network.enum";
import { Expose, Transform, Type } from "class-transformer";
import { NatRestController } from "@natiwi/core/controllers/rest.controller";
import { OrmModel } from "@natiwi/core/models/model";

export class NatFilter {

    private _where: NatFilterWhere;
    private _include: Array<NatFilterInclude>;
    private _order: Array<NatFilterOrder>;
    private _skip: number;
    private _limit: number;
    private _aggregate: Array<NatFilterAggregate>;
    private _fields: NatFilterFields;

    constructor() { }

    @Expose()
    @Transform((value, obj, type) => {
        if (value && !value.size) {
            return undefined;
        }
        return value;
    })
    public get where(): NatFilterWhere {
        if (!this._where) {
            this._where = new NatFilterWhere();
        }
        return this._where;
    }

    public set where(v: NatFilterWhere) {
        this._where = v;
    }

    @Expose()
    @Transform(value => {
        if (value && !value.length) {
            return undefined;
        }
        return value;
    })
    public get include(): Array<NatFilterInclude> {
        if (!this._include) {
            this._include = new Array();
        }
        return this._include;
    }

    public set include(v: Array<NatFilterInclude>) {
        this._include = v;
    }
    @Expose()
    @Transform(value => {
        if (value && !value.length) {
            return undefined;
        }
        let orderList = new Array<string>();
        value.forEach(element => {
            let order = `${element.property} ${element.order}`;
            orderList.push(order);
        });
        return orderList;
    })
    public get order(): Array<NatFilterOrder> {
        if (!this._order) {
            this._order = new Array();
        }
        return this._order;
    }

    public set order(v: Array<NatFilterOrder>) {
        this._order = v;
    }
    @Expose()
    public get skip(): number {
        return this._skip;
    }

    public set skip(v: number) {
        this._skip = v;
    }
    @Expose()
    public get limit(): number {
        return this._limit;
    }

    public set limit(v: number) {
        this._limit = v;
    }
    @Expose()
    @Transform(value => {
        if (value && !value.size) {
            return undefined;
        }
        return value;
    })
    @Expose()
    @Transform(value => {
        if (value && !value.size) {
            return undefined;
        }
        return value;
    })
    public get aggregate(): Array<NatFilterAggregate> {
        if (!this._aggregate) {
            this._aggregate = new Array();
        }
        return this._aggregate;
    }

    public set aggregate(v: Array<NatFilterAggregate>) {
        this._aggregate = v;
    }
    @Expose()
    @Transform(value => {
        if (value && !value.size) {
            return undefined;
        }
        return value;
    })
    public get fields(): NatFilterFields {
        if (!this._fields) {
            this._fields = new NatFilterFields();
        }
        return this._fields;
    }

    public set fields(v: NatFilterFields) {
        this._fields = v;
    }

    /**
     * merge
     */
    public merge(item: NatFilter): NatFilter {
        let filter = this;
        let mergedFilter = new NatFilter();

        if (filter.where || item.where) {
            let andOperator = new NatFilterOperatorAnd();
            andOperator.push(item.where, filter.where)
            mergedFilter.where.set('and', andOperator);
        }
        if (filter.include || item.include) {
            mergedFilter.include = filter.include.concat(filter.include, item.include);
        }
        if (filter.order || item.order) {
            mergedFilter.order = filter.order.concat(filter.order, item.order);
        }
        if (filter.skip || item.skip) {
            mergedFilter.skip = item.skip;
        }
        if (filter.limit || item.limit) {
            mergedFilter.limit = item.limit;
        }
        return mergedFilter
    }

}

export class NatFilterWhere extends Map<string, any>{

    constructor() {
        super();
    }

}
export class NatFilterInclude {

    private _relation: string;
    private _scope: NatFilter;

    constructor(relation: string, scope?: NatFilter) {
        this.relation = relation;
        this.scope = scope;
    }
    @Expose()
    public get relation(): string {
        return this._relation;
    }

    public set relation(v: string) {
        this._relation = v;
    }
    @Expose()
    @Transform(value => {
        if (!value) {
            return undefined;
        }
        return value;
    })
    public get scope(): NatFilter {
        // if (!this._scope) {
        //     this._scope = new NatFilter();
        // }
        return this._scope;
    }

    public set scope(v: NatFilter) {
        this._scope = v;
    }

}
export class NatFilterOrder {

    private _property: string;
    private _order: NatOrder;

    constructor(property: string, order: NatOrder = NatOrder.ASC) {
        this._property = property;
        this._order = order;
    }

    public get property(): string {
        return this._property;
    }

    public get order(): NatOrder {
        return this._order;
    }

}
@Expose()
class NatFilterAggregate {

    [key: string]: any;

    constructor() { }

}
@Expose()
class NatFilterFields {

    constructor() { }

}

// export class NatFilterOperatorAnd extends NatFilterWhere {

//     constructor() {
//         super();
//     }

//     set(value: any): this {
//         let values = []
//         if (super.has('and')) {
//             values = super.get('and');
//         }
//         values.push(value);
//         return super.set('and', values);
//     }

// }

export class NatFilterOperatorRegExp extends NatFilterWhere {

    constructor() {
        super();
    }

    public set(value: string, flags: Array<string> = ['i', 'g']): this {
        let regexpFlags = flags.join('');
        let regexp = new RegExp(value, regexpFlags);
        return super.set('regexp', regexp);
    }

}


export class NatFilterOperatorAnd extends Array<NatFilterWhere> {

    constructor() {
        super();
    }

}

export class NatFilterOperatorOr extends Array<NatFilterWhere> {

    constructor() {
        super();
    }
}

export class NatFilterOperatorNeq extends NatFilterWhere {

    constructor() {
        super();
    }

    set(key: string, value: any): this {
        let newValue = {
            neq: value
        }
        return super.set(key, newValue);
    }

}

export class NatFilterOperatorGt extends NatFilterWhere {

    constructor() {
        super();
    }

    set(key: string, value: any): this {
        let newValue = {
            gt: value
        }
        return super.set(key, newValue);
    }

}

export class NatFilterOperatorLt extends NatFilterWhere {
    
        constructor() {
            super();
        }
    
        set(key: string, value: any): this {
            let newValue = {
                lt: value
            }
            return super.set(key, newValue);
        }
    
    }



export class NatFilterOperatorInq {

    private inq: Array<any>;

    constructor() {
        this.inq = new Array();
    }

    /**
     * push
     */
    public push(...el: any[]) {
        this.inq = this.inq.concat(el);
    }

}

class NatFilterOperatorNin extends Array<any>{

    constructor() {
        super();
    }

}