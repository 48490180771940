import { Injectable } from "@angular/core";
import { NatDocumentController } from "@natiwi/core/controllers/document.controller";
import { OrmSettingPricesOfProducts } from "@natiwi/shared/models/setting-prices-of-products.model";
import { NatSettingPricesOfProductsRepository } from "@natiwi/shared/repositories/setting-prices-of-products.repository";

export class NatSettingPricesOfProductsController extends NatDocumentController<OrmSettingPricesOfProducts>{

    constructor(_repository?: NatSettingPricesOfProductsRepository) {
        super(_repository);
        super.registerDataClass(OrmSettingPricesOfProducts);
    }
}