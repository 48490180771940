import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nat-menu-body',
  templateUrl: './main-menu-body.component.html',
  styleUrls: ['./main-menu-body.component.css']
})
export class NatMenuBodyComponent implements OnInit {
  constructor(
    private route: Router,
    public dialogRef: MatDialogRef<NatMenuBodyComponent>
  ) {
    this.routeEvent(this.route);
  }

  ngOnInit() {
  }

  closeMenu() {
    this.dialogRef.close();
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.closeMenu();
      }
    });
  }
}
