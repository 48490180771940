import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmCashBox } from "@natiwi/shared/models/cash-box.model";

export class NatCashBoxRepository extends NatCatalogRepository<OrmCashBox> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/cashBoxs';
        let repoModel: Type<OrmCashBox> = OrmCashBox;
        this.init(repoUrl, repoModel);
    }
}