import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmReport } from "@natiwi/shared/models/report.model";
import { NatReportRepository, NatReportExecuteResponse } from "@natiwi/shared/repositories/report.repository";
import { Observable, throwError } from "rxjs";
import { OrmReportPreset } from "@natiwi/shared/models/report-preset.model";

export class NatReportController extends NatCatalogController<OrmReport>{

    constructor(_repository?: NatReportRepository) {
        super(_repository);
        super.registerDataClass(OrmReport);
    }
    
    /**
     * execute
     */
    public execute(reportId: string, preset: OrmReportPreset): Observable<NatReportExecuteResponse> {
        if (!reportId) {
            return throwError( 'Идентификатор не задан');
        }
        if (!preset) {
            return throwError( 'Настройки отчета не заданы');
        }
        let repo: NatReportRepository = this.getRepository();
        return repo.execute(reportId, preset);
    }
}