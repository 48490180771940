import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
let OrmSamplingSchemeDataSetItem = class OrmSamplingSchemeDataSetItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get name() {
        return this._name;
    }
    set name(v) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    get targetModel() {
        return this._targetModel;
    }
    set targetModel(v) {
        let oldValue = this._targetModel;
        this._targetModel = v;
        super.notifyFieldChange('targetModel', oldValue, v);
    }
    get query() {
        return this._query;
    }
    set query(v) {
        let oldValue = this._query;
        this._query = v;
        super.notifyFieldChange('query', oldValue, v);
    }
    get fieldItems() {
        return this._fieldItems;
    }
    set fieldItems(v) {
        let oldValue = this._fieldItems;
        this._fieldItems = v;
        super.notifyFieldChange('fieldItems', oldValue, v);
    }
    get fieldItemList() {
        return this._fieldItems;
    }
    set fieldItemList(v) {
        let oldValue = this._fieldItems;
        this._fieldItems = v;
        super.notifyFieldChange('fieldItems', oldValue, v);
    }
    /**
  * toString
  */
    toString() {
        return this._name || '';
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: true,
        standart: false
    })
], OrmSamplingSchemeDataSetItem.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Целевая модель',
        type: 'string',
        required: true,
        standart: false
    })
], OrmSamplingSchemeDataSetItem.prototype, "_targetModel", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Запрос',
        type: 'string',
        required: false,
        standart: false
    })
], OrmSamplingSchemeDataSetItem.prototype, "_query", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поля',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemeDataSetItem.prototype, "_fieldItems", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeDataSetItem.prototype, "name", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeDataSetItem.prototype, "targetModel", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeDataSetItem.prototype, "query", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemeDataSetItem.prototype, "fieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemeDataSetItem.prototype, "fieldItemList", null);
OrmSamplingSchemeDataSetItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Набор данных',
        pluralDisplayName: 'Наборы данных'
    }),
    NatHierarchical(false)
], OrmSamplingSchemeDataSetItem);
export { OrmSamplingSchemeDataSetItem };
