import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { natPlainToClass, natClassToPlain } from "@natiwi/core/network/repositories/rest.repository";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { plainToClass, classToPlain } from "class-transformer";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { Type } from "@angular/core";
import { NatModelRepository } from "@natiwi/core/network/repositories/model.repository";

export abstract class NatPersistedModelRepository<T extends OrmPersistedModel> extends NatModelRepository<T> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);

        super.createСontractItem('findById', '/:id');
        super.createСontractItem('bulkUpsert', '/bulkUpsert', NatVerbType.POST);
        super.createСontractItem('create', '/', NatVerbType.POST);
        super.createСontractItem('save', '/:id', NatVerbType.PUT);
        super.createСontractItem('aggregate', '/aggregate');

    }

    public findById(id: string, params?: Map<string, any>): Observable<T> {
        if (!id || !id.length) {
            return throwError('Параметр id является обязательным');
        }
        let stringifyParams: Map<string, string> = new Map<string, string>();
        stringifyParams.set('id', id);
        if (params && params.size) {
            params.forEach((value: any, key: string) => {
                let plainParams = classToPlain(value, { excludePrefixes: ["_"], enableCircularCheck: true })
                stringifyParams.set(key, JSON.stringify(plainParams));
            });
        }
        let request: Observable<T> = this.invokeStaticMethod<T>('findById', stringifyParams);
        return request.pipe(map(response => {
            return natPlainToClass(this.dataClass, response) as T;
        }));
    }

    public bulkUpsert(data: Array<T>): Observable<Array<T>> {
        let stringlifyData: string = natClassToPlain(this.dataClass, data);
        let request: Observable<Array<T>> = this.invokeStaticMethod<Array<T>>('bulkUpsert', null, stringlifyData);
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as Array<T>;
        }));
    }

    public saveOrCreate(object: T): Observable<T> {
        let stringlifyData: string = JSON.stringify(classToPlain(object, { excludePrefixes: ["_"] }));
        let request: Observable<T>;
        if (object.isNew()) {
            request = this.invokeStaticMethod<T>('create', null, stringlifyData);
        } else {
            let stringifyParams: Map<string, string> = new Map<string, string>();
            stringifyParams.set('id', object.id);
            request = this.invokeStaticMethod<T>('save', stringifyParams, stringlifyData);
        }
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as T;;
        }));
    }

    public aggregate(params?: Map<string, any>): Observable<Array<T>> {
        let stringifyParams: Map<string, string> = new Map<string, string>();
        params.forEach((value: any, key: string) => {
            stringifyParams.set(key, JSON.stringify(classToPlain(value, { excludePrefixes: ["_"] })));
        });
        let request: Observable<Array<T>> = this.invokeStaticMethod<Array<T>>('aggregate', stringifyParams);
        return request.pipe(map(response => {
            return plainToClass(this.dataClass, response,
                {
                    excludeExtraneousValues: true
                }
            ) as Array<T>;
        }));
    }
}