import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NatAppService, NatAuthService,
  NatRouteReuseStrategyService, NatAuthInterceptor, NatNavigationService
} from '@natiwi/core/services';
import * as globals from '@natiwi/core/network/shared/global-variables';
import { NatNotificationService } from '@natiwi/core/services/notification.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    MatSnackBarModule
  ],
  exports: [],
  providers: [
    NatAppService,
    NatAuthService,
    NatNavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NatAuthInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: NatRouteReuseStrategyService
    },
    NatNotificationService
  ]
})
export class NatCoreModule {
  constructor(injector: Injector) {
    globals.setGlobalInjector(injector);
  }
  
}
