import { NatInformationRegisterController } from "@natiwi/core/controllers/information-register.controller";
import { NatPartnerContactRepository } from "@natiwi/shared/repositories/partner-contact.repository";
import { OrmPartnerContact } from "@natiwi/shared/models/partner-contact.model";

export class NatPartnerContactController extends NatInformationRegisterController<OrmPartnerContact>{

    constructor(_repository?: NatPartnerContactRepository) {
        super(_repository);
        super.registerDataClass(OrmPartnerContact);
    }
}