import { NatObjectRepository } from "@natiwi/core/network/repositories/object.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmDocument } from "@natiwi/core/models/document";
import { NatVerbType } from "@natiwi/core/network/shared/network.enum";
import { Observable } from "rxjs";
import { classToPlain, plainToClass } from "class-transformer";
import { map } from "rxjs/operators";
import { Type } from "@angular/core";
import { natClassToPlain, natPlainToClass } from "@natiwi/core/network/repositories/rest.repository";

export abstract class NatDocumentRepository<T extends OrmDocument> extends NatObjectRepository<T> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        super.createСontractItem('makePosted', '/makePosted?mode=:mode', NatVerbType.POST);
    }

    public makePosted(mode: string, object: T): Observable<T> {
        let stringlifyBodyData: string = natClassToPlain(this.dataClass, object);//JSON.stringify(classToPlain(object, { excludePrefixes: ["_"] }));
        let stringifyParams: Map<string, string> = new Map<string, string>();
        stringifyParams.set('mode', mode);

        let result: Observable<T> = this.invokeStaticMethod<T>('makePosted', stringifyParams, stringlifyBodyData);
        return result.pipe(map(response => {
            return natPlainToClass(this.dataClass, response) as T;
        }));
    }
}