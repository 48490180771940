import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";

export class NatPriceTypeRepository extends NatCatalogRepository<OrmPriceType> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/priceTypes';
        let repoModel: Type<OrmPriceType> = OrmPriceType;
        this.init(repoUrl, repoModel);
    }
}