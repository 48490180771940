import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmWarehouse } from "@natiwi/shared/models/warehouse.model";
import { NatWarehouseRepository } from "@natiwi/shared/repositories/warehouse.repository";

export class NatWarehouseController extends NatCatalogController<OrmWarehouse>{

    constructor(_repository?: NatWarehouseRepository) {
        super(_repository);
        super.registerDataClass(OrmWarehouse);
    }
}