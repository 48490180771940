'use strict';
import { Injector } from "@angular/core";

export let GlobalInjector: Injector;

export function setGlobalInjector(injector: Injector) {
    GlobalInjector = injector;
}


export let GobalModelStore: Map<string, Object> = new Map();
export function setModelToGlobalStore(modelName: string, model: Object) {
    if (modelName && model && model instanceof Object) {
        GobalModelStore.set(modelName, model);
    } else {
        console.warn('Ошибка добавления модели в глобальное хранилище');
    }
}

export function getModelFromGlobalStore(modelName: string): Object {
    if (!modelName) {
        throw 'Имя модели обязательно';
    }
    if (GobalModelStore.has(modelName)) {
        return GobalModelStore.get(modelName);
    } else {
        console.warn(`Модель с именем "${modelName}" не найдена`);
        return null
    }
}