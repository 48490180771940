import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmCashBox } from "@natiwi/shared/models/cash-box.model";
import { NatCashBoxRepository } from "@natiwi/shared/repositories/cash-box.repository";

export class NatCashBoxController extends NatCatalogController<OrmCashBox>{

    constructor(_repository?: NatCashBoxRepository) {
        super(_repository);
        super.registerDataClass(OrmCashBox);
    }
}