import * as tslib_1 from "tslib";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { Expose, Type } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPartnerContract } from "@natiwi/shared/models/partner-contract.model";
const ɵ0 = () => Date;
let OrmPartner = class OrmPartner extends OrmCatalog {
    // "bankAccountId": {
    //     "displayName": "Идентификатор банковского счета",
    //     "type": "string",
    //     "default": null
    // },
    constructor() {
        super();
    }
    get firstName() {
        return this._firstName;
    }
    set firstName(v) {
        let oldValue = this._firstName;
        this._firstName = v;
        super.notifyFieldChange('firstName', oldValue, v);
    }
    get middleName() {
        return this._middleName;
    }
    set middleName(v) {
        let oldValue = this._middleName;
        this._middleName = v;
        super.notifyFieldChange('middleName', oldValue, v);
    }
    get lastName() {
        return this._lastName;
    }
    set lastName(v) {
        let oldValue = this._lastName;
        this._lastName = v;
        super.notifyFieldChange('lastName', oldValue, v);
    }
    get priceType() {
        return this._priceType;
    }
    set priceType(v) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    get ownershipType() {
        return this._ownershipType;
    }
    set ownershipType(v) {
        let oldValue = this._ownershipType;
        this._ownershipType = v;
        super.notifyFieldChange('ownershipType', oldValue, v);
    }
    get gender() {
        return this._gender;
    }
    set gender(v) {
        let oldValue = this._gender;
        this._gender = v;
        super.notifyFieldChange('gender', oldValue, v);
    }
    // @Expose()
    // @NatRelation(OrmPartnerContactSet)
    // public get conctactSet(): OrmPartnerContactSet {
    //     return this._conctactSet;
    // }
    // public set conctactSet(v: OrmPartnerContactSet) {
    //     let oldValue = this._conctactSet;
    //     this._conctactSet = v;
    //     super.notifyFieldChange('conctactSet', oldValue, v);
    // }
    get contract() {
        return this._contract;
    }
    set contract(v) {
        let oldValue = this._contract;
        this._contract = v;
        super.notifyFieldChange('contract', oldValue, v);
    }
    get client() {
        return this._client;
    }
    set client(v) {
        let oldValue = this._client;
        this._client = v;
        super.notifyFieldChange('client', oldValue, v);
    }
    get supplier() {
        return this._supplier;
    }
    set supplier(v) {
        let oldValue = this._supplier;
        this._supplier = v;
        super.notifyFieldChange('supplier', oldValue, v);
    }
    get otherRelations() {
        return this._otherRelations;
    }
    set otherRelations(v) {
        let oldValue = this._otherRelations;
        this._otherRelations = v;
        super.notifyFieldChange('otherRelations', oldValue, v);
    }
    get birthdate() {
        return this._birthdate;
    }
    set birthdate(v) {
        let oldValue = this._birthdate;
        this._birthdate = v;
        super.notifyFieldChange('birthdate', oldValue, v);
    }
    get itn() {
        return this._itn;
    }
    set itn(v) {
        let oldValue = this._itn;
        this._itn = v;
        super.notifyFieldChange('itn', oldValue, v);
    }
    get iec() {
        return this._iec;
    }
    set iec(v) {
        let oldValue = this._iec;
        this._iec = v;
        super.notifyFieldChange('iec', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_firstName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Отчество',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_middleName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Фамилия',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_lastName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPriceType',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_priceType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип регистрации',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'partner_types',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип владения',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'partner_ownership_types',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_ownershipType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Пол',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'gender',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_gender", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Основной договор',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPartnerContract',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_contract", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Клиент',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_client", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поставщик',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_supplier", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Прочие отношения',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_otherRelations", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата рождения',
        type: 'date',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_birthdate", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'ИНН',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_itn", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'КПП',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPartner.prototype, "_iec", void 0);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "firstName", null);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "middleName", null);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "lastName", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType)
], OrmPartner.prototype, "priceType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPartner.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPartner.prototype, "ownershipType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPartner.prototype, "gender", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPartnerContract)
], OrmPartner.prototype, "contract", null);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "client", null);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "supplier", null);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "otherRelations", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmPartner.prototype, "birthdate", null);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "itn", null);
tslib_1.__decorate([
    Expose()
], OrmPartner.prototype, "iec", null);
OrmPartner = tslib_1.__decorate([
    NatModel({
        plural: 'partners',
        displayName: 'Партнер',
        pluralDisplayName: 'Партнеры'
    })
], OrmPartner);
export { OrmPartner };
export { ɵ0 };
