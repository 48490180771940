import { Injectable, Type } from "@angular/core";
import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmOrganization } from "@natiwi/shared/models/organization.model";

export class NatOrganizationRepository extends NatCatalogRepository<OrmOrganization> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/organizations';
        let repoModel: Type<OrmOrganization> = OrmOrganization;
        this.init(repoUrl, repoModel);
    }
}