import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";
let OrmOrganizationBankAccount = class OrmOrganizationBankAccount extends OrmCatalog {
    constructor() {
        super();
    }
    get bankCode() {
        return this._bankCode;
    }
    set bankCode(v) {
        let oldValue = this._bankCode;
        this._bankCode = v;
        super.notifyFieldChange('bankCode', oldValue, v);
    }
    get bankName() {
        return this._bankName;
    }
    set bankName(v) {
        let oldValue = this._bankName;
        this._bankName = v;
        super.notifyFieldChange('bankName', oldValue, v);
    }
    get bankCorrAccount() {
        return this._bankCorrAccount;
    }
    set bankCorrAccount(v) {
        let oldValue = this._bankCorrAccount;
        this._bankCorrAccount = v;
        super.notifyFieldChange('bankCorrAccount', oldValue, v);
    }
    get bankCity() {
        return this._bankCity;
    }
    set bankCity(v) {
        let oldValue = this._bankCity;
        this._bankCity = v;
        super.notifyFieldChange('bankCity', oldValue, v);
    }
    get bankAddress() {
        return this._bankAddress;
    }
    set bankAddress(v) {
        let oldValue = this._bankAddress;
        this._bankAddress = v;
        super.notifyFieldChange('bankAddress', oldValue, v);
    }
    get bankPhone() {
        return this._bankPhone;
    }
    set bankPhone(v) {
        let oldValue = this._bankPhone;
        this._bankPhone = v;
        super.notifyFieldChange('bankPhone', oldValue, v);
    }
    get bankSwiftCode() {
        return this._bankSwiftCode;
    }
    set bankSwiftCode(v) {
        let oldValue = this._bankSwiftCode;
        this._bankSwiftCode = v;
        super.notifyFieldChange('bankSwiftCode', oldValue, v);
    }
    get bankItn() {
        return this._bankItn;
    }
    set bankItn(v) {
        let oldValue = this._bankItn;
        this._bankItn = v;
        super.notifyFieldChange('bankItn', oldValue, v);
    }
    get accountNumber() {
        return this._accountNumber;
    }
    set accountNumber(v) {
        let oldValue = this._accountNumber;
        this._accountNumber = v;
        super.notifyFieldChange('accountNumber', oldValue, v);
    }
    get correspondentText() {
        return this._correspondentText;
    }
    set correspondentText(v) {
        let oldValue = this._correspondentText;
        this._correspondentText = v;
        super.notifyFieldChange('correspondentText', oldValue, v);
    }
    get destinationText() {
        return this._destinationText;
    }
    set destinationText(v) {
        let oldValue = this._destinationText;
        this._destinationText = v;
        super.notifyFieldChange('destinationText', oldValue, v);
    }
    get bankBIC() {
        return this._bankBIC;
    }
    set bankBIC(v) {
        let oldValue = this._bankBIC;
        this._bankBIC = v;
        super.notifyFieldChange('bankBIC', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Код банка',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankCode", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Наименование банка для расчетов',
        type: 'string',
        required: true,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Корр. счет банка для расчетов',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankCorrAccount", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Город банка',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankCity", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Адрес банка',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankAddress", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Телефон банка',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankPhone", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'SWIFT код банка',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankSwiftCode", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'ИНН Банка',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankItn", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Номер счета',
        type: 'string',
        required: true,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_accountNumber", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Текст корреспондента',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_correspondentText", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Текст назначения',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_destinationText", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'БИК банка',
        type: 'string',
        required: false,
        standart: false
    })
], OrmOrganizationBankAccount.prototype, "_bankBIC", void 0);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankCode", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankName", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankCorrAccount", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankCity", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankAddress", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankPhone", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankSwiftCode", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankItn", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "accountNumber", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "correspondentText", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "destinationText", null);
tslib_1.__decorate([
    Expose()
], OrmOrganizationBankAccount.prototype, "bankBIC", null);
OrmOrganizationBankAccount = tslib_1.__decorate([
    NatModel({
        plural: 'organizationBankAccounts',
        displayName: 'Банковский счет организации',
        pluralDisplayName: 'Банковские счета организаций'
    }),
    NatHierarchical(false)
], OrmOrganizationBankAccount);
export { OrmOrganizationBankAccount };
