import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical } from "@natiwi/core/decorators/matadata.decorator";

@NatModel({
    plural: 'cashBoxs',
    displayName: 'Касса',
    pluralDisplayName: 'Кассы'
})
@NatHierarchical(false)
export class OrmCashBox extends OrmCatalog {

    constructor() {
        super();
    }
}