import { Expose } from "class-transformer";

export class NatModelMetadata {
    private _modelName: string;
    private _plural: string;
    private _displayName: string;
    private _pluralDisplayName: string;
    private _properties: Map<string, NatModelPropertyMetadata>;
    private _propertyList: Array<NatModelPropertyMetadata>;

    constructor(data: { [key: string]: any }) {
        this._modelName = data.modelName;
        this._plural = data.plural;
        this._displayName = data.displayName;
        this._pluralDisplayName = data.pluralDisplayName;
        this._propertyList = new Array();
        if (data && data.propertyList && data.propertyList instanceof Array) {
            data.propertyList.forEach(element => {
                if (element && element.propertyName) {
                    let property = new NatModelPropertyMetadata(element);
                    this._propertyList.push(property);
                }
            });
        }
        if (!this._properties) {
            this._properties = new Map();
            for (let prop of this._propertyList) {
                if (prop && prop.propertyName) {
                    this._properties.set(prop.propertyName, prop);
                }
            }
        }
    }
    @Expose()
    public get modelName(): string {
        return this._modelName;
    }
    @Expose()
    public get plural(): string {
        return this._plural;
    }
    @Expose()
    public get displayName(): string {
        return this._displayName;
    }
    @Expose()
    public get pluralDisplayName(): string {
        return this._pluralDisplayName;
    }

    /**
     * getProperties
     */
    public getProperties(): Array<NatModelPropertyMetadata> {
        return this._propertyList || null;
    }

    /**
     * getProperty
     */
    public getProperty(propertyName: string): NatModelPropertyMetadata {
        return this._properties.has(propertyName) ? this._properties.get(propertyName) : null;
    }

}

export class NatModelPropertyMetadata {
    private _propertyName: string;
    private _displayName: string;
    private _type: string;
    private _relationType: string;
    private _relationModel: string;
    private _enumerationType: string;
    private _enumerationId: string;
    private _required: boolean;
    private _standart: boolean;

    constructor(data: { [key: string]: any }) {
        if (!data) {
            console.error('Нет данных для формирования метаданных');
            return;
        }
        this._propertyName = data.propertyName;
        this._displayName = data.displayName;
        this._type = data.type;
        this._relationType = data.relationType;
        this._relationModel = data.relationModel;
        this._enumerationType = data.enumerationType;
        this._enumerationId = data.enumerationId;
        this._required = data.required || false;
        this._standart = data.standart || false;
        if (!this._propertyName) {
            console.error('Метаданные свойства не корректны: имя свойства не указано');
        }
    }

    @Expose()
    public get propertyName(): string {
        return this._propertyName;
    }

    @Expose()
    public get displayName(): string {
        return this._displayName;
    }

    @Expose()
    public get type(): string {
        return this._type;
    }
    
    @Expose()
    public get relationType(): string {
        return this._relationType;
    }
    
    @Expose()
    public get relationModel(): string {
        return this._relationModel;
    }
    
    @Expose()
    public get enumerationType(): string {
        return this._enumerationType;
    }
    
    @Expose()
    public get enumerationId(): string {
        return this._enumerationId;
    }
    
    @Expose()
    public get required(): boolean {
        return this._required;
    }

    @Expose()
    public get standart(): boolean {
        return this._standart;
    }
}