import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    plural: 'organizationBankAccounts',
    displayName: 'Банковский счет организации',
    pluralDisplayName: 'Банковские счета организаций'
})
@NatHierarchical(false)
export class OrmOrganizationBankAccount extends OrmCatalog {

    @NatProperty({
        displayName: 'Код банка',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankCode: string;

    @NatProperty({
        displayName: 'Наименование банка для расчетов',
        type: 'string',
        required: true,
        standart: false
    })
    private _bankName: string;
    
    @NatProperty({
        displayName: 'Корр. счет банка для расчетов',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankCorrAccount: string;
    
    @NatProperty({
        displayName: 'Город банка',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankCity: string;
    
    @NatProperty({
        displayName: 'Адрес банка',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankAddress: string;
    
    @NatProperty({
        displayName: 'Телефон банка',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankPhone: string;
    
    @NatProperty({
        displayName: 'SWIFT код банка',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankSwiftCode: string;

    @NatProperty({
        displayName: 'ИНН Банка',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankItn: string;
    
    @NatProperty({
        displayName: 'Номер счета',
        type: 'string',
        required: true,
        standart: false
    })
    private _accountNumber: string;
    
    @NatProperty({
        displayName: 'Текст корреспондента',
        type: 'string',
        required: false,
        standart: false
    })
    private _correspondentText: string;
    
    @NatProperty({
        displayName: 'Текст назначения',
        type: 'string',
        required: false,
        standart: false
    })
    private _destinationText: string;
    
    @NatProperty({
        displayName: 'БИК банка',
        type: 'string',
        required: false,
        standart: false
    })
    private _bankBIC: string;
    
    constructor() {
        super();
    }

    @Expose()
    public get bankCode(): string {
        return this._bankCode;
    }

    public set bankCode(v: string) {
        let oldValue = this._bankCode;
        this._bankCode = v;
        super.notifyFieldChange('bankCode', oldValue, v);
    }
    
    @Expose()
    public get bankName(): string {
        return this._bankName;
    }

    public set bankName(v: string) {
        let oldValue = this._bankName;
        this._bankName = v;
        super.notifyFieldChange('bankName', oldValue, v);
    }
    
    @Expose()
    public get bankCorrAccount(): string {
        return this._bankCorrAccount;
    }

    public set bankCorrAccount(v: string) {
        let oldValue = this._bankCorrAccount;
        this._bankCorrAccount = v;
        super.notifyFieldChange('bankCorrAccount', oldValue, v);
    }
    
    @Expose()
    public get bankCity(): string {
        return this._bankCity;
    }

    public set bankCity(v: string) {
        let oldValue = this._bankCity;
        this._bankCity = v;
        super.notifyFieldChange('bankCity', oldValue, v);
    }
    
    @Expose()
    public get bankAddress(): string {
        return this._bankAddress;
    }

    public set bankAddress(v: string) {
        let oldValue = this._bankAddress;
        this._bankAddress = v;
        super.notifyFieldChange('bankAddress', oldValue, v);
    }
    
    @Expose()
    public get bankPhone(): string {
        return this._bankPhone;
    }

    public set bankPhone(v: string) {
        let oldValue = this._bankPhone;
        this._bankPhone = v;
        super.notifyFieldChange('bankPhone', oldValue, v);
    }
    
    @Expose()
    public get bankSwiftCode(): string {
        return this._bankSwiftCode;
    }

    public set bankSwiftCode(v: string) {
        let oldValue = this._bankSwiftCode;
        this._bankSwiftCode = v;
        super.notifyFieldChange('bankSwiftCode', oldValue, v);
    }
    
    @Expose()
    public get bankItn(): string {
        return this._bankItn;
    }

    public set bankItn(v: string) {
        let oldValue = this._bankItn;
        this._bankItn = v;
        super.notifyFieldChange('bankItn', oldValue, v);
    }
    
    @Expose()
    public get accountNumber(): string {
        return this._accountNumber;
    }

    public set accountNumber(v: string) {
        let oldValue = this._accountNumber;
        this._accountNumber = v;
        super.notifyFieldChange('accountNumber', oldValue, v);
    }
    
    @Expose()
    public get correspondentText(): string {
        return this._correspondentText;
    }

    public set correspondentText(v: string) {
        let oldValue = this._correspondentText;
        this._correspondentText = v;
        super.notifyFieldChange('correspondentText', oldValue, v);
    }
    
    @Expose()
    public get destinationText(): string {
        return this._destinationText;
    }

    public set destinationText(v: string) {
        let oldValue = this._destinationText;
        this._destinationText = v;
        super.notifyFieldChange('destinationText', oldValue, v);
    }
    
    @Expose()
    public get bankBIC(): string {
        return this._bankBIC;
    }

    public set bankBIC(v: string) {
        let oldValue = this._bankBIC;
        this._bankBIC = v;
        super.notifyFieldChange('bankBIC', oldValue, v);
    }
}