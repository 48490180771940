import { OrmModel } from "@natiwi/core/models/model";
import { NatRestRepository, natPlainToClass, natClassToPlain } from "@natiwi/core/network/repositories/rest.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { Observable } from "rxjs";
import { classToPlain, Expose, plainToClassFromExist } from "class-transformer";
import { map } from "rxjs/internal/operators/map";

export abstract class NatModelRepository<T extends OrmModel> extends NatRestRepository<T> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);

        super.createСontractItem('find', '/');
        super.createСontractItem('count', '/count');

    }

    public find(params?: Map<string, any>): Observable<Array<T>> {
        let stringifyParams: Map<string, string> = new Map<string, string>();
        if (params && params.size) {
            params.forEach((value: any, key: string) => {
                let i = natClassToPlain(this.dataClass, value)
                stringifyParams.set(key, i);
            });
        }
        let request: Observable<Array<T>> = this.invokeStaticMethod<Array<T>>('find', stringifyParams);
        return request.pipe(map(response => {
            return natPlainToClass(this.dataClass, response) as Array<T>;
        }));
    }

    public count(params?: Map<string, any>): Observable<CountResponse> {
        let stringifyParams: Map<string, string> = new Map<string, string>();
        if (params && params.size) {
            params.forEach((value: any, key: string) => {
                let i = natClassToPlain(this.dataClass, value)
                stringifyParams.set(key, i);
            });
        }
        let request: Observable<CountResponse> = this.invokeStaticMethod<CountResponse>('count', stringifyParams);
        return request.pipe(map(response => {
            return plainToClassFromExist(new CountResponse(), response) as CountResponse;
        }));
    }
}

export class CountResponse {
    private _count: number;
    constructor() { }

    @Expose()
    public get count(): number {
        return this._count;
    }

    public set count(v: number) {
        this._count = v;
    }


}