import { NatVerbType, NatParameterEncoding } from "@natiwi/core/network/shared/network.enum";

export class NatRestContractItem {
    private _methodName: string;
    private _urlPattern: string;
    private _verbType: NatVerbType;
    private _parameterEncoding: NatParameterEncoding;

    constructor(methodName: string, urlPattern: string, type: NatVerbType = NatVerbType.GET, parameterEncoding: NatParameterEncoding = NatParameterEncoding.JSON) {
        this._methodName = methodName;
        this._urlPattern = urlPattern;
        this._verbType = type;
        this._parameterEncoding = parameterEncoding;
    }

    public get methodName(): string {
        return this._methodName;
    }
    
    public get urlPattern(): string {
        return this._urlPattern;
    }

    public get verbType(): NatVerbType {
        return this._verbType;
    }

    public get parameterEncoding(): NatParameterEncoding {
        return this._parameterEncoding;
    }
}