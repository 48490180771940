import { OrmModel } from "@natiwi/core/models/model";
import { NatRestController } from "@natiwi/core/controllers/rest.controller";
import { NatModelRepository, CountResponse } from "@natiwi/core/network/repositories/model.repository";
import { NatFilter, NatFilterInclude, NatFilterWhere } from "@natiwi/core/network/shared/filter";
import { Observable } from "rxjs";
import { NatAppService } from "@natiwi/core";
import { GlobalInjector } from "@natiwi/core/network/shared/global-variables";
import { NatModelPropertyMetadata } from "@natiwi/core/models/model-metadata";


export abstract class NatModelController<Model extends OrmModel> extends NatRestController<Model> {

    private _helper: NatAppService;

    constructor(_repository: NatModelRepository<Model>) {
        super(_repository);
        this._helper = GlobalInjector.get(NatAppService)
    }

    /**
    * find
    */
    public find(filter?: NatFilter): Observable<Array<Model>> {
        let params: Map<string, any> = new Map<string, any>();
        if (!filter) {
            filter = new NatFilter()
        }
        this.includeRelations(filter);
        params.set('filter', filter);
        let repo: NatModelRepository<Model> = this.getRepository();
        return repo.find(params);
    }
    
    /**
    * count
    */
    public count(filterWhere?: NatFilterWhere): Observable<CountResponse> {
        let params: Map<string, any> = new Map<string, any>();
        if (filterWhere) {
           params.set('where', filterWhere); 
        }
        let repo: NatModelRepository<Model> = this.getRepository();
        return repo.count(params);
    }

    public includeRelations(filter?: NatFilter, deep: number = 0, modelName?: string): NatFilter {

        let metadata: Array<NatModelPropertyMetadata>;
        if (!modelName) {
            metadata = super.modelMetadata.getProperties();
        } else {
            let controller = this._helper.getController(modelName) || console.log(`Контроллер для модели "${modelName}" не найден`);
            metadata = controller && controller.modelMetadata.getProperties() || [];
        }

        metadata.filter(value => value.type === 'relation' || value.type === 'enumeration').forEach(value => {
            let include = new NatFilterInclude(value.propertyName);
            if (deep > 0) {
                // let scopeFilter = new NatFilter();
                include.scope = this.includeRelations(null, deep - 1, value.relationModel || value.enumerationType);
            }
            if (!filter) {
                filter = new NatFilter();
            }
            if (!filter.include.find(element => element.relation === value.propertyName)) {
                filter.include.push(include);
            }
        });

        return filter;
    }
}