import { OrmCatalog } from "@natiwi/core/models/catalog";
import { OrmRegister } from "@natiwi/core/models/register";
import { NatRegisterRepository } from "@natiwi/core/network/repositories/register.repository";
import { NatModelController } from "@natiwi/core/controllers/model.controller";
import { Observable } from "rxjs";

export abstract class NatRegisterController<Model extends OrmRegister> extends NatModelController<Model>{

    constructor(_repository: NatRegisterRepository<Model>) {
        super(_repository);
    }
    
    /**
     * bulkUpsert
     */
    public bulkUpsert(data: Array<Model>): Observable<Array<Model>> {
        let repo: NatRegisterRepository<Model> = this.getRepository();
        return repo.bulkUpsert(data);
    }
}