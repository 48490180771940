import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";
let OrmProductPrice = class OrmProductPrice extends OrmInformationRegister {
    constructor() {
        super();
    }
    get product() {
        return this._product;
    }
    set product(v) {
        this._product = v;
    }
    get price() {
        return this._price;
    }
    set price(v) {
        this._price = v;
    }
    get priceType() {
        return this._priceType;
    }
    set priceType(v) {
        this._priceType = v;
    }
    get unit() {
        return this._unit;
    }
    set unit(v) {
        this._unit = v;
    }
    get feature() {
        return this._feature;
    }
    set feature(v) {
        this._feature = v;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Товар',
        type: 'relation',
        relationModel: 'OrmProduct',
        required: true,
        standart: false
    })
], OrmProductPrice.prototype, "_product", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Цена',
        type: 'number',
        required: true,
        standart: false
    })
], OrmProductPrice.prototype, "_price", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
], OrmProductPrice.prototype, "_priceType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationModel: 'OrmProductUnit',
        required: false,
        standart: false
    })
], OrmProductPrice.prototype, "_unit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Характеристика',
        type: 'relation',
        relationModel: 'OrmProductFeature',
        required: false,
        standart: false
    })
], OrmProductPrice.prototype, "_feature", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProduct)
], OrmProductPrice.prototype, "product", null);
tslib_1.__decorate([
    Expose()
], OrmProductPrice.prototype, "price", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType)
], OrmProductPrice.prototype, "priceType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductUnit)
], OrmProductPrice.prototype, "unit", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductFeature)
], OrmProductPrice.prototype, "feature", null);
OrmProductPrice = tslib_1.__decorate([
    NatModel({
        plural: 'productPrices',
        displayName: 'Цена номенклатуры',
        pluralDisplayName: 'Цены номенклатуры'
    })
], OrmProductPrice);
export { OrmProductPrice };
