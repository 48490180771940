import { Component, OnInit } from '@angular/core';
import { NatMenuBodyComponent } from '@natiwi/shared/layout/menu/main-menu/main-menu-body';
import { MatDialog } from '@angular/material/dialog';
import { NatAuthService } from '@natiwi/core';

interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen: () => void;
}

export function isFullScreen(): boolean {
  const fsDoc = <FsDocument>document;
  return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
}

interface FsDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen: () => void;
}

export function toggleFullScreen(): void {
  const fsDoc = <FsDocument>document;

  if (!isFullScreen()) {
    const fsDocElem = <FsDocumentElement>document.documentElement;

    if (fsDocElem.requestFullscreen)
      fsDocElem.requestFullscreen();
    else if (fsDocElem.msRequestFullscreen)
      fsDocElem.msRequestFullscreen();
    else if (fsDocElem.mozRequestFullScreen)
      fsDocElem.mozRequestFullScreen();
    else if (fsDocElem.webkitRequestFullscreen)
      fsDocElem.webkitRequestFullscreen();
  }
  else if (fsDoc.exitFullscreen)
    fsDoc.exitFullscreen();
  else if (fsDoc.msExitFullscreen)
    fsDoc.msExitFullscreen();
  else if (fsDoc.mozCancelFullScreen)
    fsDoc.mozCancelFullScreen();
  else if (fsDoc.webkitExitFullscreen)
    fsDoc.webkitExitFullscreen();
}

export function setFullScreen(full: boolean): void {
  if (full !== isFullScreen())
    toggleFullScreen();
}

@Component({
  selector: 'nat-header-right-menu',
  templateUrl: './header-right-menu.component.html',
  styleUrls: ['./header-right-menu.component.css']
})
export class NatHeaderRightMenuComponent implements OnInit {

  isFullScreen: boolean;

  constructor(private authService: NatAuthService) { }

  ngOnInit() {
    this.isFullScreen = isFullScreen();
  }

  toggleFullScreen(): void {
    toggleFullScreen();
    this.isFullScreen = !isFullScreen();
    console.log('fullscreen', this.isFullScreen);
  }

  logout(): void {
    this.authService.logout();
  }

}
