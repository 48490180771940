import { NatInformationRegisterController } from "@natiwi/core/controllers/information-register.controller";
import { OrmProductBarcode } from "@natiwi/shared/models/product-barcode.model";
import { NatProductBarcodeRepository } from "@natiwi/shared/repositories/product-barcode.repository";

export class NatProductBarcodeController extends NatInformationRegisterController<OrmProductBarcode>{

    constructor(_repository?: NatProductBarcodeRepository) {
        super(_repository);
        super.registerDataClass(OrmProductBarcode);
    }
}