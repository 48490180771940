import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmInformationRegister } from "@natiwi/core/models/information-register";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";

@NatModel({
    plural: 'productPrices',
    displayName: 'Цена номенклатуры',
    pluralDisplayName: 'Цены номенклатуры'
})
export class OrmProductPrice extends OrmInformationRegister {
    
    @NatProperty({
        displayName: 'Товар',
        type: 'relation',
        relationModel: 'OrmProduct',
        required: true,
        standart: false
    })
    private _product: OrmProduct;
    
    @NatProperty({
        displayName: 'Цена',
        type: 'number',
        required: true,
        standart: false
    })
    private _price: number;
    
    @NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
    private _priceType: OrmPriceType;
    
    @NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationModel: 'OrmProductUnit',
        required: false,
        standart: false
    })
    private _unit: OrmProductUnit;
    
    
    @NatProperty({
        displayName: 'Характеристика',
        type: 'relation',
        relationModel: 'OrmProductFeature',
        required: false,
        standart: false
    })
    private _feature: OrmProductFeature;

    constructor() {
        super();
    }
    
    @Expose()
    @NatRelation(OrmProduct)
    public get product(): OrmProduct {
        return this._product;
    }

    public set product(v: OrmProduct) {
        this._product = v;
    }
    
    @Expose()
    public get price(): number {
        return this._price;
    }

    public set price(v: number) {
        this._price = v;
    }
    
    @Expose()
    @NatRelation(OrmPriceType)
    public get priceType(): OrmPriceType {
        return this._priceType;
    }

    public set priceType(v: OrmPriceType) {
        this._priceType = v;
    }
    
    @Expose()
    @NatRelation(OrmProductUnit)
    public get unit(): OrmProductUnit {
        return this._unit;
    }

    public set unit(v: OrmProductUnit) {
        this._unit = v;
    }
    
    @Expose()
    @NatRelation(OrmProductFeature)
    public get feature(): OrmProductFeature {
        return this._feature;
    }

    public set feature(v: OrmProductFeature) {
        this._feature = v;
    }
}