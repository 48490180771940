import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPaymentOptionArgumentItem } from "@natiwi/shared/models/payment-option-argument-item.model";
import { OrmImageMetadata } from "@natiwi/shared/models/image-metadata";
import { OrmPaymentOptionKind } from "@natiwi/shared/models/payment-option-kind.model";
let OrmPaymentOption = class OrmPaymentOption extends OrmCatalog {
    constructor() {
        super();
        this._ownerType = 'OrmPaymentOptionKind';
    }
    get description() {
        return this._description;
    }
    set description(v) {
        let oldValue = this._description;
        this._description = v;
        super.notifyFieldChange('description', oldValue, v);
    }
    get enabled() {
        return this._enabled;
    }
    set enabled(v) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    get primaryImage() {
        return this._primaryImage;
    }
    set primaryImage(v) {
        let oldValue = this._primaryImage;
        this._primaryImage = v;
        super.notifyFieldChange('primaryImage', oldValue, v);
    }
    get type() {
        return this._type;
    }
    set type(v) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    //
    get argumentItems() {
        return this._argumentItems;
    }
    set argumentItems(v) {
        let oldValue = this._argumentItems;
        this._argumentItems = v;
        super.notifyFieldChange('argumentItems', oldValue, v);
    }
    get argumentItemList() {
        return this._argumentItems;
    }
    set argumentItemList(v) {
        let oldValue = this._argumentItems;
        this._argumentItems = v;
        super.notifyFieldChange('argumentItems', oldValue, v);
    }
    //
    get owner() {
        return this._owner;
    }
    set owner(v) {
        let oldValue = this._owner;
        this._owner = v;
        super.notifyFieldChange('owner', oldValue, v);
    }
    get ownerType() {
        return this._ownerType;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Описание',
        type: 'string',
        required: false,
        standart: false
    })
], OrmPaymentOption.prototype, "_description", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmPaymentOption.prototype, "_enabled", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Основное изображение',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmImageMetadata',
        required: false,
        standart: false
    })
], OrmPaymentOption.prototype, "_primaryImage", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'payment_option_types',
        required: false,
        standart: false
    })
], OrmPaymentOption.prototype, "_type", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Аргументы',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmPaymentOptionKindArgumentItem',
        required: false,
        standart: false
    })
], OrmPaymentOption.prototype, "_argumentItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Владелец',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmPaymentOptionKind',
        required: false,
        standart: false
    })
], OrmPaymentOption.prototype, "_owner", void 0);
tslib_1.__decorate([
    Expose()
], OrmPaymentOption.prototype, "description", null);
tslib_1.__decorate([
    Expose()
], OrmPaymentOption.prototype, "enabled", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmImageMetadata)
], OrmPaymentOption.prototype, "primaryImage", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmPaymentOption.prototype, "type", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPaymentOptionArgumentItem)
], OrmPaymentOption.prototype, "argumentItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPaymentOptionArgumentItem)
], OrmPaymentOption.prototype, "argumentItemList", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPaymentOptionKind)
], OrmPaymentOption.prototype, "owner", null);
tslib_1.__decorate([
    Expose()
], OrmPaymentOption.prototype, "ownerType", null);
OrmPaymentOption = tslib_1.__decorate([
    NatModel({
        plural: 'paymentOptions',
        displayName: 'Способ оплаты',
        pluralDisplayName: 'Способы оплаты'
    }),
    NatHierarchical(false)
], OrmPaymentOption);
export { OrmPaymentOption };
