import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";

@NatModel({
    plural: 'coupons',
    displayName: 'Купон',
    pluralDisplayName: 'Купоны'
})
export class OrmCoupon extends OrmCatalog {

    constructor() {
        super();
    }
}