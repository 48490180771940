import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemePresetSelectedFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-selected-field-item.model";
import { OrmSamplingSchemePresetConditionFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-condition-field-item.model";
import { OrmSamplingSchemePresetParameterFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-parameter-field-item.model";
import { OrmSamplingSchemePresetOrderFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-order-field-item.model";
import { OrmSamplingSchemePresetStructureListFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-structure-list-field-item.model";

@NatModel({
    displayName: 'Вариант настроек схемы выборки данных',
    pluralDisplayName: 'Варианты настроек схемы выборки данных'
})
@NatHierarchical(false)
export class OrmSamplingSchemePresetItem extends OrmObjectItem {
    
    @NatProperty({
        displayName: 'Количество пропускаемых элементов',
        type: 'number',
        required: false,
        standart: false
    })
    private _skip: number;
    
    @NatProperty({
        displayName: 'Максимальное количество элементов в выборке',
        type: 'number',
        required: false,
        standart: false
    })
    private _limit: number;

    @NatProperty({
        displayName: 'Выбранные поля',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetSelectedFieldItem',
        required: false,
        standart: false
    })
    private _selectedFieldItems: Array<OrmSamplingSchemePresetSelectedFieldItem>;

    @NatProperty({
        displayName: 'Условия',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetConditionFieldItem',
        required: false,
        standart: false
    })
    private _conditionFieldItems: Array<OrmSamplingSchemePresetConditionFieldItem>;
    
    @NatProperty({
        displayName: 'Параметры',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetParameterFieldItem',
        required: false,
        standart: false
    })
    private _parameterFieldItems: Array<OrmSamplingSchemePresetParameterFieldItem>;
    
    @NatProperty({
        displayName: 'Сортировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetOrderFieldItem',
        required: false,
        standart: false
    })
    private _orderFieldItems: Array<OrmSamplingSchemePresetOrderFieldItem>;
    
    @NatProperty({
        displayName: 'Группировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetStructureListFieldItem',
        required: false,
        standart: false
    })
    private _structureFieldItems: Array<OrmSamplingSchemePresetStructureListFieldItem>;
    
    constructor() {
        super();
    }
    
    @Expose()
    public get skip(): number {
        return this._skip;
    }

    public set skip(v: number) {
        let oldValue = this._skip;
        this._skip = v;
        super.notifyFieldChange('skip', oldValue, v);
    }
    
    @Expose()
    public get limit(): number {
        return this._limit;
    }

    public set limit(v: number) {
        let oldValue = this._limit;
        this._limit = v;
        super.notifyFieldChange('limit', oldValue, v);
    }
//
    @Expose()
    @NatRelation(OrmSamplingSchemePresetSelectedFieldItem)
    public get selectedFieldItems(): Array<OrmSamplingSchemePresetSelectedFieldItem> {
        return this._selectedFieldItems;
    }

    public set selectedFieldItems(v: Array<OrmSamplingSchemePresetSelectedFieldItem>) {
        let oldValue = this._selectedFieldItems;
        this._selectedFieldItems = v;
        super.notifyFieldChange('selectedFieldItems', oldValue, v);
    }
    @Expose()
    @NatRelation(OrmSamplingSchemePresetSelectedFieldItem)
    public get selectedFieldItemList(): Array<OrmSamplingSchemePresetSelectedFieldItem> {
        return this._selectedFieldItems;
    }

    public set selectedFieldItemList(v: Array<OrmSamplingSchemePresetSelectedFieldItem>) {
        let oldValue = this._selectedFieldItems;
        this._selectedFieldItems = v;
        super.notifyFieldChange('selectedFieldItems', oldValue, v);
    }
    //
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetConditionFieldItem)
    public get conditionFieldItems(): Array<OrmSamplingSchemePresetConditionFieldItem> {
        return this._conditionFieldItems;
    }

    public set conditionFieldItems(v: Array<OrmSamplingSchemePresetConditionFieldItem>) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
    @Expose()
    @NatRelation(OrmSamplingSchemePresetConditionFieldItem)
    public get conditionFieldItemList(): Array<OrmSamplingSchemePresetConditionFieldItem> {
        return this._conditionFieldItems;
    }

    public set conditionFieldItemList(v: Array<OrmSamplingSchemePresetConditionFieldItem>) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
    //
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetParameterFieldItem)
    public get parameterFieldItems(): Array<OrmSamplingSchemePresetParameterFieldItem> {
        return this._parameterFieldItems;
    }

    public set parameterFieldItems(v: Array<OrmSamplingSchemePresetParameterFieldItem>) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
    @Expose()
    @NatRelation(OrmSamplingSchemePresetParameterFieldItem)
    public get parameterFieldItemList(): Array<OrmSamplingSchemePresetParameterFieldItem> {
        return this._parameterFieldItems;
    }

    public set parameterFieldItemList(v: Array<OrmSamplingSchemePresetParameterFieldItem>) {
        let oldValue = this._parameterFieldItems;
        this._parameterFieldItems = v;
        super.notifyFieldChange('parameterFieldItems', oldValue, v);
    }
    //
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetOrderFieldItem)
    public get orderFieldItems(): Array<OrmSamplingSchemePresetOrderFieldItem> {
        return this._orderFieldItems;
    }

    public set orderFieldItems(v: Array<OrmSamplingSchemePresetOrderFieldItem>) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
    @Expose()
    @NatRelation(OrmSamplingSchemePresetOrderFieldItem)
    public get orderFieldItemList(): Array<OrmSamplingSchemePresetOrderFieldItem> {
        return this._orderFieldItems;
    }

    public set orderFieldItemList(v: Array<OrmSamplingSchemePresetOrderFieldItem>) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
    //
    
    @Expose()
    @NatRelation(OrmSamplingSchemePresetStructureListFieldItem)
    public get structureFieldItems(): Array<OrmSamplingSchemePresetStructureListFieldItem> {
        return this._structureFieldItems;
    }

    public set structureFieldItems(v: Array<OrmSamplingSchemePresetStructureListFieldItem>) {
        let oldValue = this._structureFieldItems;
        this._structureFieldItems = v;
        super.notifyFieldChange('structureFieldItems', oldValue, v);
    }
    @Expose()
    @NatRelation(OrmSamplingSchemePresetStructureListFieldItem)
    public get structureFieldItemList(): Array<OrmSamplingSchemePresetStructureListFieldItem> {
        return this._structureFieldItems;
    }

    public set structureFieldItemList(v: Array<OrmSamplingSchemePresetStructureListFieldItem>) {
        let oldValue = this._structureFieldItems;
        this._structureFieldItems = v;
        super.notifyFieldChange('structureFieldItems', oldValue, v);
    }
    //


}