import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";

@NatModel({
    displayName: 'Метаданные значения',
    pluralDisplayName: 'Метаданные значений'
})
@NatHierarchical(false)
export class OrmValueMetadataItem extends OrmObjectItem {
    
    @NatProperty({
        displayName: 'Имя',
        type: 'string',
        required: false,
        standart: false
    })
    private _name: string;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'string',
        required: false,
        standart: false
    })
    private _relationType: string;
    
    @NatProperty({
        displayName: 'Список принимаемых моделей',
        type: 'string',
        required: false,
        standart: false
    })
    private _polymorphicRelationAcceptedModelList: Array<string>;
    
    @NatProperty({
        displayName: 'Обязательный',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _required: boolean;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'value_metadata_types',
        required: false,
        standart: false
    })
    private _type: OrmEnumerationItem;
    
    @NatProperty({
        displayName: 'Тип',
        type: 'string',
        required: false,
        standart: false
    })
    private _typeId: string;
    
    @NatProperty({
        displayName: 'Смысловая нагрузка',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'meanings',
        required: false,
        standart: false
    })
    private _meaning: OrmEnumerationItem;
    
    constructor() {
        super();
    }
    
    @Expose()
    public get name(): string {
        return this._name;
    }

    public set name(v: string) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    
    @Expose()
    public get relationType(): string {
        return this._relationType;
    }

    public set relationType(v: string) {
        let oldValue = this._relationType;
        this._relationType = v;
        super.notifyFieldChange('relationType', oldValue, v);
    }
    
    @Expose()
    public get polymorphicRelationAcceptedModelList(): Array<string> {
        return this._polymorphicRelationAcceptedModelList;
    }

    public set polymorphicRelationAcceptedModelList(v: Array<string>) {
        let oldValue = this._polymorphicRelationAcceptedModelList;
        this._polymorphicRelationAcceptedModelList = v;
        super.notifyFieldChange('polymorphicRelationAcceptedModelList', oldValue, v);
    }
    
    @Expose()
    public get required(): boolean {
        return this._required;
    }

    public set required(v: boolean) {
        let oldValue = this._required;
        this._required = v;
        super.notifyFieldChange('required', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get type(): OrmEnumerationItem {
        return this._type;
    }

    public set type(v: OrmEnumerationItem) {
        let oldValue = this._type;
        this._type = v;
        super.notifyFieldChange('type', oldValue, v);
    }
    
    @Expose()
    public get typeId(): string {
        return this._typeId;
    }

    public set typeId(v: string) {
        let oldValue = this._typeId;
        this._typeId = v;
        super.notifyFieldChange('typeId', oldValue, v);
    }
    
    @Expose()
    @NatRelation(OrmEnumerationItem)
    public get meaning(): OrmEnumerationItem {
        return this._meaning;
    }

    public set meaning(v: OrmEnumerationItem) {
        let oldValue = this._meaning;
        this._meaning = v;
        super.notifyFieldChange('meaning', oldValue, v);
    }
    
     /**
    * toString
    */
    public toString(): string {
        return this._name || '';
    }
}