import { Injectable, Type, Injector } from '@angular/core';
import { NatUserController } from '@natiwi/shared/controllers/user.controller';
import { OrmAccessToken } from '@natiwi/core/models/access-token';
import { Router, RouteReuseStrategy } from '@angular/router';
import { OrmUser } from '@natiwi/core/models/user';
import { Observable, throwError, of } from 'rxjs';
import { NatFilter, NatFilterInclude } from '@natiwi/core/network/shared/filter';
import { NatRouteReuseStrategyService } from '@natiwi/core/services/route-reuse-strategy.service';
import { map } from 'rxjs/internal/operators/map';
import { OrmRoleMapping } from '@natiwi/core/models/role-mapping';
import { plainToClass, classToPlain } from 'class-transformer';
import { Promise } from 'q';
import { NatAppService } from '@natiwi/core/services/app.service';

@Injectable()
export class NatAuthService {

  private _redirectUrl: string;
  private _currentUser: OrmUser;
  private _authToken: OrmAccessToken;
  private _router: Router;
  private _userController: NatUserController;
  private _natRouterReuseStrategy: NatRouteReuseStrategyService;

  constructor(
    private injector: Injector,
    private helper: NatAppService,
    private routerReuseStrategy: RouteReuseStrategy) {
    this._natRouterReuseStrategy = <NatRouteReuseStrategyService>routerReuseStrategy;
    this._userController = helper.getController(OrmUser.prototype);
    this._redirectUrl = '/';

    this._currentUser = this.restoreFromStorage('user', OrmUser);
    this._authToken = this.restoreFromStorage('token', OrmAccessToken);
  }

  public set redirectUrl(v: string) {
    if (v && v.length) {
      this._redirectUrl = v;
    }
  }

  public get router(): Router {
    return this._router;
  }

  /**
   * getAuthToken
   */
  public getAuthToken(): OrmAccessToken {
    return this._authToken;
  }

  /**
   * getCurrentUser
   */
  public getCurrentUser(): OrmUser {
    return this._currentUser;
  }

  /**
   * init
   */
  public init(): Promise<boolean> {
    this._userController = this.helper.getController(OrmUser.prototype);
    this._router = this.injector.get(Router);

    this._currentUser = this.restoreFromStorage('user', OrmUser);
    this._authToken = this.restoreFromStorage('token', OrmAccessToken);

    return Promise<boolean>((resolve, reject) => {
      this.fetchUser(this._currentUser || this._authToken.user).subscribe(result => {
        this.authorize(this._authToken, result);
        return resolve(true);
      }, err => {
        this.logout();
        console.log('fetch user error')
        reject(err);
      });
    });
  }

  private getSettings() {
    //get predefined objects from register controllers
    this.helper.initPredefinedObjects();

    //get all constants
    this.helper.initConstants();
  }

  private authorize(token: OrmAccessToken, user: OrmUser): OrmUser {
    if (!token || !user || !user.roles) {
      throw ('Авторизация отменена: отсутствуют обязательные параметры (токен, пользователь, роли)');
    }
    let isAdmin: boolean = OrmRoleMapping.hasRole(user.roles, 'administrator');
    if (!isAdmin) {
      throw ('Доступ запрещен: отсутствуют права Администратора');
    }
    this._authToken = token;
    this._currentUser = user;
    token.user = null;
    this.saveToStorage('token', token);
    this.saveToStorage('user', user);
    this.getSettings();
    return user;
  }

  private fetchUser(user): Observable<OrmUser> {
    if (!user) {
      return throwError('Параметр "Пользователь(user)" является обязательным');
    }
    if (!user.id) {
      return throwError('Идентификатор пользователя является обязательным');
    }
    let filter: NatFilter = new NatFilter();
    let includePrincipal = new NatFilterInclude('principal');
    let includeRolesScope: NatFilter = new NatFilter();
    let includeRole = new NatFilterInclude('role');
    includeRolesScope.include.push(includeRole);
    let includeRoles = new NatFilterInclude('roles', includeRolesScope);
    filter.include.push(includeRoles, includePrincipal);

    return this._userController.findById(user.id, filter);
  }

  private saveToStorage<T>(key: string, data: T) {
    if (data) {
      let plainData = classToPlain(data, { excludePrefixes: ["_"], enableCircularCheck: true })
      localStorage.setItem(`natiwi-dashboard:${key}`, JSON.stringify(plainData));
    } else {
      localStorage.removeItem(`natiwi-dashboard:${key}`);
    }
  }

  private restoreFromStorage<T>(key: string, type: Type<T>) {
    let raw = localStorage.getItem(`natiwi-dashboard:${key}`);
    if (!raw) {
      return null;
    }
    return plainToClass(type, JSON.parse(raw),
      {
        excludeExtraneousValues: true
      }
    ) as T;
  }

  private removeFromStorage(...keys: Array<string>) {
    keys.forEach(key => {
      localStorage.removeItem(`natiwi-dashboard:${key}`);
    });
  }

  /**
   * login
   */
  public login(email: string, password: string, rememberMe: boolean = false) {
    let token: OrmAccessToken;
    this._userController.login(email, password, true).subscribe(result => {
      token = result;
      this._currentUser = result.user;
      this._authToken = result;
      this.fetchUser(token.user).subscribe(user => {
        this.authorize(token, user);
        this.router.navigate([this._redirectUrl]);
      }, err => {
        throw err;
      });
    });
  }

  /**
   * logout
   */
  public logout() {
    this._authToken = null;
    this._currentUser = null;
    this.removeFromStorage('token', 'user');
    this.router.navigate(['/login']);
    this._natRouterReuseStrategy.deleteAllCachedRoutes()
  }

  /**
   * isAuthorized
   */
  public isAuthorized(): boolean {
    return !!this._authToken && !!this._currentUser;
  }
}