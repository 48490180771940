import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { NatProductKindRepository } from "@natiwi/shared/repositories/product-kind.repository";
import { OrmOrganization } from "@natiwi/shared/models/organization.model";
import { NatOrganizationRepository } from "@natiwi/shared/repositories/organization.repository";

export class NatOrganizationController extends NatCatalogController<OrmOrganization>{

    constructor(_repository?: NatOrganizationRepository) {
        super(_repository);
        super.registerDataClass(OrmOrganization);
    }
}