import { Routes } from '@angular/router';
import { NatAuthGuard } from '@natiwi/auth';
import { NatRootComponent } from '@natiwi/shared/layout/root/root.component';
import { NatOverviewComponent } from '@natiwi/overview';
const ɵ0 = () => import("./../../../product-kinds/product-kind.module.ngfactory").then(mod => mod.NatProductKindModuleNgFactory), ɵ1 = () => import("./../../../products/products.module.ngfactory").then(mod => mod.NatProductsModuleNgFactory), ɵ2 = () => import("./../../../customer-orders/customer-orders.module.ngfactory").then(mod => mod.NatCustomerOrdersModuleNgFactory), ɵ3 = () => import("./../../../warehouses/warehouses.module.ngfactory").then(mod => mod.NatWarehouseModuleNgFactory), ɵ4 = () => import("./../../../price-types/price-types.module.ngfactory").then(mod => mod.NatPriceTypeModuleNgFactory), ɵ5 = () => import("./../../../product-units/product-units.module.ngfactory").then(mod => mod.NatProductUnitModuleNgFactory), ɵ6 = () => import("./../../../product-features/product-feature.module.ngfactory").then(mod => mod.NatProductFeatureModuleNgFactory), ɵ7 = () => import("./../../../partners/partners.module.ngfactory").then(mod => mod.NatPartnerModuleNgFactory), ɵ8 = () => import("./../../../settings/settings.module.ngfactory").then(mod => mod.NatSettingsModuleNgFactory), ɵ9 = () => import("./../../../reports/reports.module.ngfactory").then(mod => mod.NatReportsModuleNgFactory), ɵ10 = () => import("./../../../payment-options/payment-options.module.ngfactory").then(mod => mod.NatPaymentOptionModuleNgFactory);
const mainRoutes = [
    {
        path: '',
        component: NatRootComponent,
        canActivate: [NatAuthGuard],
        canActivateChild: [NatAuthGuard],
        children: [
            {
                path: '',
                component: NatOverviewComponent,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
            },
            {
                path: 'productKinds',
                loadChildren: ɵ0,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'products',
                loadChildren: ɵ1,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'orders',
                loadChildren: ɵ2,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'warehouses',
                loadChildren: ɵ3,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'priceTypes',
                loadChildren: ɵ4,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'productUnits',
                loadChildren: ɵ5,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'productFeatures',
                loadChildren: ɵ6,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'partners',
                loadChildren: ɵ7,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'settings',
                loadChildren: ɵ8,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'reports',
                loadChildren: ɵ9,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            },
            {
                path: 'paymentOptions',
                loadChildren: ɵ10,
                canActivate: [NatAuthGuard],
                canActivateChild: [NatAuthGuard],
                canLoad: [NatAuthGuard]
            }
        ]
    }
];
export class NatRootRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
