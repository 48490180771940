import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { Observable, throwError } from "rxjs";
import { OrmReportPreset } from "@natiwi/shared/models/report-preset.model";
import { NatReportPresetRepository } from "@natiwi/shared/repositories/report-preset.repository";

export class NatReportPresetController extends NatCatalogController<OrmReportPreset>{

    constructor(_repository?: NatReportPresetRepository) {
        super(_repository);
        super.registerDataClass(OrmReportPreset);
    }
}