import { NatCatalogController } from "@natiwi/core/controllers/catalog.controller";
import { OrmProductKind } from "@natiwi/shared/models/product-kind.model";
import { NatProductKindRepository } from "@natiwi/shared/repositories/product-kind.repository";

export class NatProductKindController extends NatCatalogController<OrmProductKind>{

    constructor(_repository?: NatProductKindRepository) {
        super(_repository);
        super.registerDataClass(OrmProductKind);
    }
}