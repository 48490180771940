import { Type, Expose } from "class-transformer";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";

@NatModel({
    plural: 'contactKinds',
    displayName: 'Вид контактной информации',
    pluralDisplayName: 'Виды контактной информации'
})
export class OrmContactKind extends OrmCatalog {

    constructor() {
        super();
    }
}