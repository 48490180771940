import { NatObjectRepository } from "@natiwi/core/network/repositories/object.repository";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { OrmCatalog } from "@natiwi/core/models/catalog";
import { Type } from "@angular/core";

export abstract class NatCatalogRepository<T extends OrmCatalog> extends NatObjectRepository<T> {
    constructor(adapter?: NatRestAdapter) {
        super(adapter);
    }
}
