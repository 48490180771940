import * as tslib_1 from "tslib";
import { NatModel } from "@natiwi/core/decorators/matadata.decorator";
import { OrmArgumentItem } from "@natiwi/core/models/argument-item";
let OrmPaymentOptionArgumentItem = class OrmPaymentOptionArgumentItem extends OrmArgumentItem {
    constructor() {
        super();
    }
};
OrmPaymentOptionArgumentItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Аргумент',
        pluralDisplayName: 'Аргументы'
    })
], OrmPaymentOptionArgumentItem);
export { OrmPaymentOptionArgumentItem };
