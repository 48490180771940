import * as tslib_1 from "tslib";
import { Type, Expose } from "class-transformer";
import { NatModel, NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { OrmUser } from "@natiwi/core/models/user";
const ɵ0 = () => Date, ɵ1 = () => OrmUser;
let OrmAccessToken = class OrmAccessToken extends OrmPersistedModel {
    get ttl() {
        return this._ttl;
    }
    set ttl(v) {
        this._ttl = v;
    }
    get created() {
        return this._created;
    }
    set created(v) {
        this._created = v;
    }
    get userId() {
        return this._userId;
    }
    set userId(v) {
        this._userId = v;
    }
    //relations
    get user() {
        return this._user;
    }
    set user(v) {
        this._user = v;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Время жизни',
        type: 'number',
        required: false,
        standart: false
    })
], OrmAccessToken.prototype, "_ttl", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Дата создания',
        type: 'date',
        required: false,
        standart: false
    })
], OrmAccessToken.prototype, "_created", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Идентификатор пользователя',
        type: 'string',
        required: false,
        standart: false
    })
], OrmAccessToken.prototype, "_userId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Пользователь',
        type: 'relation',
        relationModel: 'OrmUser',
        required: false,
        standart: false
    })
], OrmAccessToken.prototype, "_user", void 0);
tslib_1.__decorate([
    Expose()
], OrmAccessToken.prototype, "ttl", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ0)
], OrmAccessToken.prototype, "created", null);
tslib_1.__decorate([
    Expose()
], OrmAccessToken.prototype, "userId", null);
tslib_1.__decorate([
    Expose(),
    Type(ɵ1)
], OrmAccessToken.prototype, "user", null);
OrmAccessToken = tslib_1.__decorate([
    NatModel({
        plural: 'accessTokens',
        displayName: 'Токен доступа',
        pluralDisplayName: 'Токены доступа'
    })
], OrmAccessToken);
export { OrmAccessToken };
export { ɵ0, ɵ1 };
