import { Type, Expose, Transform } from "class-transformer";
import { NatModel, NatProperty, NatPolymorphicRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { OrmRoleMapping } from "@natiwi/core/models/role-mapping";
import { OrmRole } from "@natiwi/core/models/role";
import { OrmPartner } from "@natiwi/shared/models/partner.model";

@NatModel({
    plural: 'users',
    displayName: 'Пользователь',
    pluralDisplayName: 'Пользователи'
})
export class OrmUser extends OrmPersistedModel {

    @NatProperty({
        displayName: 'Анонимный',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _anonymous: boolean;

    @NatProperty({
        displayName: 'Почта сгенерирована',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _generatedEmail: boolean;

    @NatProperty({
        displayName: 'Сотрудник',
        type: 'boolean',
        required: false,
        standart: false
    })
    private _employee: boolean;

    @NatProperty({
        displayName: 'Роли',
        type: 'array',
        required: false,
        standart: false
    })
    private _roles: Array<OrmRoleMapping>;

    @NatProperty({
        displayName: 'Главная сущность',
        type: 'polymorphicRelation',
        required: false,
        standart: false
    })
    private _principal: OrmPartner;

    @NatProperty({
        displayName: 'Главная сущность тип',
        type: 'string',
        required: false,
        standart: false
    })
    private _principalType: string;

    constructor() {
        super();
    }

    @Expose()
    public get generatedEmail(): boolean {
        return this._generatedEmail;
    }

    public set generatedEmail(v: boolean) {
        this._generatedEmail = v;
    }

    @Expose()
    public get anonymous(): boolean {
        return this._anonymous;
    }

    public set anonymous(v: boolean) {
        this._anonymous = v;
    }

    @Expose()
    public get employee(): boolean {
        return this._employee;
    }

    public set employee(v: boolean) {
        this._employee = v;
    }

    @Expose()
    @Type(() => OrmRoleMapping)
    public get roles(): Array<OrmRoleMapping> {
        return this._roles;
    }

    public set roles(v: Array<OrmRoleMapping>) {
        this._roles = v;
    }

    @Expose()
    @NatPolymorphicRelation('principalType', [OrmPartner])
    public get principal(): OrmPartner {
        return this._principal;
    }

    public set principal(v: OrmPartner) {
        this._principal = v;
    }

    @Expose()
    public get principalType(): string {
        return this._principalType;
    }

    public set principalType(v: string) {
        this._principalType = v;
    }

}