import { Component, OnInit } from '@angular/core';
import { NatAuthService } from '@natiwi/core';
import { Router } from '@angular/router';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NatPropertyErrorStateMatcher } from '@natiwi/shared/form-controls';

@Component({
  selector: 'nat-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class NatLoginComponent implements OnInit {

  private _loginFormGroup: FormGroup;
  private _emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  private _passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  private rememberMeCheckBoxFormControl = new FormControl(true);

  public hidePassword: boolean = true;
  public matcher = new NatPropertyErrorStateMatcher();

  constructor(private authServise: NatAuthService, private formBuilder: FormBuilder) { }

  public get loginFormGroup(): FormGroup {
    return this._loginFormGroup;
  }

  public get emailFormControl(): FormControl {
    return this._emailFormControl;
  }
  
  public get passwordFormControl(): FormControl {
    return this._passwordFormControl;
  }
  
  ngOnInit() {
    this.initLoginForm();
  }

  login() {
    if (this.loginFormGroup.invalid) {
      throw 'Заполните обязательные поля';
    }
    let email: string = this.loginFormGroup.controls['email'].value;
    let password: string = this.loginFormGroup.controls['password'].value;
    let rememberMe: boolean = this.loginFormGroup.controls['rememberMe'].value || false;
    this.authServise.login(email, password, rememberMe);
  }

  // token 'mcw1JLNFNEuyFBDptqIhHZMfkPibY3gzqpyoEXS9rMEXpkPgLyPAomJp1aRbytUN'

  private initLoginForm() {
    this._loginFormGroup = this.formBuilder.group({
      email: this.emailFormControl,
      password: this.passwordFormControl,
      rememberMe: this.rememberMeCheckBoxFormControl
    })
  }

}
