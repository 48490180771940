import { Injectable, Type } from "@angular/core";
import { NatRestAdapter } from "@natiwi/core/network/adapters/rest.adapter";
import { NatDocumentRepository } from "@natiwi/core/network/repositories/document.repository";
import { OrmCustomerOrder } from "@natiwi/shared/models/customer-order.model";

export class NatCustomerOrdersRepository extends NatDocumentRepository<OrmCustomerOrder> {

    constructor(adapter?: NatRestAdapter) {
        super(adapter);
        let repoUrl: string = '/orders';
        let repoModel: Type<OrmCustomerOrder> = OrmCustomerOrder;
        this.init(repoUrl, repoModel);
    }
}