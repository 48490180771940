import { Component, OnInit } from '@angular/core';
import { NatAuthService } from '@natiwi/core';

@Component({
  selector: 'nat-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class NatHeaderComponent implements OnInit {
  
  constructor(){
    
  }

  ngOnInit() {
  }
  
}
