import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { OrmPropertyMetadataItem } from "@natiwi/core/models/property-metadata-item.model";
let OrmSamplingSchemeFieldItem = class OrmSamplingSchemeFieldItem extends OrmPropertyMetadataItem {
    constructor() {
        super();
    }
    get fieldType() {
        return this._fieldType;
    }
    set fieldType(v) {
        let oldValue = this._fieldType;
        this._fieldType = v;
        super.notifyFieldChange('fieldType', oldValue, v);
    }
    get fieldTypeId() {
        return this._fieldTypeId;
    }
    set fieldTypeId(v) {
        let oldValue = this._fieldTypeId;
        this._fieldTypeId = v;
        super.notifyFieldChange('fieldTypeId', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип формы',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'field_types',
        required: false,
        standart: false
    })
], OrmSamplingSchemeFieldItem.prototype, "_fieldType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип формы',
        type: 'string',
        required: false,
        standart: false
    })
], OrmSamplingSchemeFieldItem.prototype, "_fieldTypeId", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmSamplingSchemeFieldItem.prototype, "fieldType", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemeFieldItem.prototype, "fieldTypeId", null);
OrmSamplingSchemeFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Поле',
        pluralDisplayName: 'Поля'
    }),
    NatHierarchical(false)
], OrmSamplingSchemeFieldItem);
export { OrmSamplingSchemeFieldItem };
