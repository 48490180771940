import { OrmMetadata } from "@natiwi/core/models/metadata";
import { NatRestController } from "@natiwi/core/controllers/rest.controller";
import { OrmContainer } from "@natiwi/shared/models/container.model";
import { NatContainerRepository, NatUploadFileMetadata } from "@natiwi/shared/repositories/container.repository";
import { OrmFileMetadata } from "@natiwi/shared/models/file-metadata";
import { Observable } from "rxjs";

export class NatContainerController extends NatRestController<OrmContainer>{

    constructor(_repository: NatContainerRepository) {
        super(_repository);
        super.registerDataClass(OrmContainer);
    }
    
    /**
     * upload
     */
    public upload<T extends OrmMetadata>(container: string, metadata: NatUploadFileMetadata): Observable<T> {
        let repo: NatContainerRepository = this.getRepository();
        return repo.upload<T>(container, metadata);
    }
    
}