import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemePresetOrderFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-preset-order-field-item.model";
let OrmSamplingSchemePresetStructureFieldItem = class OrmSamplingSchemePresetStructureFieldItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get title() {
        return this._title;
    }
    set title(v) {
        let oldValue = this._title;
        this._title = v;
        super.notifyFieldChange('title', oldValue, v);
    }
    get enabled() {
        return this._enabled;
    }
    set enabled(v) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    get orderFieldItems() {
        return this._orderFieldItems;
    }
    set orderFieldItems(v) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
    //
    get orderFieldItemList() {
        return this._orderFieldItems;
    }
    set orderFieldItemList(v) {
        let oldValue = this._orderFieldItems;
        this._orderFieldItems = v;
        super.notifyFieldChange('orderFieldItems', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Заголовок',
        type: 'string',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetStructureFieldItem.prototype, "_title", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetStructureFieldItem.prototype, "_enabled", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Сортировки',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetOrderFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetStructureFieldItem.prototype, "_orderFieldItems", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetStructureFieldItem.prototype, "title", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetStructureFieldItem.prototype, "enabled", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetOrderFieldItem)
], OrmSamplingSchemePresetStructureFieldItem.prototype, "orderFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetOrderFieldItem)
], OrmSamplingSchemePresetStructureFieldItem.prototype, "orderFieldItemList", null);
OrmSamplingSchemePresetStructureFieldItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Поле структуры группировки',
        pluralDisplayName: 'Поля структуры группировки'
    }),
    NatHierarchical(false)
], OrmSamplingSchemePresetStructureFieldItem);
export { OrmSamplingSchemePresetStructureFieldItem };
