import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NatFooterComponent } from '@natiwi/shared/layout/footer/footer.component';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [NatFooterComponent],
  imports: [
    CommonModule,
    MatToolbarModule
  ],
  exports: [NatFooterComponent]
})
export class NatFooterModule { }
