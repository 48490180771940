<mat-sidenav-container class="nat-root-container" [hasBackdrop]="false">
	<mat-sidenav #notifyCenter [mode]="'over'" position="end">
		<div>
			Центр уведомлений
		</div>
	</mat-sidenav>
	<mat-sidenav #rightMenu [mode]="'side'" position="start">Список открытых вкладок</mat-sidenav>
	<mat-sidenav-content class="nat-sidebar-content">
		<!-- Header -->
		<nat-header></nat-header>
		<!-- Content -->
		<!-- <div class="nat-toolbar">
			<mat-toolbar nat-toolbar color="primary">
				<ng-container *ngTemplateOutlet="relation; context:{field: field}"></ng-container>
			</mat-toolbar>
		</div> -->
		<div class="nat-root-content">
			<!-- <div style="text-align: center;">
				<button mat-raised-button (click)="notifyCenter.toggle()">открыть уведомления</button>
				<button mat-raised-button (click)="rightMenu.toggle()">открыть список</button>
			</div> -->
			<div class="container">
				<router-outlet #outlet="outlet" (activate)='onActivate($event, outlet)'></router-outlet>
			</div>
		</div>
		<!-- Footer -->
		<!-- <nat-footer></nat-footer> -->
	</mat-sidenav-content>
</mat-sidenav-container>
