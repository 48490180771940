import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmObject } from "@natiwi/core/models/object";
import { NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
export class OrmCatalog extends OrmObject {
    constructor() {
        super();
    }
    get name() {
        return this._name;
    }
    set name(v) {
        let oldValue = this._name;
        this._name = v;
        super.notifyFieldChange('name', oldValue, v);
    }
    get displayName() {
        return this._displayName;
    }
    set displayName(v) {
        let oldValue = this._displayName;
        this._displayName = v;
        super.notifyFieldChange('displayName', oldValue, v);
    }
    get elementType() {
        return this._elementType;
    }
    set elementType(v) {
        let oldValue = this._elementType;
        this._elementType = v;
        super.notifyFieldChange('elementType', oldValue, v);
    }
    get priority() {
        return this._priority;
    }
    set priority(v) {
        let oldValue = this._priority;
        this._priority = v;
        super.notifyFieldChange('priority', oldValue, v);
    }
    get parent() {
        return this._parent;
    }
    set parent(v) {
        let oldValue = this._parent;
        this._parent = v;
        super.notifyFieldChange('parent', oldValue, v);
    }
    get sequenceNumber() {
        //remove lead nulls
        // let nullMatch = this._sequenceNumber && this._sequenceNumber.match(/^0*/);
        // if (nullMatch) {
        //     let formatedNumber: string = this._sequenceNumber.slice();
        //     formatedNumber = formatedNumber.replace(/^0*/, '');
        //     return formatedNumber;
        // }
        return this._sequenceNumber;
    }
    set sequenceNumber(v) {
        let oldValue = this._sequenceNumber;
        this._sequenceNumber = v;
        super.notifyFieldChange('sequenceNumber', oldValue, v);
    }
    /**
    * toString
    */
    toString() {
        return this._displayName || this._name || '';
    }
}
tslib_1.__decorate([
    NatProperty({
        displayName: 'Наименование',
        type: 'string',
        required: true,
        standart: false
    })
], OrmCatalog.prototype, "_name", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Отображаемое наименование',
        type: 'string',
        required: false,
        standart: true
    })
], OrmCatalog.prototype, "_displayName", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип элемента',
        type: 'number',
        required: false
    })
], OrmCatalog.prototype, "_elementType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Приоритет',
        type: 'number',
        required: false
    })
], OrmCatalog.prototype, "_priority", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Родитель',
        type: 'relation',
        relationType: 'belongsTo',
        relationModel: 'OrmCatalog',
        required: false,
        standart: false
    })
], OrmCatalog.prototype, "_parent", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Номер',
        type: 'string',
        required: false,
        standart: true
    })
], OrmCatalog.prototype, "_sequenceNumber", void 0);
tslib_1.__decorate([
    Expose()
], OrmCatalog.prototype, "name", null);
tslib_1.__decorate([
    Expose()
], OrmCatalog.prototype, "displayName", null);
tslib_1.__decorate([
    Expose()
], OrmCatalog.prototype, "elementType", null);
tslib_1.__decorate([
    Expose()
], OrmCatalog.prototype, "priority", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmCatalog)
], OrmCatalog.prototype, "parent", null);
tslib_1.__decorate([
    Expose()
], OrmCatalog.prototype, "sequenceNumber", null);
