<mat-toolbar class="nat-toolbar" style="background-color:#ffffff00;" role="heading">
	<button mat-icon-button color="accent" aria-label="Закрыть меню" (click)="closeMenu()">
        <mat-icon>clear</mat-icon>
    </button>
	<span class="nat-toolbar-header">Меню</span>
</mat-toolbar>

<div class="container">
	<div class="items">
		<div class="item">
			<div class="title">
				<h3>Продажи</h3>
			</div>
			<div class="content">
				<a mat-button routerLink="/orders" queryParamsHandling="preserv">Заказы</a>
				<a mat-button routerLink="/orders/new">Создать заказ</a>
			</div>
		</div>
		<div class="item">
			<div class="title">
				<h3>Справочники</h3>
			</div>
			<div class="content">
				<a mat-button routerLink="/partners" queryParamsHandling="preserv">Контрагенты</a>
				<a mat-button routerLink="/products" queryParamsHandling="preserv">Номенклатура</a>
				<a mat-button routerLink="/productKinds" queryParamsHandling="preserv">Виды номенклатуры</a>
				<a mat-button routerLink="/productUnits" queryParamsHandling="preserv">Единицы измерений</a>
				<a mat-button routerLink="/productFeatures" queryParamsHandling="preserv">Характеристики</a>
				<a mat-button routerLink="/priceTypes" queryParamsHandling="preserv">Типы цен</a>
				<a mat-button routerLink="/warehouses" queryParamsHandling="preserv">Склады</a>
			</div>
		</div>
		<div class="item">
			<div class="title">
				<h3>Отчеты</h3>
			</div>
			<div class="content">
				<a mat-button routerLink="/reports" queryParamsHandling="preserv">Все отчеты</a>
			</div>
		</div>
		<div class="item">
			<div class="title">
				<h3>Настройки</h3>
			</div>
			<div class="content">
				<a mat-button routerLink="/settings/trade">Торговля</a>
				<a mat-button routerLink="/settings/web">Интернет-магазин</a>
				<a mat-button routerLink="/paymentOptions" queryParamsHandling="preserv">Способы оплаты</a>
			</div>
		</div>


	</div>
</div>
