import * as tslib_1 from "tslib";
import { NatModel } from "@natiwi/core/decorators/matadata.decorator";
import { OrmCatalog } from "@natiwi/core/models/catalog";
let OrmMetadata = class OrmMetadata extends OrmCatalog {
    // @NatProperty({
    //     displayName: 'Контейнер',
    //     type: 'OrmContainerMetadata',
    //     required: true,
    //     standart: false
    // })
    // private _container: OrmContainerMetadata;
    constructor() {
        super();
    }
};
OrmMetadata = tslib_1.__decorate([
    NatModel({
        displayName: 'Метаданные файла',
        pluralDisplayName: 'Метаданные файлов'
    })
], OrmMetadata);
export { OrmMetadata };
