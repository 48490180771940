import { OrmModel } from "@natiwi/core/models/model";
import { NatProperty } from "@natiwi/core/decorators/matadata.decorator";
import { Expose } from "class-transformer";
import { Type } from "@angular/core";
import * as uuid from 'uuid';

export abstract class OrmObjectItem extends OrmModel {
    
    private _id: string;
    
    constructor() {
        super();
        this._id = uuid.v4();
    }
    @NatProperty({
        displayName: 'Идентификатор',
        type: 'string',
        required: true,
        standart: false
    })
    
    @Expose()
    public get id() : string {
        return this._id;
    }
    
    public set id(v : string) {
        let oldValue = this._id;
        if (v) {
            this._id = v;
        } else {
            this._id = null;
        }
        super.notifyFieldChange('id', oldValue, this._id);
    }
    
    
}