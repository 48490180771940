import { NatMetadataController } from "@natiwi/core/controllers/metadata.controller";
import { OrmContainerMetadata } from "@natiwi/shared/models/container-metadata";
import { NatContainerMetadataRepository } from "@natiwi/shared/repositories/container-metadata.repository";

export class NatContainerMetadataController extends NatMetadataController<OrmContainerMetadata>{

    constructor(_repository: NatContainerMetadataRepository) {
        super(_repository);
        super.registerDataClass(OrmContainerMetadata);
    }
}