var OrmSamplingSchemePresetConditionFieldItem_1;
import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { NatModel, NatProperty, NatHierarchical, NatRelation, NatPolymorphicProperty } from "@natiwi/core/decorators/matadata.decorator";
import { OrmObjectItem } from "@natiwi/core/models/object-item";
import { OrmSamplingSchemeFieldItem } from "@natiwi/core/models/sampling-scheme/sampling-scheme-field-item.model";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
let OrmSamplingSchemePresetConditionFieldItem = OrmSamplingSchemePresetConditionFieldItem_1 = class OrmSamplingSchemePresetConditionFieldItem extends OrmObjectItem {
    constructor() {
        super();
    }
    get elementType() {
        return this._elementType;
    }
    set elementType(v) {
        let oldValue = this._elementType;
        this._elementType = v;
        super.notifyFieldChange('elementType', oldValue, v);
    }
    get enabled() {
        return this._enabled;
    }
    set enabled(v) {
        let oldValue = this._enabled;
        this._enabled = v;
        super.notifyFieldChange('enabled', oldValue, v);
    }
    get valueId() {
        return this._valueId;
    }
    set valueId(v) {
        this._valueId = v;
    }
    get valueType() {
        return this._valueType;
    }
    set valueType(v) {
        this._valueType = v;
    }
    get comparisonType() {
        return this._comparisonType;
    }
    set comparisonType(v) {
        let oldValue = this._comparisonType;
        this._comparisonType = v;
        super.notifyFieldChange('comparisonType', oldValue, v);
    }
    get fieldType() {
        return this._fieldType;
    }
    set fieldType(v) {
        let oldValue = this._fieldType;
        this._fieldType = v;
        super.notifyFieldChange('fieldType', oldValue, v);
    }
    //
    get field() {
        return this._field;
    }
    set field(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    get fieldValue() {
        return this._field;
    }
    set fieldValue(v) {
        let oldValue = this._field;
        this._field = v;
        super.notifyFieldChange('field', oldValue, v);
    }
    //
    get conditionFieldItems() {
        return this._conditionFieldItems;
    }
    set conditionFieldItems(v) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
    get conditionFieldItemList() {
        return this._conditionFieldItems;
    }
    set conditionFieldItemList(v) {
        let oldValue = this._conditionFieldItems;
        this._conditionFieldItems = v;
        super.notifyFieldChange('conditionFieldItems', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип элемента',
        type: 'number',
        required: true,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_elementType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Используется',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_enabled", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Значение',
        type: 'polymorphicProperty',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_valueId", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип значения',
        type: 'string',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_valueType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип сравнения',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'comparison_types',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_comparisonType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип условия',
        type: 'enumeration',
        enumerationType: 'OrmEnumerationItem',
        enumerationId: 'condition_types',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_fieldType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Поле',
        type: 'relation',
        relationType: 'embedsOne',
        relationModel: 'OrmSamplingSchemeFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_field", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Условия',
        type: 'relation',
        relationType: 'embedsMany',
        relationModel: 'OrmSamplingSchemePresetConditionFieldItem',
        required: false,
        standart: false
    })
], OrmSamplingSchemePresetConditionFieldItem.prototype, "_conditionFieldItems", void 0);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetConditionFieldItem.prototype, "elementType", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetConditionFieldItem.prototype, "enabled", null);
tslib_1.__decorate([
    Expose(),
    NatPolymorphicProperty('valueType')
], OrmSamplingSchemePresetConditionFieldItem.prototype, "valueId", null);
tslib_1.__decorate([
    Expose()
], OrmSamplingSchemePresetConditionFieldItem.prototype, "valueType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmSamplingSchemePresetConditionFieldItem.prototype, "comparisonType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmEnumerationItem)
], OrmSamplingSchemePresetConditionFieldItem.prototype, "fieldType", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemePresetConditionFieldItem.prototype, "field", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemeFieldItem)
], OrmSamplingSchemePresetConditionFieldItem.prototype, "fieldValue", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetConditionFieldItem_1)
], OrmSamplingSchemePresetConditionFieldItem.prototype, "conditionFieldItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSamplingSchemePresetConditionFieldItem_1)
], OrmSamplingSchemePresetConditionFieldItem.prototype, "conditionFieldItemList", null);
OrmSamplingSchemePresetConditionFieldItem = OrmSamplingSchemePresetConditionFieldItem_1 = tslib_1.__decorate([
    NatModel({
        displayName: 'Отображаемое поле',
        pluralDisplayName: 'Отображаемые поля'
    }),
    NatHierarchical(false)
], OrmSamplingSchemePresetConditionFieldItem);
export { OrmSamplingSchemePresetConditionFieldItem };
