import * as tslib_1 from "tslib";
import { Expose } from "class-transformer";
import { OrmDocument } from "@natiwi/core/models/document";
import { NatModel, NatProperty, NatRelation } from "@natiwi/core/decorators/matadata.decorator";
import { OrmOrganization } from "@natiwi/shared/models/organization.model";
import { OrmDocumentItem } from "@natiwi/core/models/document-item";
import { OrmProduct } from "@natiwi/shared/models/product.model";
import { OrmProductUnit } from "@natiwi/shared/models/product-unit.model";
import { OrmPriceType } from "@natiwi/shared/models/price-type.model";
import { OrmProductFeature } from "@natiwi/shared/models/product-feature.model";
let OrmSettingPricesOfProductsProductItem = class OrmSettingPricesOfProductsProductItem extends OrmDocumentItem {
    constructor() {
        super();
    }
    get product() {
        if (!this._product) {
            this._product = new OrmProduct();
        }
        return this._product;
    }
    set product(v) {
        let oldValue = this._product;
        this._product = v;
        super.notifyFieldChange('product', oldValue, v);
    }
    get feature() {
        if (!this._feature) {
            this._feature = null;
        }
        return this._feature;
    }
    set feature(v) {
        let oldValue = this._feature;
        this._feature = v;
        super.notifyFieldChange('feature', oldValue, v);
    }
    get unit() {
        if (!this._unit) {
            this._unit = new OrmProductUnit();
        }
        return this._unit;
    }
    set unit(v) {
        let oldValue = this._unit;
        this._unit = v;
        super.notifyFieldChange('unit', oldValue, v);
    }
    get priceType() {
        if (!this._priceType) {
            this._priceType = new OrmPriceType();
        }
        return this._priceType;
    }
    set priceType(v) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }
    get price() {
        return this._price;
    }
    set price(v) {
        let oldValue = this._price;
        this._price = v;
        super.notifyFieldChange('price', oldValue, v);
    }
    get priceChangedManually() {
        return !!this._priceChangedManually;
    }
    set priceChangedManually(v) {
        let oldValue = this._priceChangedManually;
        this._priceChangedManually = v;
        super.notifyFieldChange('priceChangedManually', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Товар',
        type: 'relation',
        relationModel: 'OrmProduct',
        required: true,
        standart: false
    })
], OrmSettingPricesOfProductsProductItem.prototype, "_product", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Характеристика',
        type: 'relation',
        relationModel: 'OrmProductFeature',
        required: false,
        standart: false
    })
], OrmSettingPricesOfProductsProductItem.prototype, "_feature", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Единица измерения',
        type: 'relation',
        relationModel: 'OrmProductUnit',
        required: true,
        standart: false
    })
], OrmSettingPricesOfProductsProductItem.prototype, "_unit", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
], OrmSettingPricesOfProductsProductItem.prototype, "_priceType", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Цена',
        type: 'number',
        required: true,
        standart: false
    })
], OrmSettingPricesOfProductsProductItem.prototype, "_price", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Цена изменена вручную',
        type: 'boolean',
        required: false,
        standart: false
    })
], OrmSettingPricesOfProductsProductItem.prototype, "_priceChangedManually", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProduct)
], OrmSettingPricesOfProductsProductItem.prototype, "product", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductFeature)
], OrmSettingPricesOfProductsProductItem.prototype, "feature", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmProductUnit)
], OrmSettingPricesOfProductsProductItem.prototype, "unit", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType)
], OrmSettingPricesOfProductsProductItem.prototype, "priceType", null);
tslib_1.__decorate([
    Expose()
], OrmSettingPricesOfProductsProductItem.prototype, "price", null);
tslib_1.__decorate([
    Expose()
], OrmSettingPricesOfProductsProductItem.prototype, "priceChangedManually", null);
OrmSettingPricesOfProductsProductItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Товар',
        pluralDisplayName: 'Товары'
    })
], OrmSettingPricesOfProductsProductItem);
export { OrmSettingPricesOfProductsProductItem };
let OrmSettingPricesOfProductsPriceTypeItem = class OrmSettingPricesOfProductsPriceTypeItem extends OrmDocumentItem {
    constructor() {
        super();
    }
    get priceType() {
        if (!this._priceType) {
            this._priceType = new OrmPriceType();
        }
        return this._priceType;
    }
    set priceType(v) {
        let oldValue = this._priceType;
        this._priceType = v;
        super.notifyFieldChange('priceType', oldValue, v);
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Тип цены',
        type: 'relation',
        relationModel: 'OrmPriceType',
        required: true,
        standart: false
    })
], OrmSettingPricesOfProductsPriceTypeItem.prototype, "_priceType", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmPriceType)
], OrmSettingPricesOfProductsPriceTypeItem.prototype, "priceType", null);
OrmSettingPricesOfProductsPriceTypeItem = tslib_1.__decorate([
    NatModel({
        displayName: 'Использованный тип цены',
        pluralDisplayName: 'Использованные типы цен'
    })
], OrmSettingPricesOfProductsPriceTypeItem);
export { OrmSettingPricesOfProductsPriceTypeItem };
let OrmSettingPricesOfProducts = class OrmSettingPricesOfProducts extends OrmDocument {
    constructor() {
        super();
    }
    get organization() {
        if (!this._organization) {
            this._organization = new OrmOrganization();
        }
        return this._organization;
    }
    set organization(v) {
        let oldValue = this._organization;
        this._organization = v;
        super.notifyFieldChange('organization', oldValue, v);
    }
    // @Expose()
    get productItems() {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }
    set productItems(v) {
        let oldValue = this._productItems;
        this._productItems = v;
        super.notifyFieldChange('productItems', oldValue, v);
    }
    get productItemList() {
        if (!this._productItems) {
            this._productItems = new Array();
        }
        return this._productItems;
    }
    // @Expose()
    get priceTypeItems() {
        if (!this._priceTypeItems) {
            this._priceTypeItems = new Array();
        }
        return this._priceTypeItems;
    }
    set priceTypeItems(v) {
        let oldValue = this._priceTypeItems;
        this._priceTypeItems = v;
        super.notifyFieldChange('priceTypeItems', oldValue, v);
    }
    get priceTypeItemList() {
        if (!this._priceTypeItems) {
            this._priceTypeItems = new Array();
        }
        return this._priceTypeItems;
    }
};
tslib_1.__decorate([
    NatProperty({
        displayName: 'Организация',
        type: 'relation',
        relationModel: 'OrmOrganization',
        required: false,
        standart: false
    })
], OrmSettingPricesOfProducts.prototype, "_organization", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Список товаров',
        type: 'relation',
        relationModel: 'OrmSettingPricesOfProductsProductItem',
        required: false,
        standart: false
    })
], OrmSettingPricesOfProducts.prototype, "_productItems", void 0);
tslib_1.__decorate([
    NatProperty({
        displayName: 'Список использованных типов цен',
        type: 'relation',
        relationModel: 'OrmSettingPricesOfProductsPriceTypeItem',
        required: false,
        standart: false
    })
], OrmSettingPricesOfProducts.prototype, "_priceTypeItems", void 0);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmOrganization)
], OrmSettingPricesOfProducts.prototype, "organization", null);
tslib_1.__decorate([
    NatRelation(OrmSettingPricesOfProductsProductItem)
], OrmSettingPricesOfProducts.prototype, "productItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSettingPricesOfProductsProductItem)
], OrmSettingPricesOfProducts.prototype, "productItemList", null);
tslib_1.__decorate([
    NatRelation(OrmSettingPricesOfProductsPriceTypeItem)
], OrmSettingPricesOfProducts.prototype, "priceTypeItems", null);
tslib_1.__decorate([
    Expose(),
    NatRelation(OrmSettingPricesOfProductsPriceTypeItem)
], OrmSettingPricesOfProducts.prototype, "priceTypeItemList", null);
OrmSettingPricesOfProducts = tslib_1.__decorate([
    NatModel({
        plural: 'settingPricesOfProducts',
        displayName: 'Установка цен на товары',
        pluralDisplayName: 'Установки цен на товары'
    })
], OrmSettingPricesOfProducts);
export { OrmSettingPricesOfProducts };
