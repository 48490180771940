import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NatMainMenuComponent } from '@natiwi/shared/layout/menu/main-menu/main-menu.component';
import { NatMenuBodyComponent } from '@natiwi/shared/layout/menu/main-menu/main-menu-body';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [NatMainMenuComponent, NatMenuBodyComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule
  ],
  exports: [NatMainMenuComponent],
  entryComponents:[NatMenuBodyComponent]
})
export class NatMainMenuModule {
  
  
}
