import { NatCatalogRepository } from "@natiwi/core/network/repositories/catalog.repository";
import { OrmDocument } from "@natiwi/core/models/document";
import { NatDocumentRepository } from "@natiwi/core/network/repositories/document.repository";
import { Observable } from "rxjs";
import { NatObjectController } from "@natiwi/core/controllers/object.controller";

export abstract class NatDocumentController<Model extends OrmDocument> extends NatObjectController<Model>{

    constructor(_repository: NatDocumentRepository<Model>) {
        super(_repository);
    }
    
    /**
     * makePosted
     */
    public makePosted(mode:string, object: Model): Observable<Model> {
        let repo: NatDocumentRepository<Model> = this.getRepository();        
        return repo.makePosted(mode, object);
    }
    
}