import { NatFormControlHost } from "@natiwi/shared/form-controls/form-control-hosts/form-control.host";
import { NatValueObservable, NatModelObservable } from "@natiwi/core/models/observable";
import { NatValueObserver, NatKeyValueObserver } from "@natiwi/core/models/observer";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NatFilter, NatFilterWhere, NatFilterOperatorAnd, NatFilterOperatorNeq, NatFilterOperatorRegExp } from "@natiwi/core/network/shared/filter";
import { NatPersistedModelController } from "@natiwi/core/controllers/persisted-model.controller";
import { OrmPersistedModel } from "@natiwi/core/models/persisted-model";
import { NatAppService } from "@natiwi/core";
import { BehaviorSubject } from "rxjs";
import { plainToClass } from "class-transformer";
import { Type } from "@angular/core";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { NatFormControlHostConfigure } from "@natiwi/core/forms/form";
import { OrmModel } from "@natiwi/core/models/model";
import { NatModelController } from "@natiwi/core/controllers/model.controller";
import { OrmEnumerationItem } from "@natiwi/shared/models/enumeration-item.model";
import { debounceTime } from "rxjs/internal/operators/debounceTime";

export class NatRelationFormControlHost<ValueType extends OrmModel, ObjectType extends OrmModel, ObservableType extends NatModelObservable<ValueType, ObjectType>> extends NatFormControlHost<ValueType, ObjectType, ObservableType> {


    protected _autocompleteOptions: BehaviorSubject<Array<ValueType>>;
    protected _controller: NatModelController<ValueType>;
    protected _filter: NatFilter;

    public isLoading: boolean;

    constructor(private _modelName: string, private _appService: NatAppService, private _controlName: string, private _formControl: FormControl,
        private _formGroup: FormGroup, private _observable: ObservableType, private _configuration?: NatFormControlHostConfigure, private _observer?: NatKeyValueObserver<ValueType, ObjectType>) {
        super(_controlName, _formControl, _formGroup, _observable, _configuration, _observer);
        this.configure();
    }

    public get autocompleteOptions(): BehaviorSubject<Array<ValueType>> {
        return this._autocompleteOptions;
    }

    private configure() {
        let model: any;
        let relationObject: ValueType;
        let controller: NatModelController<ValueType>;
        let autocompleteOptionValue = this._observable.get();
        
        this.isLoading = false;

        relationObject = this._observable.get();
        model = this._appService.getModel(this._modelName); //relationObject && relationObject.constructor.prototype || 
        if (!model) {
            return;
            //throw 'Не удалось получить модель объекта элемента управления'
        }
        controller = this._appService.getController(model);
        if (!controller) {
            //throw 'Не удалось получить контролеер объекта элемента управления'
            return;
        }
        this._controller = controller;
        this._autocompleteOptions = new BehaviorSubject((autocompleteOptionValue) ? [autocompleteOptionValue] : []);
        this._filter = new NatFilter();
        this._formGroup.addControl(this._controlName, this._formControl);
        this._formControl.valueChanges.subscribe(value => {
            this.controlValueChangedHandler(value)
        });
    }

    protected controlValueChangedHandler(value: ValueType) {
        if (this._baseFilterFn) {
            let filter = this._baseFilterFn(this._formObject);
            if (filter) {
                this._filter = filter
            }
        }
        let relationObject = this._observable.get();
        let filter = new NatFilter();
        let deletedCondition = new NatFilterWhere();
        let andOperator = new NatFilterOperatorAnd();
        let neqOperator = new NatFilterOperatorNeq();
        andOperator.push(deletedCondition);
        filter.where.set('and', andOperator);
        filter.limit = 50;
        if (relationObject) {
            if ((relationObject instanceof OrmPersistedModel || relationObject instanceof OrmEnumerationItem) && relationObject.id) {
                let id = relationObject.id;
                neqOperator.set('id', id);
                andOperator.push(neqOperator);
            }
        }
        if (typeof value === 'string') {
            this.isLoading = true;
            if (value !== '') {
                let nameCondition = new NatFilterWhere();
                let regexpOperator = new NatFilterOperatorRegExp();
                regexpOperator.set(value);
                nameCondition.set('name', regexpOperator);
                andOperator.push(nameCondition);
            }
            filter = this._filter.merge(filter);
            this._controller.find(filter).subscribe(result => {
                if ((relationObject instanceof OrmPersistedModel || relationObject instanceof OrmEnumerationItem) && relationObject.id) {
                    result.unshift(relationObject);
                }
                this.isLoading = false;
                return this._autocompleteOptions.next(result);
            });
        } else {
            if (relationObject !== value) {
                console.log('set value to model from relation')
                this._observable.set(value);
            }
            this._formControl.markAsDirty();
            this._formControl.markAsTouched();
        }
    }

    public opened(autocomplete) {
        this._formControl.setValue('');
    }

    public closed(autocomplete) {
        let value = this._formControl.value;
        if (typeof value === 'string') {
            this._formControl.setValue(this._observable.get());
            this._formControl.markAsDirty();
            this._formControl.markAsTouched();
        }
        this._autocompleteOptions.next([]);
    }

    public focus() {
        if (!this._autocompleteOptions.getValue().length) {
            this._formControl.setValue('');
        }
        console.log('focused')
    }

    public blur() {

    }

    static create<ValueType extends OrmModel, ObjectType extends OrmModel, ObservableType extends NatModelObservable<ValueType, ObjectType>>(modelName: string, appService: NatAppService, controlName: string,
        formGroup: FormGroup, observable: ObservableType, configuration?: NatFormControlHostConfigure, observer?: NatKeyValueObserver<ValueType, ObjectType>): NatRelationFormControlHost<ValueType, ObjectType, ObservableType> {

        if (!appService || !controlName || !observable || !modelName) {
            throw 'Недостаточно данных для создания элемента управления'
        }

        if (configuration && !(configuration instanceof NatFormControlHostConfigure)) {
            throw 'Неверный тип объекта конфигурации.'
        }

        let state = observable.get();
        if (!state) {
            state = null;
        }
        let formControl = new FormControl(state, { updateOn: 'change' });
        let host = new NatRelationFormControlHost(modelName, appService, controlName, formControl, formGroup, observable, configuration, observer) as NatRelationFormControlHost<ValueType, ObjectType, ObservableType>;
        return host;
    }

}